import React, { useContext, useEffect, useState } from "react";
import {  Box,  Button,  Checkbox,  CircularProgress,  Dialog,  DialogContent,  FormControl,  FormControlLabel,  FormGroup,  IconButton, 
   Card,  CardContent,  InputLabel,  MenuItem,  Select,  TextField,  Typography,  Fab, List, ListItem, ListItemText, Container, 
    DialogActions,  Tabs,  Tab,  Autocomplete,  Divider,  Chip} from "@mui/material";
    import { ArrowBack, Remove, Add, Delete, Discount, ArrowForward } from "@mui/icons-material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";
import Cart from "../newCart";
import AddOnProductsScreen from "../addOns";
import OrderForm from "../selectionScreen";
import SelectParty from "../selectParty";
const isMobile = window.innerWidth < 900;
// const StyledDialog = styled(Dialog)`
//   padding:10px;
//   position: relative;
//   width:150px;
// `;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 65%;
    height: 100vh;
    max-width: none;
    max-height: none;
  }
`;

const TabContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddOrderModal = ({ props, onClose1, onClose, remarks, dataProp, id, distributorId, fetchData, status,editMode,partyS }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [payload,setPayload]=useState({})
  const [getSeriesCategory, setgetSeriesCategory] = useState()
  const [cart, setCart] = useState([]);
  const [addOncart, setAddOnCart] = useState([]);
  const [className, setClassName] = useState()
  const [filteredClassName, setFilteredClassName] = useState()
  const [medium, setMedium] = useState()
  const [partyType,setPartyType]=useState(partyS)
  const [allProducts, setAllProducts] = useState([]);
  const [selectedParty,setSelectedParty]=useState();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [owner,setOwner]=useState("")
  const [user,setUSer]=useState(JSON.parse(localStorage.getItem('storedUserData')).user)

  const [productData, setProductData] = useState({
    assignTo: "",
    orderType: "",
    productGroup: "",
    series: "",
    class: "",
    medium: "",
    product: "",
    quantity: "",
  });


  const handleQuantityChange = (index, delta) => {


   let temp=[...cart]
    
    temp[index].quantity=Math.max(1, temp[index].quantity + delta)
     setCart(temp)
  };

  const handleRemoveItem = (index) => {
    
  
    let temp=[...cart]
    temp.splice(index,1)
    console.log(temp)
    setCart(temp)
  };

  function closePopup(){
    fetchData()
    onClose1()
  }

  useEffect(() => {
    getSeriesCategoryData()
    getClass()
    getMedium()
    fetchInitialData();
  }, [])

 


  useEffect(() => {
    filterProducts();
  }, [productData.class]);

  const fetchInitialData = async () => {
    const seriesResults = await window.Platform.database.getSeriesCategory();
    setgetSeriesCategory(seriesResults.data);

    const classResults = await window.Platform.database.getClass();
    const classData = classResults?.data?.map((obj, index) => ({
      ...obj,
      id: index + 1,
    }));
    setClassName(classData);

    const mediumResults = await window.Platform.database.getMedium();
    const filteredMedium = mediumResults?.data?.filter(
      (medium) => medium.mediumTableId !== "buCiAvMirI"
    );
    setMedium(filteredMedium);

    const productResults = await window.Platform.database.fetchProduct();
    const productData = productResults?.data?.map((obj, index) => ({
      ...obj,
      id: index + 1,
    }));
    if(editMode){
      console.log(dataProp)
      
      let obj={
        orderType:dataProp.orderType,
        productGroup:"CttetdmeoW"
      }

      setProductData(obj)
      editProductData(dataProp?.orderId,productData)
    }
    setAllProducts(productData);
    
  };
 





 

  const getSeriesCategoryData = async (data) => {
    let results = await window.Platform.database.getSeriesCategory();
    setgetSeriesCategory(results.data)
  }

  const getClass = async (data) => {
    const results = await window.Platform.database.getClass(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setClassName(jsonArrayWithId)
  }

  const getMedium = async (data) => {
    let results = await window.Platform.database.getMedium();
    const finalData = results?.data?.filter(medium => medium.mediumTableId != "buCiAvMirI")
    setMedium(finalData)
  }

  const filterProducts = () => {
    const filtered = allProducts.filter((product) => {
      const matchesSeries =
        product.seriesCategory === productData.series;
      const matchesClass =
        !productData.class || product.classId === productData.class;
      const matchesMedium =
        !productData.medium || product.mediumTableId === productData.medium;

      return matchesSeries && matchesClass && matchesMedium;
    });
    setFilteredProducts(filtered);
  };

  async function editProductData(val,all){
   try{
    
    let sentData = { id: val }
    let d=JSON.parse(dataProp.seriesDiscount)
    let results = await window.Platform.database.fetchOrderLineItem(sentData);
    console.log(results.data);
    let temp=[...cart]
    results.data.forEach((ele)=>{
      let p=all.filter((s)=>{return s.skuId==ele.itemId})[0]
      if(p){
        if (Object.keys(d).includes(p.seriesCategory)){
          p.discount=d[p.seriesCategory]
        }else{
          p.discount=0
        }
        temp.push(  

          {
            data: p,
            group: ele.productGroup,
            quantity :parseInt(ele?.QTY, 10),
            orderType: p['type']=='set'?'Choose from Set':'Choose from Individual Book',
          })
      }
    })
    setCart(temp)
  }catch(e){

  }
}
  
const handleAddProductToCart = (productData) => {
 
  const selectedProduct = filteredProducts.find(
    (product) => product.skuId === productData.product
  );
console.log(selectedProduct)
  
  if (selectedProduct && productData.quantity) {
    let temp=[...cart]
    let flag=false;
    let index
    for (let i=0;i<temp.length;i++){
       if(temp[i].data.skuId==selectedProduct.skuId){
        flag=true;
        index=i
        break;
       }
    }
if(!flag){
   temp.push(  
      {
        data: selectedProduct,
        group: productData?.productGroup,
        quantity :parseInt(productData?.quantity, 10),
        orderType: selectedProduct['type']=='set'?'Choose from Set':'Choose from Individual Book',
      })
}else{
temp[index]['quantity']+=parseInt(productData?.quantity, 10)
}
setCart(temp)
    setProductData({ ...productData, product: "", quantity: "" });
    window.NotificationUtils.showSuccess("Product added successfully");
  } else {
    window.NotificationUtils.showError("Please select a product and quantity");
  }
};


  function proceedToAddOns(value,body){
    
    setPayload(body)
    setAddOnCart(value)
    setActiveTab(3)
  }
  function proceedToOrderForm(value){
    setPayload(value)
    setActiveTab(4)
  }

  

  
  function sortClasses(value){
    let pro=allProducts.filter((ele)=>{return ele.seriesCategory==value}).map((ele)=>ele.classId)
    setFilteredClassName(className.filter((ele)=>{return pro.includes(ele.classId)}))
  }
  function proceedToCart(){
    console.log(selectedParty)
    let milliseconds=Date.now()
    let  obj={
    "address": selectedParty['AddressLine1'],
    "addressId":selectedParty['addressId'],
    "approvalStatus": "1",
    "date": milliseconds,
    "discount": "0",
    "email": selectedParty['email'],
    "mobileNo": selectedParty['makerContact'],
    "orderType":productData.orderType,
    "partyId": selectedParty['schoolId']?selectedParty['schoolId']:selectedParty['distributorID'],
    "partyType": partyType.toLowerCase()=='distributor'?"P6E9TGXewd":"cQpLw8vwZf",
    "totalAmount": 0,
    "transport": selectedParty['transporterId'],
    "ownerId":owner
  };
  setPayload(obj)
    setActiveTab(2)
  }
  function back(){
setActiveTab(activeTab-1)
  }

  return (
    <StyledDialog open={true} style={{width:"100vw"}}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">School Order</Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Tabs value={activeTab} onChange={(e, newTab) => setActiveTab(newTab)}>
        <Tab label="Select Party" style={{width:"20%"}}  disabled/>
        <Tab label="Order Details" style={{width:"20%"}} disabled/>
        <Tab label="My Cart" style={{width:"20%"}} disabled/>
        <Tab label="Add-On Products" style={{width:"20%"}} disabled/>
        <Tab label="Create Order" style={{width:"20%"}} disabled />
      </Tabs>
      {activeTab === 0 && (
      <>
     <SelectParty editMode={editMode} back={back} partyId={dataProp.partyId}  ownerId={dataProp.ownerId} chooseParty={setSelectedParty} choosePartyType={setPartyType}  setTab={setActiveTab} partyType={partyType} setOwner={setOwner}/>
      </>
      )}

      {activeTab === 1 && (
        <div style={{ display: "flex", width: "100%", gap: "20px" }}>
       
        <div style={{ flex: 1, maxWidth: "50%" }}>
          <TabContainer style={{width:"100%"}}>
          
              <FormControl fullWidth sx={{ minWidth: 200, mb: 2 }}>
                <InputLabel>Order Type</InputLabel>
                <Select
                label="Order Type"
                  value={productData.orderType}
                  onChange={(e) => setProductData({ ...productData, orderType: e.target.value })}
                >
                  <MenuItem value="Sales">Sales</MenuItem>
                </Select>
              </FormControl>
            <FormControl>
            <InputLabel>Product Group</InputLabel>
            <Select
            label="Product Group"
              value={productData.productGroup}
              onChange={(e) => setProductData({ ...productData, productGroup: e.target.value })}
              fullWidth
            >
              <MenuItem value="CttetdmeoW">MittSure</MenuItem>
            </Select>
            </FormControl>

          </TabContainer>
          {productData.orderType && productData.productGroup && <TabContainer>
            <FormControl>
            <InputLabel>Select Series</InputLabel>
            <Select
            label="Select Series"
              value={productData.series}
              onChange={(e) => {setProductData({ ...productData, series: e.target.value });sortClasses(e.target.value)}}
              fullWidth
            >
              {getSeriesCategory?.map((option, optionIndex) => (
                <MenuItem key={option.seriesTableId} value={option.seriesTableId}>
                  {option.seriesName.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            <FormControl>
            <InputLabel>Class</InputLabel>
            <Select
            label="Class"
              value={productData.class}
              onChange={(e) => setProductData({ ...productData, class: e.target.value })}
              fullWidth
            >
              {filteredClassName?.map((option, optionIndex) => (
                <MenuItem key={option.classId} value={option.classId}>
                  {option.className}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
           
            <FormControl>
            <InputLabel>Product</InputLabel>
            <Select
            label="Product"
              value={productData.product}
              onChange={(e) => setProductData({ ...productData, product: e.target.value })}
              fullWidth
            >
              <MenuItem hidden>Select Product</MenuItem>
              {filteredProducts.map((product) => (
                <MenuItem key={product.skuId} value={product.skuId}>
                  {product.nameSku}
                </MenuItem>
              ))}
            </Select>
            </FormControl>

            <TextField label="Quantity"
              value={productData.quantity}
              type="number"
              fullWidth
              onChange={(e) => setProductData({ ...productData, quantity: e.target.value })}
            />
            <Button variant="contained" color="primary" onClick={()=>handleAddProductToCart(productData)}>
              Add Product
            </Button>
            <Button onClick={() => proceedToCart()}>Next</Button>
          </TabContainer>}
          </div>
          <div   style={{ flex: 1, maxWidth: "50%" }}>
          <TabContainer>
          
         
          <Typography>Added Products</Typography>
          
          {cart.length === 0 ? (
    <Typography sx={{ textAlign: "center" }}  style={{marginTop:"50%"}}>
     <p style={{fontSize:"20px"}}> <b>No Product Added Yet</b></p>
    </Typography>
  ) : ( <List>
        {cart.map((item, index) => (
          <Card key={index} sx={{ margin: 2, padding: 1 }}>
            <CardContent>
              <ListItem>
                <ListItemText
                  primary={item.data.nameSku  }
                  secondary={`Total Price: ₹${(item['data'].unitPrice * item.quantity).toFixed(2)} | Price: ₹${item.data.unitPrice}`}
                />
                <IconButton onClick={() => handleQuantityChange(index, -1)}>
                  <Remove />
                </IconButton>
                <Typography>{item.quantity}</Typography>
                <IconButton onClick={() => handleQuantityChange(index, 1)}>
                  <Add />
                </IconButton>
                <IconButton onClick={() => handleRemoveItem(index)} color="error">
                  <Delete />
                </IconButton>
              </ListItem>
            </CardContent>
          </Card>
        ))}
      </List>)}
        </TabContainer>
</div>
        </div>
      )}

      {activeTab === 2 && (
      <>
      <Cart payload={payload} editMode={editMode} seriesD={dataProp.seriesDiscount} series={getSeriesCategory} initialCartItems={cart} setCart={setCart} proceed={proceedToAddOns} />
      </>
      )}

      {activeTab === 3 && (
        <AddOnProductsScreen editMode={editMode} erp={dataProp?.onBoardERP} addOnPro={dataProp?.addOnProduct} payload={payload} items={addOncart} series={getSeriesCategory} proceed={proceedToOrderForm}  />
      )}

      {activeTab === 4 && (
        <OrderForm payload={payload} closePopup={closePopup} editData={dataProp} editMode={editMode} />
      )}
    </StyledDialog>
  );
};


export default AddOrderModal;