import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
  Divider,
  Chip
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const UpdateTracking = ({ props,onClose,remarks,propData,id,distributorId,fetchData,status}) => {
  console.log("DATA0PROPA",propData);
  const [tabValue, setTabValue] = useState(0);

  const [loading,setLoading]=useState(false)
const [product,setProduct]=useState([])
const [date,setDate]=useState()
const [date1,setDate1]=useState()
const [date2,setDate2]=useState()
const [date3,setDate3]=useState()

const [statusType,setStatustype]=useState(false)
const [statusType1,setStatustype1]=useState(false)





 const [form,setForm]=useState({trackingNo:propData?.trackingNumber||""
,
carrier:propData?.carrier||"",
deliveryStatus:propData?.deliveryStatus||"",





 })
 
 
 useEffect(()=>{
    getDeliveryStatus()
    if(propData.estimatedDeliveryDate){
        const newDate=new Date(propData.estimatedDeliveryDate)
        formatDate(dayjs(newDate))
    }
    if(propData.dispatchedDate){
        console.log("getDeliveryStatus1")
        const newDate=new Date(propData.dispatchedDate)
        formatDate3(dayjs(newDate))
    }
    if(propData.shippedDate){
        console.log("getDeliveryStatus2")
        const newDate=new Date(propData.shippedDate)
        formatDate1(dayjs(newDate))
    }
    if(propData.deliveryDate){
      console.log("getDeliveryStatus3")
      const newDate=new Date(propData.deliveryDate)
      formatDate2(dayjs(newDate))
  }
   
    if(propData?.deliveryStatus){
        deliveryStatusHandler(propData?.deliveryStatus)
    }
 },[])

  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
   
  const getDeliveryStatus=async(data)=>{
    try{
      setLoading(true)
      const results = await window.Platform.database.getDeliveryStatus();
      setProduct(results.data)
      setLoading(false)

    }
    catch(err){
      console.log(err)
      setLoading(false)

    }
  }
  const formatDate=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate(data)


changeHandler("estimatedDelivery",unixTimestamp)
console.log("UNIX",unixTimestamp);
}
const formatDate1=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate1(data)


changeHandler("shippedDate",unixTimestamp)
console.log("UNIX",unixTimestamp);
}
const formatDate2=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate2(data)


changeHandler("deliveryDate",unixTimestamp)
console.log("UNIX",unixTimestamp);
}
const formatDate3=(data)=>{
    console.log("DATAAAA",data   )
    // setDate(data)
    const timestamp = new Date(data.$d);
    
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
setDate3(data)


changeHandler("dispatchedDate",unixTimestamp)
console.log("UNIX",unixTimestamp);
}
  console.log("FORMM",form)
 





const changeHandler = (fieldName, value) => {
    // console.log(value)
    setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
  };



  const renderContent1=()=>{
    return(
    <div style={{maxHeight:"510px",height:"505px"}}>
<Stack direction={"row"} padding={2} spacing={2}>
<TextField label="Tracking No" required value={form?.trackingNo || ""} disabled={propData?.trackingNumber} type="text" fullWidth onChange={(e)=>changeHandler("trackingNo",e.target.value)}/>
<TextField label="Carrier" required value={form?.carrier || ""} type="text" disabled={propData?.carrier} fullWidth onChange={(e)=>changeHandler("carrier",e.target.value)}/>
   </Stack>
   <Stack direction={"row"} spacing={2} padding={2}>
   <FormControl variant="outlined" style={{ width: isMobile?"100%":"100%" }}>
          <InputLabel style={{ background: 'white' }} >Delivery Status</InputLabel>
          <Select
            labelId={`Product`}
            label="School Board"
            disabled={loading||propData.deliveryDate}
            value={form?.deliveryStatus||""}
            onChange={(e) => deliveryStatusHandler(e.target.value)}
          >
            {product?.map((option, optionIndex) => (
    <MenuItem key={option.deliveryStatusId} value={option.deliveryStatusId}>
      {option.statusName}
    </MenuItem>
  ))}
          </Select>
        </FormControl>
        </Stack>
        <Stack direction={"row"} spacing={2} padding={2}>
        {!statusType&&!statusType1 &&
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label=" Dispatched Date" value={date3} sx={{width:"100%"}}  disabled={loading||propData.dispatchedDate} format="YYYY/MM/DD" onChange={(data)=>formatDate3(data)} />
            </LocalizationProvider>}
   <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Estimated Delivery Date" value={date} sx={{width:"100%"}}  disabled={loading||propData.deliveryDate} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} />
            </LocalizationProvider>
       {statusType&& <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Shipped Date" value={date1} sx={{width:"100%"}}  disabled={loading||propData.shippedDate} format="YYYY/MM/DD" onChange={(data)=>formatDate1(data)} />
            </LocalizationProvider>}
            
            {statusType1 &&
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label=" Delivery Date" value={date2} sx={{width:"100%"}}  disabled={loading||propData.deliveryDate} format="YYYY/MM/DD" onChange={(data)=>formatDate2(data)} />
            </LocalizationProvider>}
            
    </Stack>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:"20px" }}>
<Button onClick={()=>onSubmit()} variant="contained" disabled={loading||propData.deliveryDate}>
Update
</Button>
</div>
        </div>
    )
  }
const deliveryStatusHandler=(data)=>{
    if(data==="7uvfSsPcuj"){
   setStatustype(true)
setStatustype1(false)

    }
    else if(data==="hGbUzpmbgo"){
setStatustype1(true)
setStatustype(false)

    }
    else{
        setStatustype1(false)
setStatustype(false)
    }
    changeHandler("deliveryStatus",data)

}

  const onSubmit=async()=>{
    try{
setLoading(true)
if(!statusType&&!statusType1){
const results = await window.Platform.database.addOrderItemToTracking({...form,orderId:propData.orderId,
    orderLineItemId:propData.orderLineItemId
    });
}
else{
    const results = await window.Platform.database.updateOrderItemToTracking({...form,orderId:propData.orderId,
        orderLineItemId:propData.orderLineItemId
        });
}
fetchData()
onClose()
window.NotificationUtils.showSuccess("Tracking details updated succesfully.")
setLoading(false)

    }
    catch(error){
        console.log(error)
setLoading(false)
    
    }
  }

 
  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
        Update Tracking Details
         <Tooltip title="Order Id">
 
           
         </Tooltip>
           
           </Typography>
       <IconButton onClick={onClose} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Tracking Details" />
           
            </StyledTabs>
            {tabValue==0&& renderContent1()}
 
          </TabsContainer>
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default UpdateTracking;