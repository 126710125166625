import {  Avatar,  Button,  CircularProgress,  Divider,  Fab,  Tab,  Tabs,  Tooltip,
  Typography,  MenuItem,   Select,  TextField,  IconButton,  InputAdornment} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import MemberDetails from "./memberDetails";
import Box from "@mui/material/Box";
import {  DataGrid,  GridToolbarContainer,  GridToolbarColumnsButton} from "@mui/x-data-grid";
import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json";
import Drawer from "../../components/common/drawer/drawer";
import { TABS, ROLES, DATE_FORMATS } from "../../constants";
import moment from "moment";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import {  Add,  AddAPhoto,  AddTask,  SaveAlt,  SaveAltOutlined,  SaveAs,  ManageSearch,  MobileFriendly,  MobileOff,
  Edit,  LocationCity,  MonetizationOn,  Search} from "@mui/icons-material";
import Lottie from "lottie-react";
import DeletionReasonModal from "../../components/modals/deletionReasonModal";
import { useNavigate } from "react-router-dom";
import UserModal from "../../components/modals/userModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import BulkUploadTargetUser from "../../components/modals/bulkUploadTargetUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "56px - 20px - 82.23px" : "100px - 20px - 43.77px"}
  // );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DetailsPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  // height: 548px;
  width: 100%;
  // border: solid 1px lightGrey;
  display: ${isMobile ? "flex" : "grid"};
  border-radius: 8px;

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px - 43.77px);
  `}
`;

const BulkUploadButton = styled(Button)`
  margin-right: 10px !important;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const UserManagement = (props) => {
  
  const navigation = useNavigate();
  const [userModal, setUserModal] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [visibleEmployees, setVisibleEmployees] = useState([]);
  const [reasonModalObj, setReasonModalObj] = useState({});
  const [selectedMember, setSelectedMember] = useState(null);
  const [LocalStoredData, setLocalStoredData] = useState({})
  const [search,setSearch]=useState("")
  const [clusters,setClusters]=useState([])
  const [selectedRole,setSelectedRole]=useState("")
  const [selectedTab, setSelectedTab] = useState(TABS.REGISTERED);
  const [showReplacementPicker, setShowReplacementPicker] = useState(false);
  const [selectedCluster,setSelectedCluster]=useState("")
  const [showDeletionReasonPicker, setShowDeletionReasonPicker] =
    useState(false);
  const [showBulkUploadPicker, setShowBulkUploadPicker] = useState(false);
  const [showBulkUserDeleteModal, setShowBulkUserDeleteModal] = useState(false);
  const [bulkTargetUpload, setBulkTargetUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
      page: currentPage - 1,
      pageSize: 20,
    });
  const [rowCount, setRowCount] = useState();
    


  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("storedUserData"));
    getClusterInPicklist()
  fetchRoles()
    setLocalStoredData(storedUserData)
    
    if (storedUserData?.user ) {
      if(storedUserData.user.role.toLowerCase().includes('admin') ){
        if(selectedTab==1){
          fetchEmployees(storedUserData,1)
        }
        else{
          fetchEmployees(storedUserData)
        }
      }
      else{
        // fetchEmployees(storedUserData)
        if(selectedTab==1){
          fetchEmployees(storedUserData,1)
        }
        else{
          fetchEmployees(storedUserData)
        }
      }
    }
  }, [selectedCluster,selectedRole, selectedTab, paginationModel.page, paginationModel.pageSize]);
  const [roleId, setRoleId] = useState([])
  const fetchRoles=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.fetchRoles(details);
  console.log("PRODUCT RESULTS: " ,results.data.map((data)=>  data.roleName ))
  setRoleId(results.data)
  // setProduct(results.data)
  setLoading(false)
    }
    catch(err){
      console.log(err);
  setLoading(false)
   
    }
  }
  
  useEffect(() => {
    
      if(LocalStoredData)
    // fetchEmployees(LocalStoredData);
      if(selectedTab==1){
        fetchEmployees(LocalStoredData,1)
      }
      else{
        fetchEmployees(LocalStoredData)
      }
    
  }, [debouncedSearch]);

  // useEffect(()=>{
  //   console.log("DSADa",LocalStoredData)
  //   if(LocalStoredData)
  //   fetchEmployees(LocalStoredData);

  // },[])

  const onClose = () => {
    setUserModal(null);
    handleTabChange(TABS.REGISTERED);
  };

  const discardModal = () => {
    setUserModal(null);
  };

  let contextState = { ...auth };

  // useEffect(() => {
  //   if (auth.user) {
  //   }
  // }, [contextState]);

  var disableScroll = false;

  const isManager = [
    ROLES.SERVICE_MANAGER,
    ROLES.SALES_MANAGER_ENGAGEMENT,
  ].includes(auth?.user.role);

  const getEmployeeName = (employeeCode) => {
    let result = "";
    let member = staffMembers.find(
      (member) => member.employeeCode === employeeCode
    );
    if (member) {
      result = `${member.firstName} ${member.lastName ? member.lastName : ""}`;
    } else {
      member = visibleEmployees.find(
        (member) => member.employeeCode === employeeCode
      );
      if (member) {
        result = `${member.firstName} ${
          member.lastName ? member.lastName : ""
        }`;
      }
    }
    return result;
  };
  const resetDeviceId = async (val) => {
    try {
      setLoading(true);
      let results = await window.Platform.database.resetDeviceId(val);
      window.NotificationUtils.showSuccess("Device ID has been reset");
      fetchEmployees(LocalStoredData);
      setLoading(false);
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);
    }
  };

  const employeeColumns = () => {
    var results = [
      {
        field: "name",
        headerName: "Name",
        minWidth: 180,
        flex: 0.22,
        editable: false,
        valueGetter: (params) => {
          let name = params.row.name || "N/A";
          return name.split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
          .join(" ");
        },
        renderCell: (params) => {
          let name = params.row.name.split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
          .join(" ") || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 220,
        flex: 0.35,
        editable: false,
        valueGetter: (params) => {
          return params.row.email || "N/A";
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.email || "N/A"}>
              <Typography variant="inherit">{params.row.email || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "username",
        headerName: "Username",
        minWidth: 170,
        flex: 0.35,
        editable: false,
        valueGetter: (params) => {
          return params.row.username || "N/A";
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.username || "N/A"}>
              <Typography variant="inherit">{params.row.username || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "mobile",
        headerName: "Mobile",
        minWidth: 150,
        flex: 0.35,
        editable: false,
        valueGetter: (params) => {
          return params.row.mobno || "N/A";
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.mobno || "N/A"}>
              <Typography variant="inherit">{params.row.mobno || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "role",
      //   headerName: "Role",
      //   minWidth: 150,
      //   flex: 0.22,
      //   editable: false,
      //   valueGetter: (params) => {
      //     return params.row.role || "N/A";
      //   },
      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.row.role || "N/A"}>
      //         <Typography variant="inherit">{params.row.role || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      // {
      //   field: "role",
      //   headerName: "Role",
      //   minWidth: 150,
      //   flex: 0.22,
      //   editable: false,
      //   valueGetter: (params) => {
      //     if (!params.row.role) return "N/A";
      //     return params.row.role.toLowerCase() === "se" ? "RM" : params.row.role.charAt(0).toUpperCase() + params.row.role.slice(1).toLowerCase();
      //   },
      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.value || "N/A"}>
      //         <Typography variant="inherit">{params.value || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "role",
        headerName: "Role",
        minWidth: 150,
        flex: 0.22,
        editable: false,
        valueGetter: (params) => {
          if (!params.row.role) return "N/A";
          if (params.row.role.toLowerCase() === "se") return "RM";
          return params.row.role
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
        },
        renderCell: (params) => {
          return (
            <Tooltip title={params.value || "N/A"}>
              <Typography variant="inherit">{params.value || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      
      
        // {
        //   field: "DOJ",
        //   headerName: "Date Of Joining",
        //   minWidth: 200,
        //   flex: 0.22,
        //   editable: false,
        //   valueGetter: (params) => {
        //     return params.row.dob || "N/A";
        //   },
        //   renderCell: (params) => {
        //     const timestamp = params.row?.dob ? Number(params.row.dob) * 1000 : null;
            
        //     if (!timestamp || isNaN(timestamp)) return "N/A"; // Handle invalid dates
        
        //     const formattedDate = new Date(timestamp).toLocaleDateString("en-US", {
        //       year: "numeric",
        //       month: "long",
        //       day: "numeric",
        //     });
        
        //     return (
        //       <Tooltip title={formattedDate}>
        //         <Typography variant="inherit">{formattedDate}</Typography>
        //       </Tooltip>
        //     );
        //   },
        // }
        {
          field: "DOJ",
          headerName: "Date Of Joining",
          minWidth: 170,
          flex: 0.22,
          editable: false,
          valueGetter: (params) => {
            return params.row.dob || "N/A";
          },
          renderCell: (params) => {
            const timestamp = params.row?.dob ? Number(params.row.dob) * 1000 : null;
        
            if (!timestamp || isNaN(timestamp)) return "N/A";
        
            const formattedDate = new Date(timestamp).toLocaleDateString("en-IN", {
              year: "numeric",
              month: "long",
              day: "numeric",
              timeZone: "Asia/Kolkata",
            });
        
            return (
              <Tooltip title={formattedDate}>
                <Typography variant="inherit">{formattedDate}</Typography>
              </Tooltip>
            );
          },
        },
        // {
        //   field: "cluster",
        //   headerName: "Cluster",
        //   minWidth: 150,
        //   flex: 0.22,
        //   editable: false,
        //   valueGetter: (params) => {
        //     return params.row.cluster || "N/A";
        //   },
        //   renderCell: (params) => {
        //     return (
        //       <Tooltip title={params.row.cluster || "N/A"}>
        //         <Typography variant="inherit">{params.row.cluster || "N/A"}</Typography>
        //       </Tooltip>
        //     );
        //   },
        // },
      // {
      //   field: "deviceId",
      //   headerName: "Reset Device ID",
      //   width: 150,
      //   headerAlign: "center",
      //   align: "center",
      //   sortable: false,
      //   filterable: false,
      //   disableExport: true,
      //   renderCell: (params) => {
      //     if (["zsm", "se", "asm", "rsm"].includes(params.row.role))
      //       return (
      //         <Tooltip title="Click to Reset">
      //           <IconButton
      //             size="large"
      //             variant="extended"
      //             color="primary"
      //             disabled={params.row.device_id == null}
      //             onClick={() => {
      //               resetDeviceId(params.row);
      //             }}
      //           >
      //             <MobileOff fontSize="inherit" />
      //           </IconButton>
      //         </Tooltip>
      //       );
      //     else {
      //       return "Not Applicable";
      //     }
      //   },
      // },
      {
        field: `${!selectedTab ? "editUser" : "viewUser"}`,
        headerName: `${!selectedTab ? "Edit User" : "View User"}`,
        width: 150,
        headerAlign: "center",
        align: "center",
        sortable: false,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <Tooltip
              title={
                !selectedTab ? "Click to edit details" : "Click to view details"
              }
            >
              <IconButton
                size="large"
                variant="extended"
                color="primary"
                onClick={() => {
                  setUserModal(params.row);
                }}
              >
                {!selectedTab ? (
                  <Edit fontSize="inherit" />
                ) : (
                  <RemoveRedEyeIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          );
        },
      },
    ];
    return results;
  };

  var deletedColumns = [
    {
      field: "firstName",
      headerName: "Name",
      minWidth: 200,
      valueGetter: (params) => {
        let name = getEmployeeName(params.row.employeeCode);
        return name;
      },
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        let name = getEmployeeName(params.row.employeeCode);
        return (
          <Tooltip title={name || "N/A"}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      flex: 0.35,
      editable: false,
      valueGetter: (params) => {
        return params.row.previousEmail || "";
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.previousEmail || "N/A"}>
            <Typography variant="inherit">
              {params.row.previousEmail}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      flex: 0.22,
      editable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.role || "N/A"}>
            <Typography variant="inherit">{params.row.role || "N/A"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "deletionReason",
      headerName: "De-Activation reason",
      minWidth: 250,
      flex: 0.35,
      editable: false,
      valueGetter: (params) => {
        let name = params.row.deletionReason;
        if (name == null || name.length == 0) name = "No reason provided";
        return name;
      },
      renderCell: (params) => {
        let name = params.row.deletionReason;
        if (name == null || name.length == 0) name = "No reason provided";
        return (
          <Tooltip title={name || "N/A"}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "terminationDate",
      headerName: "Date of leaving",
      minWidth: 250,
      flex: 0.35,
      editable: false,
      valueGetter: (params) => {
        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY");
        if (name == "Invalid date") name = "Termination date not provided";
        return name;
      },
      renderCell: (params) => {
        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY");
        if (name == "Invalid date") name = "Termination date not provided";
        return (
          <Tooltip title={name || "N/A"}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },

      renderCell: (params) => {
        let name = moment.unix(params.row.terminationDate).format("MM/DD/YYYY");
        return (
          <Tooltip title={name || "N/A"}>
            <Typography variant="inherit">{name}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  // useEffect(() => {
  //   console.log(auth,"kjasdaodowhqwiwqoiwqwqwqeh")
  //   if (auth && auth.user) {
  //     fetchEmployees(); // Assuming fetchEmployees is a function that makes the API call
  //   } else {
  //   }
  // }, [auth]);

  const handleTabChange = async (value, keepSelected = false) => {
    setVisibleEmployees([]);
    if (!keepSelected) {
      setSelectedMember(null);
    }
    setSelectedTab(value);
    if (value === TABS.REGISTERED) {
      await fetchEmployees(LocalStoredData);
    } else {
      await fetchEmployees(LocalStoredData,1);
    }
  };

  const addNewMember = () => {
    setUserModal(true);
    setSelectedMember({});

    if (isMobile) {
      scrollToPreview();
    }
  };

  const exportUsers = async () => {
    setLoading(true);
    try {
        let data = {
            role: selectedRole,
            cluster: selectedCluster,
            search: search
        };

        let result = await window.Platform.database.exportData("/user/exportUsers",
          data
        )
        // Check if the response is actually a Blob
        console.log(result.data, "API Response Data");

        const blob = new Blob([result.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Export error:", error);
    }
};

  const onClickBulkUpload = () => {
    try {
      if (selectedTab !== TABS.PENDING) {
        handleTabChange(TABS.PENDING);
      }

      setShowBulkUploadPicker(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onClickDeleteEmployee = async (reportingManager = "") => {
    try {
      let data = { ...selectedMember };
      data = { ...data, warehouse: data.warehouse?.id };
      let { reason, terminationDate } = reasonModalObj;
      console.log(data);
      await window.Platform.database.deleteEmployee(data, {
        reason,
        terminationDate,
      });
      if (selectedTab === TABS.PENDING) {
        let index = visibleEmployees.findIndex(
          (member) => member.id === data.id
        );
        let newMembers = [...visibleEmployees];
        newMembers.splice(index, 1);
        setVisibleEmployees(newMembers);
        setSelectedMember(null);
      } else {
        if (showDeletionReasonPicker) {
          setShowDeletionReasonPicker(false);
        }
        await handleTabChange(TABS.REGISTERED);
      }
      window.NotificationUtils.showSuccess("Successfully deleted employee");
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const setReasonModalData = (name, value) => {
    try {
      setReasonModalObj({ ...reasonModalObj, [name]: value });
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const deletedUsers = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.deletedUsers();
      setVisibleEmployees(results);
      setLoading(false);
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);
    }
  };

  const fetchPendingEmployees = async () => {
    try {
      setLoading(true);

      let results = await window.Platform.database.fetchPendingEmployees();

      setVisibleEmployees(results);

      setLoading(false);
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error Fetching Employees");

      setLoading(false);
    }
  };


  const fetchAllEmployeeBasics = async () => {
    try {
      if (auth?.user?.role === ROLES.ADMIN) {
        return;
      }
      setLoading(true);
      let results = await window.Platform.database.fetchAllEmployeeBasics();
      setStaffMembers(results);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Employees");
      setLoading(false);
    }
  };

  const uploadTarget = async () => {
    setBulkTargetUpload(true);
  };
  const getClusterInPicklist = async () => {
    setLoading(true);
    try {
      let results = await window.Platform.database.getClusterInPicklist();
      
      const jsonArrayWithId = results?.data?.map((obj) => ({ 
        ...obj, 
        dbId: obj.id
      }));
      
     
      setClusters(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error("ERRORCHECK", error);
        setLoading(false);
    }
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Set debounced value after delay
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup function
  }, [search]);

  const fetchEmployees = async (data,status) => {
    try {
     if(Object.keys(data).length){ 
      setLoading(true);
      let results ;
     
      if (data?.user?.role.toLowerCase().includes('admin')) {
        results = await window.Platform.database.getUsersWeb( status,selectedRole,selectedCluster,search, paginationModel.page, paginationModel.pageSize );
      } else {
        results = await window.Platform.database.getUsersBasedOnManager( {userId: data.user.id} );
      }

      setStaffMembers(results.data);
      setRowCount(results?.data1);
      setVisibleEmployees(results.data);
      setLoading(false);
      if (results.status) {
        window.NotificationUtils.showError(results.message);
      } else {
      }
    }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Employees");
      setLoading(false);
    }
  };


  const scrollToPreview = () => {
    window.scrollTo(
      0,
      document.getElementById("detailsPreviewContainer").getBoundingClientRect()
        .top
    );
  };

  const renderLoading = () => {
    return <OpaqueLoading />;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
      </GridToolbarContainer>
    );
  }

  const renderTabContent = () => {
    if (loading) {
      return renderLoading();
    }

      return (
          <>
          <div className="d-flex justify-content-end align-items-center  py-2">
                                          <Typography variant="body1">Select Page Size :&nbsp; </Typography>
                                      <Select
                                        value={paginationModel.pageSize}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e)=>{setPaginationModel({...paginationModel,pageSize:e.target.value})}}
                                        sx={{ width: 90, fontSize: 14 }}
                                      >
                                        {[10,20,30,40,50,60].map((role) => (
                                          <MenuItem key={role} value={role}>{role}</MenuItem>
                                        ))}
                                      </Select>
                                          </div>
        <DataGridContainer
          style={{ height: !visibleEmployees.length ? "200px" : "auto" }}
        >
          <DataGrid
            className="payrollGrid"
            density="compact"
            rows={visibleEmployees || []}
            columns={employeeColumns()}
            disableSelectionOnClick
            disableRowSelectionOnClick
            rowHeight={60}
            rowCount={rowCount}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: 10,
            //     },
            //   },
            // }}
            // pageSizeOptions={[10]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
          </>
      );
  };

  const deleteConfirmationModal = () => {
    setShowBulkUserDeleteModal(true);
  };

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          {userModal && (
            <UserModal
              user={auth.user}
              onClose={onClose}
              discardModal={discardModal}
              data={userModal}
              allusers={visibleEmployees}
            />
          )}
          {/* <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          </div> */}
          <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
 {LocalStoredData?.user?.role.toLowerCase().includes('admin')&& 
 <> <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
 <Tooltip title="Search By Name, Username, Number, Email">
  <TextField
    label="Search"
    variant="outlined"
    size="small"
    placeholder="Search By Name, Username, Number, Email"
    value={search}
    onChange={(e) => setSearch(e.target.value)} // Proper event handler
    sx={{ width: 200, fontSize: 14 }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search color="action" />
        </InputAdornment>
      )
    }}
  />
</Tooltip>

    <Typography variant="body1">Select Role:</Typography>
    <Select
      value={selectedRole}
      variant="outlined"
      size="small"
      onChange={(e)=>{setSelectedRole(e.target.value)}}
      sx={{ width: 150, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {roleId.map((role) => (
        <MenuItem key={role} value={role.roleName}>{role.name}</MenuItem>
      ))}
    </Select>
    <Typography variant="body1">Select Cluster:</Typography>
    <Select
      value={selectedCluster}
      variant="outlined"
      size="small"
      onChange={(e)=>{setSelectedCluster(e.target.value)}}
      sx={{ width: 150, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {clusters.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
  </Box>
  <Button onClick={()=>{exportUsers()}}>Download</Button>
  </>

  }
  <Box display="flex" gap={2}>
    <Button
      variant="contained"
      color="primary"
      onClick={uploadTarget}
      sx={{ borderRadius: "10px", height: 40 }}
    >
      <Add sx={{ mr: 1 }} />
      Bulk Add Users
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={addNewMember}
      sx={{ borderRadius: "10px", height: 40 }}
    >
      <Add sx={{ mr: 1 }} />
      Add New
    </Button>
  </Box>
</Box>
        </HeaderContainer>
        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Active" />
              {<Tab label="Inactive" />}
            </StyledTabs>

            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
      {showDeletionReasonPicker && (
        <DeletionReasonModal
          selectedMember={selectedMember}
          data={reasonModalObj}
          setData={setReasonModalData}
          staffMembers={staffMembers}
          closeModal={() => setShowDeletionReasonPicker(false)}
          onClickDeleteEmployee={onClickDeleteEmployee}
        />
      )}
      {bulkTargetUpload && (
        <BulkUploadTargetUser
          onClose={() => setBulkTargetUpload(false)}
          fetchData={() => fetchEmployees(LocalStoredData)}
          allData={visibleEmployees}
        />
      )}
    </>
  );
};

export default UserManagement;
