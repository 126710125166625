import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Fab,
  Tab,
  Tabs,
  Grid,
  Tooltip,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Stack,
  Modal, Table, TableHead, TableRow, TableCell, TableBody, Paper,
} from "@mui/material";

import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import React, { useState, useEffect, useContext } from "react";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridDensitySelector,
  getGridStringOperators,
} from "@mui/x-data-grid";

import DeleteIcon from "@mui/icons-material/Delete";

import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json";

import Drawer from "../../components/common/drawer/drawer";

import { TABS, ROLES, DATE_FORMATS } from "../../constants";

// import ReplaceManagerModal from "../../components/modals/replaceManagerModal";

// import BulkUploadRoleSelector from "../../components/modals/bulkUploadRoleSelector";

// import BulkUserDelete from '../../components/modals/bulkUserDelete';

// import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import moment from "moment";

import { AuthContext } from "../../components/contextAPI/ContextAPI";

import {
  Add,
  AddAPhoto,
  AddTask,
  SaveAlt,
  SaveAltOutlined,
  SaveAs,
  ManageSearch,
  Send,
  Search
} from "@mui/icons-material";

// import { LottiePlayer } from 'lottie-react';

import Lottie from "lottie-react";

import DeletionReasonModal from "../../components/modals/deletionReasonModal";

import { useNavigate } from "react-router-dom";
import UserModal from "../../components/modals/userModal";
import SchoolModal from "../../components/modals/schoolModal";
import DistributorModal from "../../components/modals/distributorModal";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import CommonOtpModal from "../../components/modals/commonOtpModal";
import BulkAddSchool from "../../components/modals/bulkAddSchool";
import BulkAddDistributor from "../../components/modals/bulkAddDistributor";
import CommonOtpModalConsent from "../../components/modals/commonOtpModalConsent";
import "../../App.css";
import { height } from "@mui/system";
import axios from "axios";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;

  margin-bottom: 7px;
`;

const TableContainer = styled.div`
  //   height: calc(
  //     100vh - ${isMobile
    ? "56px - 20px - 82.23px"
    : "128px - 29px - 50.77px"}
  //   );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DetailsPreviewContainer = styled.div`
  height: 100%;

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px);

  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;



const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  //   border: solid 1px lightGrey;
  display: ${isMobile ? "flex" : "grid"};
  border-radius: 4px;

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;


const TabsContainer = styled.div`
  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}
`;




const PartyManagement = (props) => {
  const location = useLocation();
  const selectedTabProp1 = location.state?.selectedTabProp;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 20,
  });
  const [paginationModel1, setPaginationModel1] = useState({
    page: currentPage1 - 1,
    pageSize: 20,
  });
  const [otpVerify, setOtpVerify] = useState(false);
  const [rowCount, setRowCount] = useState();
  const [rowCount1, setRowCount1] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange1, setDateRange1] = useState(false);

  const [rsms, setRsms] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [asms, setAsms] = useState([]);
  const [rms, setRms] = useState([]);
  const [rm, setRm] = useState("")
  const [rsm, setRsm] = useState("")
  const [asm, setAsm] = useState("")
  const [consentData, setConsentData] = useState(null);

  const navigation = useNavigate();

  const [userModal, setUserModal] = useState(null);

  const [staffMembers, setStaffMembers] = useState([]);

  const [visibleEmployees, setVisibleEmployees] = useState([]);

  const [reasonModalObj, setReasonModalObj] = useState({});

  const [selectedMember, setSelectedMember] = useState(null);

  const [selectedTab, setSelectedTab] = useState(
    selectedTabProp1 || TABS.REGISTERED
  );
  const [showDeletionReasonPicker, setShowDeletionReasonPicker] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const [pageSize, setPageSize] = useState(20)
  const { auth, setAuth } = useContext(AuthContext);
  const [schoolModal, setSchoolModal] = useState(false);
  const [distributorModal, setDistributorModal] = useState(false);
  const [editSchool, setEditSchool] = useState(false);
  const [editData, setEditData] = useState();
  const [editData1, setEditData1] = useState();
  const [editDistributor, setEditDistributor] = useState(false);
  const [filter, setFilter] = useState(false);
  const [clusters, setClusters] = useState([])
  const [selectedCluster, setSelectedCluster] = useState("")
  const [searchTerm1, setSearchTerm1] = useState(null);
  const [otpModalData, setOtpModalData] = useState();
  const [otpModal1, setOtpModal1] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('storedUserData')).user)
  const [search, setSearch] = useState()

  const [otpModalDataDistributor, setOtpModalDataDistributor] = useState();
  const [otpmModalDistributor, setOtpModalDistributor] = useState(false);
  const [bulkSchool, setBulkSchool] = useState(false);
  const [bulkDistributor, setBulkDistributor] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDistributorViewModal, setOpenDistributorViewModal] = useState(false)
  const [modalDistributorViewData, setModalDistributorViewData] = useState([])
  const [selectedDistributorId, setSelectedDistributorId] = useState(null);
  const [showSwapCheck, setShowSwapCheck] = useState(false)
  const [swapValue, setSwpValue] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [newRows, setNewRows] = useState([])
  const [open, setOpen] = useState(false);
  const [rmForSwap, setRmForSwap] = useState("")
  const [selectAll, setSelecetAll] = useState("0")

  const consentOtpResend = async (data) => {
    try {
      setLoading(true);
      const response = await window.Platform.database.consentOtpResend({
        partyId: data.schoolId,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");

      // setDistributorModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const consentOtpSend = async (data) => {
    try {
      setLoading(true);
      const response = await window.Platform.database.consentOtpSend({
        partyId: data.schoolId,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");

      // setDistributorModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
    }
  };
  const [debouncedSearch, setDebouncedSearch] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Set debounced value after delay
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup function
  }, [search]);

  useEffect(() => {


    fetchEmployees();

  }, [debouncedSearch]);

  useEffect(() => {

    fetchEmployees();

  }, [selectedCluster, rm, rsm, asm])

  const consentOtpSubmit = async (data, otp) => {
    try {
      setLoading(true);

      // const response = await window.Platform.database.resendOTP({ mobile: otpModalData1.mobileNo })
      const response = await window.Platform.database.consentOtpSubmit({
        partyId: data.partyId,
        otp: otp,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");

      // setDistributorModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (schoolId) => {
    setLoading(true);
    try {
      const response = await window.Platform.database.getSchoolDataBasedOnId({ schoolId })
      if (response.status == false) {
        setModalData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setModalData([]);
    }
    setLoading(false);
  };

  const fetchData1 = async (distributorID) => {
    setLoading(true);
    try {
      const response = await window.Platform.database.getDistributorByID({ id: distributorID })
      if (response.status == false) {
        setModalDistributorViewData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setModalDistributorViewData([]);
    }
    setLoading(false);
  };

  const handleOpenModal = (schoolId) => {
    setSelectedSchoolId(schoolId);
    setOpenModal(true);
    fetchData(schoolId);
  };

  const handleOpenDistributorViewModal = (distributorID) => {
    setSelectedDistributorId(distributorID);
    setOpenDistributorViewModal(true);
    fetchData1(distributorID);
  }
  // const handleCloseModal = () => setOpenModal(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData([]);
  };

  const handleCloseDistributorViewModal = () => {
    setOpenDistributorViewModal(false);
    setModalDistributorViewData([]);
  }

  useEffect(() => {
    // console.log("auth", auth);
    if (!filter) {
      if(selectAll=="1"){
        setSelecetAll("1")
      }
      fetchEmployees();
    }
  }, [selectedTab, paginationModel.page, paginationModel1.page, paginationModel.pageSize, paginationModel1.pageSize]);
  useEffect(() => {
    getClusterInPicklist()
    if (userData?.role.toLowerCase().includes('admin')) {

      getAllUsers()
    }
    if (userData?.role == 'rsm') {
      getUsers('rsm', userData?.id)
    }
    if (userData?.role == 'asm') {
      getUsers('asm', userData?.id)
    }
  }, [])

  async function getAllUsers() {
    let results = await window.Platform.database.fetchEmployees()
    setAllUsers(results?.data)
    setRsms(results?.data?.filter((ele) => ele.role.toLowerCase() == 'rsm'))
    setRms(results?.data?.filter((ele) => ele.role.toLowerCase() == 'se'))
    setAsms(results?.data?.filter((ele) => ele.role.toLowerCase() == 'asm'))
  }


  async function getUsers(role, id) {
    if (id != "") {
      let results = await window.Platform.database.getUsersBasedOnManager({ userId: id })
      if (role == 'rsm') {
        setAsms(results?.data)
        setRms(results?.data1)
      }
      if (role == 'asm') {
        setRms(results?.data)
      }
    } else {
      if (role == 'rsm') {
        setRm("")
        setAsm("")
        setRms(allUsers?.filter((ele) => ele.role == 'se'))
        setAsms(allUsers?.filter((ele) => ele.role == 'asm'))
      }

      if (role == 'asm') {
        setRm("")
        setRms(allUsers?.filter((ele) => ele.role == 'se'))
      }
    }

  }
  let contextState = { ...auth };

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour clock
    };

    const formattedDate = new Date(timestampFromDB).toLocaleString(
      "en-US",
      options
    );

    return formattedDate;
  };

  const handleSelectAll = (e) => {

    setSelecetAll("1")
    if (e.target.checked) {
      const allSchoolIds = newRows.map((row) => row.schoolId);
      setSelectedSchools(allSchoolIds);
    } else {
      setSelecetAll("0")
      setSelectedSchools([]);
    }
  };

  
// const handleSelectAll = (e) => {
//   const isChecked = e.target.checked;
//   setSelecetAll("1");

//   if (selectAll == "1") {
//     const allIds = newRows.map((item) => item.schoolId);
//     setSelectedSchools(allIds);
//   } else {
//     setSelectedSchools([]);
//   }
// };

  const handleCheckboxChange = (e, schoolId) => {
    const isChecked = e.target.checked;
    if (e.target.checked) {
      setSelectedSchools((prev) => [...prev, schoolId]);
    } else {
      setSelectedSchools((prev) => prev.filter((id) => id !== schoolId));
    }
    if (!isChecked) {
      setSelecetAll("0");
    }
  };
  const schoolColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );

    let result = [
      showSwapCheck && {
        field: "Swap",
        headerName: (
          <>
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectAll == "1" ? true : false}
              // checked={selectedSchools.length === newRows.length && newRows.length > 0}
              width={"30"}
            />
          </>
        ),
        width: 30,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <input
            type="checkbox"
            checked={selectedSchools.includes(params.row.schoolId)}
            onChange={(e) => handleCheckboxChange(e, params.row.schoolId)}
            style={{
              // width: "100%",
              // padding: "4px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        ),
      },

      {
        field: "approvalStatus",
        headerName: "School Id",
        width: 100,
        filterable: false,
        sortable: false,
        valueGetter: (params) => {
          let name = params.row.schoolId
          return name;
        },
        renderCell: (params) => {
          let name = params.row.schoolId;

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "schoolName",

        headerName: "Name",

        minWidth: 300,

        valueGetter: (params) => {
          let name = params.row?.schoolName;
          return name.split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(" ");
        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.schoolName.split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(" ") || "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "name",

        headerName: "Assigned To",

        minWidth: 150,

        valueGetter: (params) => {
          let name = params.row.ownerName;
          return name;
        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row.ownerName || "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },


      {
        field: "makerName",

        headerName: "Decision Maker Name",

        minWidth: 170,

        flex: 0.35,

        editable: false,
        filterable: false,

        renderCell: (params) => {
          return (
            <Tooltip title={params.row.makerName || "N/A"}>
              <Typography variant="inherit">
                {params.row.makerName || "N/A"}
              </Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "makerContact",

        headerName: "Decision Maker Contact",

        minWidth: 170,

        flex: 0.35,

        editable: false,
        filterable: false,

        renderCell: (params) => {
          return (
            <Tooltip title={params.row.makerContact || "N/A"}>
              <Typography variant="inherit">
                {params.row.makerContact || "N/A"}
              </Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "View",
        headerName: "View",
        minWidth: 120,
        flex: 0.17,
        editable: false,
        renderCell: (params) => {
          return (
            <>
              <Tooltip>
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleOpenModal(params.row.schoolId)}
                    sx={{ minWidth: "80px" }}
                  >
                    {"View"}
                  </Button>
                </span>
              </Tooltip>
              <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 900,
                    bgcolor: "white",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "90%",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6" component="h2" mb={5} style={{ textAlign: "center" }}>
                    School Details
                  </Typography>
                  <div>
                    <div className="row mb-4 mt-2">
                      <div className="col-md-6">
                        <b> School Id:</b> {modalData && modalData[0]?.schoolId || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b> Party Name: </b>{modalData && modalData[0]?.schoolName || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>E-Mail :</b> {modalData && modalData[0]?.email || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Created At :</b> {modalData && modalData[0]?.createdAt
                          ? new Date(modalData[0].createdAt).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric'
                        })
                      : "N/A"}
                    </div>

                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Decision Maker name :</b> {modalData && modalData[0]?.makerName || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Decision Maker Contact :</b> {modalData && modalData[0]?.makerContact || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Decision Maker Role :</b> {modalData && modalData[0]?.decisionMakerRole || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Board :</b> {modalData && modalData[0]?.board || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Medium : </b> {modalData && modalData[0]?.medium || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b> Grade :</b> {modalData && modalData[0]?.grade || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Strength :</b> {modalData && modalData[0]?.Board || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b> Transporter Name :</b> {modalData && modalData[0]?.transporter_name || "N/A"}
                      </div>
                     
                    </div>
                    <div className="row mb-4">
                    <div className="col-md-6">
                        <b>Pincode :</b> {modalData && modalData[0]?.Pincode || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b> District :</b> {modalData && modalData[0]?.District || "N/A"}
                      </div>
                      
                    </div>
                    <div className="row mb-4">
                    <div className="col-md-6">
                        <b>State :</b> {modalData && modalData[0]?.State || "N/A"}
                      </div>
                     
                    </div>
                  </div>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleCloseModal} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </>
          );
        },
      },


      {
        field: "Approve Order",
        headerName: "Actions ",
        width: 110,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          let value = params.row?.schoolId;

          return (
            <Stack direction={"row"} spacing={1}>
              <Tooltip title={"Edit School"}>
                <IconButton onClick={() => handleEdit(params)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => handleDelete(params.row.schoolId, e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Click to approve school"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "#008000" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "1" });
                            }} >
                                <ThumbUpIcon />
                            </IconButton></Tooltip>


                            <Tooltip title="Click to reject school"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "red" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "2" });
                            }} >
                                <ThumbDownIcon />
                            </IconButton></Tooltip> */}
            </Stack>
          );
        },
      },

      // {
      //     field: "Consent",
      //     headerName: "Consent",
      //     width: 120,
      //     // height: 10,
      //     // headerAlign: 'center',
      //     sortable: false,
      //     // editable: true,
      //     filterable: false,
      //     disableExport: true,
      //     renderCell: (params) => {

      //         let value = params.row?.consent

      //         return (
      //             <Stack direction={"row"} spacing={1}>
      //                 <Tooltip title={"Consent Approval"}>

      //                     <IconButton disabled={params.row.consent || !params.row.makerContact?.length} onClick={() =>{
      //                         setConsentData(params.row)
      //                         updateConsent(params.row)}} >
      //                         <Send />
      //                     </IconButton>

      //                 </Tooltip>
      //                 {/* <Tooltip title="Click "><IconButton disabled={params.row?.approveStatus===1||params.row?.approveStatus===2} variant="extended" sx={{color:"#008000"}}  onClick={(e) => {
      //       e.stopPropagation();
      //       approveRejectOrder({id:value,approvalStatus:"1"});}} >
      //      <ThumbUpIcon/>
      //     </IconButton></Tooltip> */}
      //                 {/* <Tooltip title="Click to reject school"><IconButton disabled={params.row?.approveStatus===1||params.row?.approveStatus===2} variant="extended" sx={{color:"red"}} onClick={(e) => {
      //       e.stopPropagation();
      //       approveRejectOrder({id:value,approvalStatus:"2"});}} >
      //      <ThumbDownIcon/>
      //     </IconButton></Tooltip> */}
      //             </Stack>
      //         )

      //     },
      // },
      // {

      //     field: "Consent Status",

      //     headerName: "Consent Status",

      //     editable: false,

      //     hide: true,

      //     minWidth: 150,
      //     filterable: false,

      //     valueGetter: (params) => {

      //         let name = params.row.consent ? 'true' : 'false';

      //         return name;

      //     },

      //     renderCell: (params) => {

      //         let name = params.row.consent ? 'true' : 'false';

      //         return (

      //             <Tooltip title={name || "N/A"}>

      //                 <Typography variant="inherit">{name === 'true' ? 'Active' : 'Inactive'}</Typography>

      //             </Tooltip>

      //         )

      //     },

      // },
    ].filter(Boolean);
    return result;
  };

  const distributorColumn = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );

    let result = [
      {
        field: "distributorid",
        headerName: "Distributor Id",
        width: 130,
        filterable: false,

        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        renderCell: (params) => {
          let name = params.row.distributorID;

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "DistributorName",

        headerName: "Name",

        minWidth: 250,

        valueGetter: (params) => {
          let name = params.row.DistributorName;

          return name.split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");
        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row.DistributorName.split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ") || "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },

      // {
      //   field: "GSTno",

      //   headerName: "GSTno",

      //   minWidth: 250,

      //   flex: 0.35,

      //   editable: false,
      //   filterable: false,

      //   valueGetter: (params) => {
      //     return params.row.GSTno || "";
      //   },

      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.row.GSTno || "N/A"}>
      //         <Typography variant="inherit">
      //           {params.row.GSTno || "N/A"}
      //         </Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      {
        field: "name",

        headerName: "Assigned To",

        minWidth: 150,

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.name || "N/A"}>
              <Typography variant="inherit">
                {params.row.name || "N/A"}
              </Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "createdAt",
      //   headerName: "Created At",
      //   width: 250,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   filterable: false,

      //   // editable: true,
      //   renderCell: (params) => {
      //     let name = formatTimeStamp(params.row.createdAt) || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "transporter_name",
      //   headerName: "Transporter Name",
      //   minWidth: 250,
      //   flex: 0.35,
      //   editable: false,
      //   filterable: false,
      //   valueGetter: (params) => {
      //     let name = params.row.transporter_name;
      //     if (name == null || name?.length == 0) name = "N/A";
      //     return name;
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.transporter_name;
      //     if (name == null || name?.length == 0) name = "N/A";
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      {
        field: "makerName",

        headerName: "Decision Maker Name",

        minWidth: 200,

        flex: 0.35,

        editable: false,
        filterable: false,

        valueGetter: (params) => {
          let name = params.row.makerName;

          if (name == null || name?.length == 0) name = "N/A";

          return name;
        },

        renderCell: (params) => {
          let name = params.row.makerName;

          if (name == null || name?.length == 0) name = "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "makerContact",

        headerName: "Decision Maker Number",

        minWidth: 180,

        flex: 0.35,

        editable: false,
        filterable: false,

        valueGetter: (params) => {

          let name = params.row.makerContact;

          return name;
        },

        renderCell: (params) => {
          let name = params.row.makerContact || "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "View",
        headerName: "View",
        minWidth: 120,
        flex: 0.17,
        editable: false,
        renderCell: (params) => {
          return (
            <>
              <Tooltip>
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleOpenDistributorViewModal(params.row.distributorID)}
                    sx={{ minWidth: "80px" }}
                  >
                    {"View"}
                  </Button>
                </span>
              </Tooltip>
              <Modal open={openDistributorViewModal} onClose={handleCloseDistributorViewModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 900,
                    bgcolor: "white",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "90%",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6" component="h2" mb={5} style={{ textAlign: "center" }}>
                    Distributor Details
                  </Typography>
                  <div>
                    <div className="row mb-4 mt-2">
                      <div className="col-md-6">
                        <b> Distributor Id:</b> {modalDistributorViewData && modalDistributorViewData[0]?.distributorID || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b> Party Name : </b>{modalDistributorViewData && modalDistributorViewData[0]?.DistributorName || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Sub Distributor Name : </b> {modalDistributorViewData && modalDistributorViewData[0]?.subDistributorName || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>E-Mail :</b> {modalDistributorViewData && modalDistributorViewData[0]?.email || "N/A"}
                      </div>

                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Created At :</b> {modalDistributorViewData && modalDistributorViewData[0]?.createdAt? new Date(modalDistributorViewData[0].createdAt).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric'
                        })
                      : "N/A" || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Decision Maker name :</b> {modalDistributorViewData && modalDistributorViewData[0]?.makerName || "N/A"}
                      </div>

                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Decision Maker Contact :</b> {modalDistributorViewData && modalDistributorViewData[0]?.makerContact || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Decision Maker Role :</b> {modalDistributorViewData && modalDistributorViewData[0]?.decisionMakerRole || "N/A"}
                      </div>

                    </div>
                    <div className="row mb-4">
                    <div className="col-md-6">
                        <b> Transporter Name :</b> {modalDistributorViewData && modalDistributorViewData[0]?.transporter_name || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b>Cluster :</b> {modalDistributorViewData && modalDistributorViewData[0]?.cluster_name || "N/A"}
                      </div>

                    </div>
                    <div className="row mb-4">
                    <div className="col-md-6">
                        <b>Pan Card Number :</b> {modalDistributorViewData && modalDistributorViewData[0]?.panNumber || "N/A"}
                      </div>
                    <div className="col-md-6">
                        <b>Pincode :</b> {modalDistributorViewData && modalDistributorViewData[0]?.Pincode || "N/A"}
                      </div>

                     
                      
                    </div>
                    <div className="row mb-4">
                    <div className="col-md-6">
                        <b> District :</b> {modalDistributorViewData && modalDistributorViewData[0]?.District || "N/A"}
                      </div>
                    <div className="col-md-6">
                        <b>State :</b> {modalDistributorViewData && modalDistributorViewData[0]?.State || "N/A"}
                      </div>
                    </div>
                    
                  </div>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleCloseDistributorViewModal} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </>
          );
        },
      },

      {
        field: "Approve Order",
        headerName: "Actions ",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          let value = params.row?.distributorID;

          return (
            <Stack direction={"row"} spacing={1}>
              <Tooltip title="Convert Distributor to Stockist">
                <IconButton
                  onClick={() => handleConvertStockist(params)}
                  style={{
                    //   transform: rotate ? 'rotate(360deg)' : 'rotate(0deg)',
                    transition: "transform 0.6s ease",
                  }}
                >
                  <SwapVertIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Edit Distributor"}>
                <IconButton onClick={() => handleEdit1(params)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="black"
                  onClick={(e) =>
                    handleDeleteDistributor(params.row.distributorID, e)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Click to approve distributor"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "#008000" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "1" });
                            }} >
                                <ThumbUpIcon />
                            </IconButton></Tooltip>
                            <Tooltip title="Click to reject distributor"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "red" }} onClick={(e) => {
                                e.stopPropagation();
                                approveRejectOrder({ id: value, approvalStatus: "2" });
                            }} >
                                <ThumbDownIcon />
                            </IconButton></Tooltip> */}
            </Stack>
          );
        },
      },
    ];
    return result;
  };

  const handleDeleteDistributor = async (distributorId, event) => {
    event.stopPropagation(); // Prevent row selection on click

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Distributor ?"
    );
    if (!confirmDelete) return;

    try {
      setLoading(true);

      const response = await window.Platform.database.deleteDistributor({
        id: distributorId,
      });

      window.NotificationUtils.showSuccess("Distributor deleted successfully");

      fetchEmployees();
    } catch (error) {
      console.error("Error deleting the Distributor:", error);
      window.NotificationUtils.showError("Error deleting the Distributor");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit1 = (data) => {
    setEditDistributor(true);
    setEditData1(data.row);
  };

  const handleConvertStockist = async (data) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to convert this distributor into a stockist ?"
    );

    if (isConfirmed) {

      const results = await window.Platform.database.addDistributorToStockist({
        distributorId: data?.row?.distributorID,
      });

      if (results.status == false) {
        alert(results.message); // Success 
        window.NotificationUtils.showSuccess(results.message);
      } else {
        alert(results.message); // failure
        window.NotificationUtils.showError(results.message);

      }
    }
  };

  const handleEdit = async (data) => {
    let data11 = {
      schoolId: data.row.schoolId
    }
    const response = await window.Platform.database.getSchoolDataBasedOnId( data11 )
    setEditSchool(true);
    setEditData(response?.data);
  };

  const handleDelete = async (schoolId, event) => {
    event.stopPropagation(); // Prevent row selection on click

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this School?"
    );
    if (!confirmDelete) return;

    try {
      setLoading(true);

      const response = await window.Platform.database.deleteSchool({
        id: schoolId,
      });

      window.NotificationUtils.showSuccess("School deleted successfully");

      fetchEmployees();
    } catch (error) {
      console.error("Error deleting the School:", error);
      window.NotificationUtils.showError("Error deleting the School");
    } finally {
      setLoading(false);
    }
  };

  const updateConsent = (data) => {
    consentOtpSend(data);
    setOtpVerify(true);
  };

  const onSubmitOtp = async (data, otp) => {
    try {
      setLoading(true);
      let onsubmitOtp = await window.Platform.database.consentOtpSubmit({
        partyId: data.schoolId,
        otp: otp,
      });

      if (onsubmitOtp.status) {
        setLoading(false);
        window.NotificationUtils.showError(onSubmitOtp.message);
      } else {
        setLoading(false);
        window.NotificationUtils.showSuccess("Consent verified successfully");
        setOtpVerify(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      window.NotificationUtils.showError("OTP Submission Failed");
    }
  };

  const handleTabChange = async (value, keepSelected = false) => {
    setVisibleEmployees([]);
    if (!keepSelected) {
      setSelectedMember(null);
    }
    setFilter(false);
    setStartDate(null);
    setEndDate(null);

    setDateRange1(false);
    setSelectedTab(value);
  };

  const addNewSchool = () => {
    setSchoolModal(true);

    if (isMobile) {
      scrollToPreview();
    }
  };

  const addNewDistributor = () => {
    setDistributorModal(true);

    if (isMobile) {
      scrollToPreview();
    }
  };

  const fetchEmployees = async () => {
    setLoading(true);

    try {
      setVisibleEmployees([]);
      let results;
      if (selectedTab === 1) {
        results = await window.Platform.database.getDistributor({
          pageNumber: paginationModel1.page,
          recordPerPage: paginationModel1.pageSize,
          filter: search,
          cluster: selectedCluster,
          ownerId: rm,
          rsm: rsm,
          asm: asm
        });
        setRowCount1(results?.data1);
      } else {
        results = await window.Platform.database.getSchool({
          pageNumber: paginationModel.page,
          filter: search,
          recordPerPage: paginationModel.pageSize,
          cluster: selectedCluster,
          ownerId: rm,
          rsm: rsm,
          asm: asm
        });
        setRowCount(results?.data1);
        setNewRows(results?.data)
      }

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));

      // console.log("results", results);

      // if (auth?.user?.role === ROLES.ADMIN) {
      // console.log("response", results.data);

      // }

      setVisibleEmployees(jsonArrayWithId);
      if (selectAll == "1") {
        const allIds = results?.data?.map((item) => item.schoolId);
        setSelectedSchools((prevSelected) => [...new Set([...prevSelected, ...allIds])]);
      }
  
      setLoading(false);
      // window.NotificationUtils.showError(results.message)
      if (results.status) {
        setLoading(false);
        window.NotificationUtils.showError(results.message);
      } else {
        setLoading(false);
        // window.NotificationUtils.showSuccess(results.message)
      }
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error Fetching Data");

      setLoading(false);
    }
  };

  const scrollToPreview = () => {
    window.scrollTo(
      0,
      document.getElementById("detailsPreviewContainer").getBoundingClientRect()
        .top
    );
  };

  const onFilterChange = async (filterModel) => {
    try {
      setLoading(true);
      setFilter(true);
      // console.log(filterModel, "filterModel");
      if (filterModel) {
        const response = await window.Platform.database.filterSchool({
          filterField: filterModel.field,
          filterValue: filterModel.value,
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
        });
        // console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setVisibleEmployees(jsonArrayWithId);
        setRowCount(response.data1);
      } else {
        const response = await window.Platform.database.filterSchool({
          filterField: "date",
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
        });
        // console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setVisibleEmployees(jsonArrayWithId);
        setRowCount(response.data1);
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );
    }
  };

  const onFilterChange1 = async (filterModel) => {
    try {
      setLoading(true);
      setFilter(true);
      // console.log(filterModel, "filterModel");
      if (filterModel) {
        const response = await window.Platform.database.filterDistributor({
          filterField: filterModel.field,
          filterValue: filterModel.value,
          pageNumber: paginationModel1.page,
        });
        // console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setVisibleEmployees(jsonArrayWithId);
        setRowCount(response.data1);
      } else {
        const response = await window.Platform.database.filterDistributor({
          filterField: "date",
          pageNumber: paginationModel1.page,
          startDate: startDate,
          endDate: endDate,
        });
        // console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setVisibleEmployees(jsonArrayWithId);
        setRowCount(response.data1);
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />

        <GridToolbarFilterButton />

        {/* <GridToolbarDensitySelector /> */}

        <GridToolbarExport
          csvOptions={{
            fileName: "users-report",
          }}
        />
      </GridToolbarContainer>
    );
  }

  const swapColumnShow = () => {
    if (showSwapCheck) {
      setSelectedSchools([]);
      setShowSwapCheck(false);
    }
    else {
      setSelectedSchools([]);
      setShowSwapCheck(true);
    }
  }

  const swapFunctionality = () => {
    setRmForSwap('')
    setOpen(true)
  }

  const handleOkSwap = async () => {
    console.log(rmForSwap, ";;;;;;;;llllllllllll", selectedSchools)

    let data = {
      selectOwnerId: rm,
      selectedSchools: selectAll,
      ownerIdArr: selectedSchools,
      updateOwnerId: rmForSwap
    }

    let results = await window.Platform.database.swipeOwnerIdForSchool(data);
    if (results) {
      window.NotificationUtils.showSuccess("RM Swaped SuccessFully");
      fetchEmployees()
      setSelectedSchools([])
      // setShowSwapCheck(false)
    }

    setOpen(false)
  }


  const renderTabContent = () => {

    if (selectedTab === 0) {
      return (<>
        {/* <div>
        <Button onClick={()=>{swapColumnShow()}}>Swap</Button>
        <Button onClick={()=>{swapFunctionality()}}>New Swap</Button>
      </div> */}
        <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="swap-modal">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              bgcolor: "white",
              boxShadow: 24,
              p: 3,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Typography id="swap-modal" variant="h6" mb={2}>
              Swap
            </Typography>

            {/* RM Dropdown */}
            <Select
              value={rmForSwap}
              onChange={(e) => setRmForSwap(e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select RM</MenuItem>
              {rms.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>

            {/* Buttons */}
            <Box mt={2} gap={3} display="flex" justifyContent="center">
              <Button variant="contained" color="secondary" onClick={() => handleOkSwap()}>
                Ok
              </Button>
              <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
        <div className="d-flex justify-content-end align-items-center  py-2">
          <div>
            {showSwapCheck ?
              <>
                <Button className="mr-3" onClick={() => { swapFunctionality() }} variant="contained" color="primary" >Proceed</Button>
                <Button className="mr-4" variant="contained" color="primary" onClick={() => { setShowSwapCheck(false) }}>Cancel</Button>
              </>
              :
              <Button onClick={() => { swapColumnShow() }} variant="contained" color="primary" className="mr-4">Swap</Button>
            }

          </div>
          <Typography variant="body1">Select Page Size:&nbsp; </Typography>
          <Select
            value={paginationModel.pageSize}
            variant="outlined"
            size="small"
            onChange={(e) => { setPaginationModel({ ...paginationModel, pageSize: e.target.value }) }}
            sx={{ width: 90, fontSize: 14 }}
          >
            {[10, 20, 30, 40, 50, 60].map((role) => (
              <MenuItem key={role} value={role}>{role}</MenuItem>
            ))}
          </Select>
        </div>
        <DataGridContainer
          style={{ height: !visibleEmployees.length ? "200px" : "auto" }}
        >
          <DataGrid
            className="payrollGrid"
            // checkboxSelection={true}
            density="compact"
            rows={visibleEmployees || []}
            columns={schoolColumns()}
            // disableSelectionOnClick

            disableSelectionOnClick
            disableRowSelectionOnClick

            onRowClick={(rowParams, event) => {
              setUserModal(rowParams.row);
              // console.log(rowParams.onRowClick);
            }}
            rowHeight={60}
            rowCount={rowCount}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}

          />
        </DataGridContainer>
      </>
      );
    } else if (selectedTab === 1) {
      return (
        <>
          <div className="d-flex justify-content-end align-items-center  py-2">
            <Typography variant="body1">Select Page Size:&nbsp; </Typography>
            <Select
              value={paginationModel1.pageSize}
              variant="outlined"
              size="small"
              onChange={(e) => { setPaginationModel1({ ...paginationModel1, pageSize: e.target.value }) }}
              sx={{ width: 90, fontSize: 14 }}
            >
              {[10, 20, 30, 40, 50, 60].map((role) => (
                <MenuItem key={role} value={role}>{role}</MenuItem>
              ))}
            </Select>
          </div>
          <DataGridContainer
            style={{ height: !visibleEmployees?.length ? "200px" : "auto" }}
          >
            <DataGrid
              className="payrollGrid"
              // checkboxSelection={true}
              density="compact"
              rows={visibleEmployees || []}
              columns={distributorColumn()}
              // disableSelectionOnClick

              disableSelectionOnClick
              disableRowSelectionOnClick
              pageSizeOptions={[10]}
              onRowClick={(rowParams, event) => {
                setUserModal(rowParams.row);
                // console.log(rowParams.onRowClick);
              }}
              rowHeight={60}

              filterMode="server"
              rowCount={rowCount1}
              paginationModel={paginationModel1}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel1}

            />
          </DataGridContainer>
        </>
      );
    }
  };



  const onClose = async (data) => {
    // console.log("COMMONOTPMODAL", data.makerContact);
    try {
      // const response = await window.Platform.database.sendOtp({ mobile: data.makerContact })
      // window.NotificationUtils.showSuccess("OTP Sent Successfully");

      setOtpModalData(data);
      submitOtp(data);
      // setOtpModal1(true)
      // setSchoolModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseDistributor = async (data) => {
    // console.log("COMMONOTPMODAL", data.makerContact);
    try {
      // const response = await window.Platform.database.sendOtp({ mobile: data.makerContact })
      // window.NotificationUtils.showSuccess("OTP Sent Successfully");

      setOtpModalDataDistributor(data);
      submitOtpDistributor(data);
      // setOtpModalDistributor(true)
      // setDistributorModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
    }
  };

  const resendOTP = async (data) => {
    try {
      setLoading(true);
      const response = await window.Platform.database.resendOTP({
        mobile: otpModalData.makerContact,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");

      // setDistributorModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
    }
  };
  const getClusterInPicklist = async () => {
    setLoading(true);
    try {
      let results = await window.Platform.database.getClusterInPicklist();

      const jsonArrayWithId = results?.data?.map((obj) => ({
        ...obj,
        dbId: obj.id
      }));


      setClusters(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error("ERRORCHECK", error);
      setLoading(false);
    }
  };

  const resendOTPDistributor = async (data) => {
    try {
      setLoading(true);
      const response = await window.Platform.database.resendOTP({
        mobile: otpModalDataDistributor.makerContact,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");

      // setDistributorModal(false)
      // setEditSchool(false)
    } catch (error) {
      console.log(error);
    }
  };

  const submitOtp = async (data) => {
    try {
      setLoading(true);
      const response1 = await window.Platform.database.addSchool(data);
      if (response1.status === true) {
        window.NotificationUtils.showError(response1.message);
        setSchoolModal(false);
        setLoading(false);
        return;
      } else {
        setSchoolModal(false);
        setLoading(false);
        window.NotificationUtils.showError(response1.message);
        setLoading(false);
      }
      setSchoolModal(false);
      setLoading(false);
      fetchEmployees();
      window.NotificationUtils.showSuccess("Party Added Successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message.includes("ER_DUP_ENTRY")) {
        window.NotificationUtils.showError("Duplicate school ID detected. Please use a unique school ID.");
      } else {
        window.NotificationUtils.showError("Duplicate school ID detected. Please use a unique school ID.");
      }
    }
  };

  // const submitOtp = async (data) => {
  //   try {
  //     // const response = await window.Platform.database.verifyOtp({ otp: data, mobile: otpModalData.makerContact })
  //     // if (response.status === true) {
  //     //     window.NotificationUtils.showError(response.message);
  //     //     setLoading(false)

  //     //     return;
  //     // }
  //     // setOtpModal1(false)
  //     setLoading(true);
  //     const response1 = await window.Platform.database.addSchool(data);
  //     if (response1.status === true) {
  //       window.NotificationUtils.showError(response1.message);
  //       setSchoolModal(false);
  //       setLoading(false);

  //       return;
  //     }
  //     else{
  //       setSchoolModal(false);
  //       setLoading(false);
  //       window.NotificationUtils.showError(response1.message);
  //       setLoading(false);
  //     }
  //     setSchoolModal(false);
  //     setLoading(false);
  //     fetchEmployees();
  //     window.NotificationUtils.showSuccess("Party Added Successfully");
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };
  const submitOtpDistributor = async (data) => {
    try {
      // const response = await window.Platform.database.verifyOtp({ otp: data, mobile: otpModalDataDistributor.makerContact })
      // if (response.status === true) {
      //     window.NotificationUtils.showError(response.message);
      //     setLoading(false)

      //     return;
      // }
      // setOtpModalDistributor(false)

      setLoading(true);
      const results = await window.Platform.database.addDistributor(data);
      if (results.status === true) {
        window.NotificationUtils.showError(results.message);
        setLoading(false);

        return;
      }

      setDistributorModal(false);
      setLoading(false);
      fetchEmployees();
      window.NotificationUtils.showSuccess("Party Added Successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const exportSchool = async () => {
    setLoading(true);
    try {
      let data = {
        pageNumber: paginationModel.page,
        filter: search,
        recordPerPage: paginationModel.pageSize,
        cluster: selectedCluster,
        ownerId: rm,
        rsm: rsm,
        asm: asm
      };

      // const result = await axiosInstance.post(

      // data,
      // {
      //     responseType: "blob",
      //     Token : 'token='+localStorage.getItem("token")
      // }
      // );

      let result = await window.Platform.database.exportData("/party/exportSchool",
        data
      )

      // Check if the response is actually a Blob
      // console.log(result, "API Response Data");

      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "school.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Export error:", error);
    }
  };


  const exportDistributor = async () => {
    setLoading(true);
    try {
      let data = {
        filter: search,
        cluster: selectedCluster,
        ownerId: rm,
        rsm: rsm,
        asm: asm
      };

      let result = await window.Platform.database.exportData("/party/exportDistributor",
        data
      )

      // Check if the response is actually a Blob
      // console.log(result, "API Response Data");

      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "distributors.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Export error:", error);
    }
  };

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>

          <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
            <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
              <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
                <Tooltip title="Search By Id or Name">
                  <div>
                    <Typography variant="body1">Enter to search</Typography>
                    <TextField

                      variant="outlined"
                      size="small"
                      placeholder="Search By Id or Name"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)} // Proper event handler
                      sx={{ width: 250, fontSize: 14 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search color="action" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Tooltip>

                {userData?.role.includes('admin') && <div>
                  <Typography variant="body1">Select RSM:</Typography>
                  <Select
                    value={rsm}
                    variant="outlined"
                    size="small"
                    onChange={(e) => { setRsm(e.target.value); getUsers('rsm', e.target.value) }}
                    sx={{ width: 170, fontSize: 14 }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {rsms.map((role) => (
                      <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                    ))}
                  </Select>
                </div>}
                {userData?.role != 'asm' && <div >
                  <Typography variant="body1">Select ASM:</Typography>
                  <Select
                    value={asm}
                    variant="outlined"
                    size="small"
                    onChange={(e) => { setAsm(e.target.value); getUsers('asm', e.target.value) }}
                    sx={{ width: 170, fontSize: 14 }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {asms.map((role) => (
                      <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                    ))}
                  </Select>
                </div>}
                <div >
                  <Typography variant="body1">Select RM:</Typography>
                  <Select
                    value={rm}
                    variant="outlined"
                    size="small"
                    onChange={(e) => { setRm(e.target.value) }}
                    sx={{ width: 170, fontSize: 14 }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {rms.map((role) => (
                      <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                    ))}
                  </Select>
                </div>
                <div >
                  <Typography variant="body1">Select Cluster:</Typography>
                  <Select
                    value={selectedCluster}
                    variant="outlined"
                    size="small"
                    onChange={(e) => { setSelectedCluster(e.target.value) }}
                    sx={{ width: 170, fontSize: 14 }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {clusters.map((role) => (
                      <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
              <div className="mt-3">
                {selectedTab == 0 ? <Button variant="contained" color="primary" onClick={() => { exportSchool() }}>Download</Button> : <Button variant="contained" color="primary" onClick={() => { exportDistributor() }}>Download</Button>}
              </div>

            </Box>
          </div>

          {/* <Grid item xs={12} md={8} textAlign="right">
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
              variant="contained"
              color="primary"
              onClick={
                selectedTab === 0
                  ? () => setBulkSchool(true)
                  : () => setBulkDistributor(true)
              }
              sx={{ borderRadius: "10px" }}
            >
              <Add sx={{ mr: 1 }} />
              {selectedTab === 0 ? "Bulk Add School" : "Bulk Add Distributor"}
            </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={selectedTab === 0 ? addNewSchool : addNewDistributor}
                sx={{ borderRadius: "10px" }}
              >
                <Add />
                {selectedTab === 0 ? "Add School" : "Add Distributor"}
              </Button>
            </Stack>
          </Grid> */}

        </HeaderContainer>
        <div style={{ margin: "5px" }}>
          <Grid item xs={12} md={8} textAlign="right">
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={
                  selectedTab === 0
                    ? () => setBulkSchool(true)
                    : () => setBulkDistributor(true)
                }
                sx={{ borderRadius: "10px" }}
              >
                <Add sx={{ mr: 1 }} />
                {selectedTab === 0 ? "Bulk Add School" : "Bulk Add Distributor"}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={selectedTab === 0 ? addNewSchool : addNewDistributor}
                sx={{ borderRadius: "10px" }}
              >
                <Add />
                {selectedTab === 0 ? "Add School" : "Add Distributor"}
              </Button>
            </Stack>
          </Grid>
        </div>
        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="School" />

              {<Tab label="Distributor" />}
            </StyledTabs>
            {loading && <OpaqueLoading />}

            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>

      {otpVerify && (
        <CommonOtpModalConsent
          data={consentData}
          closeModal={() => setOtpVerify(false)}
          onClickSubmit={onSubmitOtp}
          resendOTP={consentOtpResend}
        />
      )}
      {schoolModal && (
        <SchoolModal
          onClose1={(data) => onClose(data)}
          onClose={() => setSchoolModal(false)}
          fetchData={() => fetchEmployees()}
          status={true}
        />
      )}
      {distributorModal && (
        <DistributorModal
          onClose={() => setDistributorModal(false)}
          onClose1={(data) => onCloseDistributor(data)}
          fetchData={() => fetchEmployees()}
          status={true}
        />
      )}

      {editSchool && (
        <SchoolModal
          onClose={() => setEditSchool(false)}
          fetchData={() => fetchEmployees()}
          dataProp={editData}
          status={false}
        />
      )}
      {editDistributor && (
        <DistributorModal
          onClose={() => setEditDistributor(false)}
          fetchData={() => fetchEmployees()}
          dataProp={editData1}
          status={false}
        />
      )}
      {otpModal1 && (
        <CommonOtpModal
          closeModal={() => setOtpModal1(false)}
          onClickSubmit={(data) => submitOtp(data)}
          resendOTP={() => resendOTP()}
        />
      )}

      {otpmModalDistributor && (
        <CommonOtpModal
          closeModal={() => setOtpModalDistributor(false)}
          onClickSubmit={(data) => submitOtpDistributor(data)}
          resendOTP={() => resendOTPDistributor()}
        />
      )}

      {bulkSchool && (
        <BulkAddSchool
          onClose={() => setBulkSchool(false)}
          fetchData={() => fetchEmployees()}
        />
      )}
      {bulkDistributor && (
        <BulkAddDistributor
          onClose={() => setBulkDistributor(false)}
          fetchData={() => fetchEmployees()}
        />
      )}
    </>
  );
};

export default PartyManagement;
