import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete
} from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from "@mui/material/Tooltip";
import { Check as CheckIcon, Clear as ClearIcon, Close, ContactSupportOutlined, CropSharp, Details } from "@mui/icons-material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { ACTIVITY_STATUS, ROLES } from "../../constants";
import moment from "moment";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { AuthContext } from "../contextAPI/ContextAPI";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { all } from "axios";
 
const isMobile = window.innerWidth < 900;
 
const TotalAmountTypography = styled(Typography)`
 
  position: absolute;
 
  bottom: 10px;
 
  right: 10px;
 
  font-size: ${isMobile ? "14px" : "inherit"};
 
  bottom: ${isMobile ? "85px" : "70px"};
 
  right: ${isMobile ? "15px" : "70px"};
 
`;

const Container = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // margin-bottom: 15px;
  // padding: 10px;
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? "106px - 20px - 82.23px" : "100px - 20px - 23.77px"}
  // );
  width: ${isMobile?"100%":"98%"};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left:${isMobile?"0px":"10px"};
  margin-bottom:${isMobile?"0px":"10px"};

`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile && `
    height: calc(100vh - 176px - 20px - 43.77px);
  `}
`
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;
 
const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
 
const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;
 
const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
 
const StyledDialog = styled(Dialog)`
  position: relative;
`;
 
const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
 
const DataGridContainer = styled.div`
  width: 100%;
 
 
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom:${!isMobile&& "20px"};
 
//   height: 500px;
`;
 
const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;
 
const Input = styled.input`
  display: none;
`;
 
 
const UserModal = ({ props,onClose, discardModal, remarks,data,id,distributorId,selectedTab}) => {
  const [changePassword, setChangePassword] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  console.log("DATAAUTH",auth.user.plant_master__c );
  const [dob, setDob] = useState('')
 const [allusers, setAllusers] = useState([])
  const [errors, setErrors] = useState({});

//  console.log("vaalues", data)
  const [details, setDetails] = useState({
    "password": "",
    "username": data.username || "",
    "email": data.email || "",
    "name": data.name || "",
    "incentive": data.incentive || "",
    "mobno": data.mobno || "",
    "role": data.role || "",
    "roleId": data.roleId || "",
    "cluster":data.cluster || "",
    "dob": data.dob || null,
    "pin": data.pin || "",
    "state": data.state || "",
    "district": data.district || "",
    "AddressLine1": data.AddressLine1 || "",
    "AddressLine2": data.AddressLine1 || "",
    "landmark": data.landmark || "" ,
"reportingManager": data.reportingManagerName || "",

  });
  useEffect(()=> {
    console.log("DATAs",dob);

    // setDetails(data)
  },[dob])

  const getAddress = async (data) => {
    try{
      setLoading(true)
      let results = await window.Platform.database.getUserById(data);
  console.log("pincode: " ,results, data)
  // getUsersOnRole(results.data.role)
  fetchReportingManagerRole(results.data[0].role)
  setDetails({...results.data[0]})
  fetchPinCode(results.data[0].Pincode)
  setDob(moment.unix(results.data[0].dob).format('YYYY/MM/DD'));

    setLoading(false)
    
  
  // setDetails(results.data[0]);
  // setProduct(results.data)
  }
  catch(err){
    console.log(err);
  setLoading(false)
   
    }
  }

  useEffect(()=>{
    if(data.id)
    {
      console.log('data is available', data)
      getAddress(data.id);
    }
  },[])

  // const newDate=new Date(dataProp.incorporationDate)
  
  const [roles, setRoles] = useState([])
  const [plantCode,setPlantCode]=useState("")
  const [loading,setLoading]=useState(false)
  const [product,setProduct]=useState()
  const [dynamicFields, setDynamicFields] = useState(data?.orderDetails?.map((obj, index) => ({pricebookentryid:'',name:obj.product_name__c,quantity:obj.quantity,unitprice:obj.unitprice,totalprice:obj.totalprice,oid:obj.orderitemnumber, product2id:obj.product2id,id: index + 1 })));
  const [deletedField,setDeletedFields]=useState([])
  const [clusters,setClusters] = useState([])
 
  const [hasChanges, setHasChanges] = useState(false);
 const [remark,setRemrak]=useState(remarks)
 
 
  const handleChangeTab = (event, newValue) => {
    console.log("newValue: " , newValue);
    setTabValue(newValue);
  };
const [state , setState] = useState('')
const [district , setDistrict] = useState('')

useEffect(()=> {
  // const newDate=new Date(data.dob)
// formatDate(dayjs(newDate))
},[])

 
const fetchPinCode=async(details)=>{
  try{
    setLoading(true)
    // console.log("PLANTCODE",plantcode[0].plant_code__c)
    let results = await window.Platform.database.fetchPinCode(details);
console.log("pincode: " ,results.data[0].StateName)
  setState(results.data[0].StateName)
  setDistrict(results.data[0].District)
  setLoading(false)

// setDetails(results.data[0]);
// setProduct(results.data)
}
catch(err){
  console.log(err);
setLoading(false)
 
  }
}
   
useEffect(()=>{
fetchPlantCode(distributorId)
fetchRoles();
},[])
 
useEffect(()=>{
// if(tabValue===1){
//   fetchProduct(plantCode,distributorId)
// }
console.log("currentTab", tabValue)
},[tabValue])
 
  const getItemColumns = () => {
    let result = [
      {
        field: "id",
        headerName: "Order Item Number",
        sortable: false,
        width: 150,
        valueGetter:(params)=>params.row?.orderitemnumber||"N/A",
        renderCell: (params) => {
          let val =  params.row?.orderitemnumber ||"N/A" ;
          return <Tooltip title={val}>{val}</Tooltip>;
 
        }
        // editable: true,
      },
     
      {
        field: "product_name__c",
        headerName: "Product Name",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "listprice",
        headerName: "List Price",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "unitprice",
        headerName: "Unit Price",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "totalprice",
        headerName: "Total Price",
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: "status__c",
        headerName: "Status",
        sortable: false,
        width: 150,
        // editable: true,
      }
    ];
 
    return result;
  };


 
const fetchData=async(details)=>{
  try{
    setLoading(true)
    // console.log("PLANTCODE",plantcode[0].plant_code__c)
    let results = await window.Platform.database.getUserById(details);
console.log("PRODUCT RESULTS: " ,results.data[0])
setDetails(results.data[0]);
// setProduct(results.data)
setLoading(false)
  }
  catch(err){
    console.log(err);
setLoading(false)
 
  }
}


 const [roleId, setRoleId] = useState([])
// const fetchRoles=async(details)=>{
//   try{
//     setLoading(true)
//     // console.log("PLANTCODE",plantcode[0].plant_code__c)
//     let results = await window.Platform.database.fetchRoles(details);
// console.log("PRODUCT RESULTS: " ,results.data.map((data)=>  data.roleName ))

// // setDetails(results.data[0]);
// setRoles(results.data.map((data)=> data.roleName))
// setRoleId(results.data)
// // setProduct(results.data)
// setLoading(false)
//   }
//   catch(err){
//     console.log(err);
// setLoading(false)
 
//   }
// }
 
const fetchRoles = async (details) => {
  try {
    setLoading(true);

    // Fetching the roles from the API
    let results = await window.Platform.database.fetchRoles(details);
    console.log("PRODUCT RESULTS:", results.data.map((data) => data.roleName));

    // Filter the data based on hierarchy and map role names
    const filteredRoles = results.data
        // Filter roles with non-empty hierarchy
      .map((data) => {
        let roleName = data.roleName.toLowerCase() === "se" ? "RM" : data.roleName;
        // Capitalize only the first letter of each word
        roleName = roleName.toUpperCase()
        return { ...data, roleName };
      });

    // Set the roles and role IDs
    setRoles(filteredRoles.map((data) => data.roleName));
    setRoleId(filteredRoles);
    setLoading(false);
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
};

// const fetchRoles = async (details) => {
//   try {
//     setLoading(true);

//     // Fetching the roles from the API
//     let results = await window.Platform.database.fetchRoles(details);
//     console.log("PRODUCT RESULTS:", results.data.map((data) => data.roleName));

//     // Filter the data based on hierarchy and map role names
//     const filteredRoles = results.data
//       // .filter((item) => item.hierarchy)  // Filter roles with non-empty hierarchy
//       .map((data) => {
//         let roleName = data.roleName.toLowerCase() === "se" ? "RM" : data.roleName;
//         // Capitalize only the first letter of each word except "RM"
//         roleName = roleName
//           .split(" ")
//           .map((word) => (word.toUpperCase() === "RM" ? "RM" : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
//           .join(" ");
//         return { ...data, roleName };
//       });

//     // Set the roles and role IDs
//     setRoles(filteredRoles.map((data) => data.roleName));
//     setRoleId(filteredRoles);
//     setLoading(false);
//   } catch (err) {
//     console.log(err);
//     setLoading(false);
//   }
// };

 
  const fetchPlantCode=async(data)=>{
try{
setLoading(true)
 
  let results = await window.Platform.database.getDepotsForOrder({distributorSfid:data});
  console.log("RESULTS",results)
const plantCode=results?.data?.filter((data)=>data.sfid===auth.user.plant_master__c)
console.log("plantCode",plantCode)
setPlantCode(plantCode)
setLoading(false)
 
}
catch(err){
console.log(err)
setLoading(false)
}
  }
 
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: "product-list",
          }}
        />
       
      </GridToolbarContainer>
    );
  }
 
  const generateSO = async(val) => {
 
        // try {
    //   console.log(val);
    //   // let results = await window.fdatabase.approveAdminQuotation(val)
    //   window.NotificationUtils.showSuccess("Quotation Approved");
    //   // return results
    // } catch (error) {
    //   window.NotificationUtils.showError(error);
    // }
  }

  const onlyNumeric = (event, type) => {
    if (isNaN(event.target.value)) {

      let mobno, pinCode;

      if(type === "mobile") {
        mobno = event.target.value.replace(/\D/g, "");
        setDetails((prev) => {
          return { ...prev, mobno }
        });
      } else {
        pinCode = event.target.value.replace(/\D/g, "");
        setDetails((prev) => {
          return { ...prev, Pincode: pinCode }
        });
      }
    }
  }
 
 
//   useEffect(()=> {
//     if(data)
// fetchData(details)
//   },[])
 
 useEffect(()=> {
  getClusterInPicklist()
 },[])




const getClusterInPicklist = async () => {
  setLoading(true);
  try {
    let results = await window.Platform.database.getClusterInPicklist();
    
    // const jsonArrayWithId = results?.data?.map((obj) => ({ 
    //   ...obj, 
    //   dbId: obj.id
    // }));
    
   if(results && results.status){
    setClusters(results.data)
   }
    // setClusters(jsonArrayWithId);
    setLoading(false);
  } catch (error) {
    console.error("ERRORCHECK", error);
      setLoading(false);
  }
};


 
  const calculateTotalPrice = (quantity, unitPrice) => {
    const totalPrice = quantity * unitPrice;
   
    return isNaN(totalPrice) ? "" : totalPrice;
  };
 
  const handleQuantityChange = (index, value) => {
    console.log("VALUEE",value)
    const sanitizedValue = value.replace(/[^0-9]/g, '');
 
 
    setDynamicFields((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index].quantity = sanitizedValue;
      updatedDetails[index].totalprice=sanitizedValue*updatedDetails[index].unitprice
      setHasChanges(true);
 
      return updatedDetails;
    });
 
  };

  console.log("DynamicFields",dynamicFields)
 
  const handleTextFieldChange = (index, quantity,unit) => {
    const price=calculateTotalPrice(quantity,unit)
    setDynamicFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].totalprice = price;
      return updatedFields;
    });
  };
 
//   const detailUpdate = (key, value) => {
//     if (!hasChange) {
//       setHasChange(!hasChange);
//     }
//     var newDetails = { ...detailsRef.current };
//     if (key === "email") {
//       newDetails.email = window.trimEmail(value);
//     } else {
//       newDetails[key] = value;
//     }
//     if(key === "warehouse"){
//       console.log("sss",newDetails.warehouse);
//       if (typeof newDetails.warehouse === "object" && newDetails.warehouse !== null) {
//         console.log("sss", newDetails.warehouse);
//         // Assuming value is the new id you want to set
//         newDetails.warehouse["id"] = value;
//     } else {
//         // If newDetails.warehouse is not an object, initialize it as an object
//         newDetails.warehouse = { id: value };
//     }
//     }
//     setDetails(newDetails);
//   };
const [hasChange, setHasChange] = useState(false);
const [selectedReportingManager, setSelectedReportingManager] = useState('');
const detailsRef = useRef();

const detailUpdate = (key, value) => {
  if (!hasChange) {
    setHasChange(!hasChange);
  }
  var newDetails = { ...details };
  // if (key === "email") {
  //   newDetails.email = window.trimEmail(value);
  // } else {

    newDetails[key] = value;

  // }

  if(key === 'role')
  {
    let roleIID = roleId.find((val) => val?.roleName === value);
              // console.log("ROLEID", roleIID?.roleId);
    setDetails({...newDetails, roleId: roleIID?.roleId});
    return
  }
  
  setDetails(newDetails);

  if (key === "reportingManager") {
    setDetails({ ...newDetails, reportingManager: value });
    return;
  }
};


  const handleSelectChange = (index, value) => {
    console.log("SELECTEDVAL",value)
    const unitPrice=product?.filter((data)=>(data.name===value))
    setDynamicFields((prevFields) => {
      // console.log("UNITPRICE",unitPrice[0].unitprice)
      const updatedFields = [...prevFields];
      updatedFields[index].name = value;
      updatedFields[index].pricebookentryid = unitPrice[0]?.pricebookentryid;
 
      updatedFields[index].unitprice = unitPrice[0]?.unitprice;
      updatedFields[index].product2id=unitPrice[0]?.product2id
      setHasChanges(true);
 
      return updatedFields;
    });
  };
  const isLastItemComplete = () => {
    const lastItem = dynamicFields?.[dynamicFields?.length - 1];
 
    if (lastItem) {
      const isProductComplete = lastItem.product2id !== "" && lastItem.name !== "";
      const isQuantityComplete = lastItem.quantity !== "";
 
      return isProductComplete && isQuantityComplete;
    } else {
      return true;
    }
  };
const handleDeleteField = (index) => {
console.log("DATAAAA",data?.orderDetails)
  setDynamicFields((prevDetails) => {
    const updatedDetails = [...prevDetails];
    const deletedItem = updatedDetails.splice(index, 1)[0]; // Remove the item and store it
   console.log("DELETE",deletedItem)
    setDeletedFields((prevDeletedFields) => [...prevDeletedFields, deletedItem]); // Store in another array
    setHasChanges(true);
    return updatedDetails;
  });
};
console.log("NO OF DELTED FIELD",deletedField?.length)
const remarkOnChange=(value)=>{
  setHasChanges(true);
  console.log("REMOVE",value)
setRemrak(value)
}

const fetchReportingManagerRole = async(data) => {
  try{
    setLoading(true)
     
    const payload = data === "RM" ? "se" : data.toLowerCase();

      let results = await window.Platform.database.fetchReportingManagerRole(payload);
      console.log("RESULTS",results) 
    // const plantCode=results?.data?.filter((data)=>data.sfid===auth.user.plant_master__c)
    // console.log("plantCode",plantCode)
    getUsersOnRole(results.data.results[0].hierarchy)
    // setPlantCode(plantCode)
    // window.NotificationUtils.showSuccess("fetched Successfully")
    setLoading(false)
    // onClose()


     
    }
    catch(err){
    console.log(err)
    // window.NotificationUtils.showError("User creation Failed")

    setLoading(false)
    }
}
const [reportingUserLabel, setReportingUserLabel] = useState([])
const getUsersOnRole = async(data) => {
  try{
    setLoading(true)
     
      let results = await window.Platform.database.getUsersOnRole(data);
      console.log("RESULTS",results) 
    // const plantCode=results?.data?.filter((data)=>data.sfid===auth.user.plant_master__c)
    // console.log("plantCode",plantCode)
    // setPlantCode(plantCode)
    // window.NotificationUtils.showSuccess("fetched Successfully")
    setLoading(false)
    // onClose()
// (results.data.results)

setReportingUserLabel(results.data.results)
     setAllusers(results.data.results.map((data)=> data.id))

    }
    catch(err){
    console.log(err)
    // window.NotificationUtils.showError("User creation Failed")

    setLoading(false)
    }
}

const validateForm = (data) => {
  const errors = {};

  if (!data.name?.trim()) {
    errors.name = 'Name is required';
  }

  if (!data.username?.trim()) {
    errors.username = 'Username is required';
  }

  if (!data.email?.trim()) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
    errors.email = 'Email is invalid';
  }
  
  if (!data.mobno?.trim()) {
    errors.mobile = 'Mobile is required';
  }
  
  if (!data.role?.trim()) {
    errors.role = 'Role is required';
  }

  if (!data.cluster) {
    errors.cluster = 'Cluster is required';
  }

  if (!data.Pincode?.trim()) {
    errors.pin = 'Pin is required';
  } else if (data.Pincode?.length < 6) {
    errors.pin = 'Pin code must be exactly 6 digits long.';
  }

  if (data.roleId?.trim() !== "WYBlHskzHA") {
    if (!data.reportingManager?.trim()) {
      errors.reportingManager = 'Reporting manager is required';
    }
  }

  return errors;
};


const createNewUser = async() => {
  
// console.log(details,"details")
//   return;

  try{
    setLoading(true);
    const errors = validateForm(details);
    setErrors(errors);
    
    if (Object.keys(errors).length === 0) {
      const role1 = details?.role.toUpperCase() === "RM" ? "se" : details?.role.toLowerCase();
      const formattedDetails = { 
                ...details, 
                role: role1, 
                state: state, 
                district: district, 
                reportingManager: details.reportingManager, 
                dob: moment(dob).unix() 
              };
        console.log("formattedDetail=>>>>>>>>>>>>>>>>>s",formattedDetails)
              // return;
              let results = await window.Platform.database.createNewUser(formattedDetails);

      // let results = await window.Platform.database.createNewUser({...details, "state": state, "district": district, reportingManager: details.reportingManager , dob: moment(dob).unix() });
      // console.log("RESULTSasss",{...details, "state": state, "district": district, reportingManager: details.reportingManager.id }) 
    // const plantCode=results?.data?.filter((data)=>data.sfid===auth.user.plant_master__c)
    // console.log("plantCode",plantCode)
    // setPlantCode(plantCode)

      if (results.message === "User already exists") {
        window.NotificationUtils.showError("This user account already exists in our system.");
        setLoading(false);
        return;
      }

      window.NotificationUtils.showSuccess("The user has been successfully created.");
      setLoading(false);
      onClose();
      
    } else {
      window.NotificationUtils.showError("Please make sure to complete all required fields in the form.");
      setLoading(false);
    }

  } catch(err) {
      console.log("Error while creating the user: " + err);
      window.NotificationUtils.showError(err.response.data.message);
      setLoading(false);
    }
}

// const createNewUser = async () => {
//   try {
//     setLoading(true);
//     const errors = validateForm(details);
//     setErrors(errors);

//     if (Object.keys(errors).length === 0) {
//       // Convert role to lowercase before sending
//       const formattedDetails = { 
//         ...details, 
//         role: details.role.toLowerCase(), 
//         state: state, 
//         district: district, 
//         reportingManager: details.reportingManager, 
//         dob: moment(dob).unix() 
//       };

//       let results = await window.Platform.database.createNewUser(formattedDetails);

//       if (results.message === "User already exists") {
//         window.NotificationUtils.showError("This user account already exists in our system.");
//         setLoading(false);
//         return;
//       }

//       window.NotificationUtils.showSuccess("The user has been successfully created.");
//       setLoading(false);
//       onClose();
//     } else {
//       window.NotificationUtils.showError("Please make sure to complete all required fields in the form.");
//       setLoading(false);
//     }
//   } catch (err) {
//     console.log("Error while creating the user: " + err);
//     window.NotificationUtils.showError(err.response.data.message);
//     setLoading(false);
//   }
// };


const editUser = async() => {
  try{
    setLoading(true)

     console.log("edited User new",{...details, "state": state, "district": district, id: data.id, reportingManager: details.reportingManager
    //  , dob: data.dob ? new Date(data.dob * 1000).toISOString().split('T')[0] : undefined
  
    }, details
     )
     console.log("detailsss", details)

    const errors = validateForm(details);
    setErrors(errors);
    
    if (Object.keys(errors).length === 0) {

      let results = await window.Platform.database.editUser({
        ...details,
        state: state,
        district: district,
        id: data.id,
        reportingManager: details.reportingManager,
        dob: moment(dob).unix()
      });

      if (results.message === "User already exists") {
        window.NotificationUtils.showError("User already exists");
        setLoading(false);
        return;
      }

      window.NotificationUtils.showSuccess("User updated successfully");
      setLoading(false);
      onClose();

    } else {
      window.NotificationUtils.showError("Please make sure to complete all required fields in the form.");
      setLoading(false);
    }


     
    }
    catch(err){
    console.log(err)
    window.NotificationUtils.showError(err.message)

    setLoading(false)
    }
}

const deleteUser = () => {
  window.confirmationModal(
    `Are you sure you want to deactivate ${details.name}(${details.id})?`,
    deleteUser1
  );
};

const activateUser = () => {
  window.confirmationModal(
    `Are you sure you want to activate ${details.name}(${details.id})?`,
    activateUser1
  );
};

const activateUser1 = async() => {
  try{
      setLoading(true)
      let results = await window.Platform.database.activateUser({id: data.id });
      window.NotificationUtils.showSuccess("User activated successfully")
      setLoading(false)
      onClose()
    }
    catch(err){
      window.NotificationUtils.showError("User activation Failed")
      setLoading(false)
    }
}

const deleteUser1 = async() => {
  try{
    setLoading(true)
     console.log("edited User new",{...details, "state": state, "district": district, id: data.id, reportingManager: details.reportingManager})
      let results = await window.Platform.database.deleteUser({...details, "state": state, "district": district, id: data.id, reportingManager: details.reportingManager });
      console.log("RESULTS",results) 
    // const plantCode=results?.data?.filter((data)=>data.sfid===auth.user.plant_master__c)
    // console.log("plantCode",plantCode)
    // setPlantCode(plantCode)
    window.NotificationUtils.showSuccess("User deactivated successfully")
    setLoading(false)
    onClose()


     
    }
    catch(err){
    console.log(err)
    window.NotificationUtils.showError("User deactivation Failed")

    setLoading(false)
    }
}

const formatDate=(data)=>{
  console.log("DATAAAA",data   )
  // setDate(data)
  const timestamp = new Date(data.$d);
 
  const datePickerResponse = new Date(data.$d);

  const year = datePickerResponse.getFullYear();
const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
const day = String(datePickerResponse.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
const unixTimestamp = Math.floor(timestamp.getTime() / 1000);
// setDate(data)
setDob(data)

detailUpdate("dob",unixTimestamp)
console.log("UNIX",unixTimestamp);
}

const renderContent1=()=>{
  return (
    <div style={{ maxHeight:"510px", height:"505px", overflowY:"auto" }}>
    <Stack direction={"row"} spacing={2} padding={2}>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
          {/* <TextField label="First Name" value={details.firstName || ""} type="text" fullWidth onChange={(e)=>remarkOnChange(e.target.value)}/> */}
          {/* <TextField label="Name" value={details.name || ""} type="text" fullWidth               
            onChange={(e) => detailUpdate("name", e.target.value)}

                      /> */}

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
          <TextField label="Name" value={details.name || ""} type="text" fullWidth disabled={data.status === 0}              
            onChange={(e) => detailUpdate("name", e.target.value)}
            required error={errors.name ? true : false}
            inputProps={{ maxLength: 255 }}
                      />

    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
          <TextField label="Email" value={details.email || ""} type="text"fullWidth  disabled={data.status === 0} 
                      onChange={(e) => detailUpdate("email", e.target.value)} required
                      error={errors.email ? true : false}
                      inputProps={{ maxLength: 255 }}
                      />
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
            <FormControl style={{ width: isMobile?"100%":"100%" }}>
              <TextField
                disabled={data.status === 0}
                label="Mobile"
                value={details.mobno || ""}
                type="text"
                fullWidth
                onChange={(e) => { detailUpdate("mobno", e.target.value); onlyNumeric(e, "mobile") }}
                inputProps={{ maxLength: 10 }}
                required
                error={errors.mobile ? true : false}
              />
            </FormControl>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
          <FormControl style={{ width: isMobile?"100%":"100%" }}>
           <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DatePicker label="Incorporation Date" sx={{width:"100%"}}  disabled={loading} format="YYYY/MM/DD" onChange={(data)=>formatDate(data)} /> */}
            <DatePicker
  label="DOJ"
  type="text"
  fullWidth
  format="YYYY/MM/DD"
  value={dayjs(dob)}

  disabled={data.status === 0}
  onChange={(data) => setDob(moment(Number(data)).format('YYYY/MM/DD'))}
  disableFuture={true}
/>
                      </LocalizationProvider>
                      </FormControl>
       

          </Stack>

          <Stack direction={"row"} spacing={2} padding={2}>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
          <TextField label="Address" value={details.AddressLine1 || ""} type="text" fullWidth           
          disabled={data.status === 0}  
           onChange={(e) => detailUpdate("AddressLine1", e.target.value)}
/>
          {/* <TextField label="Address 2" value={details.AddressLine2 || ""} type="text" fullWidth 
          disabled={data.status === 0} 
                      onChange={(e) => detailUpdate("AddressLine2", e.target.value)}
                      /> */}

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
           <TextField label="Pin Code" value={details.Pincode || ""} type="text" fullWidth 
           disabled={data.status === 0} 
                      onChange={(e) => 
                       {
                        console.log("logg", e.target.value)
                        if(e.target.value.length === 6) {
                          fetchPinCode(e.target.value)
                        }
                        detailUpdate("Pincode", e.target.value);
                        onlyNumeric(e, "pin");
                      }
                    
                    }
                      required
                      error={errors.pin ? true : false}
                      inputProps={{ maxLength: 6 }}
                      />

            <TextField label="State" value={state || ""} type="text"fullWidth disabled
              onChange={(e) => setState(e.target.value)}
            />
                    

          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
          {/* <TextField label="Landmark" value={details.landmark || ""} type="text" fullWidth 
                      onChange={(e) => detailUpdate("landmark", e.target.value)}
                      /> */}
       
                        <TextField label="District" value={district || ""} type="text" fullWidth  disabled

onChange={(e) => setDistrict(e.target.value)}
                      />
          <TextField label="Landmark" value={details?.Landmark || ""} type="text" fullWidth            disabled={data.status === 0} 

                      onChange={(e) => detailUpdate("Landmark", e.target.value)} inputProps={{ maxLength: 255 }} /> 
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
             <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "100%" }}>
  <InputLabel style={{ background: "white" }} required error={errors.cluster ? true : false}>Select Cluster</InputLabel>
  <Select
    labelId="Cluster"
    label="Clusters"
    value={details.cluster || data.cluster}
    onChange={(e) => detailUpdate("cluster", e.target.value)}
    required
                error={errors.cluster ? true : false}
  >
    {clusters?.map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          </Stack>
        {/* {renderTextFields(data?.orderDetails?.length)} */}
      
</div>
)}
// const fetchRoles = () => {
//     const roles = props?.user?.role || ['Admin'];
//     const filteredRoles = roles.filter(role => role !== "Super Admin");

//     const role=auth?.user?.role;
//      if(role !== ROLES.ADMIN && role !== ROLES.SUPER_ADMIN ){
//       const finalroles=filteredRoles.filter(role => role !== "Admin");

//       setRoles(finalroles);
//      }
//      else{

//     setRoles(filteredRoles);
//   }
// }
 
  const renderContent = (params) => {
    console.log("CheckTeabRenderContent",tabValue)
    return (
      <div style={{ maxHeight:"510px",height:"505px", overflowY:"auto" }}>
        {/* <DataGridContainer> */}
          {/* <Box sx={{ height: "100%", width: "100%" }}> */}
          <Stack direction={"row"} spacing={2} padding={2}>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
          <TextField label="Username" type="text" value={details.username || ""} fullWidth disabled={data.status === 0}   onChange={(e) => detailUpdate("username", e.target.value)} required error={errors.username ? true : false} inputProps={{ maxLength: 255 }} />
          
          <Autocomplete
          disabled={data.status === 0} 
            // options={roles
            //   .filter(role => role.toLowerCase() !== "se")
            //   .concat("rm")
            //   .map(role => role.toUpperCase())}

            options={roles}
            style={{ width: "100%" }}
            value={details?.role || ""}
            // value={details?.role?.toLowerCase() == "rm" ? "se" : details?.role?.toLowerCase() || ""}
            // disabled={loading 
              // || !addNewMode || props.isManager
            // }
            onChange={async (e, value) => {
              detailUpdate("role", value);
           
              
              fetchReportingManagerRole(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading 
                  // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={"Role"}
                required
                variant="outlined"
                error={errors.role ? true : false}
              />
            )}
            // onChange={(event, value) =>{ 
            //   if(value==='SCM Executive - China-Warehouse' || value==='SCM Executive - India-Warehouse' ){
            //     setWarehouseRole(value)
            //   }
            //   else{
            //     setWarehouseRole(null)
            //   }
            //   detailUpdate("role", value || "")}}
          />

          {/* <Stack direction={"row"} spacing={2} padding={2}> */}

          {/* </Stack> */}
          </Stack>
          <Stack direction={"row"} spacing={2} padding={2}>
    {/* <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading} onClick={handleAddFields}>
            Add Product
          </Button> */}
{/* <TextField label="Target Incentive" defaultValue={""} value={details.incentive || null}   onChange={(e) => detailUpdate("incentive", e.target.value)}/> */}
<TextField label="Target for the Incentive" type="text" value={details.incentive || ""} fullWidth disabled={data.status === 0}   onChange={(e) => detailUpdate("incentive", e.target.value)}/>

{/* <Autocomplete
  // options={allusers}

  options={allusers}
  disabled={data.status === 0} 
  // value={selectedContainer}
  value={details.reportingManager || data.reportingManager}
  getOptionLabel={(option) => {
    let manager = reportingUserLabel.find(
      (member) => member.id === option
    );
    console.log("manager:",option);
    return `${manager?.name} (${manager?.role})`; 
  }}
  // getOptionValue={(option) => {
  //   console.log("valuesOption",option)
  //   return option?.id || ''}}
  style={{ width: "100%" }}
  onChange={(event, selectedOption) => {
    console.log("selectedOption", selectedOption);
    detailUpdate("reportingManager", selectedOption);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Reporting Manager"
      required
      variant="outlined"
      error={errors.reportingManager ? true : false}
    />
  )}
/> */}


          <FormControl fullWidth variant="outlined">
            <InputLabel required>Reporting Manager</InputLabel>
            <Select
              value={details.reportingManager || data.reportingManager || ''}
              disabled={data.status === 0}
              onChange={(event) => {
                const selectedOption = event.target.value;
                console.log("selectedOption", selectedOption);
                detailUpdate("reportingManager", selectedOption);
              }}
              label="Reporting Manager"
              required
              error={errors.reportingManager}
            >
              {allusers.map((user) => {
                const manager = reportingUserLabel.find(member => member.id === user);
                return (
                  <MenuItem key={user} value={user}>
                    {manager ? `${manager.name} (${manager.role})` : user}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>


{/* <TextField label="Target Incentive" type="text" value={details.incentive} fullWidth disabled={data.status === 0}   onChange={(e) => detailUpdate("incentive", e.target.value)}/> */}

              
          {/* </Stack> */}
      
          {/* { !changePassword && <Stack direction={"row"} spacing={2} padding={2}>
    <Button sx={{margin:"7px"}} variant="contained" color="primary" disabled={!isLastItemComplete()||loading}
     onClick={handleAddFields}>
            Add Product
          </Button>
          </Stack>
          } */}

          </Stack >

        <Stack direction={"row"} padding={1}>
          {
            (data.id && data.status != 0)
              &&  
            <Container>
              <Checkbox variant="contained" value={changePassword} onChange={(e) => {
                if (!changePassword === false) {
                  const newState = { ...details };

                  delete newState.password;

                  setDetails(newState);
                }

                setChangePassword(!changePassword)
              }} />
              <Typography variant="contained">Check this to change password</Typography>
            </Container>
          }

          {
            !data.id 
              &&
              <Stack style={{ width: '100%' }} direction={"row"} spacing={2} padding={1}>
                <TextField type="password" label="Password" value={details.password || ""} 
                  fullWidth onChange={(e) => detailUpdate("password", e.target.value)} 
                  disabled={changePassword}
                />
              </Stack>
          }
        </Stack>

   {data.id && changePassword &&      <Stack direction={"row"} spacing={2} padding={2}>

          <TextField label="New Password" value={details.password || ""} type="password" fullWidth onChange={(e)=> detailUpdate("password", e.target.value)} 
          // disabled={changePassword}
          />
          </Stack>}

          {/* </Stack>} */}

          {/* </Box> */}
        {/* </DataGridContainer> */}
        <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translate(-50%, -50%)' }}>
{console.log('data.status', data.status)}
{data.status !== 0  && <>
 <Button variant="contained" onClick={()=> 
  {if(data.id)
    {
      editUser()
      console.log("aaaaaa")
    }
    else
    {
      createNewUser()
    } 
  }
} >
   {data.id? 'Update': 'Create'}
 </Button>
 {data.id && <Button sx={{marginLeft: '10px'}} variant="contained" onClick={deleteUser}>
    Inactivate
  </Button>}
  </>}
  {
    data.status === 0 
    &&
    <Button sx={{marginLeft: '10px'}} variant="contained" onClick={activateUser}>
      Activate User
    </Button>
  }
 </div>
      </div>
    );
  };
 
  const saveChanges=async()=>{
    try{
      setLoading(true);
console.log('Saving changes',dynamicFields)
const filteredArray = dynamicFields?.filter(obj => {
  return obj.hasOwnProperty("oid");
});
const filteredArray1 = dynamicFields?.filter(obj => {
  return !obj.hasOwnProperty("oid");
});
const totalAmount = dynamicFields.reduce((acc, obj) => {
  return acc + (obj.totalprice || 0); // If 'age' is undefined or null, use 0
}, 0);
 
console.log("TOTAL",totalAmount);
console.log('Filtered array',filteredArray)
console.log('Filtered array1',filteredArray1)
 
const herokuId=localStorage.getItem('hId')
let results = await window.Platform.database.updateOrderDetails({remarks:remark,herokuId:herokuId,updateOrder:filteredArray,newOrder:filteredArray1,totalPrice:totalAmount,deletedField:deletedField});
if(results.hasError===false){
  window.NotificationUtils.showSuccess("Order Update Success");
  onClose()
  fetchData()
 
}
else{
  window.NotificationUtils.showError("Error Please Wait and try again");
 
}
setLoading(false);
 
}
catch(e){
  console.log(e)
  window.NotificationUtils.showError("Error Please Wait and try again");
  setLoading(false);
 
}
}
 
  return (
    <Dialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true} >
     {loading && <OpaqueLoading/>}
    <ModalHeaderContainer style={{marginBottom:2,paddingBottom:3}}>
       <Typography  variant={isMobile?"h6":"h5"}>
         {/* {items?.customerName} */}
       {data.id ? 'Edit User' :'Add User'}
         <Tooltip title="Order Id">
 
           {id}
         </Tooltip>
           
           </Typography>
       <IconButton onClick={discardModal} >
         <CloseOutlined />
       </IconButton>
     </ModalHeaderContainer>
     <TableContainer>
 
     <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={tabValue}
              onChange={ handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Personal Details" />
              <Tab label="User Credentials" />
           
            </StyledTabs>
            {tabValue==1? renderContent() :renderContent1()}
 
          </TabsContainer>
  {/* { tabValue===1&&<div style={{alignItems:"center",justifyContent:"center",display:"flex"}}><Button variant="contained" disabled={!hasChanges||!isLastItemComplete()||loading} onClick={()=>saveChanges()} >Save Changes</Button></div>} */}
   
     </TableContainer>
 
   </Dialog>
  );
};
 
export default UserModal;