import react, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, Select, MenuItem, InputLabel, FormControl } from "@mui/material"; 
import Drawer from "../../components/common/drawer/drawer";
import { Typography, Button, Checkbox, Fab, IconButton, Stack, Tab, Tabs, CircularProgress, Link } from "@mui/material";
import { Modal,Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt, Search, Edit } from "@mui/icons-material";
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AddOrderModal from "../../components/modals/addOrder";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AddOrderModalDistributor from "../../components/modals/addOrderDistributor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonOtpModal from "../../components/modals/commonOtpModal";
import RepartitionIcon from '@mui/icons-material/Repartition';
import ReturnOrderModal from "../../components/modals/returnOrderModal";
import { useNavigate } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';
import { width } from "@mui/system";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelJS from 'exceljs'


const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "128px - 28px - 51.77px"}
// );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;

export default function OrderManagement() {
  const navigation = useNavigate()

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [editMode,setEditMode]=useState(false)
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState([]);
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal,setModal]=useState(false)
  const [modalData,setModalData]=useState()
  const [addModal,setAddModal] = useState(false)
  const [addModal1,setAddModal1] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [editModal,setEditModal] = useState(false)
  const [editModal1,setEditModal1] = useState(false)
  const [userData,setUserData]=useState(JSON.parse(localStorage.getItem('storedUserData')).user)

  const [editModalData,setEditModalData] = useState(false)
  const [rowCount,setRowCount] = useState()
const [currentPage, setCurrentPage] = useState(1);
const [paginationModel, setPaginationModel] = useState({
  page: currentPage-1,
  pageSize: 20,
});

const [currentPage1, setCurrentPage1] = useState(1);
const [paginationModel1, setPaginationModel1] = useState({
  page: currentPage1,
  pageSize: 20,
});
const [rsms, setRsms] = useState([]);
const [allUsers, setAllUsers] = useState([]);
const [asms, setAsms] = useState([]);
const [rms, setRms] = useState([]);
const [rm,setRm]=useState("")
const [rsm,setRsm]=useState("")
const [asm,setAsm]=useState("")
const [newOrderStatus, setNewOrderStatus] = useState(0);
const [selectedTab, setSelectedTab] = useState(0);
const [filter,setFilter]=useState(false)
const [filter1,setFilter1]=useState(false)

const [searchTerm, setSearchTerm]=useState(null)
const [searchTerm1, setSearchTerm1]=useState(null)
const [startDate,setStartDate]=useState()
const [endDate,setEndDate]=useState()
const [dateRange1,setDateRange1]=useState(false)

const [startDate1,setStartDate1]=useState()
const [endDate1,setEndDate1]=useState()
const [minDate,setMinDate]=useState()
const [otpModalData,setOtpModalData]=useState()
const [otpModal1,setOtpModal1]=useState(false)
const [otpModalData1,setOtpModalData1]=useState()
const [otpModal2,setOtpModal2]=useState(false)
const [returnData,setReturnData]=useState()
const [returnItems,setReturnItems]=useState(false)
const [openDialog, setOpenDialog] = useState(false);
// const [remark, setRemark] = useState("");
const [rejectData, setRejectData] = useState(null);
const [selectedImage, setSelectedImage] = useState(null);
const [selectedPdf, setSelectedPdf] = useState(null);
const [reason, setReason] = useState("");
const [search,setSearch]=useState("")
const [LocalStoredData, setLocalStoredData] = useState(JSON.parse(localStorage.getItem("storedUserData")))
const [debouncedSearch, setDebouncedSearch] = useState("");
const [selectedSeries,setSelectedSeries]=useState("")
const [series,setSeries]=useState([])
const [selectedRole,setSelectedRole]=useState("")
const [roleId, setRoleId] = useState([])
const [fromDate, setFromDate] = useState("")
const [toDate, setToDate] = useState("")
const [schoolOrderModalData, setSchoolOrderModalData] = useState([])
const [schoolOrderOpenViewModal, setSchoolOrderOpenViewModal] = useState(false)
const [distributorOrderModalData, setDistributorOrderModalData] = useState([])
const [distributorOrderOpenViewModal, setDistributorOrderOpenViewModal] = useState(false)
// const [newPageCount, setNewPageCount] = useState(0)

async function  getUsers(role,id){
  if(id!=""){
  let results = await window.Platform.database.getUsersBasedOnManager( {userId:id} )
  if(role=='rsm'){
  setAsms(results.data)
  setRms(results.data1)
  }
  if(role=='asm'){
  setRms(results.data)
  }
}else{
if(role=='rsm'){
setRm("")
setAsm("")
setRms(allUsers.filter((ele)=>ele.role=='se'))
setAsms(allUsers.filter((ele)=>ele.role=='asm'))
}

if(role=='asm'){
setRm("")
setRms(allUsers.filter((ele)=>ele.role=='se'))
}
}
 
}
  const handleTabChange = async (value) => {

    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    setFilter(false)
    setFilter1(false)


    setSelectedTab(value);


  }

useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Set debounced value after delay
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup function
  }, [search]);

 

  useEffect(() => {
      if(selectedTab == 0){
        fetchOrder();
      }
      else{
        fetchOrder1()
      }
}, [debouncedSearch]);


  useEffect(() => {
    if (selectedTab === 0) {
      fetchOrder();
    }
    else {
      fetchOrder1()
    }
  }, [newOrderStatus,rsm,rm,asm,selectedSeries,paginationModel.page, paginationModel1.page,paginationModel.pageSize,paginationModel1.pageSize]);


  
  
  useEffect(() => {
    getSeries()
    if(userData?.role.includes('admin')){
     
      getAllUsers()
     }
     if(userData?.role=='rsm'){
       getUsers('rsm',userData?.id)
     }
     if(userData?.role=='asm'){
       getUsers('asm',userData?.id)
     }

    if (selectedTab === 0) {
      fetchOrder();
    }
    else {
      fetchOrder1()
    }
  }, []);

  


   async function getAllUsers(){
    let results = await window.Platform.database.fetchEmployees()
    setAllUsers(results.data)
    setRsms(results.data.filter((ele)=>ele.role=='rsm'))
    setRms(results.data.filter((ele)=>ele.role=='se'))
    setAsms(results.data.filter((ele)=>ele.role=='asm'))
  }

  const getSeries = async () => {
    setLoading(true);
    try {
      let results = await window.Platform.database.getSeriesCategory();
      
      const jsonArrayWithId = results?.data?.map((obj) => ({ 
        ...obj, 
        dbId: obj.id
      }));
      
     
      setSeries(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      console.error("ERRORCHECK", error);
        setLoading(false);
    }
  };


  const fetchRoles=async(details)=>{
    try{
      setLoading(true)
      // console.log("PLANTCODE",plantcode[0].plant_code__c)
      let results = await window.Platform.database.fetchRoles(details);
  console.log("PRODUCT RESULTS: " ,results.data.map((data)=>  data.roleName ))
  setRoleId(results.data)
  // setProduct(results.data)
  setLoading(false)
    }
    catch(err){
      console.log(err);
  setLoading(false)
   
    }
  }


  const formatDateTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    // Check if the input date is valid
    if (isNaN(inputDate.getTime())) {
      return 'Invalid Date';
    }

    // Extract date and time components
    const year = inputDate.getUTCFullYear();
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getUTCDate()).padStart(2, '0');
    const hours = String(inputDate.getUTCHours()).padStart(2, '0');
    const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');

    // Format the date string without 'T' and 'Z'
    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateString;

  }


// bassss

  useEffect(() => {
    // setAccessChanges(rows);
    if (selectedTab === 0 && !filter) {
      fetchOrder()
    }
    if (selectedTab === 0 && filter) {
      onFilterChange(searchTerm)
    }
  }, [paginationModel.page, selectedTab]);
 

  useEffect(() => {
    if (selectedTab === 1 && !filter1) {
      fetchOrder1()
    }
    if (selectedTab === 1 && filter1) {
      onFilterChange1(searchTerm1)
    }
  }, [paginationModel1.page, selectedTab]);



  const fetchOrder = async () => {
    try {
      setLoading(true);
      // let data 
      // if(userData?.role=='asm' && newOrderStatus==1){
      //   data = {
      //     pageNumber: paginationModel.page,
      //     filter:search,
      //     fromDate:fromDate,
      //     toDate:toDate,
      //     series:selectedSeries,
      //     rsm:rsm,
      //     asm:asm,
      //     ownerId:rm,
      //     recordPerPage:paginationModel.pageSize,
      //     approvalStatus: 0
      //   }
      // }
      // else{
      //   data = {
      //     pageNumber: paginationModel.page,
      //     filter:search,
      //     fromDate:fromDate,
      //     toDate:toDate,
      //     series:selectedSeries,
      //     rsm:rsm,
      //     asm:asm,
      //     ownerId:rm,
      //     recordPerPage:paginationModel.pageSize,
      //     approvalStatus: newOrderStatus
      //   }
      // }
      let data = {
        pageNumber: paginationModel.page,
        filter:search,
        fromDate:fromDate,
        toDate:toDate,
        series:selectedSeries,
        rsm:rsm,
        asm:asm,
        ownerId:rm,
        recordPerPage:paginationModel.pageSize,
        approvalStatus: newOrderStatus
      }

        let results = await window.Platform.database.fetchOrder(data);
    
      let jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      // if (userData?.role=='asm' && newOrderStatus==0) {
      //   jsonArrayWithId = jsonArrayWithId.filter(row => row.asmApproval == 0);
      //   setRowCount(jsonArrayWithId?.length)
      // }
      // else if (userData?.role=='asm' && newOrderStatus==1) {
      //   // jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      //  jsonArrayWithId = jsonArrayWithId.filter(row => row.asmApproval === 1 && row.approvalStatus === 0);
      //  setRowCount(jsonArrayWithId?.length)
      // }else{

      // }
      setRowCount(results?.data1)
      setAccessChanges(jsonArrayWithId);
     
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally {
      setLoading(false)
    }
  }

  const approveRejectOrder = async (val) => {
    console.log("VALuesss", val,"userData=>>>>>>>>>>>>", userData)
    val["ownerId"]=userData.id
    // return;
    try {
      setLoading(true)
      if(userData?.role=='asm'){
        const results = await window.Platform.database.updateAsmApproval({orderId : val?.id})
      }
      else{
        const results = await window.Platform.database.approverejectOrder(val)
      }
      window.NotificationUtils.showSuccess("Order Update Success")
      setLoading(false)
      if (selectedTab === 0) {
        // fetchOrder(LocalStoredData)
        fetchOrder()
      } else {
        fetchOrder1()

      }

    }
    catch (err) {
      console.log(err)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)

    }
  }

  
  const handleReject = (data) => {
    setRejectData(data);
    setOpenDialog(true);
  };

  const handleConfirmReject = () => {
    if (reason.trim() === "") {
      window.NotificationUtils.showError("Remark is required to reject the order.")
      return;
    }
    approveRejectOrder({ ...rejectData, approvalStatus: "2", reason });
    setOpenDialog(false);
    setReason("");
    setRejectData(null);
  };
  
  const handleCloseImageView = () => {
    setSelectedImage(null);
    setSelectedPdf(null);
  };
  const handleImageClick = (file) => {
    setLoading(true);
    setSelectedImage(file);
    setSelectedPdf(null);
  };

  const handlePdfClick = (file) => {
    setLoading(true);
    setSelectedPdf(file);
    setSelectedImage(null);
  };
  
  const handleImageLoad = () => {
    console.log("image loader false ")
    setLoading(false);
  };
  
  const handlePdfLoad = () => {
    console.log("pdf loader false ")

    setLoading(false);
  };

  useEffect(() => {
    if (!selectedImage && !selectedPdf) {
      setLoading(false);
    }
  }, [selectedImage, selectedPdf]);



  const handleOpenModal = (attachmentData) => {
    if (attachmentData !== "N/A") {
      try {
        const parsedData = JSON.parse(attachmentData);
        setAttachments(parsedData);
      } catch (error) {
        console.log("Invalid JSON format:", error);
        setAttachments([]);
      }
    } else {
      setAttachments([]);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);



  const fetchOrder1 = async (data) => {
    try {
      setLoading(true);
      // let data1 
      // if(userData?.role=='asm' && newOrderStatus==1){
      //   data1 = {
      //     pageNumber: paginationModel.page,
      //     filter:search,
      //     orderStatus: 1,
      //     fromDate:fromDate,
      //     toDate:toDate,
      //     series:selectedSeries,
      //     rsm:rsm,
      //     asm:asm,
      //     ownerId:rm,
      //     recordPerPage:paginationModel.pageSize,
      //     approvalStatus: 0
      //   }
      // }
      // else{
      //   data1 = {
      //     pageNumber: paginationModel.page,
      //     filter:search,
      //     orderStatus: 1,
      //     fromDate:fromDate,
      //     toDate:toDate,
      //     series:selectedSeries,
      //     rsm:rsm,
      //     asm:asm,
      //     ownerId:rm,
      //     recordPerPage:paginationModel.pageSize,
      //     approvalStatus: newOrderStatus
      //   }
      // }
      let data1 = {
        pageNumber: paginationModel.page,
        orderStatus: 1,
        filter:search,
        series:selectedSeries,
        fromDate:fromDate,
        toDate:toDate,
        rsm:rsm,
        asm:asm,
        ownerId:rm,
        recordPerPage:paginationModel1.pageSize,
        approvalStatus: newOrderStatus
      };
let results ;
      if (data?.user?.role.toLowerCase().includes('admin')) {
       results = await window.Platform.database.fetchOrder(data1, selectedRole, selectedSeries, search);
      }
      else{
        results = await window.Platform.database.fetchOrder(data1);
      }
      console.log("results", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      let jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      // if (userData?.role=='asm' && newOrderStatus==0) {
      //   jsonArrayWithId = jsonArrayWithId.filter(row => row.asmApproval == 0);
      //   setRowCount(jsonArrayWithId?.length)
      // }
      // else if (userData?.role=='asm' && newOrderStatus==1) {
      //   // jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      //  jsonArrayWithId = jsonArrayWithId.filter(row => row.asmApproval === 1 && row.approvalStatus === 0);
      //  setRowCount(jsonArrayWithId?.length)
      // }
      // else{
      // }
      setRowCount(results.data1)
      setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      // setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      // setLoading(false);
    }
    finally {
      setLoading(false)
    }
  }


  const handleDeleteOrder = async (data,tab) => {
   
    const isConfirmed = window.confirm("Are you sure you want to delete this picklist item?");
    
    if (!isConfirmed) {
      return;
    }
  
    try {
      setLoading(true);
      let results = await window.Platform.database.deleteOrder({ id: data });
      tab == 0 ? fetchOrder() : fetchOrder1()
      setLoading(false);
      window.NotificationUtils.showSuccess("Deleted successfully!");
   
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleOpenSchoolOrderViewModal = (id) => {
    console.log(id,"isdsdsdsd")
    setSchoolOrderModalData(id)
    setSchoolOrderOpenViewModal(true);
  }

  const handleCloseSchoolOrderViewModal = () => {
    setSchoolOrderOpenViewModal(false);
    setSchoolOrderModalData([]);
  }

  const downloadSingleOrder = async (data) => {
    const getShippingAddress = (data1) => {
      const addresses = ["dAddress", "stAddress", "sAddress"];
      
      for (let key of addresses) {
        try {
          const addressObj = JSON.parse(data1[key] || "{}");
          return ` Address Line 1: ${addressObj.addressLine1 || "N/A"} ,  Address Line 2: ${addressObj.addressLine2 || ""}, State: ${addressObj.state || "N/A"}, District: ${addressObj.district || "N/A"}, Pincode: ${addressObj.pincode || "N/A"}`;
        } catch (error) {
          console.error(`Invalid JSON in ${key}:`, data1[key]);
        }
      }
    
      return {
        state: null,
        pincode: null,
        district: null,
        addressLine1: null,
        addressLine2: null,
      };
    };    

    let shippingAddress = await getShippingAddress(data);
    try {
      setLoading(true);
      let sentData = { id: data?.orderId };
      let results = await window.Platform.database.fetchOrderLineItem(sentData);
      setLoading(false);
  
      if (!results?.data || results.data.length === 0) {
        window.NotificationUtils.showError("No order line items found!");
        return;
      }
  
      const seriesDiscounts = data.seriesDiscount
        ? JSON.parse(data.seriesDiscount)
        : {};
  
        results.data.sort((a, b) => (a.seriesName || "").localeCompare(b.seriesName || ""));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Order Line Items");
  
      // Define headers
      const headers = [
        " Sales Order Number",
        "Total Amount",
        "Date (Order Booking Date)",
        "Booked By (RM Name)",
        "Approved By (CH/VP)",
        "Party Name",
        "Remark",
        "State",
        "Add On Products",
        "Order Remark",
        "Ware House",
        "Transporter",
        "Shipping Address",

        "Series Name",
        "Quantity",
        "SKU Code",
        "Item Name",
        "Class",
        "Price",
        "Total",
        "Discount (%)",
        "Product Group"
      ];
      worksheet.addRow(headers);
      //  shippingAddress = getShippingAddress(data);

      // worksheet.addRow([
      //   data.so_id || "N/A",
      //   data.totalAmount || "N/A",
      //   data.createdAt ? new Date(data.createdAt).toLocaleDateString() : "N/A",
      //   data.name || "N/A",
      //   data.approvedBy || "N/A",
      //   data.schoolName || "N/A",
      //   data.remark || "N/A",
      //   data.state || "N/A",
      //   Array.isArray(data.addOnProduct) ? data.addOnProduct.join(", ") : typeof data.addOnProduct === "string" ? JSON.parse(data.addOnProduct).join(", ") : "N/A",
      //   data.order_remark || "N/A",
      //   data.warehouse || "N/A",
      //   data.transporter_name || "N/A",
      //   shippingAddress,
      //   "", "", "", "", "", "", "", "", "", "" 
      // ]);

      
      results.data.forEach((item, index) => {
        const discount = seriesDiscounts[item.series] || 0;
  
        worksheet.addRow([
          // "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
          data.so_id || "N/A",
          data.totalAmount || "N/A",
          data.createdAt ? new Date(data.createdAt).toLocaleDateString() : "N/A",
          data.name || "N/A",
          data.approvedBy || "N/A",
          data.schoolName || "N/A",
          data.remark || "N/A",
          data.state || "N/A",
          Array.isArray(data.addOnProduct) ? data.addOnProduct.join(", ") : JSON.parse(data.addOnProduct).join(", ") || "N/A",
          data.order_remark || "N/A",
          data.warehouse || "N/A",
          data.transporter_name || "N/A",
          shippingAddress,

          item.seriesName || "N/A",
          item.QTY || 0,
          item.sku_code || "N/A",
          item.nameSku || "N/A",
          item.className || "N/A",
          item.Price || 0,
          item.Total || 0,
          discount,
          item.productGroupName || "N/A"
        ]);
      });
  
      worksheet.columns.forEach((column) => {
        column.width = 20;
      });
  
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `single_Order_${data?.so_id}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError("Something Went Wrong!");
      setLoading(false);
    }
  };
  
  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));
    let result = [
    {
      field: "approvalStatus",
      headerName: "Status",
      width: 110,
      filterable: false,

      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        // let name = params.row?.approvalStatus === 0 ? "Pending" : params.row?.approvalStatus === 1 ? "Approved" : "Rejected" || "N/A"
        // let name = params.row?.approvalStatus === 0 ? "Pending" : params.row?.approvalStatus === 1 ? "Approved" : params.row?.approvalStatus == 2 ? "Rejected" : params.row?.approvalStatus == 3 ? "Shipped" : params.row?.approvalStatus == 4 ? "Delivered" : "N/A" || "N/A"
        let name = params.row?.approvalStatus === 0 ? "Pending" : params.row?.approvalStatus === 1 ? "Approved" : params.row?.approvalStatus == 2 ? "Rejected" : params.row?.approvalStatus == 3 ? "Shipped" : params.row?.approvalStatus == 4 ? "Delivered" : params.row?.approvalStatus == 6 ? "Saved For Later" : "N/A" || "N/A"
        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "SONo",
      headerName: "SO No.",
      sortable: false,
      width: 150,

      editable: false,
      filterOperators: stringOperators
      ,
      renderCell: (params) => {

        let name = params.row?.so_id || "N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    // {
    //   field: "name",
    //   headerName: "Booked By",
    //   sortable: false,
    //   width: 110,

    //   editable: false,
    //   filterOperators: stringOperators
    //   ,
    //   renderCell: (params) => {

    //     let name = params.row?.name || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   width: 250,
    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   filterable: false,

    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = formatTimeStamp(params.row.createdAt) || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    // {
    //   field: "orderType",
    //   headerName: "Order Type",
    //   width: 140,
    //   filterOperators: stringOperators
    //   ,
    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = params.row?.orderType || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },

    {
      field: "schoolName",
      headerName: "Party Name",
      // width: 280,
      width : newOrderStatus==1 ? 350 : newOrderStatus==2 ? 250 : newOrderStatus == 3 ? 350 : newOrderStatus == 4 ? 350 : newOrderStatus==6 ? 263 : 300,
      filterOperators: stringOperators
      ,
      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name = params.row?.schoolName ? params.row?.schoolName : params.row?.DistributorName || "N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 120,
      filterable: false,

      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        // const name1 = +params.row?.totalAmount||"N/A"
        // const name2= name1 && name1?.toFixed(2)
        // let name=name2.toLocaleString('en-IN');
        const rawAmount = +params.row?.totalAmount;
        let name;

        if (isNaN(rawAmount)) {
          name = "N/A";
        } else {
          const formattedAmount = rawAmount.toFixed(2);
          name = Number(formattedAmount).toLocaleString('en-IN');
        }
        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },

    // {
    //   field: "emailId",
    //   headerName: "Party Email Id",
    //   width: 250,
    //   filterable: false,

    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = params.row.emailId || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },


    // {
    //   field: "mobileNo",
    //   headerName: "Party Mobile No",
    //   width: 200,
    //   filterable: false,

    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = params.row.mobile || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },

    // {
    //   field: "Discount",
    //   headerName: "Discount(in %)",
    //   width: 150,
    //   filterable: false,

    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = params.row?.Discount == 0 ? 0 : params.row.Discount || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    // {
    //   field: "Transport",
    //   headerName: "Transporter Name",
    //   width: 200,
    //   filterable: false,

    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = params.row?.transporter_name || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
    // {
    //   field: "Remark",
    //   headerName: "Remark",
    //   width: 200,
    //   filterable: false,

    //   // height: 10,
    //   // headerAlign: 'center',
    //   sortable: false,
    //   // editable: true,
    //   renderCell: (params) => {

    //     let name = params.row?.remark || "N/A"

    //     return (

    //       <Tooltip title={name || "N/A"}>

    //         <Typography variant="inherit">{name}</Typography>

    //       </Tooltip>

    //     )

    //   },
    // },
      // {
      //   field: "attachment",
      //   headerName: "attachment",
      //   width: 100,
      //   filterable: false,

      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   renderCell: (params) => {

      //     // let name = params.row?.attachment || "N/A"
      //     let attachmentData = params.row?.attachment || "N/A";
      //     let name = "N/A";
      //     let hasAttachments = false;

      //     if (attachmentData !== "N/A") {
      //       try {
      //         const parsedData = JSON.parse(attachmentData);
      //         hasAttachments = parsedData?.length > 0;
      //         name = parsedData[0]?.originalName || "N/A";
      //       } catch (error) {
      //         console.log("Invalid JSON format:", error);
      //       }
      //     }

      //     return (

      //       <>
      //         <Tooltip>
      //           <span>
      //             <Button
      //               variant="contained"
      //               size="small"
      //               onClick={() => hasAttachments && handleOpenModal(params.row.attachment)}
      //               disabled={!hasAttachments}
      //               sx={{ minWidth: "70px" }}
      //             >
      //               {hasAttachments ? "View" : "No Data"}
      //             </Button>
      //           </span>
      //         </Tooltip>


      //         {/* Modal */}
      //         <Modal open={openModal} onClose={handleCloseModal}>
      //           <Box
      //             sx={{
      //               position: "absolute",
      //               top: "50%",
      //               left: "50%",
      //               transform: "translate(-50%, -50%)",
      //               width: "80%",
      //               maxWidth: 800,
      //               bgcolor: "background.paper",
      //               boxShadow: 24,
      //               p: 4,
      //               borderRadius: 2,
      //               maxHeight: "80%",
      //               overflowY: "auto",
      //             }}
      //           >
      //             <Typography variant="h6" component="h2" mb={2}>
      //               Attachment Details
      //             </Typography>
      //             <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      //               {attachments && attachments?.map((file, index) => {
      //                 const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file.fileName);
      //                 const isPDF = /\.pdf$/i.test(file.fileName);

      //                 return (
      //                   <div key={index} style={{ cursor: "pointer" }}>
      //                     {isImage && (
      //                       <img
      //                         src={`https://mittsureone.com:3001/file/${file.fileName}`}
      //                         alt={file.originalName}
      //                         style={{
      //                           width: "150px",
      //                           height: "auto",
      //                           margin: "10px",
      //                           borderRadius: "8px",
      //                           boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
      //                         }}
      //                         onClick={() => handleImageClick(file)}
      //                       />
      //                     )}
      //                     {isPDF && (
      //                       <div
      //                         style={{
      //                           width: "150px",
      //                           height: "200px",
      //                           margin: "10px",
      //                           border: "1px solid #ddd",
      //                           display: "flex",
      //                           alignItems: "center",
      //                           justifyContent: "center",
      //                           backgroundColor: "#f5f5f5",
      //                         }}
      //                         onClick={() => handlePdfClick(file)}
      //                       >
      //                         <Typography
      //                           variant="body2"
      //                           color="textSecondary"
      //                           textAlign="center"
      //                         >
      //                           {file.originalName}
      //                         </Typography>
      //                       </div>
      //                     )}
      //                     {!isImage && !isPDF && (
      //                       <a
      //                         href={`https://mittsureone.com:3001/file/${file.fileName}`}
      //                         target="_blank"
      //                         rel="noopener noreferrer"
      //                         onClick={() => handleImageClick(file)}
      //                       >
      //                         {file.originalName}
      //                       </a>
      //                     )}
      //                   </div>
      //                 );
      //               })}
      //             </div>

      //             <Box mt={2} display="flex" justifyContent="flex-end">
      //               <Button onClick={handleCloseModal} variant="contained" color="primary">
      //                 Close
      //               </Button>
      //             </Box>
      //           </Box>
      //         </Modal>

      //         {/* Enlarged View Modal */}
      //         {(selectedImage || selectedPdf) && (
      //           <Modal open={!!selectedImage || !!selectedPdf} onClose={handleCloseImageView}>
      //             <Box
      //               sx={{
      //                 position: "absolute",
      //                 top: "50%",
      //                 left: "50%",
      //                 transform: "translate(-50%, -50%)",
      //                 width: "90%",
      //                 maxWidth: 1000,
      //                 bgcolor: "background.paper",
      //                 boxShadow: 24,
      //                 p: 4,
      //                 borderRadius: 2,
      //                 maxHeight: "90vh",
      //                 overflowY: "auto",
      //                 display: "flex",
      //                 flexDirection: "column",
      //                 alignItems: "center",
      //               }}
      //             >
      //               {selectedImage && (
      //                 <img
      //                 onLoad={handleImageLoad}
      //                   src={`https://mittsureone.com:3001/file/${selectedImage.fileName}`}
      //                   alt={selectedImage.originalName || "Attachment"}
      //                   style={{
      //                     width: "100%",
      //                     height: "auto",
      //                     borderRadius: "8px",
      //                     maxHeight: "70vh",
      //                   }}
      //                 />
      //               )}
      //               {selectedPdf && (
      //                 <iframe
      //                 onLoad={handlePdfLoad}
      //                   src={`https://mittsureone.com:3001/file/${selectedPdf.fileName}`}
      //                   title={selectedPdf.originalName}
      //                   style={{
      //                     width: "100%",
      //                     height: "500px",
      //                     margin: "10px",
      //                     border: "1px solid #ddd",
      //                   }}
      //                 ></iframe>
      //               )}
      //               <Typography variant="h6" mt={2} textAlign="center">
      //                 {selectedImage?.originalName || selectedPdf?.originalName || "N/A"}
      //               </Typography>
      //               <Button
      //                 onClick={handleCloseImageView}
      //                 variant="contained"
      //                 color="error"
      //                 sx={{ mt: 2 }}
      //               >
      //                 Close
      //               </Button>
      //             </Box>
      //           </Modal>
      //         )}
      //       </>



      //     )

      //   },
      // },

    {
      field: "approvedBy",
      // headerName: "Approved By",
      headerName: `${newOrderStatus == 1 ? "Approved By" :newOrderStatus == 2? "Rejected By":"Action Taken By"}`,
      // width: 140,
      width : newOrderStatus == 2 ? 140 : newOrderStatus==6 ? 150 : 190,
      filterable: false,

      // height: 10,
      // headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let name = params.row?.approvedBy || "N/A"

        return (

          <Tooltip title={name || "N/A"}>

            <Typography variant="inherit">{name}</Typography>

          </Tooltip>

        )

      },
    },
    newOrderStatus && newOrderStatus == 2 && {
      field: "Reject Reason",
      headerName: "Reject Reason",
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        let name = params.row?.reason || "N/A"
        return (
          <Tooltip title={name || "N/A"}>
            <Typography variant="inherit">{name || "N/A"}</Typography>
          </Tooltip>
        )
      },
    },

    {
      field: "View",
      headerName: "Order Details",
      width:100,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip>
              <span>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleOpenSchoolOrderViewModal(params.row)}
                  sx={{ minWidth: "70px" }}
                >
                  {"View"}
                </Button>
              </span>
            </Tooltip>
            <Modal open={schoolOrderOpenViewModal} onClose={handleCloseSchoolOrderViewModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxWidth: 900,
                  bgcolor: "white",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  maxHeight: "90%",
                  overflowY: "auto",
                }}
              >
                <Typography variant="h6" component="h2" mb={5} style={{ textAlign: "center" }}>
                  Product Details
                </Typography>
                <div>
                  <div className="row mb-4 mt-2">
                    <div className="col-md-6">
                      <b> Approval Status :</b> {schoolOrderModalData && schoolOrderModalData?.approvalStatus === 0 ? "Pending" : schoolOrderModalData?.approvalStatus === 1 ? "Approved" : schoolOrderModalData?.approvalStatus == 2 ? "Rejected" : schoolOrderModalData?.approvalStatus == 3 ? "Shipped" : schoolOrderModalData?.approvalStatus == 4 ? "Delivered" : "N/A" || "N/A" }
                    </div>

                    <div className="col-md-6">
                      <b> Booked By : </b> {schoolOrderModalData && schoolOrderModalData?.name || "N/A"}
                    </div>
                    
                  </div>

                  <div className="row mb-4">
                    
                 {selectedTab == 0 ?
                    <div className="col-md-6">
                      <b> Party Name : </b>{schoolOrderModalData && schoolOrderModalData?.schoolName || "N/A"}
                    </div>
                    :
                    <div className="col-md-6">
                      <b> Party Name : </b>{schoolOrderModalData && schoolOrderModalData?.DistributorName || "N/A"}
                    </div>
                    }

                    <div className="col-md-6">
                      <b> Total Amount :</b> {schoolOrderModalData && schoolOrderModalData?.totalAmount || "N/A"}
                    </div>

                  </div>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <b> Email Id :</b> {schoolOrderModalData && schoolOrderModalData?.emailId || "N/A"}
                    </div>
                    <div className="col-md-6">
                      <b> Mobile Number :</b> {schoolOrderModalData && schoolOrderModalData?.mobile || "N/A"}
                    </div>

                  </div>

                  <div className="row mb-4">
                    <div className="col-md-6">
                      <b>Transporter Name :</b> {schoolOrderModalData && schoolOrderModalData?.transporter_name || "N/A"}
                    </div>

                    <div className="col-md-6">
                      <b>OTP Consent :</b> {schoolOrderModalData && schoolOrderModalData?.otpConsent || "N/A"}
                    </div>
                  </div>

                  <div className="row mb-4 mt-2">

                    <div className="col-md-6">
                      {/* <b> Created At :</b> {schoolOrderModalData && schoolOrderModalData?.createdAt || "N/A" } */}
                      {/* <b>Created At:</b>{" "}
{schoolOrderModalData?.createdAt
  ? new Date(schoolOrderModalData.createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    })
  : "N/A"} */}
  <b>Created At:</b>{" "}
{schoolOrderModalData?.createdAt
  ? `${new Date(schoolOrderModalData.createdAt).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })}, ${new Date(schoolOrderModalData.createdAt).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })}`
  : "N/A"}


                    </div>
                    
                    <div className="col-md-6">
                      <b> Remark :</b> {schoolOrderModalData && schoolOrderModalData?.remark || "N/A"}
                    </div>

                  </div>

                  <div className="row mb-4">
                    
                  <div className="col-md-6">
                      <b> Order Remark : </b> {schoolOrderModalData && schoolOrderModalData?.order_remark || "N/A"}
                    </div>
                    <div className="col-md-6">
  <b>Pincode:</b>{" "}
  {(() => {
    const addresses = [
      schoolOrderModalData?.sAddress,
      schoolOrderModalData?.dAddress,
      schoolOrderModalData?.stAddress
    ];

    for (const addr of addresses) {
      if (addr) {
        try {
          const parsedAddr = JSON.parse(addr);
          if (parsedAddr?.pincode) {
            return parsedAddr.pincode;
          }
        } catch (error) {
          console.error("Invalid JSON in address:", addr);
        }
      }
    }

    return "N/A";
  })()}
</div>

                  </div>

                  <div className="row mb-4">

<div className="col-md-6">
  <b>District:</b>{" "}
  {(() => {
    const addresses = [
      schoolOrderModalData?.sAddress,
      schoolOrderModalData?.dAddress,
      schoolOrderModalData?.stAddress
    ];

    for (const addr of addresses) {
      if (addr) {
        try {
          const parsedAddr = JSON.parse(addr);
          if (parsedAddr?.district) {
            return parsedAddr.district;
          }
        } catch (error) {
          console.error("Invalid JSON in address:", addr);
        }
      }
    }

    return "N/A";
  })()}
</div>

                    <div className="col-md-6">
  <b>State:</b>{" "}
  {(() => {
    const addresses = [
      schoolOrderModalData?.sAddress,
      schoolOrderModalData?.dAddress,
      schoolOrderModalData?.stAddress
    ];

    for (const addr of addresses) {
      if (addr) {
        try {
          const parsedAddr = JSON.parse(addr);
          if (parsedAddr?.state) {
            return parsedAddr.state;
          }
        } catch (error) {
          console.error("Invalid JSON in address:", addr);
        }
      }
    }

    return "N/A";
  })()}
</div>

                  </div>

                  <div className="row mb-4">
                   <div className="col-md-6">
                   <b>Attachment </b> 
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() =>  handleOpenModal(schoolOrderModalData?.attachment)}
                    // onClick={() => hasAttachments && handleOpenModal(params.row.attachment)}
                    disabled={schoolOrderModalData && !(schoolOrderModalData?.attachment && JSON.parse(schoolOrderModalData?.attachment).length > 0)}
                    sx={{ minWidth: "150px" }}
                  >
                    {/* {hasAttachments ? "View" : "No Data"} */}
                    { schoolOrderModalData && schoolOrderModalData?.attachment && JSON.parse(schoolOrderModalData?.attachment).length > 0 ? 'View Attachments' : "No Data Found"}
                  </Button>

                    <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 800,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "80%",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6" component="h2" mb={2}>
                    Attachment Details
                  </Typography>
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {attachments && attachments?.map((file, index) => {
                      const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file.fileName);
                      const isPDF = /\.pdf$/i.test(file.fileName);

                      return (
                        <div key={index} style={{ cursor: "pointer" }}>
                          {isImage && (
                            <img
                              src={`https://mittsureone.com:3001/file/${file.fileName}`}
                              alt={file.originalName}
                              style={{
                                width: "150px",
                                height: "auto",
                                margin: "10px",
                                borderRadius: "8px",
                                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
                              }}
                              onClick={() => handleImageClick(file)}
                            />
                          )}{console.log(`https://mittsureone.com:3001/file/${file.fileName}}`)}
                          {isPDF && (
                            <div
                              style={{
                                width: "150px",
                                height: "200px",
                                margin: "10px",
                                border: "1px solid #ddd",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#f5f5f5",
                              }}
                              onClick={() => handlePdfClick(file)}
                            >
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                textAlign="center"
                              >
                                {file.originalName}
                              </Typography>
                            </div>
                          )}
                          {!isImage && !isPDF && (
                            <a
                              href={`https://mittsureone.com:3001/file/${file.fileName}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => handleImageClick(file)}
                            >
                              {file.originalName}
                            </a>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleCloseModal} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>

              {(selectedImage || selectedPdf) && (
                <Modal open={!!selectedImage || !!selectedPdf} onClose={handleCloseImageView}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "90%",
                      maxWidth: 1000,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      borderRadius: 2,
                      maxHeight: "90vh",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {selectedImage && (
                      <img
                      onLoad={handleImageLoad}
                        src={`https://mittsureone.com:3001/file/${selectedImage.fileName}`}
                        alt={selectedImage.originalName || "Attachment"}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "8px",
                          maxHeight: "70vh",
                        }}
                      />
                    )}
                    {selectedPdf && (
                      <iframe
                      onLoad={handlePdfLoad}
                        src={`https://mittsureone.com:3001/file/${selectedPdf.fileName}`}
                        title={selectedPdf.originalName}
                        style={{
                          width: "100%",
                          height: "500px",
                          margin: "10px",
                          border: "1px solid #ddd",
                        }}
                      ></iframe>
                    )}
                    <Typography variant="h6" mt={2} textAlign="center">
                      {selectedImage?.originalName || selectedPdf?.originalName || "N/A"}
                    </Typography>
                    <Button
                      onClick={handleCloseImageView}
                      variant="contained"
                      color="error"
                      sx={{ mt: 2 }}
                    >
                      Close
                    </Button>
                  </Box>
                </Modal>
              )}
                   </div>
                  </div>

                </div>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button onClick={handleCloseSchoolOrderViewModal} variant="contained" color="primary">
                    Close
                  </Button>
                </Box>
              </Box>
            </Modal>
          </>
        );
      },
    },

    
    
    newOrderStatus && newOrderStatus == 6 &&{
      field: "discard",
      headerName: "Discard",
      width:110,
      editable: false,
      renderCell: (params) => {
        // {console.log("apna paramas",params)}
        return (
          <>
            <Tooltip>
              <span>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleDeleteOrder(params.row.orderId ,selectedTab)}
                  sx={{ minWidth: "80px" }}
                >
                  {"Discard"}
                </Button>
              </span>
            </Tooltip>
          </>
        );
      },
    },


   
    {
      field: "Approve Order",
      headerName: "Actions",
      width: (newOrderStatus == 0 ? 200 : newOrderStatus == 1 ? 130 : newOrderStatus == 2 ? 120 : newOrderStatus == 3 ? 120 : newOrderStatus == 4 ? 120 : newOrderStatus == 5 ? 200 : newOrderStatus == 6 ? 200 : 200),
      // width: (newOrderStatus !== 1 && newOrderStatus !== 2) ? 200 : 140,
      filterable: false,
      // align:"center",
      // headerStyle: { textAlign: 'right' },
      // height: 10,
      headerAlign: 'center',
      sortable: false,
      // editable: true,
      renderCell: (params) => {

        let value = params.row?.orderId
        let ownerId = params.row?.ownerId
        let allValues = params.row

        return (
          <Stack direction={"row"} spacing={0}>
            <Tooltip title="Click to view order items"><IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              fetchOrderLineItem(value);
            }} >
              <RemoveRedEyeIcon />
            </IconButton></Tooltip>

            {/* <Tooltip title="Click to return order items">
              <IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              returnOrder(allValues);
            }} >
              <RepartitionIcon />
            </IconButton>
            </Tooltip> */}
            <Tooltip title="Download Single Order">
              <IconButton variant="extended" color="primary" onClick={(e) => {
              e.stopPropagation();
              downloadSingleOrder(allValues);
            }} >
              <FileDownloadIcon />
            </IconButton>
            </Tooltip>

            <Tooltip title="Click to dasdadedit order"><IconButton variant="extended"
              // disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2}
              onClick={(e) => {
                
                editData(allValues);
              }} >
              <DriveFileRenameOutlineIcon />
            </IconButton></Tooltip>
            {/* {(params.row.asmApproval == 1 && newOrderStatus !== 1 && newOrderStatus !== 2 && newOrderStatus !== 3 && newOrderStatus !== 4) &&<Tooltip title="Click to approve order"><IconButton disabled={params.row?.approvalStatus === 1 || params.row?.approvalStatus === 2} variant="extended" sx={{ color: "#008000" }} onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({ id: value, approvalStatus: "1", ownerId });
            }} >
              <ThumbUpIcon />
            </IconButton></Tooltip>} */}
            {userData.role === 'asm' ? (
  params.row.asmApproval !== 1 && 
  newOrderStatus !== 1 && 
  newOrderStatus !== 2 && 
  newOrderStatus !== 3 && 
  newOrderStatus !== 4 && (
    <Tooltip title="Click to approve order">
      <IconButton 
        disabled={params.row?.approvalStatus === 1 || params.row?.approvalStatus === 2} 
        variant="extended" 
        sx={{ color: "#008000" }} 
        onClick={(e) => {
          e.stopPropagation();
          approveRejectOrder({ id: value, approvalStatus: "1", ownerId });
        }}
      >
        <ThumbUpIcon />
      </IconButton>
    </Tooltip>
  )
) : (
  newOrderStatus !== 1 && 
  newOrderStatus !== 2 && 
  newOrderStatus !== 3 && 
  newOrderStatus !== 4 && (
    <Tooltip title="Click to approve order">
      <IconButton 
        disabled={params.row?.approvalStatus === 1 || params.row?.approvalStatus === 2} 
        variant="extended" 
        sx={{ color: "#008000" }} 
        onClick={(e) => {
          e.stopPropagation();
          approveRejectOrder({ id: value, approvalStatus: "1", ownerId });
        }}
      >
        <ThumbUpIcon />
      </IconButton>
    </Tooltip>
  )
)}

            {/* <Tooltip title="Click to reject order"><IconButton disabled={params.row?.approvalStatus===1||params.row?.approvalStatus===2} variant="extended" sx={{color:"red"}} onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({id:value,approvalStatus:"2",ownerId});}} >
             <ThumbDownIcon/>
            </IconButton></Tooltip> */}
            {/* {(params.row.asmApproval == 1 && newOrderStatus !== 1 && newOrderStatus !== 2 && newOrderStatus !== 3 && newOrderStatus !== 4) &&<Tooltip title="Click to reject order">
              <IconButton
                disabled={params.row?.approvalStatus === 1 || params.row?.approvalStatus === 2}
                variant="extended"
                sx={{ color: "red" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleReject({ id: value, ownerId });
                }}
              >
                <ThumbDownIcon />
              </IconButton>
            </Tooltip>} */}
            {userData.role === 'asm' ? (
  params.row.asmApproval !== 1 &&
  newOrderStatus !== 1 &&
  newOrderStatus !== 2 &&
  newOrderStatus !== 3 &&
  newOrderStatus !== 4 && (
    <Tooltip title="Click to reject order">
      <IconButton
        disabled={params.row?.approvalStatus === 1 || params.row?.approvalStatus === 2}
        variant="extended"
        sx={{ color: "red" }}
        onClick={(e) => {
          e.stopPropagation();
          handleReject({ id: value, ownerId });
        }}
      >
        <ThumbDownIcon />
      </IconButton>
    </Tooltip>
  )
) : (
  newOrderStatus !== 1 &&
  newOrderStatus !== 2 &&
  newOrderStatus !== 3 &&
  newOrderStatus !== 4 && (
    <Tooltip title="Click to reject order">
      <IconButton
        disabled={params.row?.approvalStatus === 1 || params.row?.approvalStatus === 2}
        variant="extended"
        sx={{ color: "red" }}
        onClick={(e) => {
          e.stopPropagation();
          handleReject({ id: value, ownerId });
        }}
      >
        <ThumbDownIcon />
      </IconButton>
    </Tooltip>
  )
)}

          </Stack>
        )

      },
    },

    ].filter(Boolean);


    return result
  }

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };

    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);

    return formattedDate;
  };

  const fetchOrderLineItem = async (val) => {
    try {
      // console.log(val)
      setLoading(true)
      setModalData()
      let sentData = { id: val }
      let results = await window.Platform.database.fetchOrderLineItem(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      setModalData({ data: jsonArrayWithId, id: val })
      setModal(true)
      setLoading(false)

    }
    catch (error) {
      console.log(error)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)



    }



  }
  const returnOrder = async (data) => {
    try {
      setLoading(true)
      let sentData = { id: data.orderId }
      let results = await window.Platform.database.fetchOrderLineItem(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setReturnData({ product: jsonArrayWithId, order: data })
      setReturnItems(true)
      setLoading(false)

    }
    catch (error) {
      console.log(error)
      setLoading(false)

    }
  }

const editData=(data)=>{
console.log("EDITDATA",data)
setEditModalData(data)
setEditMode(true)
setAddModal(true)
}



    







  function CustomToolbar() {
    return (
      // <GridToolbarContainer>
      //   <GridToolbarColumnsButton />
      //   <GridToolbarFilterButton />
      //   <GridToolbarDensitySelector />
      //   <GridToolbarExport
      //     csvOptions={{
      //       fileName: "export-order",
      //     }}
      //   />
      // </GridToolbarContainer>
      <>
        {/* <GridToolbarContainer>
        <Button onClick={()=>{setNewOrderStatus(0)}}>Panding</Button>
        <Button onClick={()=>{setNewOrderStatus(1)}}>Approved</Button>
        <Button onClick={()=>{setNewOrderStatus(2)}}>Rejected</Button>
       </GridToolbarContainer> */}

        <GridToolbarContainer>
        <Button
            onClick={() => setNewOrderStatus(5)}
            style={{
              backgroundColor: newOrderStatus === 5 ? "#8787f470" : "white",
              color: newOrderStatus === 5 ? "#000000" : "black",
            }}
          >
            All
          </Button>
          <Button
            onClick={() => setNewOrderStatus(0)}
            style={{
              backgroundColor: newOrderStatus === 0 ? "#8787f470" : "white",
              color: newOrderStatus === 0 ? "#000000" : "black",
            }}
          >
            Pending
          </Button>
          <Button
            onClick={() => setNewOrderStatus(1)}
            style={{
              backgroundColor: newOrderStatus === 1 ? "#8787f470" : "white",
              color: newOrderStatus === 1 ? "#000000" : "black",
            }}
          >
            Approved
          </Button>
          <Button
            onClick={() => setNewOrderStatus(2)}
            style={{
              backgroundColor: newOrderStatus === 2 ? "#8787f470" : "white",
              color: newOrderStatus === 2 ? "#000000" : "black",
            }}
          >
            Rejected
          </Button>
          <Button
            onClick={() => setNewOrderStatus(3)}
            style={{
              backgroundColor: newOrderStatus === 3 ? "#8787f470" : "white",
              color: newOrderStatus === 3 ? "#000000" : "black",
            }}
          >
            Shipped
          </Button>
          <Button
            onClick={() => setNewOrderStatus(4)}
            style={{
              backgroundColor: newOrderStatus === 4 ? "#8787f470" : "white",
              color: newOrderStatus === 4 ? "#000000" : "black",
            }}
          >
            Delivered
          </Button>
          <Button
            onClick={() => setNewOrderStatus(6)}
            style={{
              backgroundColor: newOrderStatus === 6 ? "#8787f470" : "white",
              color: newOrderStatus === 6 ? "#000000" : "black",
            }}
          >
            Saved For Later
          </Button>
        </GridToolbarContainer>

      </>
    );
  }


  const onFilterChange = async (filterModel) => {


    try {
      setLoading(true);
      setFilter(true)
      console.log(filterModel, 'filterModel')
      if (filterModel) {
        const response = await window.Platform.database.filterOrder({ filterField: filterModel.field, filterValue: filterModel.value, pageNumber: paginationModel.page, startDate: startDate, endDate: endDate })
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
      }
      else {
        const response = await window.Platform.database.filterOrder({ filterField: "date", pageNumber: paginationModel1.page, startDate: startDate, endDate: endDate })
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)

      }

      setLoading(false);
    }
    catch (err) {
      console.error(err)
      setLoading(false);
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
    }

  };

  const onFilterChange1 = async (filterModel) => {


    try {
      setLoading(true);
      setFilter1(true)
      console.log(filterModel, 'filterModel')
      if (filterModel) {
        const response = await window.Platform.database.filterOrderDistributor({ filterField: filterModel.field, filterValue: filterModel.value, pageNumber: paginationModel1.page, startDate: startDate, endDate: endDate })
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
      }
      else {
        const response = await window.Platform.database.filterOrderDistributor({ filterField: "date", pageNumber: paginationModel1.page, startDate: startDate, endDate: endDate })
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)

      }

      setLoading(false);
    }
    catch (err) {
      console.error(err)
      setLoading(false);
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
    }

  };



  const renderContent = () => {
    
    if (selectedTab === 0) {
      return (<>
      <div className="d-flex justify-content-end align-items-center  py-2">
        <Typography variant="body1">Select Page Size:&nbsp; </Typography>
    <Select
      value={paginationModel.pageSize}
      variant="outlined"
      size="small"
      onChange={(e)=>{setPaginationModel({...paginationModel,pageSize:e.target.value})}}
      sx={{ width: 90, fontSize: 14 }}
    >
      {[10,20,30,40,50,60].map((role) => (
        <MenuItem key={role} value={role}>{role}</MenuItem>
      ))}
    </Select>
        </div>
        <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
          <DataGrid
            className="payrollGrid"
            //   checkboxSelection={true}
            density="compact"
            rows={accessChanges}
            columns={getEmployeeColumns()}
            disableSelectionOnClick
            disableRowSelectionOnClick
            rowHeight={60}
            disableDensitySelector
            pageSizeOptions={[10]}
            onFilterModelChange={(val) => {
              //       console.log("vvvv",val);



              if (val?.items[0]?.value?.length > 0) {
                onFilterChange(val.items[0])
                setSearchTerm(val.items[0])



              }
              else if (!val.items[0]?.value && startDate && endDate) {
                setSearchTerm(null)
                onFilterChange()
                console.log("CHECKING...1")

              }
              else {
                fetchOrder()
                // fetchOrder(LocalStoredData)
                setSearchTerm(null)
                setFilter(false)

              }
             
            }

            }
            filterMode="server"

            rowCount={rowCount}

            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}

            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
        </>
      );
    }
    else if (selectedTab === 1) {

      return (<>
      <div className="d-flex justify-content-end align-items-center  py-2">
        <Typography variant="body1">Select Page Size:&nbsp; </Typography>
    <Select
      value={paginationModel.pageSize}
      variant="outlined"
      size="small"
      onChange={(e)=>{setPaginationModel1({...paginationModel1,pageSize:e.target.value})}}
      sx={{ width: 90, fontSize: 14 }}
    >
      {[10,20,30,40,50,60].map((role) => (
        <MenuItem key={role} value={role}>{role}</MenuItem>
      ))}
    </Select>
        </div>
      
        <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
          <DataGrid
            className="payrollGrid"
            //   checkboxSelection={true}
            density="compact"
            rows={accessChanges}
            columns={getEmployeeColumns()}
            disableSelectionOnClick
            disableRowSelectionOnClick
            rowHeight={60}
            pageSizeOptions={[10]}
            disableDensitySelector
            onFilterModelChange={(val) => {
              //       console.log("vvvv",val);



              if (val?.items[0]?.value?.length > 0) {
                onFilterChange1(val.items[0])
                setSearchTerm1(val.items[0])
              }
              else if (!val.items[0]?.value && startDate && endDate) {
                setSearchTerm1(null)
                onFilterChange1()
                console.log("CHECKING...1")

              }
              else {
                fetchOrder1()
                setSearchTerm1(null)
                setFilter1(false)
              }
             
            }

            }
            filterMode="server"

            rowCount={rowCount}

            paginationModel={paginationModel1}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel1}

            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
        </>
      );

    }
  };

  const formatDate = async (data) => {
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate1(formattedDate)
    setStartDate(formattedDate)
  

  }




  const onClose=async()=>{
    
try{



    setAddModal(false)
    // setEditSchool(false)
  }
catch(error){
console.log(error)
setLoading(false)

    }
  }

  const exportOrders = async () => {
    setLoading(true);
    try {
          let data = {
            filter:search,
            fromDate:fromDate,
            toDate:toDate,
            series:selectedSeries,
            rsm:rsm,
            asm:asm,
            ownerId:rm,
            approvalStatus: newOrderStatus
          }
       
  
        let result = await window.Platform.database.exportData("/order/exportOrders",
          data
        )
  
        // Check if the response is actually a Blob
        console.log(result, "API Response Data");
  
        const blob = new Blob([result.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "schoolOrders.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
  
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Export error:", error);
    }
  }

  const exportOrders1 = async () => {
    setLoading(true);
    try {
          let data = {
            filter:search,
            orderStatus: 1,
            fromDate:fromDate,
            toDate:toDate,
            series:selectedSeries,
            rsm:rsm,
            asm:asm,
            ownerId:rm,
            approvalStatus: newOrderStatus
          }
       
  
        let result = await window.Platform.database.exportData("/order/exportOrders",
          data
        )
  
        // Check if the response is actually a Blob
        console.log(result, "API Response Data");
  
        const blob = new Blob([result.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "distributorOrders.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
  
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Export error:", error);
    }
  }


  const clearFilters = async () => {
    setLoading(true);
    setRm("")
    setAsm("")
    setRsm("")
    setSearch("")
    setFromDate("")
    setToDate("")
    setSelectedRole("")
    setSelectedSeries("")
    try {
      let data1 = {
        pageNumber: paginationModel.page,
        // recordPerPage:15,
        approvalStatus: newOrderStatus
      }

let  results = await window.Platform.database.fetchOrder(data1);
      console.log("results=======", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setRowCount(results?.data1)
      setAccessChanges(jsonArrayWithId);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
    finally {
      setLoading(false)
    }
  }

  const findOrdersByDate = async () => {
    console.log(fromDate, ",,,,,,,,,,,,,,,,", toDate) 
    try {
      setLoading(true);
      let data1 = {
        pageNumber: paginationModel.page,
        // recordPerPage:15,
        approvalStatus: newOrderStatus
      }
let results ;
      // if (LocalStoredData?.user?.role.toLowerCase().includes('admin')) {
        results = await window.Platform.database.fetchOrder(data1, selectedRole, selectedSeries, search, fromDate, toDate);
        console.log("aayayayya",data1,",", selectedRole, ",", selectedSeries,",", search,"end")
      // }
      // else{
      //   results = await window.Platform.database.fetchOrder(data1);
      // }
      console.log("results=======", results);
      // if(auth?.user?.role === ROLES.ADMIN){
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
      setRowCount(results?.data1)
      setAccessChanges(jsonArrayWithId);
      // }
      // setVisibleEmployees(results);
      setLoading(false);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <>

      <StaffEditorPageContainer>
        <HeaderContainer>
          {/* <Typography variant="h5">Order Management</Typography> */}
          {loading && <OpaqueLoading />}
          <div style={{ width: "80%", display: "flex", flexDirection: "row", gap: "10px" }}>
          <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
  <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
 <Tooltip title="Search By SO ID">
  <div>
  <Typography variant="body1">Enter to search</Typography>
  <TextField
    
    variant="outlined"
    size="small"
    placeholder="Search By SO ID"
    value={search}
    onChange={(e) => setSearch(e.target.value)} // Proper event handler
    sx={{ width: 200, fontSize: 14 }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search color="action" />
        </InputAdornment>
      )
    }}
  />
  </div>
</Tooltip>

    {userData?.role.includes('admin')&&<div>
    <Typography variant="body1">Select RSM:</Typography>
    <Select
      value={rsm}
      variant="outlined"
      size="small"
      onChange={(e)=>{setRsm(e.target.value);getUsers('rsm',e.target.value)}}
      sx={{ width: 150, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {rsms.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
    </div>}
   {userData?.role!='asm'&& <div >
    <Typography variant="body1">Select ASM:</Typography>
    <Select
      value={asm}
      variant="outlined"
      size="small"
      onChange={(e)=>{setAsm(e.target.value);getUsers('asm',e.target.value)}}
      sx={{ width: 150, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {asms.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
      </div>}
      <div >
    <Typography variant="body1">Select RM:</Typography>
    <Select
      value={rm}
      variant="outlined"
      size="small"
      onChange={(e)=>{setRm(e.target.value)}}
      sx={{ width: 150, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {rms.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
      </div>
    <div >
    <Typography variant="body1">Select Series:</Typography>
    <Select
      value={selectedSeries}
      variant="outlined"
      size="small"
      onChange={(e)=>{setSelectedSeries(e.target.value)}}
      sx={{ width: 150, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {series.map((role) => (
        <MenuItem key={role} value={role.seriesTableId}>{role.seriesName}</MenuItem>
      ))}
    </Select>
      </div>
  </Box>
  <Box  display="flex" alignItems="end" gap={1} flexWrap="wrap">
    <div>
  <Typography variant="body1">From Date</Typography>
                <input type="date" className="form-control w-auto" value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}}/>
                </div>
                <div>
                <Typography variant="body1">To Date:</Typography>
                <input type="date" className="form-control w-auto" value={toDate} onChange={(e)=>{setToDate(e.target.value)}}/>
                </div>
                <Button  variant="contained" color="primary" style={{fontSize:"14px"}} sx={{ mr: 1, borderRadius: '10px' }} onClick={()=>{selectedTab == 0?fetchOrder():fetchOrder1()}}>Find</Button>
                <button className="btn btn-outline-secondary text-white" style={{fontSize:"13px"}} onClick={()=>{clearFilters()}}>Clear Filters</button>
              </Box>

<div className="mt-3">
{selectedTab == 0 ?  <Button variant="contained" color="primary" onClick={()=>{exportOrders()}}>Download</Button> : <Button variant="contained" color="primary" onClick={()=>{exportOrders1()}}>Download Distributor Report</Button>}
</div>
             {/* {selectedTab == 0 ?  <Button variant="contained" color="primary" onClick={()=>{exportOrders()}}>Download School Report</Button> : <Button variant="contained" color="primary" onClick={()=>{exportOrders1()}}>Download Distributor Report</Button>} */}
 
</Box>
         
          </div>
          <Stack spacing={2} direction={"row"}>
           


  
    <Button variant="contained" color="primary" 
      onClick={()=> setAddModal(true)} sx={{ mr: 1, borderRadius: '10px' }}>
      <Add sx={{ mr: 1 }}  />
      Add New  Order 
    </Button>
    

    
  

          </Stack>

          
        </HeaderContainer>
        <TableContainer>
          <TabsContainer>

            <StyledTabs

              variant="fullWidth"

              value={selectedTab}

              onChange={(event, value) => handleTabChange(value)}

              indicatorColor="primary"

              textColor="primary"

            >

              <Tab label="School Orders" />


              <Tab label="Distributor Orders" />

            </StyledTabs>

            {renderContent()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
      {
      modal &&
      <OrderLineItemModal
      onCancel={() => setModal(null)}
      loading={loading}
      data={modalData}
      props={accessChanges}
    //   onSubmit={updateWarehouseData}
      />
    }
    {
      addModal && <AddOrderModal onClose1={onClose} onClose={()=>setAddModal(false)} partyS={selectedTab==0?'school':'distributor'}
      //  fetchData={()=>fetchOrder(LocalStoredData)} 
       fetchData={()=>fetchOrder()} 
       status={true} editMode={editMode} dataProp={editModalData} />
    }
    

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
        <DialogTitle>Reject Order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Remark"
            type="text"
            fullWidth
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmReject} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
