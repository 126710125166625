import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Chip } from '@mui/material';
import styled, { keyframes } from 'styled-components';


// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import LogoMittsure from '../../Logo1.png';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {

  const LogoContainer = styled.div`
  background-color: ${(props) =>
    props.drawerOpen ? "white" : isMobile ? "unset" : "white"};

  padding: ${(props) => (props.drawerOpen ? "0px" : isMobile ? "0px" : "10px")};

  border-radius: ${(props) =>
    props.drawerOpen ? (isMobile ? "20px" : "0px") : "0px"};
`;

const Logo = styled.img`
  width: 100%;

  margin-top: ${(props) =>
    props.drawerOpen ? (isMobile ? "15px" : "0px") : "0px"};

  padding: ${(props) =>
    props.drawerOpen ? (isMobile ? "0px" : "15px") : "0px"};

  height: ${(props) =>
    props.drawerOpen ? (isMobile ? "auto" : "auto") : "auto"};
`;

  const Container = styled.div`
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  `;

  const theme = useTheme();
  const isMobile = window.innerWidth < 900;
  let drawerOpen = true;

  return (
    // only available in paid version
    <Container>
      <DrawerHeaderStyled theme={theme} open={open}>
        <Stack direction="row" spacing={0.5} alignItems="center" sx={{mr: 2}}>
          {/* <Logo /> */}
          <LogoContainer drawerOpen={true}>
              <Logo
                drawerOpen={drawerOpen}
                src={
                  drawerOpen
                    ? LogoMittsure
                    : isMobile
                      ? LogoMittsure
                      : "logo512.png"
                }
              />
            </LogoContainer>
          <Chip
            label={'1.0'}
            size="small"
            sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', paddingTop:"4px", paddingBottom:"3px"} }}
            component="a"
            // href="https://github.com/codedthemes/mantis-free-react-admin-template"
            target="_blank"
            // clickable
          />
        </Stack>
      </DrawerHeaderStyled>
    </Container>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
