import React, { useState, useRef } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import moment from "moment";
import ExcelJS from 'exceljs'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CloseOutlined } from "@mui/icons-material";

import {
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Button,
    Tooltip,
  } from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkUploadTargetUser = ({onClose,fetchData, allData}) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const hiddenFileInput = useRef(null);
    const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);


  // const getRowClassName = (params) => {
  //   let uploadStatus = params.row.status || "";
  //   if (uploadStatus === "Success") {
  //     return "successRow";
  //   } else if (uploadStatus === "Failure") {
  //     return "failureRow";
  //   } else {
  //     return "";
  //   }
  // };
  const getRowClassName = (params) => {
    let uploadStatus = (params.row.status || "").toLowerCase();
    if (uploadStatus === "success") {
      return "successRow";
    } else if (uploadStatus === "failure") {
      return "failureRow";
    } else {
      return "";
    }
  };
  

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
          csvOptions={{
            fileName: 'bulk-upload-Supplier',
          }}
        />
      </GridToolbarContainer>
    );
  }
    const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploaded(false);
    handleImport(file);
    setFileKey((prevKey) => prevKey + 1);
  };
  //   const handleImport = (file) => {
  //     try {
  //   if (file) {
  //     setLoading(true);
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       const data = new Uint8Array(e.target.result);
  //       const workbook = XLSX.read(data, { type: "array" });
  //       const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //       const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //       const formattedData = jsonData.slice(1).map((row, index) => ({
  //         Name: row[0],
  //         Email: row[1],
  //         Mobile: row[2],
  //         // DOJ: row[3] ? moment(XLSX.SSF.parse_date_code(row[3])).valueOf() : "",
  //         DOJ: row[3] ? moment(new Date(row[3])).valueOf() : "",
  //         Address:row[4],
  //         Pincode:row[5],
  //         State:row[6],
  //         District:row[7],
  //         Role:row[8],
  //         ReportingManager:row[9],
  //         Cluster:row[10],
  //         UserName:row[11],
  //         password:row[12],
  //         id: index + 1,
  //       }));
  //         console.log("formattedData",formattedData)
  //       setTargetRows(formattedData)
  //       setLoading(false);
  //     };
  //     reader.readAsArrayBuffer(file);
  //   }}
  //   catch (error) {
  //     console.error(error);
  //     window.NotificationUtils.showError("Error retreiving targets data");
  //     setLoading(false);
  //   }
  // };

  // const handleImport = (file) => {
  //   try {
  //     if (file) {
  //       setLoading(true);
  //       const reader = new FileReader();
  //       reader.onload = function (e) {
  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: "array" });
  //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //         const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
  //         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //         const formattedData = jsonData.slice(1).map((row, index) => {
  //           let doj = row[3];
  //           if (typeof doj === 'number') {
  //             doj = moment(XLSX.SSF.parse_date_code(doj)).valueOf();
  //           } else {
  //             doj = moment(new Date(doj)).valueOf();
  //           }
  //           return {
  //             Name: row[0],
  //             Email: row[1],
  //             Mobile: row[2],
  //             DOJ: doj,
  //             Address: row[4],
  //             Pincode: row[5],
  //             State: row[6],
  //             District: row[7],
  //             Role: row[8],
  //             ReportingManager: row[9],
  //             Cluster: row[10],
  //             UserName: row[11],
  //             password: row[12],
  //             id: index + 1,
  //           };
  //         });
  //         console.log("formattedData", formattedData);
  //         setTargetRows(formattedData);
  //         setLoading(false);
  //       };
  //       reader.readAsArrayBuffer(file);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     window.NotificationUtils.showError("Error retrieving targets data");
  //     setLoading(false);
  //   }
  // };

  // const handleImport = (file) => {
  //   try {
  //     if (file) {
  //       setLoading(true);
  //       const reader = new FileReader();
  //       reader.onload = function (e) {
  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: "array" });
  //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //         const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
  //         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //         const formattedData = jsonData.slice(1).map((row, index) => {
  //           let doj = row[3];
            
  //           if (typeof doj === "number") {
  //             // Handle Excel date number (days since 1900)
  //             const dateObj = XLSX.SSF.parse_date_code(doj);
  //             doj = moment(`${dateObj.y}-${dateObj.m}-${dateObj.d}`).unix();
  //           } else if (typeof doj === "string") {
  //             // Handle date string from Excel
  //             doj = moment(new Date(doj)).unix();
  //           }
  
  //           return {
  //             Name: row[0],
  //             Email: row[1],
  //             Mobile: row[2],
  //             DOJ: doj,
  //             Address: row[4],
  //             Pincode: row[5],
  //             State: row[6],
  //             District: row[7],
  //             Role: row[8],
  //             ReportingManager: row[9],
  //             Cluster: row[10],
  //             UserName: row[11],
  //             password: row[12],
  //             id: index + 1,
  //           };
  //         });
  //         console.log("formattedData", formattedData);
  //         setTargetRows(formattedData);
  //         setLoading(false);
  //       };
  //       reader.readAsArrayBuffer(file);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     window.NotificationUtils.showError("Error retrieving targets data");
  //     setLoading(false);
  //   }
  // };
  
  const handleImport = (file) => {
    try {
      if (file) {
        setLoading(true);
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const formattedData = jsonData.slice(1).map((row, index) => {
            let doj = row[3];
  
            if (typeof doj === "number") {
              // Handle Excel date number (days since 1900)
              const dateObj = XLSX.SSF.parse_date_code(doj);
              doj = moment(`${dateObj.d}/${dateObj.m}/${dateObj.y}`, "DD/MM/YYYY").unix();
            } else if (typeof doj === "string") {
              // Handle date string in dd/mm/yyyy format
              doj = moment(doj, "DD/MM/YYYY").unix();
            }
  
            return {
              Name: row[0],
              Email: row[1],
              Mobile: row[2],
              DOJ: doj,
              Address: row[4],
              Pincode: row[5],
              State: row[6],
              District: row[7],
              Role: row[8],
              ReportingManager: row[9],
              Cluster: row[10],
              UserName: row[11],
              password: row[12],
              id: index + 1,
            };
          });
          console.log("formattedData", formattedData);
          setTargetRows(formattedData);
          setLoading(false);
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retrieving targets data");
      setLoading(false);
    }
  };
  
  
  const getGridColumns = () => {
    var results = [
      {
                  field: "id",
                  headerName: "S.no",
                  maxWidth: 100,
                  valueGetter: (params) => {
                    let name = params.row.id || "";
                    return name;
                  },
                  flex: 0.22,
                  editable: false,
                  renderCell: (params) => {
                    let name = params.row.id || "";
                    return (
                      <Tooltip title={name}>
                        <Typography variant="inherit">{name}</Typography>
                      </Tooltip>
                    );
                  },
                },
      {
        field: "name",
        headerName: "Name",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Name
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Name
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Email
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Email
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "mobile",
        headerName: "Mobile",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Mobile
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Mobile
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "DOJ",
        headerName: "DOJ",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.DOJ
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.DOJ
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },  
      {
        field: "address",
        headerName: "Address",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Address
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Address
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "pincode",
        headerName: "Pin Code",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Pincode
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Pincode
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "state",
        headerName: "State",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.State
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.State
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "district",
        headerName: "District",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.District
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.District
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "role",
        headerName: "Role",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Role
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Role
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "reportingManager",
        headerName: "Reporting Manager",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.ReportingManager
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.ReportingManager
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "cluster",
        headerName: "Cluster Name",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Cluster
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.Cluster
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "user",
        headerName: "User Name",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.UserName
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.UserName
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
      {
        field: "password",
        headerName: "Password",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.password
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.password
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      }, 
    ]
     if (uploaded) {
            results.splice(
              0,
              0,
              {
                field: "status",
                headerName: "Upload Status",
                width: 100,
                renderCell: (params) => {
                    // console.log("PARAMSINDATA",params)
                  let name = "";
                  if (params.row.status) {
                    name =
                      params.row.status === "Success" ? "Success" : "Failure";
                  }
                  return (
                    <Tooltip title={name}>
                      <Typography variant="inherit">{name}</Typography>{console.log(params.row,"params.row")}
                    </Tooltip>
                  );
                },
                editable: false,
              },
              {
                field: "reason",
                headerName: "Reason",
                width: 200,
                renderCell: (params) => {
                  let name = params.row.reason || "";
                  return (
                    <Tooltip title={name}>
                      <Typography variant="inherit">{name}</Typography>
                    </Tooltip>
                  );
                },
                editable: false,
              }
            );
          };
    return results;
  }

  const getFileRows = async (file) => {
    try {
      let data = await file.arrayBuffer();
      const wb = XLSX.read(data, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let dataParse = XLSX.utils.sheet_to_json(ws);
      if(dataParse.length > 0){
        dataParse = dataParse.map((entry, index) => {
          return {
            ...entry,
            id: index,
          };
        });
      }
      let element = document.getElementById("fileUploadPicker");
      if (element) {
        element.value = "";
      }
      return dataParse;
    } catch (error) {
      throw error;
    }
  };

  const handleUpload = async () => {
    try {
      let uploadInput = document.getElementById("filePickerButton");
      if (uploadInput) {
        if (uploadInput.files.length > 0) {
          let file = uploadInput.files[0];
          setLoading(true);
          let response = await getFileRows(file);
          // console.log("RESPONSE",response)
          if (response.length > 0) {
            setTargetRows(response);
            setUploaded(false);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };

  const hasErrors = () => {
    try {
      if (!targetRows) {
        window.NotificationUtils.showError(
          "Please select a valid file for upload"
        );
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const dataRowCheck = (rowDetails) => {
    try {
      let result = false;
      if (!rowDetails.employeeCode) {
        // result = BULK_UPLOAD_FAILURE_CODES.MISSING_EMPLOYEE_CODE;
      }
      return result;
    } catch (error) {
      throw error;
    }
  };

    const handleRowSelection = (selection) => {
    console.log("SELECT",selection)
    let array=[]
    let data=targetRows.filter((row) => selection.includes(row.id));
    console.log(data,"dataatassa")
    let finalData=data.map((row) => {
      let sent={
        id:row.id, Name:row.Name, Email:row.Email, Mobile:row.Mobile, DOJ:row.DOJ, Address:row.Address, Pincode:row.Pincode, State:row.State, District:row.District, 
        Role:row.Role, ReportingManager:row.ReportingManager, Cluster:row.Cluster, UserName:row.UserName, password:row.password
      }
      array.push(sent)
    })
    return array;
  };

   const handleDownload = async ( ) => {
      try
     {
      const headers =['Name', 'Email', 'Mobile', 'DOJ', "Address", "Pincode", "State", "District", 'Role', "ReportingManager", "Cluster", "UserName",  "password"]

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
      
      worksheet.addRow(headers);
      
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'bulkUsers.xlsx';
        a.click();
        URL.revokeObjectURL(url);
        setLoading(false)
      });
    }
    catch (error) {
      console.error(error);
      setLoading(false)
    }  
    };

  const onClickUpload = async () => {
    try {
   const data=handleRowSelection(selectedRowIds)
   console.log("SELECTED ROWS",data)
    let finalData =[...targetRows]
    console.log("SELECTED ROWS",selectedRowIds);
    console.log("SELECTED ROWS FINALLY",finalData)
      setLoading(true);
      let response = await window.Platform.database.addUserBulk(data);    //  addSchoolBulk
      console.log("SUCCESS",response)
      setTargetRows(response.data)
    console.log("FINAL",finalData)
    setSelectedRowIds([])
      for(let responseEntry of response.data){
        let rowIndex = finalData.findIndex(row => row.id === responseEntry.id);
        if(rowIndex !== -1){
            finalData[rowIndex].status = responseEntry.status;
            finalData[rowIndex].reason = 'N/A';
          if(responseEntry.status === 'Failure'){
            finalData[rowIndex].reason = responseEntry.reason;
          }
        }
      }
      setTargetRows(finalData);
      if (!uploaded) {
        setUploaded(true);
      }
      setLoading(false);
      if (response.status) {
        window.NotificationUtils.showError(response.message);
      } else {
        window.NotificationUtils.showSuccess('Submition Complete')
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError(error?.message);
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        {targetRows.length > 0 && (
          <>
            <DataGridContainer>
            <DataGrid
  disableSelectionOnClick={true}
  checkboxSelection={true}
  rows={targetRows}
  columns={getGridColumns()}
  onRowSelectionModelChange={(newRowSelectionModel) => {
    setSelectedRowIds(newRowSelectionModel);
  }}
  density="compact"
  autoPageSize
  getRowClassName={getRowClassName}
  components={{
    Toolbar: CustomToolbar,
  }}
  pageSizeOptions={[10]}
/>
            </DataGridContainer>
            <NoteText variant="body2">
              Note: If selected, only the selected rows will be uploaded
            </NoteText>
          </>
        )}
        <InputFieldContainer>
        <Button variant="contained" color="primary" onClick={handleDownload}>
        Download Template
      </Button>
               <input
                    key={fileKey}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      background: "primary",
                      borderColor: "#003974",
                      // borderRadius: "40px",
                      padding: "5px 10px",
                      marginRight: "20px",
                    }}
                    onClick={handleButtonClick}
                  >
                    Select File
                  </Button>
        </InputFieldContainer>
        <ModalActionsContainer>
          <div>

          </div>
          <Button
            disabled={loading || selectedRowIds.length === 0}
            onClick={onClickUpload}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </ModalActionsContainer>
      </>
    );
  };

  const handleClose=()=>{
    onClose()
    // fetchData()
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Upload Bulk of users</Typography>
          <IconButton disabled={loading} onClick={()=>handleClose()}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkUploadTargetUser;