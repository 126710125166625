import { useEffect, useState } from "react";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    getGridStringOperators,
} from "@mui/x-data-grid";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from "styled-components";
import { Typography, Grid, Box, Button, IconButton, Stack, Select, MenuItem, InputAdornment, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
    Add, AddAPhoto, AddTask, SaveAlt, SaveAltOutlined, SaveAs, ManageSearch, MobileFriendly, MobileOff,
    Edit, LocationCity, MonetizationOn, Search
} from "@mui/icons-material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import AddIncentiveModal from '../../components/modals/AddIncentiveModal'
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile && `position: relative;`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 0px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 8px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? "flex" : "grid"};
  ${isMobile &&
    `
    flex-direction: column;
  `}
`;

export default function IncentiveManagement() {
    const [loading, setLoading] = useState(false);
    const [incentiveModal, setIncentiveModal] = useState(false);
    const [incentiveList, setIncentiveList] = useState(false);
    const [editIncentiveModal, setEditIncentiveModal] = useState(false);
    const [dataModal, setDataModal] = useState();
    const [rowCount, setRowCount] = useState(0);
    const [rowCount1, setRowCount1] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [search, setSearch] = useState("")
    const [seriesFilter, setSeriesFilter] = useState([])
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [selectedseries, setSelectedSeries] = useState("")
    const [dataList1, setDataList1] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rsm, setRsm] = useState("")
    const [rsms, setRsms] = useState([]);
    const [asm, setAsm] = useState("")
    const [asms, setAsms] = useState([]);
    const [rm, setRm] = useState("")
    const [rms, setRms] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState("")
    const [clusters, setClusters] = useState([])
    const [allUsers, setAllUsers] = useState([]);
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('storedUserData')).user)
    const [paginationModel, setPaginationModel] = useState({
        page: currentPage - 1,
        pageSize: 20,
    });

    useEffect(() => {
        getClusterInPicklist()
        if (userData?.role.toLowerCase().includes('admin')) {

            getAllUsers()
        }
        if (userData?.role == 'rsm') {
            getUsers('rsm', userData?.id)
        }
        if (userData?.role == 'asm') {
            getUsers('asm', userData?.id)
        }
    }, [])

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);
        return () => clearTimeout(handler);
    }, [search]);

    useEffect(() => {
        getSeriesCategory()
    }, [])

    useEffect(() => {
        GetAllIncentives();
    }, [paginationModel.page, paginationModel, selectedseries, debouncedSearch, startDate, endDate, selectedCluster, rm, rsm, asm]);

    const formatTimeStamp = (timestampFromDB) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        };
        const formattedDate = new Date(timestampFromDB).toLocaleString("en-US", options);
        return formattedDate;
    };

    const getClusterInPicklist = async () => {
        setLoading(true);
        try {
            let results = await window.Platform.database.getClusterInPicklist();

            const jsonArrayWithId = results?.data?.map((obj) => ({
                ...obj,
                dbId: obj.id
            }));


            setClusters(jsonArrayWithId);
            setLoading(false);
        } catch (error) {
            console.error("ERRORCHECK", error);
            setLoading(false);
        }
    };

    async function getAllUsers() {
        let results = await window.Platform.database.fetchEmployees()
        setAllUsers(results?.data)
        setRsms(results?.data?.filter((ele) => ele.role.toLowerCase() == 'rsm'))
        setRms(results?.data?.filter((ele) => ele.role.toLowerCase() == 'se'))
        setAsms(results?.data?.filter((ele) => ele.role.toLowerCase() == 'asm'))
    }

    async function getUsers(role, id) {
        if (id != "") {
            let results = await window.Platform.database.getUsersBasedOnManager({ userId: id })
            if (role == 'rsm') {
                setAsms(results?.data)
                setRms(results?.data1)
            }
            if (role == 'asm') {
                setRms(results?.data)
            }
        } else {
            if (role == 'rsm') {
                setRm("")
                setAsm("")
                setRms(allUsers?.filter((ele) => ele.role == 'se'))
                setAsms(allUsers?.filter((ele) => ele.role == 'asm'))
            }

            if (role == 'asm') {
                setRm("")
                setRms(allUsers?.filter((ele) => ele.role == 'se'))
            }
        }

    }

    const getSeriesCategory = async () => {
        setLoading(true);
        try {
            let results = await window.Platform.database.getSeriesCategory();

            const jsonArrayWithId = results?.data?.map((obj) => ({
                ...obj,
                dbId: obj.id
            }));

            setSeriesFilter(jsonArrayWithId);
            setLoading(false);
        } catch (error) {
            console.error("ERRORCHECK", error);
            setLoading(false);
        }
    };

    const getIncentiveList = async () => {
        setLoading(true);
        try {
            let results = await window.Platform.database.getIncentiveList({ pageNumber: paginationModel.page, recordPerPage: paginationModel.pageSize });
            setRowCount1(results?.data1);
            setDataList1(results?.data);
            setLoading(false);
        } catch (error) {
            window.NotificationUtils.showError("Data not found");
            setLoading(false);
        }
    }


    const handleClearFilters = async () => {
        setStartDate("")
        setEndDate("")
        setSelectedSeries("")
        setSearch("")
        setRsm("")
        setAsm("")
        setRm("")
        setSelectedCluster("")
        // GetAllIncentives()
    }

    const GetAllIncentives = async () => {
        
        setLoading(true);
       
        let data = {
            pageNumber: paginationModel.page,
            recordPerPage: paginationModel.pageSize,
            search: search,
            selectedSeries: selectedseries,
            startDate: startDate,
            endDate: endDate,
            selectedUserId: ((rsm && asm && rm) ? rm : (rsm && asm) ? asm : (rsm && rm) ? rm : (asm && rm) ? rm : rsm ? rsm : "") || ((rsm && asm && rm) ? rm : (rsm && asm) ? asm : (rsm && rm) ? rm : (asm && rm) ? rm : asm ? asm : "") || ((rsm && asm && rm) ? rm : (rsm && asm) ? asm : (rsm && rm) ? rm : (asm && rm) ? rm : rm ? rm : "") || "",
            selectedCluster: selectedCluster
        }

        try {
            let results = await window.Platform.database.GetAllIncentives(data);

            setRowCount(results?.totalRecords);
            setDataList(results?.data);
            setLoading(false);
            // window.NotificationUtils.showError(results.message)
        } catch (error) {
            window.NotificationUtils.showError("Data not found");
            setLoading(false);
        }
    };

    const deleteIncentive = async (id) => {

        const confirmDelete = window.confirm("Are you sure you want to delete this Incentive ?");
        if (!confirmDelete) return;

        try {
            setLoading(true);

            const response = await window.Platform.database.deleteIncentive({ id });


            window.NotificationUtils.showSuccess("Incentive deleted successfully");

            //   GetAllIncentives();
            getIncentiveList()

        } catch (error) {
            console.error("Error deleting the Incentive:", error);
            window.NotificationUtils.showError("Error deleting the Incentive");
        } finally {
            setLoading(false);
        }
    }

    const handleEdit = (data) => {
        setDataModal(data);
        setEditIncentiveModal(true);
        setIncentiveModal(false);
    };

    const getIncentiveColumnsNew = () => {
        const stringOperators = getGridStringOperators().filter((op) => ["contains"].includes(op.value));

        return [
            {
                field: "Series Name",
                headerName: "Series",
                width: 170,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.seriesName.split(" ")
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(" ") || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.seriesName.split(" ")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(" ") || "N/A"}>
                        <Typography variant="inherit">{params.row?.seriesName.split(" ")
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(" ") || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "Percent",
                headerName: "Incentive Percent",
                width: 150,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.incentivePercent || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.incentivePercent || "N/A"}>
                        <Typography variant="inherit">{params.row?.incentivePercent || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "actions",
                headerName: "Actions",
                width: 120,
                filterable: false,
                sortable: false,
                disableExport: true,
                renderCell: (params) => (
                    <>
                        <Tooltip title="Edit Incentive">
                            <IconButton onClick={() => handleEdit(params.row)}>
                                <DriveFileRenameOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Incentive">
                            <IconButton onClick={() => deleteIncentive(params.row?.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
            },

        ];
    };

    const getIncentiveColumns = () => {
        const stringOperators = getGridStringOperators().filter((op) => ["contains"].includes(op.value));

        return [
            {
                field: "Owner Name",
                headerName: "User Name",
                width: 230,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.ownerName || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.ownerName || "N/A"}>
                        <Typography variant="inherit">{params.row?.ownerName || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "Series Name",
                headerName: "Series",
                width: 150,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.seriesName.split(" ")
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(" ") || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.seriesName.split(" ")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(" ") || "N/A"}>
                        <Typography variant="inherit">{params.row?.seriesName.split(" ")
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(" ") || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "Amount",
                headerName: "Amount",
                width: 150,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.amount || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.amount || "N/A"}>
                        <Typography variant="inherit">{params.row?.amount || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "Percent",
                headerName: "Incentive Percent",
                width: 150,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.percent || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.percent || "N/A"}>
                        <Typography variant="inherit">{params.row?.percent || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "SO ID",
                headerName: "Reference Sales Order",
                width: 200,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.so_id || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.so_id || "N/A"}>
                        <Typography variant="inherit">{params.row?.so_id || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            {
                field: "Cluster Name",
                headerName: "Cluster Name",
                width: 200,
                filterable: true,
                sortable: false,
                valueGetter: (params) => params.row?.clusterName || "N/A",
                renderCell: (params) => (
                    <Tooltip title={params.row?.clusterName || "N/A"}>
                        <Typography variant="inherit">{params.row?.clusterName || "N/A"}</Typography>
                    </Tooltip>
                ),
            },
            // {
            //     field: "UpdatedAt",
            //     headerName: "updatedAt",
            //     width: 250,
            //     filterable: true,
            //     sortable: false,
            //     valueGetter: (params) => formatTimeStamp(params.row?.updatedAt) || "N/A",
            //     renderCell: (params) => (
            //         <Tooltip title={formatTimeStamp(params.row?.updatedAt) || "N/A"}>
            //             <Typography variant="inherit">{formatTimeStamp(params.row?.updatedAt) || "N/A"}</Typography>
            //         </Tooltip>
            //     ),
            // },
            // {
            //     field: "actions",
            //     headerName: "Actions",
            //     width: 250,
            //     filterable: false,
            //     sortable: false,
            //     disableExport: true,
            //     renderCell: (params) => (
            //         <>
            //             <Tooltip title="Edit Incentive">
            //                 <IconButton onClick={() => handleEdit(params.row)}>
            //                     <DriveFileRenameOutlineIcon />
            //                 </IconButton>
            //             </Tooltip>
            //             <Tooltip title="Delete Incentive">
            //                 <IconButton onClick={() => deleteIncentive(params.row?.id)}>
            //                     <DeleteIcon />
            //                 </IconButton>
            //             </Tooltip>
            //         </>
            //     ),
            // },
        ];
    };

    const renderContent = () => {
        return (
            <>
                <div className="d-flex justify-content-end align-items-center  py-2">
                    <Typography variant="body1">Select Page Size:&nbsp; </Typography>
                    <Select
                        value={paginationModel.pageSize}
                        variant="outlined"
                        size="small"
                        onChange={(e) => { setPaginationModel({ ...paginationModel, pageSize: e.target.value }) }}
                        sx={{ width: 90, fontSize: 14 }}
                    >
                        {[10, 20, 30, 40, 50, 60].map((role) => (
                            <MenuItem key={role} value={role}>{role}</MenuItem>
                        ))}
                    </Select>
                </div>
                <DataGridContainer style={{ height: !dataList.length ? "200px" : "auto" }}>
                    <DataGrid
                        className="payrollGrid"
                        density="compact"
                        rows={!incentiveList ? dataList : dataList1 || []}
                        columns={incentiveList ? getIncentiveColumnsNew() : getIncentiveColumns()}
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        rowCount={incentiveList ? rowCount1 : rowCount}
                    // pageSizeOptions={[10]}

                    />
                </DataGridContainer>
            </>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                    csvOptions={{
                        fileName: "export_Incentive_Data",
                    }}
                />
            </GridToolbarContainer>
        );
    }

    async function goToIncentiveList() {
        setIncentiveList(true);
        getIncentiveList()
    }

    const addIncentive = () => {
        setIncentiveModal(true);
        // setIncentiveList(true)
    };

    const exportIncentive = async () => {


        setLoading(true);
        try {
            let data = {
                search: search,
                selectedSeries: selectedseries,
                startDate: startDate,
                endDate: endDate,
                selectedUserId: ((rsm && asm && rm) ? rm : (rsm && asm) ? asm : (rsm && rm) ? rm : (asm && rm) ? rm : rsm ? rsm : "") || ((rsm && asm && rm) ? rm : (rsm && asm) ? asm : (rsm && rm) ? rm : (asm && rm) ? rm : asm ? asm : "") || ((rsm && asm && rm) ? rm : (rsm && asm) ? asm : (rsm && rm) ? rm : (asm && rm) ? rm : rm ? rm : "") || "",
                selectedCluster: selectedCluster
            }
            let result = await window.Platform.database.exportData("/incentive/ExportIncentivesToExcel",
                data
            )
            console.log(result.data, "API Response Data");

            const blob = new Blob([result.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "incentive.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Export error:", error);
        }
    }

    return (
        <>
            <StaffEditorPageContainer>
                {loading && <OpaqueLoading />}
                <HeaderContainer>
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", }}>
                        {!incentiveList &&
                            <>
                                <div >
                                    <Typography variant="body1" className="">Search:</Typography>
                                    <TextField
                                        // label="Search"
                                        // variant="outlined"
                                        size="small"
                                        placeholder="Search By UserName or SO No."
                                        // className="mt-3"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)} // Proper event handler
                                        sx={{ width: 270, fontSize: 14 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search color="action" />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>

                                <div>
                                    <Typography variant="body1" className="">Select Series:</Typography>
                                    <Select
                                        value={selectedseries}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { setSelectedSeries(e.target.value) }}
                                        sx={{ width: 150, fontSize: 14 }}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {seriesFilter.filter((t) => t.incentiveApplied).map((role) => (
                                            <MenuItem key={role} value={role.seriesTableId}>{role.seriesName}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                {userData?.role.includes('admin') && <div>
                                    <Typography variant="body1">Select RSM:</Typography>
                                    <Select
                                        value={rsm}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { setRsm(e.target.value); getUsers('rsm', e.target.value) }}
                                        sx={{ width: 150, fontSize: 14 }}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {rsms.map((role) => (
                                            <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </div>}
                                {userData?.role != 'asm' && <div >
                                    <Typography variant="body1">Select ASM:</Typography>
                                    <Select
                                        value={asm}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { setAsm(e.target.value); getUsers('asm', e.target.value) }}
                                        sx={{ width: 150, fontSize: 14 }}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {asms.map((role) => (
                                            <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </div>}
                                <div >
                                    <Typography variant="body1">Select RM:</Typography>
                                    <Select
                                        value={rm}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { setRm(e.target.value) }}
                                        sx={{ width: 150, fontSize: 14 }}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {rms.map((role) => (
                                            <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div >
                                    <Typography variant="body1">Select Cluster:</Typography>
                                    <Select
                                        value={selectedCluster}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { setSelectedCluster(e.target.value) }}
                                        sx={{ width: 170, fontSize: 14 }}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {clusters.map((role) => (
                                            <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </>}

                    </div>
                    {/* <Stack spacing={1} direction={"row"}>
                        {incentiveList && <IconButton
                            aria-label="back"
                            onClick={() => { setIncentiveList(false) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>}
                        {!incentiveList ? <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1, borderRadius: "10px" }}
                            onClick={() => goToIncentiveList(true)}
                        >
                            Configure Incentive
                        </Button>
                            :
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mr: 1, borderRadius: "10px" }}
                                onClick={() => addIncentive()}
                            >
                                Add Incentive
                            </Button>}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => exportIncentive()}
                            //   onClick={() => addIncentive()}
                            sx={{ mr: 1, borderRadius: "10px" }}
                        >
                            <FileDownloadIcon sx={{ mr: 1 }} />
                            Download
                        </Button>
                    </Stack> */}
                </HeaderContainer>
                <div style={{ display: "flex" }} className="row">
                    <div className="col-md-8">
                        {!incentiveList && <div className="mt-2">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <TextField
                                        label="From Date"
                                        type="date"
                                        width="100%"
                                        value={startDate}
                                        onChange={(event) => { setStartDate(event.target.value); }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>


                                <Grid item >
                                    <TextField
                                        label="To Date"
                                        type="date"
                                        width="100%"
                                        value={endDate}
                                        onChange={(event) => { setEndDate(event.target.value); }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button variant="contained"
                                        onClick={() => { GetAllIncentives() }}
                                        disabled={!startDate && !endDate}
                                    >
                                        Find
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined"
                                        onClick={() => handleClearFilters()}
                                    >
                                        Clear Filters
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>}
                    </div>
                    <div className="col-md-4 mt-3">
                        {incentiveList && <IconButton
                            aria-label="back"
                            onClick={() => { setIncentiveList(false) }}
                        >
                            <ArrowBackIcon />
                        </IconButton>}
                        {!incentiveList ? <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1, borderRadius: "10px" }}
                            onClick={() => goToIncentiveList(true)}
                        >
                            Configure Incentive
                        </Button>
                            :
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mr: 1, borderRadius: "10px" }}
                                onClick={() => addIncentive()}
                            >
                                Add Incentive
                            </Button>}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => exportIncentive()}
                            //   onClick={() => addIncentive()}
                            sx={{ mr: 1, borderRadius: "10px" }}
                        >
                            <FileDownloadIcon sx={{ mr: 1 }} />
                            Download
                        </Button>
                    </div>
                </div>
                <TableContainer>{renderContent()}</TableContainer>
            </StaffEditorPageContainer>
            {incentiveModal && (
                <AddIncentiveModal
                    onClose={() => setIncentiveModal(false)}
                    fetchData={() => getIncentiveList()}
                />
            )}
            {editIncentiveModal && (
                <AddIncentiveModal
                    onClose={() => setEditIncentiveModal(false)}
                    fetchData={() => getIncentiveList()}
                    props={dataModal}
                />
            )}
        </>
    );
}
