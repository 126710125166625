import React, { useEffect, useState, forwardRef, useContext } from "react";
import styled from "styled-components";
import { Card, Drawer, Typography } from "@mui/material";
import { PRIORITY_COLORS, PRIORITY_TYPES } from "../../../src/constants";
import moment from "moment";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import { Link, Navigate } from "react-router-dom";
import { Navigation } from "@mui/icons-material";
import NotificationModal from "../modals/NotificationModal";

const getNotificationColor = (priority) => {
  switch (priority) {
    case PRIORITY_TYPES.HIGH:
      return PRIORITY_COLORS.HIGH;
    case PRIORITY_TYPES.MEDIUM:
      return PRIORITY_COLORS.MEDIUM;
    case PRIORITY_TYPES.LOW:
      return PRIORITY_COLORS.LOW;
    default:
      console.warn("Invalid Notification Priority:", priority);
      return PRIORITY_COLORS.DEFAULT; // Return a default color or handle the invalid case as needed
  }
};

const DrawerContentContainer = styled.div`
  padding: 15px;
  height: 100%;
  background-color: #d3d3d338;
  width: 300px;
  overflow-y: auto;
`;

const NotificationCard = styled(Card)`
  margin-bottom: 20px;
  padding: 8px;
  background-color: ${(props) => getNotificationColor(props.priority)};
`;

const NotificationText = styled(Typography)`
  text-transform: none;
  white-space: pre-wrap;
`;

const CreatedDateText = styled.div`
  margin-bottom: 5px;
`;

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationContainer = styled.div`
 ${props => props.route && "cursor: pointer;"}
`


const NotificationsDrawer = forwardRef((props, ref) => {
  
  const [loading, setLoading] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [displayCount, setDisplayCount] = useState([]);
  const [showNotifications, setShowNotifications] = useState(props.showNotifications || false);
  const [navigate, setNavigate] = useState(false)
  const [showModal, setShowModal] = useState(false);

  // const { auth, setAuth } = useContext(AuthContext);
  // let test = [];
  // test = auth?.permissions?.filter((val) => val.role === auth.user.role)[0]
  // // console.log(`my test: ${test}`);

  const getCreatedDate = (date) => {
    return moment(date).format("Do MMMM YYYY, h:mm a");
  }
console.log(props,'lllloooppppp')
  const fetchNotifications = async (data) => {
   console.log(data,"datatatast")
    try {
      const data1 ={ownerId:data?.id}
      let results = await window.Platform.database.fetchNotifications(data1);
      // console.log('notification frontend started' + results);
      if (results) {
        setNotifications(results.data);
        
      }
    } catch (error) {
      console.error(error);
      // window.NotificationUtils.showError("Error fetching Notifications");
    }
  };
// console.log("NOTIFICATIONS",notifications)
  const markNotificationsRead = async () => {
    try {
      // console.log('mmmmarks read',notifications)
setLoading(true)
      await window.Platform.database.markNotificationsRead(notifications);
      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)

    }
  }

  const handleNotificationClick = async (notificationId) => {
    try {
      const data2 = { notificationId };
      let results = await window.Platform.database.MarkNotificationsRead(data2);
      if (results) {
        let storedUser = JSON.parse(window.localStorage.getItem("storedUserData"));
        fetchNotifications(storedUser?.user);
        props?.setShowNotifications(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleViewMore = () => {
    setDisplayCount(notifications.length);
  };

   // const handleNotificationClick = async (notificationId) => {
     
    //   try {
    //     const data2 ={
    //       notificationId:notificationId,  
    //     }
    //     let results = await window.Platform.database.MarkNotificationsRead(data2);
    //     // console.log('notification frontend started' + results);
    //     if (results) {
    //       // setNotifications(results.data);
    //       // fetchNotifications(storedUser)
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     // window.NotificationUtils.showError("Error fetching Notifications");
    //   }
    // };

  useEffect(() => {
    let storedUser;
    storedUser = JSON.parse(
      window.localStorage.getItem("storedUserData")
    );
   
    fetchNotifications(storedUser?.user);
    // ref.current = fetchNotifications;
  },[])
  useEffect(() => {
    if (props.showNotifications) {
      let storedUser = JSON.parse(window.localStorage.getItem("storedUserData"));
      fetchNotifications(storedUser?.user);
    }
  }, [props?.showNotifications]);

  // useEffect(() => {
  //   if (props.showNotifications !== showNotifications) {
  //     setShowNotifications(props.showNotifications);
  //   }
  // }, [props.showNotifications])

  const navigateTo = (route) => {
    if (route) {
      props.history && props.history.replace(route);
      closeDrawer();
    }
  }

  const closeDrawer = () => {
    setShowNotifications(false);
    props.setShowNotifications(false);
    if (notifications.length > 0) {
      // setNotifications([]);
      // markNotificationsRead();
    }
  };

  const renderNotifications = () => {
    return (
      <Drawer anchor={"right"} onClose={closeDrawer} open={props.showNotifications}>
      {loading && <OpaqueLoading />}
      
      <DrawerContentContainer>
        {notifications.length > 0 ? (
          notifications.slice(0, 6).map((notification) => {
            const createdAtDate = new Date(notification.createAt);
            const isUnread = notification.is_read === 0;
            const unreadStyle = {
              backgroundColor: isUnread ? "#f0f8ff" : "white",
              fontWeight: isUnread ? "bold" : "normal",
              borderLeft: isUnread ? "4px solid #007bff" : "none",
              cursor: "pointer",
            };

            return (
              <NotificationContainer key={notification.id} onClick={() => handleNotificationClick(notification.id)}>
                <CreatedDateText>
                  <Typography variant="">{getCreatedDate(createdAtDate)}</Typography>
                </CreatedDateText>
                <NotificationCard raised priority={notification.id} style={unreadStyle}>
                  <NotificationText display="block" variant="subtitle1">
                    {notification.header}
                  </NotificationText>
                  <Typography variant="body2">{notification.body}</Typography>
                </NotificationCard>
              </NotificationContainer>
            );
          })
        ) : (
          <CenterContainer>
            <Typography variant="body1">No New Notifications Found</Typography>
          </CenterContainer>
        )}

        {/* "View More" Button */}
         {notifications.length > 0 ? 
         <div className="text-center">
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>View More</button>
         </div>
          
          :
          <CenterContainer>
            <button className="btn btn-primary" onClick={() => setShowModal(true)}>View All</button>
          </CenterContainer>
          }
      </DrawerContentContainer>
      {
      showModal &&
      <NotificationModal
      onCancel={() => setShowModal(false)}
      // loading={loading}
      // data={modalData}
      props={notifications}
    //   onSubmit={updateWarehouseData}
      />
    }
    </Drawer>
    
    
    );
  };

  return (

    <Drawer
      anchor={"right"}
      onClose={closeDrawer}
      open={props.showNotifications}
    >
      
{loading && <OpaqueLoading />}
      {renderNotifications()}
    </Drawer>
  );
});

export default NotificationsDrawer;
