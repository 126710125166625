import react, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams, getGridStringOperators,
} from "@mui/x-data-grid";
import styled from "styled-components";
import Drawer from "../../components/common/drawer/drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Button, Checkbox, Fab, IconButton, Stack, Dialog,
  Table,  TableHead,  TableRow,  TableCell,  TableBody, Paper, Tab, Tabs, TextField, InputAdornment, Select, MenuItem,Modal } from "@mui/material";
import {DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import { ERROR_COLOR } from "../../constants";
import { Add, Save, SaveAlt, Search } from "@mui/icons-material";
import OrderLineItemModal from "../../components/modals/orderLineItemModal";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PartyList from "../../components/modals/partyListModal";
import AddRoutePlan from "../../components/modals/addRoutePlan";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UpdateIcon from '@mui/icons-material/Update';
import UpdateTracking from "../../components/modals/updateTracking";
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
      `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const HeaderButtonsContainer = styled.div`
  ${isMobile && "margin-top:10px;"}
`;

const TableContainer = styled.div`
// height: calc(
//   100vh - ${isMobile ? "56px - 20px - 82.23px" : "112px - 34px - 43.77px"}
// );
  width: 100%;
  // border: solid 1px lightGrey;
  border-radius: 8px;
`;
const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  display: ${isMobile ? 'flex' : 'grid'};

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px);
  `}
`;

const GenerateReportButton = styled(Button)`
  margin-right: 10px !important;
`;
const BulkChangePinCodeButton = styled(Button)`
  margin-right: 10px !important;
`;

const PinCodeChangeButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledTabs = styled(Tabs)`

  border-bottom: solid 1px lightGrey;

`;


export default function TrackingManagement() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [showWarehouseFormModal, setShowWarehouseFormModal] = useState();
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState()
  const [addModal, setAddModal] = useState(false)
  const [rowCount, setRowCount] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: 20,
  });
  
const [currentPage1, setCurrentPage1] = useState(1);
  const [paginationModel1, setPaginationModel1] = useState({
    page: currentPage1,
    pageSize: 20,
  });
  const [filter, setFilter] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)
const [newOrderStatus, setNewOrderStatus] = useState(1);
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [dateRange1, setDateRange1] = useState(false)
  const [startDate1, setStartDate1] = useState()
  const [endDate1, setEndDate1] = useState()
const [selectedTab, setSelectedTab] = useState(0);
  const [minDate, setMinDate] = useState()
  const [data, setData] = useState([]);
  const [uniqueOrders, setUniqueOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newModalData, setNewModalData] = useState([]);
  const [search,setSearch]=useState("")
  const [LocalStoredData, setLocalStoredData] = useState(JSON.parse(localStorage.getItem("storedUserData")))
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedSeries,setSelectedSeries]=useState("")
  const [series,setSeries]=useState([])
  const [selectedRole,setSelectedRole]=useState("")
  const [roleId, setRoleId] = useState([])
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [userData,setUserData]=useState(JSON.parse(localStorage.getItem('storedUserData')).user)
  const [rsms, setRsms] = useState([]);
const [allUsers, setAllUsers] = useState([]);
const [asms, setAsms] = useState([]);
const [rms, setRms] = useState([]);
const [rm,setRm]=useState("")
const [rsm,setRsm]=useState("")
const [asm,setAsm]=useState("")
const [openModalNew, setOpenModalNew] = useState(false);
const [selectedData, setSelectedData] = useState({})



async function  getUsers(role,id){
  if(id!=""){
  let results = await window.Platform.database.getUsersBasedOnManager( {userId:id} )
  if(role=='rsm'){
  setAsms(results.data)
  setRms(results.data1)
  }
  if(role=='asm'){
  setRms(results.data)
  }
}else{
if(role=='rsm'){
setRm("")
setAsm("")
setRms(allUsers.filter((ele)=>ele.role=='se'))
setAsms(allUsers.filter((ele)=>ele.role=='asm'))
}

if(role=='asm'){
setRm("")
setRms(allUsers.filter((ele)=>ele.role=='se'))
}
}
 
}

useEffect(() => {
  getSeries()
  if(userData?.role.includes('admin')){
   
    getAllUsers()
   }
   if(userData?.role=='rsm'){
     getUsers('rsm',userData?.id)
   }
   if(userData?.role=='asm'){
     getUsers('asm',userData?.id)
   }

  // if (selectedTab === 0) {
  //   fetchOrder();
  // }
  // else {
  //   fetchOrder1()
  // }
}, []);


async function  getUsers(role,id){
  if(id!=""){
  let results = await window.Platform.database.getUsersBasedOnManager( {userId:id} )
  if(role=='rsm'){
  setAsms(results.data)
  setRms(results.data1)
  }
  if(role=='asm'){
  setRms(results.data)
  }
}else{
if(role=='rsm'){
setRm("")
setAsm("")
setRms(allUsers.filter((ele)=>ele.role=='se'))
setAsms(allUsers.filter((ele)=>ele.role=='asm'))
}

if(role=='asm'){
setRm("")
setRms(allUsers.filter((ele)=>ele.role=='se'))
}
}
 
}

const getSeries = async () => {
  setLoading(true);
  try {
    let results = await window.Platform.database.getSeriesCategory();
    
    const jsonArrayWithId = results?.data?.map((obj) => ({ 
      ...obj, 
      dbId: obj.id
    }));
    
   
    setSeries(jsonArrayWithId);
    setLoading(false);
  } catch (error) {
    console.error("ERRORCHECK", error);
      setLoading(false);
  }
};

async function getAllUsers(){
  let results = await window.Platform.database.fetchEmployees()
  setAllUsers(results.data)
  setRsms(results.data.filter((ele)=>ele.role=='rsm'))
  setRms(results.data.filter((ele)=>ele.role=='se'))
  setAsms(results.data.filter((ele)=>ele.role=='asm'))
}


  const formatDateTime = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate.getTime())) {
      return 'Invalid Date';
    }

    const year = inputDate.getUTCFullYear();
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getUTCDate()).padStart(2, '0');
    const hours = String(inputDate.getUTCHours()).padStart(2, '0');
    const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(inputDate.getUTCSeconds()).padStart(2, '0');
    const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateString;
  }

//   useEffect(() => {
//     if(selectedTab == 0){
//       fetchOrder();
//     }
//     else{
//       fetchOrder1()
//     }
// }, [debouncedSearch]);


useEffect(() => {
  if (selectedTab === 0) {
    fetchOrder();
  }
  else {
    fetchOrder1()
  }
}, [newOrderStatus,rsm,rm,asm,selectedSeries,paginationModel.page, paginationModel1.page,paginationModel.pageSize,paginationModel1.pageSize, debouncedSearch, selectedTab]);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Set debounced value after delay
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup function
  }, [search]);

  // useEffect(() => {

  //   if(selectedTab == 0){
  //     fetchOrder();
  //   }
  //   else{
  //     fetchOrder1()
  //   }
  
  // }, [paginationModel.page, selectedTab]);
  
  // useEffect(() => {
  //   if (selectedTab === 0) {
  //     fetchOrder();
  //   }
  //   else {
  //     fetchOrder1()
  //   }
  // }, [newOrderStatus]);


  useEffect(() => {
    if (filter) {
      onFilterChange(searchTerm)
    }
  }, [paginationModel.page]);

  useEffect(() => {
    if (selectedOrderId) {
      const relatedOrders = data.filter((order) => order.orderId === selectedOrderId);
      setNewModalData(relatedOrders);
    }
  }, [data]);

  const getOrderTrackingStatus = async (id) => {
    console.log(id,"aasdasdsadsaasdas")
    setLoading(true);
    try {
        const results = await window.Platform.database.fetchOrderLineItem({ id:id });
        if (!results || !Array.isArray(results.data)) {
          setLoading(false);
            throw new Error("Invalid data structure returned from API");
        }
        setData(results?.data);
      const unique = Array.from(
        new Map(results?.data?.map((item) => [item.orderId, item])).values()
      );
      setUniqueOrders(unique);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({
            ...obj,
            id: index + 1,
        }));
        setLoading(false);
        // setAccessChanges(jsonArrayWithId);
      // setRowCount(unique.length); // Ensure `data1` exists and is a number
    } catch (error) {
        console.error("Error in fetchOrder:", error);
        window.NotificationUtils.showError("Error Fetching Data");
        setLoading(false);
    } finally {
        setLoading(false);
    }
};

const handleTabChange = async (value) => {

  setSelectedTab(value);

}

const fetchOrder = async () => {
  try {
    setLoading(true);
    let data = {
      pageNumber: paginationModel.page,
      filter:search,
      fromDate:fromDate,
      toDate:toDate,
      series:selectedSeries,
      rsm:rsm,
      asm:asm,
      ownerId:rm,
      recordPerPage:paginationModel.pageSize,
      approvalStatus: newOrderStatus
    }
      let results = await window.Platform.database.fetchOrderForTracking(data);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setRowCount(results?.data1)
    setAccessChanges(jsonArrayWithId);
  } catch (error) {
    console.error(error);
    window.NotificationUtils.showError("Error Fetching Data");
    setLoading(false);
  }
  finally {
    setLoading(false)
  }
}

const exportOrderForTracking = async () => {
  setLoading(true);
  try {
        let data = {
          filter:search,
          fromDate:fromDate,
          toDate:toDate,
          series:selectedSeries,
          rsm:rsm,
          asm:asm,
          ownerId:rm,
          approvalStatus: newOrderStatus
        }
     

      let result = await window.Platform.database.exportData("/order/exportOrderForTracking",
        data
      )

      // Check if the response is actually a Blob
      console.log(result, "API Response Data");

      const blob = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "schoolOrders.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Export error:", error);
  }
}

const exportOrderForTracking1 = async () => {
  setLoading(true);
  try {
        let data = {
          filter:search,
          orderStatus: 1,
          fromDate:fromDate,
          toDate:toDate,
          series:selectedSeries,
          rsm:rsm,
          asm:asm,
          ownerId:rm,
          approvalStatus: newOrderStatus
        }
     

      let result = await window.Platform.database.exportData("/order/exportOrderForTracking",
        data
      )

      // Check if the response is actually a Blob
      console.log(result, "API Response Data");

      const blob = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "distributorOrders.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
  } catch (error) {
      setLoading(false);
      console.error("Export error:", error);
  }
}



const clearFilters = async () => {
  setLoading(true);
  setRm("")
  setAsm("")
  setRsm("")
  setSearch("")
  setFromDate("")
  setToDate("")
  setSelectedRole("")
  setSelectedSeries("")
  try {
    let data1 = {
      pageNumber: paginationModel.page,
      // recordPerPage:15,
      approvalStatus: newOrderStatus
    }

let  results = await window.Platform.database.fetchOrderForTracking(data1);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setRowCount(results?.data1)
    setAccessChanges(jsonArrayWithId);
    setLoading(false);
  } catch (error) {
    console.error(error);
    window.NotificationUtils.showError("Error Fetching Data");
    setLoading(false);
  }
  finally {
    setLoading(false)
  }
}
const fetchOrder1 = async () => {
  try {
    setLoading(true);
      let data1 = {
        pageNumber: paginationModel.page,
        orderStatus: 1,
        filter:search,
        series:selectedSeries,
        fromDate:fromDate,
        toDate:toDate,
        rsm:rsm,
        asm:asm,
        ownerId:rm,
        recordPerPage:paginationModel1.pageSize,
        approvalStatus: newOrderStatus
      }
      let results = await window.Platform.database.fetchOrderForTracking(data1, selectedRole, selectedSeries, search);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    setRowCount(results?.data1)
    setAccessChanges(jsonArrayWithId);
    setLoading(false);
  } catch (error) {
    console.error(error);
    window.NotificationUtils.showError("Error Fetching Data");
    setLoading(false);
  }
  finally {
    setLoading(false)
  }
}
  const handleRowClick = async (id) => {
    // console.log(params, "params");
    setSelectedOrderId(id);
  
    await getOrderTrackingStatus(id); // Ensure data is fetched before filtering
  
    const relatedOrders = data.filter((order) => order.orderId === id);
    setNewModalData(relatedOrders);
    setModalOpen(true);
  };
  

  const handleCloseModal = () => {
    setModalOpen(false);
    setNewModalData([]);
  };

  const onFilterChange = async (filterModel) => {
    try {
      setLoading(true);
      setFilter(true)
      console.log(filterModel, 'filterModel')
      if (filterModel) {
        const response = await window.Platform.database.filterOrderTrackingStatus({ filterField: filterModel.field, filterValue: filterModel.value, pageNumber: paginationModel.page, startDate: startDate, endDate: endDate })
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
      }
      else {
        const response = await window.Platform.database.filterOrderTrackingStatus({ filterField: "date", pageNumber: paginationModel.page, startDate: startDate, endDate: endDate })
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
      }
      setLoading(false);
    }
    catch (err) {
      console.error(err)
      setLoading(false);
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
    }
  };

  const handleOpenModalNew = (data) => {
    // setSelectedSchoolId(schoolId);
    setSelectedData(data)
    setOpenModalNew(true);
    // fetchData(schoolId);
  };

  const handleCloseModalNew = () => {
    setOpenModalNew(false);
    // setModalData([]);
  }

  const getEmployeeColumns = () => {
    const stringOperators = getGridStringOperators().filter((op => ['contains'].includes(op.value)));

    let result = [
      {
        field: "SONO",
        headerName: "SO No.",
        sortable: false,
        width: 150,
        filterOperators: stringOperators,
        editable: false,
        valueGetter: (params) => {
          return (params.row?.so_id || "N/A")
        },
        renderCell: (params) => {
          let name = params.row?.so_id || "N/A"
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "name",
        headerName: "Created By",
        sortable: false,
        width: 150,
        filterOperators: stringOperators,
        editable: false,
        valueGetter: (params) => {
          return (params.row?.name || "N/A")
        },
        renderCell: (params) => {
          let name = params.row?.name || "N/A"
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: 220,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          return (params.row?.createdAt ? formatTimeStamp(params?.row?.createdAt) : "N/A")
        },
        renderCell: (params) => {
          let name = formatTimeStamp(params.row.createdAt) || "N/A"
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          )
        },
      },
      {
        field: "Name",
        headerName: "Party Name",
        width: 300,
        sortable: false, filterable: false,
        valueGetter: (params) => {
          return (params.row.schoolName ? params.row.schoolName : params.row.distributorName || "N/A")
        },
        renderCell: (params) => {
          let name = params.row.schoolName ? params.row.schoolName : params.row.distributorName || "N/A"
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          )
        },
      },
      // {
      //   field: "New",
      //   headerName: "New",
      //   width: 100, filterable: false,
      //   sortable: false,
      //   // valueGetter: (params) => {
      //   //   return (params.row?.tackingId || "N/A")
      //   // },
      //   renderCell: (params) => {
      //     // let name = params.row?.tackingId || "N/A"
      //     return (
      //       <Tooltip>
      //           <span>
      //             <Button
      //               variant="contained"
      //               size="small"
      //               onClick={() => handleRowClick(params.row.orderId)}
      //               // onClick={() => handleOpenModalNew()}
      //               sx={{ minWidth: "80px" }}
      //             >
      //               {"New"}
      //             </Button>
      //           </span>
      //         </Tooltip>
      //     )
      //   },
      // },
 {
        field: "View",
        headerName: "View",
        minWidth: 120,
        flex: 0.17,
        editable: false,
        renderCell: (params) => {
          return (
            <>
              <Tooltip>
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    // onClick={() => setOpenModalNew(true)}
                    onClick={() => handleOpenModalNew(params.row)}
                    sx={{ minWidth: "80px" }}
                  >
                    {"View"}
                  </Button>
                </span>
              </Tooltip>
              <Modal open={openModalNew} onClose={handleCloseModalNew}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 900,
                    bgcolor: "white",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "90%",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6" component="h2" mb={5} style={{ textAlign: "center" }}>
                    Order Tracking Details
                  </Typography>
                  <div>
                    <div className="row mb-4 mt-2">
                      <div className="col-md-6">
                      <b>SO No. :</b> {selectedData?.so_id || "N/A"}
                        
                      </div>
                      <div className="col-md-6">
                      <b> Tracking Id:</b> {selectedData?.tackingId || "N/A"}
                        
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                      <b> Tracking Number: </b>{selectedData.trackingNumber || "N/A"}
                        
                      </div>
                      <div className="col-md-6">
                      <b>Carrier :</b> {selectedData.carrier || "N/A"}
                       
                    </div>

                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                      <b>Dispatched Date :</b> {selectedData.dispatchedDate && formatTimeStamp(selectedData.dispatchedDate) || "N/A"}
                       
                      </div>
                      <div className="col-md-6">
                      <b>Shipped Date :</b> {selectedData.shippedDate && formatTimeStamp(selectedData.shippedDate) || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                      <b>Estimated Delivery Date :</b> {selectedData.estimatedDeliveryDate && formatTimeStamp(selectedData.estimatedDeliveryDate) || "N/A"}
                      </div>
                      <div className="col-md-6">
                      <b>Delivery Date :</b> {selectedData?.deliveryDate && formatTimeStamp(selectedData?.deliveryDate) || "N/A"}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                      <Button variant="contained" color="primary"
                    onClick={() => handleRowClick(selectedData?.orderId)}
                      >
                        View Details
                      </Button>
                      </div>
                    </div>
                  </div>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleCloseModalNew} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </>
          );
        },
      },
      // {
      //   field: "date",
      //   headerName: "Tracking Id",
      //   width: 200, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row?.tackingId || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.tackingId || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "approvalStatus",
      //   headerName: "Tracking Number",
      //   width: 200, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row?.trackingNumber || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.trackingNumber || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "carrier",
      //   headerName: "carrier",
      //   width: 200, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row?.carrier || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.carrier || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "dispatchedDate",
      //   headerName: "Dispatched Date",
      //   width: 250, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row.dispatchedDate && formatTimeStamp(params.row.dispatchedDate) || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.dispatchedDate && formatTimeStamp(params.row.dispatchedDate) || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "shippedDate",
      //   headerName: "Shipped Date",
      //   width: 250, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row.shippedDate && formatTimeStamp(params.row.shippedDate) || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.shippedDate && formatTimeStamp(params.row.shippedDate) || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>{console.log(params,"params")}
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "estimatedDeliveryDate",
      //   headerName: "Estimated Delivery Date",
      //   width: 250, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row.estimatedDeliveryDate && formatTimeStamp(params.row.estimatedDeliveryDate) || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.estimatedDeliveryDate && formatTimeStamp(params.row.estimatedDeliveryDate) || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "deliveryDate",
      //   headerName: "Delivery Date",
      //   width: 200, filterable: false,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return (params.row?.deliveryDate && formatTimeStamp(params.row?.deliveryDate) || "N/A")
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.deliveryDate && formatTimeStamp(params.row?.deliveryDate) || "N/A"
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      {
        field: "Approve Order",
        headerName: "Actions",
        width: 100, filterable: false,
        sortable: false,
        disableExport: true,
        renderCell: (params) => {
          let value = params.row
          return (
            <Stack direction={"row"} spacing={1}>
              <Tooltip title="Click to update"><IconButton variant="extended" color="primary" onClick={(e) => {
                e.stopPropagation();
                handleUpdate(value);
              }} 
              disabled={newOrderStatus==4}
              >
                <UpdateIcon />
              </IconButton></Tooltip>
            </Stack>
          )
        },
      },
    ];
    return result
  }

  const handleUpdate = (data) => {
    setModalData(data)
    setModal(true)
  }
  const approveRejectOrder = async (val) => {
    console.log("VALuesss", val)
    try {
      setLoading(true)
      const results = await window.Platform.database.approveRoute(val)
      window.NotificationUtils.showSuccess("Tracking Rejected")
      setLoading(false)
      fetchOrder()
    }
    catch (err) {
      console.log(err)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)
    }
  }

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,  // Use 12-hour clock
    };
    const formattedDate = new Date(timestampFromDB).toLocaleString('en-US', options);
    return formattedDate;
  };

  const formatToOnlyDate = (val) => {
    const concertDate = new Date(val);
    const year = concertDate.getFullYear();
    const month = concertDate.getMonth() + 1; // Months are zero-indexed, so we add 1
    const day = concertDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    console.log(formattedDate);
    return formattedDate;
  }

  const getRouteplanById = async (val, val1) => {
    try {
      setLoading(true)
      setModalData()
      let sentData = { id: val }
      let results = await window.Platform.database.getRouteplanById(sentData);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));

      setModalData({ data: jsonArrayWithId, id: val, date: val1 })
      setModal(true)
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      window.NotificationUtils.showError("Something Went Wrong!")
      setLoading(false)
    }
  }

  console.log(selectedData,"selectedData")
  function CustomToolbar() {
    return (
      // <GridToolbarContainer>
      //   <GridToolbarColumnsButton />
      //   <GridToolbarFilterButton />
      //   <GridToolbarDensitySelector />
      //   <GridToolbarExport
      //     csvOptions={{
      //       fileName: "export-trackings",
      //     }}
      //   />
      // </GridToolbarContainer>
      <GridToolbarContainer>
     
        <Button
          onClick={() => setNewOrderStatus(1)}
          style={{
            backgroundColor: newOrderStatus === 1 ? "#8787f470" : "white",
            color: newOrderStatus === 1 ? "#000000" : "black",
          }}
        >
          Approved
        </Button>

        <Button
          onClick={() => setNewOrderStatus(3)}
          style={{
            backgroundColor: newOrderStatus === 3 ? "#8787f470" : "white",
            color: newOrderStatus === 3 ? "#000000" : "black",
          }}
        >
          Shipped
        </Button>
        <Button
          onClick={() => setNewOrderStatus(4)}
          style={{
            backgroundColor: newOrderStatus === 4 ? "#8787f470" : "white",
            color: newOrderStatus === 4 ? "#000000" : "black",
          }}
        >
          Delivered
        </Button>
        
      </GridToolbarContainer>
    );
  }

  const renderContent = () => {
    if (selectedTab === 0) {
    return (
      <>
       <div className="d-flex justify-content-end align-items-center  py-2">
        <Typography variant="body1">Select Page Size:&nbsp; </Typography>
    <Select
      value={paginationModel.pageSize}
      variant="outlined"
      size="small"
      onChange={(e)=>{setPaginationModel({...paginationModel,pageSize:e.target.value})}}
      sx={{ width: 90, fontSize: 14 }}
    >
      {[10,20,30,40,50,60].map((role) => (
        <MenuItem key={role} value={role}>{role}</MenuItem>
      ))}
    </Select>
        </div>
      <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
        <DataGrid
          className="payrollGrid"
          density="compact"
          rows={accessChanges}
          // rows={uniqueOrders}
          columns={getEmployeeColumns()}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          // onRowClick={handleRowClick}
          disableRowSelectionOnClick
          rowHeight={60}
          disableDensitySelector
          onFilterModelChange={(val) => {
            if (val?.items[0]?.value?.length > 0) {
              onFilterChange(val.items[0])
              setSearchTerm(val.items[0])
            }
            else if (!val.items[0]?.value && startDate && endDate) {
              setSearchTerm(null)
              onFilterChange()
            }
            else {
              fetchOrder()
              setSearchTerm(null)
              setFilter(false)
            }
          }
          }
          filterMode="server"
          rowCount={rowCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10]}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </DataGridContainer>
      </>
    );
  }
  else if (selectedTab === 1) {
   return (
    <>
    <div className="d-flex justify-content-end align-items-center  py-2">
        <Typography variant="body1">Select Page Size:&nbsp; </Typography>
    <Select
      value={paginationModel.pageSize}
      variant="outlined"
      size="small"
      onChange={(e)=>{setPaginationModel1({...paginationModel1,pageSize:e.target.value})}}
      sx={{ width: 90, fontSize: 14 }}
    >
      {[10,20,30,40,50,60].map((role) => (
        <MenuItem key={role} value={role}>{role}</MenuItem>
      ))}
    </Select>
        </div>
     <DataGridContainer style={{ height: !accessChanges?.length ? '200px' : 'auto' }}>
    <DataGrid
      className="payrollGrid"
      density="compact"
      rows={accessChanges}
      // rows={uniqueOrders}
      columns={getEmployeeColumns()}
      disableSelectionOnClick
      getRowId={(row) => row.id}
      // onRowClick={handleRowClick}
      disableRowSelectionOnClick
      rowHeight={60}
      disableDensitySelector
      onFilterModelChange={(val) => {
        if (val?.items[0]?.value?.length > 0) {
          onFilterChange(val.items[0])
          setSearchTerm(val.items[0])
        }
        else if (!val.items[0]?.value && startDate && endDate) {
          setSearchTerm(null)
          onFilterChange()
        }
        else {
          fetchOrder1()
          setSearchTerm(null)
          setFilter(false)
        }
      }
      }
      filterMode="server"
      rowCount={rowCount}
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10]}
      components={{
        Toolbar: CustomToolbar,
      }}
    />
  </DataGridContainer>
  </>
   )
  }
  };

  const formatDate = async (data) => {
    setMinDate(data)
    setDateRange1(true)
    setEndDate1(null)
    setEndDate(null)
    const datePickerResponse = new Date(data.$d);
    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setStartDate1(formattedDate)
    setStartDate(formattedDate)
    console.log("CHECK!!")
    console.log("CHECKENDDATE", endDate)
  }
  const finalDateRangeFilter = async (data) => {
    try {
      setLoading(true)
      setFilter(true)
      const datePickerResponse = new Date(data);
      const year = datePickerResponse.getFullYear();
      const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
      const day = String(datePickerResponse.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;

      if (data) {
        setEndDate(formattedDate)
        setEndDate1(formattedDate)
      }
      if (searchTerm) {
        const response = await window.Platform.database.filterOrderTrackingStatus({ filterField: searchTerm.field, filterValue: searchTerm?.value, pageNumber: paginationModel.page, startDate: startDate, endDate: data ? formattedDate : endDate })
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
      }
      else {
        const response = await window.Platform.database.filterOrderTrackingStatus({ filterField: "date", filterValue: searchTerm?.value, pageNumber: paginationModel.page, startDate: startDate, endDate: data ? formattedDate : endDate })
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
      }
      setLoading(false)
    }
    catch (err) {
      console.log(err)
      setLoading(false)
      window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
      fetchOrder()
    }
  }
  const clearDateFilter = async () => {
    setStartDate(null)
    setEndDate(null)
    setStartDate1(null)
    setEndDate1(null)
    setDateRange1(false)
    if (searchTerm) {
      try {
        setLoading(true)
        const response = await window.Platform.database.filterOrderTrackingStatus({ filterField: searchTerm ? searchTerm.field : "date", filterValue: searchTerm?.value, pageNumber: paginationModel.page, startDate: '', endDate: '' })
        const jsonArrayWithId = response?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
        setAccessChanges(jsonArrayWithId)
        setRowCount(response.data1)
        setLoading(false)
      }
      catch (e) {
        console.log(e)
        window.NotificationUtils.showError("Error While Recieving Data Please Wait and try again");
        fetchOrder()
      }
    }
    else {
      setFilter(false)
      fetchOrder()
    }
  }

  return (
    <>
      {isMobile && (<Drawer />)}
      <StaffEditorPageContainer>
        <HeaderContainer>
          {loading && <OpaqueLoading />}
          <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "10px" }}>
          <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
  <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
 <Tooltip title="Search By SO ID">
  <div>
  <Typography variant="body1">Enter to search</Typography>
  <TextField
    
    variant="outlined"
    size="small"
    placeholder="Search By SO ID"
    value={search}
    onChange={(e) => setSearch(e.target.value)} // Proper event handler
    sx={{ width: 300, fontSize: 14 }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search color="action" />
        </InputAdornment>
      )
    }}
  />
  </div>
</Tooltip>

    {userData?.role.includes('admin')&&<div>
    <Typography variant="body1">Select RSM:</Typography>
    <Select
      value={rsm}
      variant="outlined"
      size="small"
      onChange={(e)=>{setRsm(e.target.value);getUsers('rsm',e.target.value)}}
      sx={{ width: 180, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {rsms.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
    </div>}
   {userData?.role!='asm'&& <div >
    <Typography variant="body1">Select ASM:</Typography>
    <Select
      value={asm}
      variant="outlined"
      size="small"
      onChange={(e)=>{setAsm(e.target.value);getUsers('asm',e.target.value)}}
      sx={{ width: 180, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {asms.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
      </div>}
      <div >
    <Typography variant="body1">Select RM:</Typography>
    <Select
      value={rm}
      variant="outlined"
      size="small"
      onChange={(e)=>{setRm(e.target.value)}}
      sx={{ width: 180, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {rms.map((role) => (
        <MenuItem key={role} value={role.id}>{role.name}</MenuItem>
      ))}
    </Select>
      </div>
    <div >
    <Typography variant="body1">Select Series:</Typography>
    <Select
      value={selectedSeries}
      variant="outlined"
      size="small"
      onChange={(e)=>{setSelectedSeries(e.target.value)}}
      sx={{ width: 180, fontSize: 14 }}
    >
      <MenuItem value="">All</MenuItem>
      {series.map((role) => (
        <MenuItem key={role} value={role.seriesTableId}>{role.seriesName}</MenuItem>
      ))}
    </Select>
      </div>
  </Box>
  <Box  display="flex" alignItems="end" gap={1} flexWrap="wrap">
    <div>
  <Typography variant="body1">From Date</Typography>
                <input type="date" className="form-control w-auto" value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}}/>
                </div>
                <div>
                <Typography variant="body1">To Date:</Typography>
                <input type="date" className="form-control w-auto" value={toDate} onChange={(e)=>{setToDate(e.target.value)}}/>
                </div>
                <Button  variant="contained" color="primary" style={{fontSize:"14px"}} sx={{ mr: 1, borderRadius: '10px' }} onClick={()=>{selectedTab == 0?fetchOrder():fetchOrder1()}}>Find</Button>
                <button className="btn btn-outline-secondary text-white" style={{fontSize:"13px"}} onClick={()=>{clearFilters()}}>Clear Filters</button>
              </Box>
 
<div className="mt-3">
{selectedTab == 0 ?  <Button variant="contained" color="primary" onClick={()=>{exportOrderForTracking()}}>Download</Button> : <Button variant="contained" color="primary" onClick={()=>{exportOrderForTracking1()}}>Download</Button>}
</div>
</Box>
         
          </div>
        </HeaderContainer>
        <TableContainer>
        <TabsContainer>

<StyledTabs
  variant="fullWidth"
  value={selectedTab}
  onChange={(event, value) => handleTabChange(value)}
  indicatorColor="primary"
  textColor="primary"
>

  <Tab label="School Orders" />

  <Tab label="Distributor Orders" />

</StyledTabs>

{renderContent()}
</TabsContainer>
          </TableContainer>
      </StaffEditorPageContainer>
      {
        modal && <UpdateTracking propData={modalData} onClose={() => setModal(false)} fetchData={() => fetchOrder()} />
      }

<Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6">Order Details </Typography>
          {/* <Typography variant="h6">Orders for: {selectedOrderId}</Typography> */}
          <IconButton onClick={handleCloseModal} size="small">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            {/* Styled Header */}
            <TableHead>
              <TableRow style={{ backgroundColor: "#1976d2" }}>
                <TableCell style={{ color: "white", fontWeight: "bold" }}>S. No.</TableCell>
                <TableCell style={{ color: "white", fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ color: "white", fontWeight: "bold" }}>Class Name</TableCell>
                <TableCell style={{ color: "white", fontWeight: "bold" }}>Series Name</TableCell>
                <TableCell style={{ color: "white", fontWeight: "bold" }}>SKU Code </TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((item, ind) => (
                    <TableRow key={ind}>
                      <TableCell>{ind + 1}</TableCell>
                      <TableCell>{item.nameSku}</TableCell>
                      <TableCell>{item.className}</TableCell>
                      <TableCell>{item.seriesName}</TableCell>
                      <TableCell>{item.sku_code}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center", fontWeight: "bold", padding: "20px" }}>
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

          </Table>
        </TableContainer>
      </div>
    </Dialog>
    </>
  );
}
