import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const AddOnProductsScreen = ({ payload, items, series,proceed,addOnPro,erp,editMode }) => {

    let addP=addOnPro
    if(addP){
        addP=JSON.parse(addOnPro).map((ele)=>{return {name:ele,or:0}})}
    const [confuse, setConfuse] = useState({});
    const [isChecked, setIsChecked] = useState(erp==1?true:false);
    const [prize, setPrize] = useState([]);
    const [dropMenu, setDropMenu] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        groupData(items);
    }, [items]);




    const getMenu = async (pp) => {
        const body = { id: pp.map(ele => ele.menu) };
        try {
            const response = await window.Platform.database.getAddOnsMenu(body);
            if (response) {
                setDropMenu(response.data);
                setIsLoading(false);
                if(editMode){
                    pp.forEach((pri)=>{
                        let a=addP.filter((ele)=>!ele.or)
                        if(a.length){
                            for(let i=0;i<a.length;i++){
                                let ap=a[i]
                                let ty=response.data.filter((r)=>{return r.id==pri.menu})
                                if(ty.filter((pr)=>pr.name==ap.name).length){
                                    ap.or=1
                                  pri.selected=a[0].name
                                }
                            }
                               
                        }
                    })
                }
            }
                    
                    
                
            
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const getMenuBasedOnId = (id) => {
        return [
            { value: "", label: "Select" },
            ...dropMenu.filter(item => item.id === id || item.id.toString() === id)
                .map(item => ({ value: item.name, label: item.name }))
        ];
    };

    const order = () => {
        let updatedPayload = { ...payload, addOns: prize.map(ele => ele.selected), onBoardERP: isChecked };
       proceed(updatedPayload)
    };

    const getAddONs = async (group) => {
        console.log(group)
        const a = Object.keys(group);
        const filteredSeries = series.filter(e => a.includes(e.seriesTableId));
        const addOns = filteredSeries.map(e => e.addOnProduct).filter(ele => ele !== null);
        const body = { id: addOns };

        try {
            if (addOns.length > 0) {
                const response = await window.Platform.database.getAddOns(body);
                if (response) {
                    const data = response.data;
                    let newPrize = [];
                    filteredSeries.forEach(series => {
                        data.forEach(add => {
                            if (add.product_id === series.addOnProduct) {
                                if (add.condition === "set" && group[series.seriesTableId].set.total >= add.amount) {
                                    const count = Math.floor(group[series.seriesTableId].set.total / add.amount);
                                    for (let i = 0; i < count; i++) {
                                        newPrize.push({ menu: add.name, selected: "", series: series.seriesName });
                                    }
                                } else if (
                                    group[series.seriesTableId].individual.total >= add.amount &&
                                    group[series.seriesTableId].individual.count >= add.bookCount &&
                                    (!add.maxCount || add.maxCount >= group[series.seriesTableId].individual.count)
                                ) {
                                    const count = Math.floor(group[series.seriesTableId].individual.total / add.amount);
                                    for (let i = 0; i < count; i++) {
                                        newPrize.push({ menu: add.name, selected: "", series: series.seriesName });
                                    }
                                }
                            }
                        });
                    });
                    console.log(newPrize,"EDITDATA ")
                    setPrize(newPrize);
                    if (newPrize.length > 0) {
                        getMenu(newPrize);
                    } else {
                        setIsLoading(false);
                    }
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const groupData = (items) => {
        
        let groupedData = {};
        items.forEach(item => {
            if (!groupedData[item.series]) {
                groupedData[item.series] = {
                    set: { total: 0.0, count: 0 },
                    individual: { total: 0.0, count: 0 }
                };
            }
            const mrp = item.price * item.qty;
            if (item.itemType?.toLowerCase() === 'choose from set') {
                groupedData[item.series].set.total += mrp;
            } else {
                groupedData[item.series].individual.total += mrp;
                groupedData[item.series].individual.count++;
            }
        });
        console.log(groupedData)
        setConfuse(groupedData);
        getAddONs(groupedData);
    };

    return (
        <div className="container">
            <h2>Add-On Products</h2>
            <p>Complimentary Products with Your Order</p>
            <label>
                <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                Onboard For ERP
            </label>
            {isLoading ? <p>Loading...</p> : (
                prize.length > 0 ? (
                    prize.map((item, index) => (
                        <div key={index} className="card">
                            <h4>Select {index + 1} Add-on Item</h4>
                            <p>Series: {item.series}</p>
                            <select
                                value={item.selected}
                                onChange={(e) => {
                                    const newPrize = [...prize];
                                    newPrize[index].selected = e.target.value;
                                    setPrize(newPrize);
                                }}
                            >
                                {getMenuBasedOnId(item.menu).map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                    ))
                ) : <p>No Add-On Product</p>
            )}
            <button onClick={order} className="btn btn-primary" >Proceed</button>
        </div>
    );
};

export default AddOnProductsScreen;
