import React, { useState, useEffect } from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Legend } from 'recharts';
import { Autocomplete, Avatar, Button, Box, Stack, TextField } from "@mui/material";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const Dashboard = () => {
  const [orderData, setOrderData] = useState([])
  const [data, setData] = useState({})
  const [allOrderData, setAllOrderData] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    fetchRoles()
    fetchOrderData()
  }, [startDate, endDate])

  // useEffect(()=>{
  //   fetchOrderData()
  // },[startDate, endDate])

  const handleClearFilters = () => {
    setStartDate('');
    setEndDate('');
    fetchOrderData()
  };

  async function fetchOrderData() {
    setLoading(true);
    try {
      let data1 = {
              startDate : startDate,
              endDate: endDate
            }
      let results = await window.Platform.database.fetchOrderDash(data1);
      setOrderData(results?.data?.order)
      setData(results?.data)
      setAllOrderData({ total: results?.data?.order?.reduce((p, q) => { return p + q?.status_count }, 0), amount: results?.data?.order?.reduce((p, q) => { return p + q?.total_amount }, 0) })
      setLoading(false);
    }
    catch (e) {
      console.log(e)
      setLoading(false);
    }
  }
  const [roleId, setRoleId] = useState([])
  const fetchRoles = async () => {
    try {

      let results = await window.Platform.database.fetchRoles();
      setRoleId(results?.data)

    }
    catch (err) {
      console.log(err);


    }
  }

  // const EnhancedOrdersGraph = ({ allOrderData, orderData }) => {
  //   const data = [
  //     { name: 'All Booked Orders', count: allOrderData?.total || 0 },
  //     { name: 'Approved Orders', count: orderData?.filter((ele) => ele.approvalStatus === 1)[0]?.status_count || 0 },
  //     { name: 'Pending Orders', count: orderData?.filter((ele) => ele.approvalStatus === 0)[0]?.status_count || 0 },
  //     { name: 'Rejected Orders', count: orderData?.filter((ele) => ele.approvalStatus === 2)[0]?.status_count || 0 },
  //     { name: 'Shipped Orders', count: orderData?.filter((ele) => ele.approvalStatus === 3)[0]?.status_count || 0 },
  //     { name: 'Delivered Orders', count: orderData?.filter((ele) => ele.approvalStatus === 4)[0]?.status_count || 0 },
  //   ];

  //   return (
  //     <div style={{ width: '100%', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
  //       {/* <h4>Orders</h4> */}
  //       <ResponsiveContainer width="100%" height={300}>
  //         <BarChart
  //           data={data}
  //           margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
  //           barSize={50}
  //         >
  //           <CartesianGrid strokeDasharray="3 3" />
  //           <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-20} textAnchor="end" />
  //           <YAxis />
  //           <Tooltip cursor={{ fill: 'rgba(200, 200, 200, 0.2)' }} />
  //           <Bar dataKey="count" fill="url(#gradient)" radius={[8, 8, 0, 0]}>
  //             <LabelList dataKey="count" position="top" style={{ fill: 'black', fontWeight: 'bold' }} />
  //           </Bar>
  //           <defs>
  //             <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
  //               <stop offset="0%" stopColor="#4caf50" />
  //               <stop offset="100%" stopColor="#81c784" />
  //             </linearGradient>
  //           </defs>
  //         </BarChart>
  //       </ResponsiveContainer>
  //     </div>
  //   );
  // };
  const EnhancedOrdersGraph = ({ allOrderData, orderData }) => {
    const data = [
      { 
        name: 'All Booked Orders', 
        count: allOrderData?.total || 0,
        amount: allOrderData?.amount || 0 
      },
      { 
        name: 'Approved Orders', 
        count: orderData?.filter((ele) => ele.approvalStatus === 1)[0]?.status_count || 0,
        amount: orderData?.filter((ele) => ele.approvalStatus === 1)[0]?.total_amount || 0 
      },
      { 
        name: 'Pending Orders', 
        count: orderData?.filter((ele) => ele.approvalStatus === 0)[0]?.status_count || 0,
        amount: orderData?.filter((ele) => ele.approvalStatus === 0)[0]?.total_amount || 0 
      },
      { 
        name: 'Rejected Orders', 
        count: orderData?.filter((ele) => ele.approvalStatus === 2)[0]?.status_count || 0,
        amount: orderData?.filter((ele) => ele.approvalStatus === 2)[0]?.total_amount || 0 
      },
      { 
        name: 'Shipped Orders', 
        count: orderData?.filter((ele) => ele.approvalStatus === 3)[0]?.status_count || 0,
        amount: orderData?.filter((ele) => ele.approvalStatus === 3)[0]?.total_amount || 0 
      },
      { 
        name: 'Delivered Orders', 
        count: orderData?.filter((ele) => ele.approvalStatus === 4)[0]?.status_count || 0,
        amount: orderData?.filter((ele) => ele.approvalStatus === 4)[0]?.total_amount || 0 
      },
    ];
  
    return (
      <div style={{ width: '100%', padding: '10px', backgroundColor: '#f8f9fa', borderRadius: '3px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <h4>Order</h4>
        <ResponsiveContainer width="100%" height={280}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
            barSize={50}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-20} textAnchor="end" />
            <YAxis />
            <Tooltip
              cursor={{ fill: 'rgba(200, 200, 200, 0.2)' }}
              formatter={(value, name, props) => {
                if (name === "count") return [`${value}`, "Number"];
                if (name === "amount") return [`₹ ${value}`, "Amount"];
                return value;
              }}
            />
            <Bar dataKey="count" fill="url(#gradient)" radius={[8, 8, 0, 0]}>
              {/* <LabelList dataKey="count" position="top" style={{ fill: 'black', fontWeight: 'bold' }} /> */}
            </Bar>
            <Bar dataKey="amount" fill="#64b5f6" radius={[8, 8, 0, 0]}>
              {/* <LabelList dataKey="amount" position="top" style={{ fill: 'black', fontWeight: 'bold' }} /> */}
            </Bar>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#4caf50" />
                <stop offset="100%" stopColor="#81c784" />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };
  

  const EnhancedPartyDataGraph = ({ data }) => {
    const chartData = [
      { name: 'Party Onboarded', count: data?.sch?.active_count + data?.d?.active_count || 0 }, //{data?.sch?.active_count+data?.d?.active_count}
      { name: 'Schools', count: data?.sch?.active_count || 0 }, //{data?.sch?.active_count}
      { name: 'Distributors', count: data?.d?.active_count || 0 }, //{data?.d?.active_count
      { name: 'Stockist', count: data?.st?.active_count || 0 }, //{data?.st?.active_count}
    ];

    return (
      <div style={{ width: '100%', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '3px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <h4>Party Data</h4>
        <ResponsiveContainer width="100%" height={280}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 30, bottom: 50 }}
            barSize={50}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-20} textAnchor="end" />
            <YAxis />
            <Tooltip cursor={{ fill: 'rgba(200, 200, 200, 0.2)' }} />
            <Bar dataKey="count" fill="url(#partyGradient)" radius={[8, 8, 0, 0]}>
              {/* <LabelList dataKey="count" position="top" style={{ fill: 'black', fontWeight: 'bold' }} /> */}
            </Bar>
            <defs>
              <linearGradient id="partyGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#ff9800" />
                <stop offset="100%" stopColor="#ffcc80" />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const EnhancedTeamDataGraph = ({ data, roleId }) => {
    const chartData = roleId?.map((ele) => ({
      name: ele?.name,
      count: data?.users?.filter((e) => e.role.toLowerCase() === ele.roleName.toLowerCase())[0]?.status_count || 0,
    }));

    return (
      <div style={{ width: '100%', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '3px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <h4>Team Data</h4>
        <ResponsiveContainer width="100%" height={280}>
          <BarChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
            barSize={50}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-20} textAnchor="end" />
            <YAxis />
            <Tooltip cursor={{ fill: 'rgba(200, 200, 200, 0.2)' }} />
            <Bar dataKey="count" fill="url(#teamGradient)" radius={[8, 8, 0, 0]}>
              {/* <LabelList dataKey="count" position="top" style={{ fill: 'black', fontWeight: 'bold' }} /> */}
            </Bar>
            <defs>
              <linearGradient id="teamGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#2196f3" />
                <stop offset="100%" stopColor="#64b5f6" />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const EnhancedProductsGraph = ({ data }) => {
    // Prepare the chart data
    const chartData = [
      {
        name: 'All Series',
        count: Array.isArray(data?.product) ? data?.product?.reduce((p, q) => p + q.status_count, 0) : 0,
        incentive: 0,
      },
      ...(Array.isArray(data?.series) ? data?.series.map((ele) => ({
        name: ele.seriesName.toUpperCase(),
        count: data?.product?.filter((e) => e.seriesCategory === ele.seriesTableId)[0]?.status_count || 0,
        incentive: data?.incentive?.filter((e) => e.seriesId === ele.seriesTableId)[0]?.status_count || 0,
      })) : []),
    ];
  
    return (
      <div style={{ width: '100%', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '3px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
       <ResponsiveContainer width="100%" height={350}>
  <BarChart
    data={chartData}
    margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
    barSize={50}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-20} textAnchor="end" />
    <YAxis />
    <Tooltip cursor={{ fill: 'rgba(200, 200, 200, 0.2)' }} />
    
    <Bar dataKey="count" fill="url(#productGradient)" radius={[8, 8, 0, 0]}>
      <LabelList dataKey="count" position="top" style={{ fill: 'black', fontWeight: 'bold' }} />
    </Bar>

    <Bar dataKey="incentive" fill="url(#incentiveGradient)" radius={[8, 8, 0, 0]}>
      {/* <LabelList dataKey="incentive" position="top" style={{ fill: 'black', fontWeight: 'bold' }} /> */}
    </Bar>

    <defs>
      <linearGradient id="productGradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#f57c00" />
        <stop offset="100%" stopColor="#ffb74d" />
      </linearGradient>
      <linearGradient id="incentiveGradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#388e3c" />
        <stop offset="100%" stopColor="#66bb6a" />
      </linearGradient>
    </defs>
  </BarChart>
</ResponsiveContainer>

      </div>
    );
  };

  const EnhancedSeriesGraph = ({ data }) => {
    // Prepare the chart data
    const chartData = [
      {
        name: 'All Series',
        Amount: Array.isArray(data?.items) ? data?.items?.reduce((p, q) => p + q.total_amount, 0) : 0,
      },
      ...(Array.isArray(data?.series) ? data?.series.map((ele) => ({
        name: ele?.seriesName?.toUpperCase(),
        Amount: data?.items?.filter((e) => e?.series === ele?.seriesTableId)[0]?.total_amount || 0,
      })) : []),
    ];
  
    return (
      <div style={{ width: '100%', padding: '10px', backgroundColor: '#f8f9fa', borderRadius: '3px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <h4>Series Wise Amount</h4>
       <ResponsiveContainer width="100%" height={280}>
  <BarChart
    data={chartData}
    margin={{ top: 20, right: 10, left: 50, bottom: 50 }}
    barSize={40}
  >
    <CartesianGrid strokeDasharray="3 3" />
    {/* <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-20} textAnchor="end" /> */}
    <XAxis dataKey="name" tick={{ fontSize: 12 }} angle={-30} textAnchor="end" height={70}  interval={0} />
    <YAxis />
    <Tooltip cursor={{ fill: 'rgba(200, 200, 200, 0.2)' }} />
    
    <Bar dataKey="Amount" fill="url(#productGradient)" radius={[8, 8, 0, 0]}>
      {/* <LabelList dataKey="Amount" position="top" style={{ fill: 'black', fontWeight: 'bold' }} /> */}
    </Bar>

    <defs>
      <linearGradient id="productGradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#f57c00" />
        <stop offset="100%" stopColor="#ffb74d" />
      </linearGradient>
      <linearGradient id="incentiveGradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#388e3c" />
        <stop offset="100%" stopColor="#66bb6a" />
      </linearGradient>
    </defs>
  </BarChart>
</ResponsiveContainer>

      </div>
    );
  };

  return (
    <>
    {loading && <OpaqueLoading/>}
      <Box sx={{ mt: 5, p:3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={3}>
            <TextField
              label="From Date"
              type="date"
              fullWidth
              value={startDate}
              onChange={(event) => {setStartDate(event.target.value); fetchOrderData()}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          

          <Grid item xs={6} sm={3}>
            <TextField
              label="To Date"
              type="date"
              fullWidth
              value={endDate}
              onChange={(event) => {setEndDate(event.target.value); fetchOrderData()}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={()=> {fetchOrderData()}} disabled = {!startDate && !endDate}>
              Find
            </Button>
          </Grid>
          <Grid item>
            {(startDate || endDate) && <Button variant="outlined" onClick={() => handleClearFilters()}>
              Clear Filters
            </Button>}
          </Grid>
        </Grid>
      </Box>
    <Grid container spacing={3} sx={{ padding: 3 }}>

      {/** Orders Table */}
      {/* <Grid item xs={12} md={10}>
      <Typography variant="h6" sx={{ backgroundColor: "#c8e6c9", padding: 1, fontWeight:"bold", fontSize:"16px" }}>Order</Typography>
        <EnhancedOrdersGraph allOrderData={allOrderData} orderData={orderData} />
      </Grid> */}
      <Grid container justifyContent="center" alignItems="center" gap={0}>
  <Grid item xs={12} md={6}>
    {/* <Typography variant="h6" sx={{ backgroundColor: "#c8e6c9", padding: 1, fontWeight: "bold", fontSize: "16px", textAlign: "center" }}>
      Order
    </Typography> */}
    <EnhancedOrdersGraph allOrderData={allOrderData} orderData={orderData} />
  </Grid>
  
<Grid item xs={12} md={6}>
      {/* <Typography variant="h6" sx={{ backgroundColor: "#c8e6c9", padding: 1, fontWeight:"bold", fontSize:"16px" }}>Series Wise Amount</Typography> */}
        <EnhancedSeriesGraph data={data} />
      </Grid>
</Grid>


      <Grid container justifyContent="center" alignItems="center">

      {/* Party Data */}
      <Grid item xs={12} md={6}>
      {/* <Typography variant="h6" sx={{ backgroundColor: "#c8e6c9", padding: 1, fontWeight:"bold", fontSize:"16px" }}>Party data</Typography> */}
        <EnhancedPartyDataGraph data={data} />
      </Grid>

 {/** Team Data Table */}
      <Grid item xs={12} md={6}>
      {/* <Typography variant="h6" sx={{ backgroundColor: "#c8e6c9", padding: 1, fontWeight:"bold", fontSize:"16px" }}>team Data</Typography> */}
        <EnhancedTeamDataGraph data={data} roleId={roleId} />
      </Grid>
</Grid>

      {/** Products Table */}
      <Grid item xs={12} md={12}>
      <Typography variant="h6" sx={{ backgroundColor: "#c8e6c9", padding: 1, fontWeight:"bold", fontSize:"16px" }}>Products</Typography>
        <EnhancedProductsGraph data={data} />
      </Grid>

      
    </Grid>
    </>

  );
};

export default Dashboard;