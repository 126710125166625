import React, { useEffect, useState } from "react";
import {Dialog, DialogContent, IconButton, Typography, Stack } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import styled from "styled-components";
import moment from "moment"; // For date formatting

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const NotificationCard = styled.div`
  padding: 12px;
  border-radius: 6px;
  background-color: ${(props) => (props.isUnread ? "#f0f8ff" : "#ffffff")};
  border-left: ${(props) => (props.isUnread ? "4px solid #007bff" : "none")};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const NotificationModal = ({ onCancel, props }) => {

    const [allNotificationData, setAllNotificationData] = useState([])

    useEffect(() => {
        fetchAllNotifications()
    }, [])

    const fetchAllNotifications = async () => {
        let storedUser;
        storedUser = JSON.parse(
            window.localStorage.getItem("storedUserData")
        );
        let data = {
            ownerId: storedUser?.user?.id
        }
        let result = await window.Platform.database.getAllNotification(data)
        if (result?.data) {
            setAllNotificationData(result?.data)
        }
    }
   


    return (
        <Dialog disableEnforceFocus maxWidth="sm" fullWidth open>
            <StyledDialogContent>
                <ModalHeaderContainer>
                    <Typography variant="h5"><b>Notifications ( {allNotificationData?.length}  )</b></Typography>
                    <IconButton onClick={onCancel}>
                        <CloseOutlined />
                    </IconButton>
                </ModalHeaderContainer>{console.log(allNotificationData,"allNotificationData")}

                <Stack gap={2} padding={2}>
                    {allNotificationData?.length > 0 ? (
                        allNotificationData?.map((ele) => (
                            <NotificationCard key={ele.id} isUnread={ele.is_read === 0}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {ele.header}
                                </Typography>
                                <Typography variant="body2" style={{fontSize:"13px"}} color="black">{ele.body}</Typography>
                                <Typography variant="caption" style={{fontSize:"12px"}} color="black">
                                    {moment(ele.createAt).format("Do MMM YYYY, h:mm a")}
                                </Typography>
                            </NotificationCard>
                        ))
                    ) : (
                        <Typography variant="body1" textAlign="center">
                            No notifications available.
                        </Typography>
                    )}
                </Stack>
            </StyledDialogContent>
        </Dialog>
    );
};

export default NotificationModal;


