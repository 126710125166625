import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import styled from "styled-components";
import { Card as BootstrapCard } from "reactstrap";

import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import WorkTypePicklist from "../Picklist/workType";
import PartyTypePicklist from "../Picklist/partyType";
import BoardMaster from "../Picklist/boardMaster";
import ClassMaster from "../Picklist/classMaster";
import CollectionType from "../Picklist/collectionType";
import ContactPersonRole from "../Picklist/contactPersonRole";
import DaType from "../Picklist/daType";
import FeedbackMaster from "../Picklist/feedbackMaster";
import GivenType from "../Picklist/givenType";
import GradeMaster from "../Picklist/gradeMaster";
import MediumMaster from "../Picklist/mediumMaster";
import NextType from "../Picklist/nextStep";
import PaymentType from "../Picklist/paymentType";
import RecieveCollection from "../Picklist/recieveCollection";
import RouteVisitType from "../Picklist/routeVisitType";
import StatusType from "../Picklist/statusType";
import SubjectMaster from "../Picklist/subjectMaster";
import VehicleType from "../Picklist/vehicleType";
import VisitType from "../Picklist/visitType";
import WorkDone from "../Picklist/workDone";
import ExpensePurpose from './../Picklist/expensePurpose';
import DocumentListSchool from "../Picklist/documentListSchool";
import ExpenseType from "../Picklist/expenseType";
import DeliveryStatus from "../Picklist/deliveryStatus";
import LeadStatus from "../Picklist/leadStatus";
import LeadSource from "../Picklist/leadSource";
import LeadRating from "../Picklist/leadRating";
import SchoolCategory from "../Picklist/schoolCategory";
import VisitOutcome from "../Picklist/visitOutcome";
import ReturnReason from "../Picklist/returnReason";
import ProductGroup from "../Picklist/productGroup";
import MittplusProductGroup from "../Picklist/mittplusProductGroup";
import SeriesMaster from "../Picklist/seriesMaster";
import VisitTypeCheck from "../Picklist/VisitTypeCheck";
import AddOnProducts from '../Picklist/AddOnProducts'
import RemarkComponent from "../Picklist/RemarkComponent";
import WareHouseComponent from "../Picklist/WareHouseComponent";
import ClusterComponent from "../Picklist/ClusterComponent";
import NotificationEmail from "../Picklist/NotificationEmail";
const HoverCard = styled(BootstrapCard)`
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 300px;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
     background-color: grey;
     color:white
  }
`;

const StaffEditorPageContainer = styled.div`
  height: ${window.innerWidth < 900 ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  margin-top: 20px;
  ${window.innerWidth < 900 && `position: relative`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${window.innerWidth < 900 ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  flex-direction: ${window.innerWidth < 900 ? "column" : "row"};
`;

const PicklistContainer = styled.div`
  padding: 10px;
`;

export default function AllPicklist() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");

  const routes = [
    {
      path: "/additionalData/boardMaster",
      name: "School Board",
      component: BoardMaster,
      access: "Picklist",
    },
    {
      path: "/additionalData/classMaster",
      name: "Class Master",
      component: ClassMaster,
      access: "Picklist",
    },
    {
      path: "/additionalData/collectionType",
      name: "Collection Type",
      component: CollectionType,
      access: "Picklist",
    },
    // {
      // path: "/additionalData/contactPersonRoleType",
      // name: "Contact Person Role Type",
      // component: ContactPersonRole,
      // access: "Picklist",
    // },
    // {
    //   path: "/additionalData/datType",
    //   name: "DA Type",
    //   component: DaType,
    //   access: "Picklist",
    // },
    {
      path: "/additionalData/deliveryStatus",
      name: "Delivery Status",
      component: DeliveryStatus,
      access: "Picklist",
    },
    // {
    //   path: "/additionalData/documentListSchool",
    //   name: "Document List School",
    //   component: DocumentListSchool,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/expensePurpose",
    //   name: "Expense Purpose",
    //   component: ExpensePurpose,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/expenseType",
    //   name: "Expense Type",
    //   component: ExpenseType,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/feedbackMaster",
    //   name: "Feedback",
    //   component: FeedbackMaster,
    //   access: "Picklist",
    // },
    {
      path: "/additionalData/gradeMaster",
      name: "Grade",
      component: GradeMaster,
      access: "Picklist",
    },
    {
      path: "/additionalData/givenType",
      name: "Given Type",
      component: GivenType,
      access: "Picklist",
    },
    // {
    //   path: "/additionalData/leadRating",
    //   name: "Lead Rating",
    //   component: LeadRating,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/leadSource",
    //   name: "Lead Source",
    //   component: LeadSource,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/leadStatus",
    //   name: "Lead Status",
    //   component: LeadStatus,
    //   access: "Picklist",
    // },
    {
      path: "/additionalData/mittplusProductGroups",
      name: "Mittplus Product Groups",
      component: MittplusProductGroup,
      access: "Picklist",
    },
    {
      path: "/additionalData/mediumMaster",
      name: "Medium",
      component: MediumMaster,
      access: "Picklist",
    },
    // {
    //   path: "/additionalData/nextStep",
    //   name: "Next Step",
    //   component: NextType,
    //   access: "Picklist",
    // },
    {
      path: "/additionalData/paymentType",
      name: "Payment Type",
      component: PaymentType,
      access: "Picklist",
    },
    {
      path: "/additionalData/productGroups",
      name: "Product Groups",
      component: ProductGroup,
      access: "Picklist",
    },
    {
      path: "/additionalData/partyType",
      name: "Party Type",
      component: PartyTypePicklist,
      access: "Picklist",
    },
    {
      path: "/additionalData/recieveCollectionType",
      name: "Recieve Collection Type",
      component: RecieveCollection,
      access: "Picklist",
    },
    {
      path: "/additionalData/returnReason",
      name: "Return Reason",
      component: ReturnReason,
      access: "Picklist",
    },
    // {
    //   path: "/additionalData/routeVisitType",
    //   name: "Route Visit Type",
    //   component: RouteVisitType,
    //   access: "Picklist",
    // },
    {
      path: "/additionalData/schoolCategory",
      name: "School Category",
      component: SchoolCategory,
      access: "Picklist",
    },
    // {
    //   path: "/additionalData/statusType",
    //   name: "Status Type",
    //   component: StatusType,
    //   access: "Picklist",
    // },
    {
      path: "/additionalData/subjectMaster",
      name: "Subject",
      component: SubjectMaster,
      access: "Picklist",
    },
    // {
    //   path: "/additionalData/vehicleType",
    //   name: "Vehicle Type",
    //   component: VehicleType,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/visitOutcomes",
    //   name: "Visit Outcomes",
    //   component: VisitOutcome,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/visitType",
    //   name: "Visit Type",
    //   component: VisitType,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/visitTypeCheck",
    //   name: "Visit Type Check",
    //   component: VisitTypeCheck,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/workDone",
    //   name: "Work Done",
    //   component: WorkDone,
    //   access: "Picklist",
    // },
    // {
    //   path: "/additionalData/workTypePicklist",
    //   name: "Work Type",
    //   component: WorkTypePicklist,
    //   access: "Picklist",
    // },
    {
      path: "/product/seriesCategory",
      name: "Series",
      component: SeriesMaster,
      access: "Picklist",
    },
    {
      path: "/product/addOnProducts",
      name: "AddOnProducts",
      component: AddOnProducts,
      access: "Picklist",
    },
    {
      path: "/product/remark",
      name: "Order Remark",
      component: RemarkComponent,
      access: "Picklist",
    },
    {
      path: "/product/wareHouse",
      name: "WareHouse",
      component: WareHouseComponent,
      access: "Picklist",
    },
    {
      path: "/product/cluster",
      name: "Cluster",
      component: ClusterComponent,
      access: "Picklist",
    },
    {
      path: "/product/notificationEmail",
      name: "Notification Email",
      component: NotificationEmail,
      access: "Picklist",
    },
  ];  

  return (
    <>
      <StaffEditorPageContainer>
        {loading && <OpaqueLoading />}
        <HeaderContainer>
          <Grid container spacing={2}>
            {routes.map((route) => (
              <Grid item xs={12} sm={4} md={3} key={route.name}>
                <HoverCard onClick={() => navigate(route.path)}>
                  <PicklistContainer>
                    <Typography
                      variant="p"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {route.name}
                    </Typography>
                  </PicklistContainer>
                </HoverCard>
              </Grid>
            ))}
          </Grid>
        </HeaderContainer>
      </StaffEditorPageContainer>
    </>
  );
}
