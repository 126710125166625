import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import styled from "styled-components";
import { ACTIVITY_STATUS } from "../../constants";
import OpaqueLoading from "../opaqueLoading/opaqueLoading";
import { useEffect } from "react";

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  // position: relative;
  // max-width: 700px;
  
`;  

  const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
    `;
  
  
  const SeriesCategory = ({ onClose,status,fetchData,propData }) => {
    console.log("propData",propData)
    const [form, setForm] = useState( {id:propData?.specimenId||"",
        
      flatDiscount: propData?.flatDiscount || '',
      maxDiscount: propData?.maxDiscount || '',
      minDiscount: propData?.minDiscount || '',
      return:propData?.return || '',
     itemId:propData?.itemId||"",
     discountedPrice:propData?.dicountedPrice||"",
     mrp:propData?.mrp||"",
     incentiveStatus: propData?.incentiveApplied== 1 ? 1 : propData?.incentiveApplied == 0 ? 0 : null || null,
    });
  const [loading,setLoading]=useState(false)


    const [product,setProduct]=useState()
    const [selectedProduct,setSelectedProduct]=useState(null)

    const isMobile = window.innerWidth < 900;

    useEffect(()=>{
        // console.log(loading)
        fetchProduct()
      },[])

      const fetchProduct=async(data)=>{
        const results = await window.Platform.database.fetchProduct(data);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({ ...obj, id: index + 1 }));
    
        setProduct(jsonArrayWithId)
        setSelectedProduct(null)
        if(!status){
            const filteredEmp=results?.data?.filter((data)=>data.skuId===propData.itemId)
            console.log("PROPDATA",filteredEmp)
            setSelectedProduct(filteredEmp[0])
            
           }
    
       }
  
    const changeHandler = (fieldName, value) => {
      // setForm((prevForm) => ({ ...prevForm, [fieldName]: value }));
      setForm((prevForm) => {
        if (fieldName === "discountType") {
          if (value === "flat") {
            return {
              ...prevForm,
              discountType: value,
              flatDiscount: "",
              minDiscount: "",
              maxDiscount: "",
            };
          } else if (value === "range") {
            return {
              ...prevForm,
              discountType: value,
              minDiscount: "",
              maxDiscount: "",
              flatDiscount: "",
            };
          }
        }
        return { ...prevForm, [fieldName]: value };
      });
    };

    const submitHandler = async() => {
      // console.log("creating activity...", form);
      try{
        console.log("PRODUCT RESULTS: " , form)
        setLoading(true)
        // console.log("PLANTCODE",plantcode[0].plant_code__c)
        let results = await window.Platform.database.addSeriesCategory({...form});

        if (results.status) {
          window.NotificationUtils.showError(results?.message);
          setLoading(false);
          return;
        }
    
    // setProduct(results.data)
    window.NotificationUtils.showSuccess("Series added successfully")
    setLoading(false)
    fetchData()
    onClose()
      }
      catch(err){
        console.log(err);
    setLoading(false)
     
      }
console.log(form)
    };

    console.log("FORMMMMM",form)

  
    return (
      <StyledDialog disableEnforceFocus maxWidth="md" fullWidth={true} open={true}>
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">{status?"Add Series":"Edit Series"}</Typography>
          <IconButton  onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {/* <DialogContent style={{ padding: "10px", width: 400 }}> */}
          <Stack gap="20px" style={{ padding: "10px" }}>
            {loading && <OpaqueLoading />}
           

            
    <Stack direction={"row"} spacing={2} padding={2}>
          
              <TextField
                label="Series Name"
                value={form?.seriesName || ""}
                type="text"
                fullWidth
                onChange={(e) => changeHandler("seriesName", e.target.value)}
              />
           </Stack>
              
           <Stack direction={"row"} spacing={2} padding={2}>
         
          <TextField label="Permissible Return Percent"
            value={form?.return || ""}
            type="number"
            fullWidth
            onChange={(e) => changeHandler("return", e.target.value)}
          />
        </Stack>
           
        <div style={{ overflowY: "auto" }}>
  <Stack direction={"row"} spacing={2} padding={2}>
    <FormControl component="fieldset" style={{ width: isMobile ? "100%" : "50%" }}>
      <FormLabel component="legend">Discount Type</FormLabel>
      <RadioGroup
        row
        value={form?.discountType}
        onChange={(e) => changeHandler("discountType", e.target.value)}
      >
        <FormControlLabel value="flat" control={<Radio />} label="Flat Discount" />
        <FormControlLabel value="range" control={<Radio />} label="Discount Range" />
      </RadioGroup>
    </FormControl>
  </Stack>

  {form.discountType === "flat" && (
    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField
        label="Discount Amount"
        type="number"
        value={form.flatDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("flatDiscount", e.target.value)}
        required
      />
    </Stack>
  )}

  {form.discountType === "range" && (
    <Stack direction={"row"} spacing={2} padding={2}>
      <TextField
        label="Minimum Range"
        type="number"
        value={form.minDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("minDiscount", e.target.value)}
        required
      />
      <TextField
        label="Maximum Range"
        type="number"
        value={form.maxDiscount || ""}
        fullWidth
        onChange={(e) => changeHandler("maxDiscount", e.target.value)}
        required
      />
    </Stack>
  )}
<Stack direction="row" spacing={2} padding={2}>
            {/* <FormControl component="fieldset">
              <FormLabel component="legend">Incentive</FormLabel>
              <RadioGroup
                row
                value={form.incentiveStatus}
                onChange={(e) => changeHandler("incentiveStatus", e.target.value === "1" ? 1 : 0)}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl> */}
                <FormControl variant="outlined" style={{ width: isMobile ? "100%" : "50%" }}>
                  <InputLabel id="Incentive Status">Incentive Status</InputLabel>
                  <Select
                    labelId="Incentive Status"
                    label="Incentive Status"
                    value={form?.incentiveStatus}
                    onChange={(e) => changeHandler("incentiveStatus", e.target.value)}

                  >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
          </Stack>
</div>


           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Button
                color="primary"
                variant="contained"
                onClick={submitHandler}
              >
                Create
              </Button>
            </div>
          </Stack>
          </StyledDialogContent>
    </StyledDialog>
    );
  };
  
  export default SeriesCategory;
  