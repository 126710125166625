import {
  Button,
  Fab,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  IconButton,
  TextField,
  InputAdornment,Modal,
  Select,
  MenuItem
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Add ,Search ,CalendarToday} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import { TABS } from "../../constants";
import { AuthContext } from "../../components/contextAPI/ContextAPI";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import "../../App.css";
import ProductModal from "../../components/modals/productModal";
import BulkProductAdd from "../../components/modals/bulkProductAdd";
import SeriesCategory from "../../components/modals/addSeriesCategory";
import ProductMittplusModal from "../../components/modals/productMittplusModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile && `position: relative;`}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
`;

const TableContainer = styled.div`
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: ${isMobile ? "flex" : "grid"};
  border-radius: 4px;
  ${isMobile && `flex-direction: column;`}
`;

const TabsContainer = styled.div`
  ${isMobile && `height: calc(100vh - 56px - 20px - 43.77px);`}
`;

const ProductManagement = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 20,
  });

  const [currentPage1, setCurrentPage1] = useState(1);
  const [paginationModel1, setPaginationModel1] = useState({
    page: currentPage1 - 1,
    pageSize: 11,
  });

  const [selectedTab, setSelectedTab] = useState(TABS.REGISTERED);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState(false);

  const [bulk, setBulk] = useState(false);
  const [series, setSeries] = useState(false);
  const [visitModal, setVisitModal] = useState(false);
  const [mittplusProduct, setMittplusProduct] = useState(false);
  const [accessChanges, setAccessChanges] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [editMittplusProduct, setEditMittplusProduct] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [modalData, setModalData] = useState([])
    const [openViewModal, setOpenViewModal] = useState(false)
  const [rowCount, setRowCount] = useState();
  const [rowCount1, setRowCount1] = useState();
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
const [searchTerm, setSearchTerm]=useState(null)
const [debouncedSearch, setDebouncedSearch] = useState("");
const [search,setSearch]=useState("")
const [medium,setMedium]=useState("")
const [mediumss,setMediumss]=useState([])
const [classes,setClasses]=useState("")
const [classeses,setClasseses]=useState([])
const [board,setBoard]=useState("")
const [boardss,setBoardss]=useState([])
const [seriess,setSeriesS]=useState("")
const [seriesss,setSeriesss]=useState([])

useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Set debounced value after delay
    }, 500); // 500ms debounce time

    return () => clearTimeout(handler); // Cleanup function
  }, [search]);


   useEffect(() => {
          fetchProduct();
  }, [debouncedSearch]);
  
  useEffect(() => {
    getMedium();
    getClass();
    getBoard();
    getSeriesCategoryData()
  }, []);

  const getMedium=async()=>{
    let results = await window.Platform.database.getMedium();
    setMediumss(results?.data)
  }

  const getClass=async(data)=>{
    const results = await window.Platform.database.getClass(data);
    setClasseses(results?.data)
   }

   const getBoard=async()=>{
    let results = await window.Platform.database.getBoard();
    setBoardss(results.data)
  }

  const getSeriesCategoryData=async()=>{
    let results = await window.Platform.database.getSeriesCategory();
    setSeriesss(results.data)
    
  
  }
  const addVisit = () => {
    setDataModal(null);
    setVisitModal(true);
  };

  const addMittplusProduct = () => {
    setDataModal(null);
    setMittplusProduct(true);
  };

  // useEffect(() => {
  //   if (!filter) {
  //     if (selectedTab === 0) {
  //       fetchProduct();
  //     } else {
  //       fetchProductMittplus();
  //     }
  //   }
  // }, [selectedTab, paginationModel.page, paginationModel1.page]);

  useEffect(() => {
    fetchProduct();
}, [medium, board, classes, seriess, fromDate, toDate, paginationModel.page, paginationModel.pageSize]);

  const fetchProduct = async () => {
    try {
      setLoading(true);
      let data = {
        pageNumber: paginationModel.page,
        nameSku:search,
          startDate:fromDate,
          endDate:toDate,
          recordPerPage:paginationModel.pageSize,
          mediumName:medium,
          boardName:board,
          className:classes,
          seriesName:seriess
      }
      let results = await window.Platform.database.fetchProduct1( data );
      setRowCount(results.data1);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.skuId,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const fetchProductMittplus = async () => {
    try {
      setLoading(true);
      let results = await window.Platform.database.fetchProductMittplus({
        pageNumber: paginationModel1.page,
      });
      setRowCount1(results.data1);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.id,
      }));
      setAccessChanges(jsonArrayWithId);
      setLoading(false);
    } catch (error) {
      window.NotificationUtils.showError("Error Fetching Data");
      setLoading(false);
    }
  };

  const handleEdit = (data, type) => {
    if (type === "MITTPLUS") {
      setEditMittplusProduct(true);
    } else {
      setEditModal(true);
    }
    setDataModal(data);
  };

  const deleteProduct = async (item, type) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this product?"
      );
      if (!isConfirmed) {
        return;
      }
      setLoading(true);
      if (type === "MITTPLUS") {
        await window.Platform.database.deleteMittplusProduct({ id: item.id });
        fetchProductMittplus();
      } else {
        await window.Platform.database.deleteProduct({ id: item.id });
        fetchProduct();
      }
      setLoading(false);
      window.NotificationUtils.showSuccess("Product deleted successfully!");
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = async (value) => {
    console.log(value)
    setSelectedTab(value);
    setAccessChanges([]);
  };

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = new Date(timestampFromDB).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const clearFilters = async () => {
   
    setFromDate("")
    setToDate("")
    setBoard("");
    setClasses("");
    setMedium("");
    setSeriesS("");
    setSearch("")
   
  }

  const exportProduct = async () => {
    setLoading(true);
    try {
      let data = {
        pageNumber: paginationModel.page,
        filter: search,
        recordPerPage: paginationModel.pageSize,
        mediumName:medium,
        boardName:board,
        className:classes,
        seriesName:seriess,
        startDate:fromDate,
        endDate:toDate
      };

      let result = await window.Platform.database.exportData("/product/exportProduct",
        data
      )
      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "product.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Export error:", error);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: "export-products",
          }}
        /> */}
      </GridToolbarContainer>
    );
  }

  const handleOpenViewModal = (id) => {
    console.log(id,"isdsdsdsd")
    setModalData(id)
    setOpenViewModal(true);
  }

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setModalData([]);
  }

  const getEmployeeColumns = () => {
    let result = [
      {
        field: "nameSku",
        headerName: "SKU Name",
        sortable: false,
        width: 250,
        editable: false,
        valueGetter: (params) => {
          return params.row?.nameSku || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.nameSku;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "skuCode",
        headerName: "SKU Code",
        width: 170,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.sku_code || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.sku_code;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "type",
        headerName: "Book Type",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.type || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.type || "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "unitPrice",
        headerName: "Unit Price",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.unitPrice || "N/A";
        },
        renderCell: (params) => {
          let name = +params.row?.unitPrice;
          let name1 = name?.toFixed(2);
          return (
            <Tooltip title={name1 || "N/A"}>
              <Typography variant="inherit">{name1 || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "mediumName",
      //   headerName: "Medium Name",
      //   width: 200,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return params.row?.mediumName || "N/A";
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.mediumName;
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      // {
      //   field: "boardName",
      //   headerName: "Board Name",
      //   width: 200,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return params.row?.boardName || "N/A";
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.boardName;
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      // {
      //   field: "className",
      //   headerName: "Class",
      //   width: 150,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return params.row?.className || "N/A";
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.className;
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      // {
      //   field: "subjectName",
      //   headerName: "Subject Name",
      //   width: 200,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return params.row?.subjectName || "N/A";
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.subjectName;
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      
      // {
      //   field: "is_specimen",
      //   headerName: "is_specimen",
      //   width: 200,
      //   sortable: false,
      //   valueGetter: (params) => {
      //     return params.row?.is_specimen || "N/A";
      //   },
      //   renderCell: (params) => {
      //     let name = params.row?.is_specimen || "N/A";
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name || "N/A"}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
     
      {
        field: "View",
        headerName: "View",
        minWidth: 120,
        flex: 0.17,
        editable: false,
        renderCell: (params) => {
          return (
            <>
              <Tooltip>
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleOpenViewModal(params.row)}
                    sx={{ minWidth: "80px" }}
                  >
                    {"View"}
                  </Button>
                </span>
              </Tooltip>
              <Modal open={openViewModal} onClose={handleCloseViewModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 900,
                    bgcolor: "white",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "90%",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6" component="h2" mb={5} style={{ textAlign: "center" }}>
                    Product Details
                  </Typography>
                  <div>
                    <div className="row mb-4 mt-2">
                      <div className="col-md-6">
                        <b> SKU Name :</b> {modalData && modalData?.nameSku || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b>SKU Code : </b> {modalData && modalData?.sku_code || "N/A"}
                      </div>
                      
                    </div>
                    <div className="row mb-4">
                      
                    <div className="col-md-6">
                        <b> Series Name : </b>{modalData && modalData?.seriesName || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b>Subject Name :</b> {modalData && modalData?.subjectName || "N/A"}
                      </div>

                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Medium Name :</b> {modalData && modalData?.mediumName || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Class Name :</b> {modalData && modalData?.className || "N/A"}
                      </div>

                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Board Name :</b> {modalData && modalData?.boardName || "N/A"}
                      </div>

                      <div className="col-md-6">
                      <b>Unit Price :</b> {modalData && modalData?.unitPrice || "N/A"}
                      </div>

                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                      <b> Type :</b> {modalData && modalData?.type || "N/A"}
                      </div>


                    </div>
                  </div>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleCloseViewModal} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </>
          );
        },
      },

      {
        field: "Actions",
        headerName: "Actions",
        editable: false,
        hide: true,
        minWidth: 150,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={"Edit Product"}>
                <IconButton onClick={() => handleEdit(params.row, "MITTSURE")}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Product"}>
                <IconButton
                  onClick={() => deleteProduct(params.row, "MITTSURE")}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];
    return result;
  };

  const getMittplusColumns = () => {
    let result = [
      {
        field: "productTypeName",
        headerName: "Product Type",
        width: 250,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.productTypeName || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.productTypeName;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "productName",
        headerName: "Product Name",
        sortable: false,
        width: 300,
        editable: false,
        valueGetter: (params) => {
          return params.row?.product_name || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.product_name;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "invoice",
        headerName: "Invoice",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.invoice || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.invoice;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "class",
        headerName: "Class",
        width: 250,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.className || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.className;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "access_code_purchased",
        headerName: "Access Code Purchased",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.access_code_purchased || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.access_code_purchased;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "landingCost",
        headerName: "Landing Cost",
        width: 200,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.landing_cost || "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.landing_cost;
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "validity_end_date",
        headerName: "Validity End Date",
        width: 250,
        sortable: false,
        valueGetter: (params) => {
          return params.row?.validity_end_date
            ? formatTimeStamp(params.row?.validity_end_date)
            : "N/A";
        },
        renderCell: (params) => {
          let name = params.row?.validity_end_date
            ? formatTimeStamp(params.row?.validity_end_date)
            : "N/A";
          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name || "N/A"}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "Actions",
        headerName: "Actions",
        editable: false,
        hide: true,
        minWidth: 150,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={"Edit Mittplus Product"}>
                <IconButton onClick={() => handleEdit(params.row, "MITTPLUS")}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Mittplus Product"}>
                <IconButton
                  onClick={() => deleteProduct(params.row, "MITTPLUS")}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];
    return result;
  };

  const renderContent = () => {
    return (
       <>
       <div className="d-flex justify-content-end align-items-center  py-2">
                          <Typography variant="body1">Select Page Size:&nbsp; </Typography>
                      <Select
                        value={paginationModel.pageSize}
                        variant="outlined"
                        size="small"
                        onChange={(e)=>{setPaginationModel({...paginationModel,pageSize:e.target.value})}}
                        sx={{ width: 90, fontSize: 14 }}
                      >
                        {[10,20,30,40,50,60].map((role) => (
                          <MenuItem key={role} value={role}>{role}</MenuItem>
                        ))}
                      </Select>
                          </div>
      <Box style={{ height: !accessChanges?.length ? "200px" : "auto" }}>
        <DataGridContainer>
          <DataGrid
            className="payrollGrid"
            density="compact"
            rows={accessChanges || []}
            columns={getEmployeeColumns()}
            rowHeight={60}
            disableDensitySelector
            disableSelectionOnClick
            disableRowSelectionOnClick
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: 10,
            //     },
            //   },
            // }}
            filterMode="server"
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            rowCount={rowCount}
            pageSizeOptions={[10]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      </Box>
       </>
    );
  };

  const renderContentMittplus = () => {
    return (
      <Box style={{ height: !accessChanges?.length ? "200px" : "auto" }}>
        <DataGridContainer>
          <DataGrid
            className="payrollGrid"
            density="compact"
            rows={accessChanges || []}
            columns={getMittplusColumns()}
            rowHeight={60}
            disableDensitySelector
            disableSelectionOnClick
            disableRowSelectionOnClick
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: 10,
            //     },
            //   },
            // }}
            filterMode="server"
            paginationModel={paginationModel1}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel1}
            rowCount={rowCount1}
            pageSizeOptions={[10]}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      </Box>
    );
  };

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "10px" }}>
            <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
          <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
              <div>
             <Tooltip title="Search By SKU Name">
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search By SKU Name"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ width: 250, fontSize: 14, marginTop: 2.6 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="action" />
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
              </div>
            
           <div>
           <Typography variant="body1">Select Medium:</Typography>
                <Select
                  value={medium}
                  variant="outlined"
                  size="small"
                  onChange={(e)=>{setMedium(e.target.value)}}
                  sx={{ width: 189, fontSize: 14 }}
                >
                  <MenuItem value="">All</MenuItem>
                  {mediumss.map((role) => (
                    <MenuItem key={role} value={role.mediumTableId}>{role.mediumName}</MenuItem>
                  ))}
                </Select>
           </div>
           {/* <div> 
   <Typography variant="body1">Select Medium:</Typography>
  <Select
    value={medium}
    variant="outlined"
    size="small"
    onChange={(e) => {
      const selectedValue = e.target.value;
      setMedium(selectedValue === "5S84L9aVpl" ? "" : selectedValue);
    }}
    sx={{ width: 189, fontSize: 14 }}
  >
    <MenuItem value="">All</MenuItem>
    {mediumss
      .filter((role) => role.mediumTableId !== "5S84L9aVpl")
      // .filter((role) => role.mediumName !== "Both")
      .map((role) => (
        <MenuItem key={role.mediumTableId} value={role.mediumTableId}>
          {role.mediumName}
        </MenuItem>
      ))}
  </Select>
</div> */}

              <div>
              <Typography variant="body1">Select Class:</Typography>
                <Select
                  value={classes}
                  variant="outlined"
                  size="small"
                  onChange={(e)=>{setClasses(e.target.value)}}
                  sx={{ width: 189, fontSize: 14 }}
                >
                  <MenuItem value="">All</MenuItem>
                  {classeses.map((role) => (
                    <MenuItem key={role} value={role.classId}>{role.className}</MenuItem>
                  ))}
                </Select>
              </div>

                <div>
                <Typography variant="body1">Select Board:</Typography>
                <Select
                  value={board}
                  variant="outlined"
                  size="small"
                  onChange={(e)=>{setBoard(e.target.value)}}
                  sx={{ width: 189, fontSize: 14 }}
                >
                  <MenuItem value="">All</MenuItem>
                  {boardss.map((role) => (
                    <MenuItem key={role} value={role.boardId}>{role.boardName}</MenuItem>
                  ))}
                  {/* {boardss
      .filter((role) => role.boardId !== "FEUf5meyNKx")
      // .filter((role) => role.boardName !== "All Boards")
      .map((role) => (
        <MenuItem key={role.boardId} value={role.boardId}>
          {role.boardName}
        </MenuItem>
      ))} */}
                </Select>
                </div>
          
             <div>
             <Typography variant="body1">Select Series:</Typography>
                <Select
                  value={seriess}
                  variant="outlined"
                  size="small"
                  onChange={(e)=>{setSeriesS(e.target.value)}}
                  sx={{ width: 189, fontSize: 14 }}
                >
                  <MenuItem value="">All</MenuItem>
                  {seriesss.map((role) => (
                    <MenuItem key={role} value={role.seriesTableId}>{role.seriesName}</MenuItem>
                  ))}
                </Select>
             </div>
</Box>
            

  <Box  display="flex" alignItems="end" gap={1} flexWrap="wrap">
              <div>
                <Typography variant="body1">From Date</Typography>
                <input type="date" className="form-control w-auto" value={fromDate} onChange={(e) => { setFromDate(e.target.value) }} />
              </div>
              <div>
                <Typography variant="body1">To Date:</Typography>
                <input type="date" className="form-control w-auto" value={toDate} onChange={(e) => { setToDate(e.target.value) }} />
              </div>
              <Button variant="contained" color="primary" style={{ fontSize: "14px" }} sx={{ mr: 1, borderRadius: '10px' }} onClick={() => { fetchProduct() }}>Find</Button>
              <Button color="warning" variant="contained" style={{ fontSize: "14px" }} onClick={() => { clearFilters() }}>Clear Filters</Button>

              <Button variant="contained" color="primary" style={{ fontSize: "14px" }} sx={{ mr: 1, borderRadius: '10px' }} onClick={() => { exportProduct() }}> <FileDownloadIcon  sx={{ mr: 1 }}/> Download</Button>



              <div style={{ display: "flex", alignItems: "end", gap: "4" }}>
            <Box display="flex" gap={1}>
              {selectedTab === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSeries(true)}
                  sx={{ mr: 1, borderRadius: "10px" }}
                >
                  <Add sx={{ mr: 1 }} />
                  Add Series
                </Button>
              )}

              {selectedTab === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setBulk(true)}
                  sx={{ mr: 1, borderRadius: "10px" }}
                >
                  <Add sx={{ mr: 1 }} />
                  Bulk Add
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={
                  selectedTab === 0
                    ? () => addVisit()
                    : () => addMittplusProduct()
                }
                sx={{ mr: 1, borderRadius: "10px" }}
              >
                <Add sx={{ mr: 1 }} />
                Add New
              </Button>
            </Box>
          </div>


            </Box>
            </Box>
          </div>

       
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant="fullWidth"
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Mittsure" />
              <Tab label="Mittplus" />
            </StyledTabs>
            {loading && <OpaqueLoading />}
            {selectedTab === 0 ? renderContent() : renderContentMittplus()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
      {visitModal && (
        <ProductModal
          onClose={() => setVisitModal(false)}
          loading={loading}
          fetchData={() => fetchProduct()}
          props={dataModal}
          status={true}
        />
      )}
      {editModal && (
        <ProductModal
          onClose={() => setEditModal(false)}
          loading={loading}
          fetchData={() => fetchProduct()}
          props={dataModal}
          status={false}
        />
      )}
      {mittplusProduct && (
        <ProductMittplusModal
          onClose={() => setMittplusProduct(false)}
          loading={loading}
          props={dataModal}
          fetchData={() => fetchProductMittplus()}
          status={true}
        />
      )}
      {editMittplusProduct && (
        <ProductMittplusModal
          onClose={() => setEditMittplusProduct(false)}
          loading={loading}
          props={dataModal}
          fetchData={() => fetchProductMittplus()}
          status={false}
        />
      )}
      {series && (
        <SeriesCategory
          onClose={() => setSeries(false)}
          loading={loading}
          fetchData={
            selectedTab === 0
              ? () => fetchProduct()
              : () => fetchProductMittplus()
          }
          status={true}
        />
      )}
      {bulk && (
        <BulkProductAdd
          onClose={() => setBulk(false)}
          fetchData={
            selectedTab === 0
              ? () => fetchProduct()
              : () => fetchProductMittplus()
          }
        />
      )}
    </>
  );
};

export default ProductManagement;
