import React, { useEffect, useState } from "react";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import { useParams } from "react-router-dom";

function ConsentDetails() {

  const [orderItems, setOrderItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [fullOrderData, setFullOrderData] = useState([])

  const { id } = useParams();

  useEffect(() => {
    fetchOrderLineItem();
    fetchOrder();
  }, [])

  const fetchOrder = async () => {
    setLoading(true);
    try {
      let data = {
        orderId: id
      };

      let results = await window.Platform.database.fetchSingleOrder(data);
      if (results.status == false) {
        setFullOrderData(results.data)
        setLoading(false)
      }
      else {
        setLoading(false)
        window.NotificationUtils.showError("No Data Found");
      }
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error Fetching Data");
    } finally {
      setLoading(false);
    }
  }

  const fetchOrderLineItem = async () => {
    setLoading(true);
    try {
      let data = { id: id };
      let results = await window.Platform.database.fetchOrderLineItem(data);

      if (results.status === false) {
        const groupedData = results.data.reduce((acc, item) => {
          const series = item.seriesName || "Unknown Series";
          if (!acc[series]) acc[series] = [];
          acc[series].push(item);
          return acc;
        }, {});

        setOrderItems(groupedData);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError("Something Went Wrong!");
      setLoading(false);
    }
  };
  // bassssssssss

  const formatDateToIndian = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
  };

  const formattedDate = formatDateToIndian(fullOrderData[0]?.createdAt);


  const parseAddress = (address) => {
    try {
      return JSON.parse(address);
    } catch (error) {
      console.error("Invalid JSON in address:", address);
      return null;
    }
  };

  const getShipToAddress = (fullOrderData) => {
    if (!fullOrderData || fullOrderData.length === 0) return "N/A";

    const order = fullOrderData[0];
    const dAddress = parseAddress(order?.dAddress);
    const sAddress = parseAddress(order?.sAddress);
    const stAddress = parseAddress(order?.stAddress);

    return (
      dAddress?.addressLine1 ||
      sAddress?.addressLine1 ||
      stAddress?.addressLine1 ||
      "N/A"
    );
  };

  const shipToAddress = getShipToAddress(fullOrderData);

  const getStateAddress = (fullOrderData) => {
    if (!fullOrderData || fullOrderData.length === 0) return "N/A";

    const order = fullOrderData[0];
    const dAddress = parseAddress(order?.dAddress);
    const sAddress = parseAddress(order?.sAddress);
    const stAddress = parseAddress(order?.stAddress);

    return (
      dAddress?.state ||
      sAddress?.state ||
      stAddress?.state ||
      "N/A"
    );
  };

  const stateAddress = getStateAddress(fullOrderData);

  const sortedItems = (arr) => {
    arr.sort((a, b) => {
      return a.sku_code.localeCompare(b.sku_code)
    })
    console.log(arr)
    return arr
  }
  let totalQuantity = 0;
  let seriesBaseDiscount = 0;
  const tableSections = Object.keys(orderItems).map(seriesName => {
    const name = seriesName || '';

    const tableRows = orderItems[seriesName].map((item, ind) => {
      return totalQuantity += item?.QTY || 0;
    })
  })

  const date = new Date();
  const currentYear = date.getFullYear();
  const nextYear = (currentYear + 1).toString();
  const upcomingYear = nextYear.slice(-2);

  console.log(id,"paramsasasasas")
  return (
    <div className="d-flex justify-content-center">
      {loading && <OpaqueLoading />}
      <div className="container text-center w-100 " style={{border:"1px solid black"}}>
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <img src="./Logo1.png" alt="logo mittsure" width="150" height="auto" />
            </div>
            <div class="mr-3">
              <h4>Date : {formattedDate}</h4>
              <h4>SO # :  {fullOrderData[0]?.so_id || "N/A"}</h4>
            </div>
          </div>
        </div>

        <div className="container">
          <h4><b>MittSure Technologies LLP</b></h4>
          <h4><b>SALES ORDER FORM - ( {currentYear}-{upcomingYear} )</b></h4>
        </div>

        <div className="container">
          <div className="d-flex justify-content-center">
            <table
              className="table table-bordered text-center"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #ddd",
              }}
            >
              <tr>
                <td className="text-dark"><b>Bill To:</b> {fullOrderData[0]?.distributorNameforSchool || "N/A"}</td>
                <td className="text-dark"><b>Ship To:</b> {shipToAddress || "N/A"}</td>
              </tr>
              <tr>
                <td className="text-dark"><b>School Name:</b> {fullOrderData[0]?.schoolName || "N/A"}</td>
                <td className="text-dark"><b>State:</b> {stateAddress || "N/A"}</td>
              </tr>
              <tr>
                <td className="text-dark"><b>Transport/Travels:</b> {fullOrderData[0]?.transporter_name || "N/A"}</td>
                <td className="text-dark"><b>OTP Consent:</b> {fullOrderData[0]?.otpConsent || "N/A"}</td>
              </tr>
              <tr>
                <td className="text-dark"><b>RM:</b> {fullOrderData[0]?.name || "N/A"}</td>
                <td className="text-dark"><b>RM Contact:</b> {fullOrderData[0]?.mobno || "N/A"}</td>
              </tr>
              <tr>
                <td className="text-dark"><b>Remark:</b> {fullOrderData[0]?.remark || "N/A"}</td>
                <td className="text-dark"><b>Onboard for ERP:</b> {fullOrderData[0]?.onBoardERP == 0 ? "No" : "Yes" || 'N/A'}</td>
              </tr>
            </table>
          </div>
        </div>


        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div className="text-dark">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}><b>Order remark :</b> </span> {fullOrderData[0]?.order_remark}
            </div>
          </div>
        </div>

        {Object.keys(orderItems).map((seriesName, index) => {
          const seriesItems = Array.isArray(orderItems[seriesName]) ? orderItems[seriesName] : [];
          const totalQuantity = seriesItems.reduce((sum, item) => sum + (item.QTY || 0), 0);

          const subTotal = seriesItems.reduce((sum, item) => sum + (item.Total || 0), 0);

          const seriesId = seriesItems?.map((series, ind) => {
            return series.series
          })

          const seriesDiscount = JSON.parse(fullOrderData[0]?.seriesDiscount)?.[seriesId[0]] || 0;

          const discount = subTotal * 0.01;
          const netAmount = subTotal - discount;

          return <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <h4 style={{ fontWeight: "bold", fontSize: "18px" }}>{seriesName.toUpperCase() || "N/A"}</h4>
            </div>
            <div>
              <table>
                <thead>
                  <tr className="text-dark">
                    <th style={{width: "10%"}} >S. No.</th>
                    <th style={{width: "10%"}} >Qty.</th>
                    <th style={{width: "13%"}} >Sku Codes</th>
                    <th style={{width: "37%"}} >Title</th>
                    <th style={{width: "12%"}} >Class</th>
                    <th style={{width: "11%"}} >Rate</th>
                    <th style={{width: "17%"}} >Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems[seriesName].map((item, ind) => (
                    <tr key={ind} className="text-dark">
                      <td>{ind + 1}</td>
                      <td>{item.QTY}</td>
                      <td>{item.sku_code}</td>
                      <td>{item.nameSku}</td>
                      <td>{item.className}</td>
                      <td>{item.Price}</td>
                      <td>{item.Total}</td>
                    </tr>
                  ))}
                </tbody>
                <tr className="text-dark" style={{ fontSize: "16px" }}>
                  <td><b>Total : </b></td>
                  <td><b>{totalQuantity}</b></td>
                  <td colspan="2"></td>
                  <td colspan="2"><b>Sub Total:</b></td>
                  <td><b>₹ {subTotal}</b></td>
                </tr>
                <tr className="text-dark" style={{ fontSize: "16px" }}>
                  <td colspan="4"></td>
                  <td colspan="2"><b>Discount:</b></td>
                  <td><b>{seriesDiscount}%</b> </td>
                </tr>
                <tr className="text-dark" style={{ fontSize: "16px" }}>
                  <td colspan="4"></td>
                  <td colspan="2"><b>Net Amount:</b></td>
                  <td><b>₹ {(subTotal - (subTotal.toFixed(2) * (fullOrderData[0]?.discountPercent) / 100)).toFixed(2)}</b> </td>
                </tr>
              </table>
            </div>
          </div>
        })}

        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div>

            </div>
            <div class="row">
              <div className="col-md-8  "></div>
              <div className="col-md-4">
              <table className="text-dark" >
                <tr>
                
              <td style={{ fontWeight: "bold", fontSize: "18px" }}>Total : </td><td>{fullOrderData[0]?.originalAmount || "N/A"}</td></tr>
              <tr>
              <td style={{ fontWeight: "bold", fontSize: "18px" }}>Net To Pay  :</td><td> {fullOrderData[0]?.totalAmount || "N/A"}</td></tr>
              </table>
              </div>
            </div>
          </div>
        </div>

        <div style={{ margin: "20px"}}>
          <h4 style={{ textAlign: "left", color: "#000" }}>
            <b>Add-On Products</b>
          </h4>
          <table
            style={{
              // width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "center",
                    border: "1px solid #ddd",
                    color: "white",
                  }}
                >
                  S. No.
                </th>
                <th
                  style={{
                    padding: "8px",
                    textAlign: "center",
                    border: "1px solid #ddd",
                    color: "white",
                  }}
                >
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {fullOrderData[0]?.addOnProduct &&
                (() => {
                  try {
                    const addOnProducts = JSON.parse(fullOrderData[0].addOnProduct);
                    return Array.isArray(addOnProducts) && addOnProducts.length > 0 ? (
                      addOnProducts.map((item, ind) => (
                        <tr key={ind}>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                              color: "#000",
                            }}
                          >
                            {ind + 1}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              textAlign: "center",
                              border: "1px solid #ddd",
                              color: "#000",
                            }}
                          >
                            {item}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" style={{ textAlign: "center", padding: "8px", border: "1px solid #ddd", color: "#000" }}>
                          No Add-On Products
                        </td>
                      </tr>
                    );
                  } catch (error) {
                    console.error("Invalid JSON in addOnProduct:", error);
                    return (
                      <tr>
                        <td colSpan="2" style={{ textAlign: "center", padding: "8px", border: "1px solid #ddd", color: "red" }}>
                          Error: Invalid Add-On Products Data
                        </td>
                      </tr>
                    );
                  }
                })()}
            </tbody>
          </table>
        </div>


      </div>
    </div>
  )
}


export default ConsentDetails;