import React, { useState, useEffect } from "react";
import { InsertDriveFile, Image, PictureAsPdf, Audiotrack, Movie } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  MenuItem,
  Select,
  Grid
} from "@mui/material";

const OrderForm = ({payload,closePopup,editData,editMode}) => {
  const [distributor, setDistributor] = useState(null);
  const [user,setUser]=useState(JSON.parse(localStorage.getItem('storedUserData')).user)
  const [stockist, setStockist] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [warehouses,setWarehouses]=useState([]);
  const [transporter, setTransporter] = useState("");
  const [attachments,setAttachment]=useState("")
  const [shippingAddress, setShippingAddress] = useState("School");
  const [contactData,setContactData]=useState({School:{},Distributor:{},Stockist:{}})
  const [remark, setRemark] = useState("");
  const [orderRemark, setOrderRemark] = useState("");
  const [updateRemark,setUpdateRemark]=useState()
  const [consentPerson, setConsentPerson] = useState("");
  const [otpOpen, setOtpOpen] = useState(false);
  const [otp,setOtp]=useState('')
  const [filteredTransporter,setFilteredTransporter]=useState([payload.transporter])
  const [sv,setSv]=useState(false);

  const [distributors,setDistributors] = useState([]);
  const [stockists,setStockists] = useState([]);

  const [transporters,setTransporters] = useState([]);
  const [shippingOptions,setShippingOptions] = useState(["School", "Distributor", "Stockist"]);
  const [remarks,setRemarks] = useState([]);
  const [consentPersons,setConsentPersons] = useState(["School", "Distributor", "Stockist"]);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
    setOpen(true);
  };

  const getFilePreview = (file) => {
    if (file.type.startsWith('image/')) {
      return <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '100%', height: '100px' }} />;
    } else {
      return <InsertDriveFileIcon style={{ fontSize: 50,height:"100px", }}  />;
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    try {
      // const response = await fetch('https://mittsure.qdegrees.com:3001/user/uploadMultipleImages', {
      //   method: 'POST',
      //   body: formData,
      // });
      const response = await window.Platform.database.uploadMultipleImages( formData );
      console.log(response);
      if (response) {
        const result = await response
        console.log(result)
        let temp=[...attachments]
        result.data.files.forEach((ele)=>{
          console.log("Da")
          temp.push(ele)
        });
        setAttachment(temp)        
        
        setOpen(false);
      } else {
        alert('Failed to upload files.');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert('Error uploading files.');
    }
  };
  

  const fetchWarehouse = async (distru,s) => {
    try {
      const response = await window.Platform.database.getWarehouseinPicklist({});
      
      if (response && response.data) {
        setWarehouses(response.data);
        fetchTransporter(distru,s)
      } else {
        throw new Error("Failed to load warehouses");
      }
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  function initisalise(){
    
  let temp={School:{},Distributor:{},Stockist:{}}
  // console.log(payload,"payload 121")
  temp['School']['phone']=payload.mobileNo
  temp['School']['email']=payload.email
  temp['School']['address']=payload.address
  temp['School']['id']=payload.partyId
temp['School']['addId']=payload.addressId

  setContactData(temp)
}

  const fetchDistributor = async () => {
    const body = { ownerId:payload.ownerId};
    
    try {
      const response = await window.Platform.database.getAllDistri(body);
      
      if (response && response.data) {
        const data = response.data;
        setDistributors(data)
        if(payload.partyType=='P6E9TGXewd'){
          setDistributor(payload?.partyId);
          setConsentPerson([ "Distributor", "Stockist"])
          setShippingOptions(["Distributor", "Stockist"])
          setShippingAddress("Distributor")
          setConsentPerson("Distributor")
        }
        fetchRemarks()
        fetchStockist(data)
        
         
      } else {
        throw new Error("Failed to load orders");
      }
    } catch (error) {
      console.error("Error fetching distributors:", error);
    }
  };

  async function order(saveLater){
  
    console.log(contactData[shippingAddress]['addId']);
    console.log(contactData[shippingAddress]['id'])
    
    var body = payload;
    body['stockistId']=stockist;
    body['distributorId']=distributor;
    body['transport']=transporter;
    body['mobileNo']=contactData[shippingAddress]['id'];
    body['email']=contactData[shippingAddress]['email'];
    body['address']=contactData[shippingAddress]['addId'];
    body['otpConsent']=consentPerson +`(${contactData[consentPerson]['phone']})`;
    body['attachment']=attachments;
    body['saveLater']=saveLater?1:0
    body['remark']=remark;
    body['order_remark']=orderRemark;
    body['warehouse']=warehouse;
    body['attachment']=attachments
    console.log(body)
    
    
    try {
      const response = await window.Platform.database.createOrder(body);
      
      if (response && !response.status) {
        
      closePopup()
      } else {
        throw new Error("Failed to load orders");
      }
    } catch (error) {
      console.error("Error fetching distributors:", error);
    }
  }

  async function updateOrder(){
    var body = {...payload};
    body['stockistId']=stockist;
    body['orderId']=editData.orderId
    body['distributorId']=distributor;
    body['transport']=transporter;
    body['date']=Date.now()
    body['mobileNo']=contactData[shippingAddress]['id'];
    body['email']=contactData[shippingAddress]['email'];
    body['address']=contactData[shippingAddress]['addId'];
    body['approvalStatus']=editData.approvalStatus
    body['attachment']=editData.attachment;
    body['remark']=remark;
    body['order_remark']=orderRemark;
    body['warehouse']=warehouse;
    body['attachment']=attachments
    body['updateRemark']=updateRemark
    body['soID']=editData.so_id
    console.log(body,"data to update")
    try {
      const response = await window.Platform.database.updateOrderDetails(body);
      
      if (response && !response.status) {
        
      closePopup()
      } else {
        throw new Error("Failed to load orders");
      }
    } catch (error) {
      console.error("Error fetching distributors:", error);
    }
  }

  async function verifyOtp(){
   
      const body = { mobile: contactData[consentPerson]['phone'],otp:otp };
      
      try {
        const response = await window.Platform.database.verifyOtp(body);
        
        if (response && !response.status) {
          console.log(response)
          order(sv)
          
        
        } else {
          throw new Error("Failed to load orders");
        }
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    };
  
  const fetchTransporter = async (d,s) => {
    const body = {};
    try {
      const response = await window.Platform.database.getTransporters(body);
      
      if (response && response.data) {
        const data = response.data;
        
        setTransporters(data);
        if(editMode){
          console.log("DASDA");
          setTimeout(()=>{
            editPrevious(d,s)
          },200)
        }
      } else {
        throw new Error("Failed to load transporters");
      }
    } catch (error) {
      console.error("Error fetching transporters:", error);
    }
  };
  
  function editPrevious(d,s){
    setDistributor(editData?.distributorIDforSchool)
    setWarehouse(editData?.warehouse)
    setStockist(editData?.stockistId)
    let a=editData?.distributorIDforSchool
    let selected=d.filter((ele)=>{
      return ele.distributorID==a
 })[0];
 let b=editData?.stockistId
 let selectedS=s.filter((ele)=>{
   return ele.sto==b.stockistID
})[0];

let tempContactData={School:{},Distributor:{},Stockist:{}}
let fil=[payload.transporter]
fil.push(selected?.transporterId)

tempContactData['Distributor']['phone']=selected?.makerContact
tempContactData['Distributor']['email']=selected?.email
tempContactData['Distributor']['address']=formatAddress(selected?selected:{})
tempContactData['Distributor']['id']=selected?.distributorID
tempContactData['Distributor']['addId']=selected?.addressId
tempContactData['School']['phone']=payload.mobileNo
tempContactData['School']['email']=payload.email
tempContactData['School']['address']=payload.address
tempContactData['School']['id']=payload.partyId
tempContactData['School']['addId']=payload.addressId
tempContactData['Stockist']['phone']=selectedS?.makerContact
tempContactData['Stockist']['email']=selectedS?.email
tempContactData['Stockist']['address']=formatAddress(selectedS?selected:{})
tempContactData['Stockist']['id']=selectedS.stockistID
tempContactData['Stockist']['addId']=selectedS.addressId
setFilteredTransporter(fil)
setContactData(tempContactData)
setTransporter(editData?.Transport)
setOrderRemark(editData?.order_remark)
setRemark(editData?.remark)
  }
  const fetchRemarks = async () => {
    const body = {};
    try {
      const response = await window.Platform.database.getRemarkInPicklist(body);
      
      if (response && response.data) {
    
        setRemarks(response.data);
      } else {
        throw new Error("Failed to load remarks");
      }
    } catch (error) {
      console.error("Error fetching remarks:", error);
    }
  };
  
  const fetchStockist = async (distru) => {
    const body = { pageNumber: 0 };
    
    try {
      const response = await window.Platform.database.getStockistAll(body);
      
      if (response && response.data) {
        setStockists(response.data);
        fetchWarehouse(distru,response.data)
        
      } else {
        throw new Error("Failed to load orders");
      }
    } catch (error) {
      console.error("Error fetching stockists:", error);
    }
  };
  useEffect(()=>{
    
    initisalise()
fetchDistributor()


  },[])

   function formatAddress(addressData) {
    // Extract the fields, defaulting to an empty string if not found
     let addressLine1 = addressData['AddressLine1'] ?? '';
     let addressLine2 = addressData['AddressLine2'] ?? '';
     let state = addressData['State'] ?? '';
     let pincode = addressData['Pincode'] ?? '';

    // Build the address dynamically, skipping empty parts
    let addressParts = [
      addressLine1,
      addressLine2? addressLine2:"",
      state? state:"",
      pincode?pincode:"",
    ];

    // Join the non-empty parts with a comma
    return addressParts.join(', ');
  }

  function selectDistributor(value,op){
setDistributor(value)
    
    let a=value
    
    let selected=distributors.filter((ele)=>{
         return ele.distributorID==a
    })[0];
  
   let temp={...contactData}
   let fil=[...filteredTransporter]
   fil.push(selected?.transporterId)
   
   temp['Distributor']['phone']=selected?.makerContact
   temp['Distributor']['email']=selected?.email
   temp['Distributor']['address']=formatAddress(selected?selected:{})
   temp['Distributor']['id']=value
   temp['Distributor']['addId']=selected.addressId
   setFilteredTransporter(fil)
   setContactData(temp)
  }

  function selectStockist(value){
    
    
    setStockist(value)
    let a=value
    let selected=stockists.filter((ele)=>{
         return ele.stockistID==a
    })[0];
   let temp={...contactData}
   let fil=[...filteredTransporter]
   fil.push(selected.transporterId)
   
   temp['Stockist']['phone']=selected.makerContact
   temp['Stockist']['email']=selected.email
   temp['Stockist']['address']=formatAddress(selected)
   temp['Stockist']['id']=value
   temp['Stockist']['addId']=selected.addressId
   setFilteredTransporter(fil.filter((ele,index)=>{return ele && fil.indexOf(ele)==index}))
   setContactData(temp)
  }

  function getNameByID(t){
return transporters.filter(ele=>ele.transporterId==t)[0]?.transporter_name
  }

  async function proceedToOrder(flag){
    setSv(flag)
    if(editMode){
updateOrder()
    }
    else if(flag){
order(flag)
    }

    else{
    

    const body = { 
      "mobile": contactData[consentPerson]['phone'] ,
    };
    
    try {
      const response = await window.Platform.database.sendOtp(body);
      
      if (response && !response.status) {
        setOtpOpen(true);
      
         
      } else {
        throw new Error("Failed to load orders");
      }
    } catch (error) {
      console.error("Error fetching distributors:", error);
    }
  }
}

  return (
    <Box sx={{ p: 3, maxWidth: 500, mx: "auto" }}>
      
       <FormControl fullWidth className="my-2">
              <InputLabel>{"Select Distributor"}</InputLabel>
              <Select value={distributor}  label={"Select Distributor"} disabled={payload.partyType=='P6E9TGXewd'} onChange={(e)=>selectDistributor(e.target.value)}>
                {distributors.map((option, i) => (
                  <MenuItem key={i} value={option?.distributorID}>{option?.DistributorName}</MenuItem>
                ))}
              </Select>
              </FormControl>

      
       <FormControl fullWidth className="my-2">
              <InputLabel>{"Select Stockist"}</InputLabel>
              <Select value={stockist}  label={"Select Stockist"} onChange={(e)=>selectStockist(e.target.value)}>
                {stockists.map((option, i) => (
                  <MenuItem key={i} value={option?.stockistID}>{option?.StockistName}</MenuItem>
                ))}
              </Select>
              </FormControl>

      
      <FormControl fullWidth className="my-2">
              <InputLabel>{"Select Warehouse"}</InputLabel>
              <Select value={warehouse}  label={"Select Warehouse"} onChange={(e)=>setWarehouse(e.target.value)}>
                {warehouses.map((option, i) => (
                  <MenuItem key={i} value={option?.wh_code}>{option?.name}</MenuItem>
                ))}
              </Select>
              </FormControl>

      {/* Transporter Selection (Shown only if Distributor & Stockist are selected) */}
      {distributor && stockist && (
        <FormControl sx={{ mt: 2 }} className="my-2">
          <FormLabel>Transporter</FormLabel>
          <RadioGroup
            value={transporter}
            onChange={(e) => setTransporter(e.target.value)}
          >
            {filteredTransporter.map((t) => (
              <FormControlLabel key={t} value={t} control={<Radio />} label={getNameByID(t)} />
            ))}
          </RadioGroup>
        </FormControl>
      )}

      {/* Shipping Address Card */}
      <Card sx={{ mt: 2, p: 2 }}>
        <FormControl>
          <FormLabel>Shipping Address</FormLabel>
          <RadioGroup
            value={shippingAddress}
            onChange={(e) => setShippingAddress(e.target.value)}
          >
            {shippingOptions.map((option) => (
              <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        </FormControl>{console.log(contactData,"contactdata",contactData[shippingAddress],"contactData[shippingAddress]")}
        <TextField label="Address" value={contactData[shippingAddress]['address'] || ""} variant="outlined"  InputLabelProps={{ shrink: true }}  fullWidth sx={{ mt: 1 }} disabled />
        <TextField label="Mobile" fullWidth sx={{ mt: 1 }} variant="outlined"  InputLabelProps={{ shrink: true }}  value={contactData[shippingAddress]['phone'] || ""} disabled/>
        <TextField label="Email" fullWidth sx={{ mt: 1 }} variant="outlined"  InputLabelProps={{ shrink: true }}  value={contactData[shippingAddress]['email'] || ""} disabled/>
      </Card>

      {/* File Upload Card */}
      {!editMode&&<Card sx={{ mt: 2, p: 2 }}>
      <FormLabel>Upload Files</FormLabel>
      <input type="file" multiple onChange={handleFileChange} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>File Preview</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {files.map((file, index) => (
              <Grid item xs={6} key={index}>
                {getFilePreview(file)}
                <Typography variant="body2" noWrap>{file.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleUpload} variant="contained" color="primary">Upload</Button>
        </DialogActions>
      </Dialog>
      </Card>}


      {/* Remark Dropdown */}
      <FormControl fullWidth sx={{ mt: 2 }}>
        
        <InputLabel>Select Remark</InputLabel>
        <Select value={remark} label="Select Remark" onChange={(e) => setRemark(e.target.value)}>
{}
          {
          remarks &&remarks.map((r) => (
            <MenuItem key={r.id} value={r.name}>{r?.name}</MenuItem>
            
          ))}
        </Select>
      </FormControl>

      {/* Order Remark TextField */}
      <TextField
        label="Order Remark"
        fullWidth
        sx={{ mt: 2 }}
        value={orderRemark}
        onChange={(e) => setOrderRemark(e.target.value)}
      />

      {editMode&&<TextField
        label="Update Remark"
        fullWidth
        sx={{ mt: 2 }}
        value={updateRemark}
        onChange={(e) => setUpdateRemark(e.target.value)}
      />}

      {/* Consent Person Selection */}
      {!editMode&&<Card sx={{ mt: 2, p: 2 }}>
        <FormControl>
          <FormLabel>Consent Person</FormLabel>
          <RadioGroup
            value={consentPerson}
            onChange={(e) => setConsentPerson(e.target.value)}
          >
            {consentPersons.map((p) => (
              <FormControlLabel key={p} value={p} control={<Radio />} label={p} />
            ))}
          </RadioGroup>
        </FormControl>
      </Card>}

      {/* Order Button & OTP Popup */}
      <Button variant="contained" sx={{ mt: 3 }} fullWidth onClick={() => proceedToOrder(false)}>
        Place Order
      </Button>
      {!editMode&&<Button variant="contained" sx={{ mt: 3 }} fullWidth onClick={() => proceedToOrder(true)}>
        Save For Later
      </Button>}

      <Dialog open={otpOpen} onClose={() => setOtpOpen(false)}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="OTP" value={otp} onChange={(e)=>setOtp(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOtpOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={verifyOtp}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrderForm;
