import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid, Typography, Container } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

export default function SelectParty({partyType,choosePartyType,chooseParty,setTab,setOwner,partyId,ownerId,editMode}) {
  const [rsm, setRsm] = useState('');
  const [user,setUSer]=useState(JSON.parse(localStorage.getItem('storedUserData')).user)
  const [asm, setAsm] = useState('');
  const [rm, setRm] = useState('');
  
  const [party, setParty] = useState('');
  const [rsmOptions,setRsmOptions] = useState(['RSM 1', 'RSM 2', 'RSM 3']);
  const [asmOptions,setAsmOptions] = useState(['ASM 1', 'ASM 2', 'ASM 3']);
  const [rmOptions,setRmOptions] = useState(['RM 1', 'RM 2', 'RM 3']);
  const [partyTypeOptions,setPartyTypeOptions] = useState(['School', 'Distributor']);
  const [partyOptions,setPartyOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectPartyTab,setSelectPartyTab]=useState(false)


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getUser = async (id,role) => {
    const body = { userId: id };
    try {
        const response = await window.Platform.database.getUsersBasedOnManager(body);
        if (response) {
            
            if(role=='rsm'){
                setRsm(id)
                setAsm("")
                setAsmOptions(response.data)
            }else if(role=='asm'){
                setAsm(id)
                setRm("")
                setRmOptions(response.data)
            }
        }
    } catch (error) {
        console.error("Error fetching orders:", error);
    }
};

async function getAllRsm(role) {
    const body = { role:role };
    try {
        const response = await window.Platform.database.fetchEmployees(0,role);
        if (response) {
          if(editMode){
            setRmOptions(response.data)
            setRm(ownerId)
            selectEditPartyType()
           
          }else{
            setRsm("")
            console.log(response);
            setRsmOptions(response.data)
          }
        }
    } catch (error) {
        console.error("Error fetching orders:", error);
    }
}

async function selectPartyType(){
  
  let body = {
    "pageNumber": page, // Backend may use 0-based indexing
    "recordPerPage": rowsPerPage,
    "ownerId":rm
  };


    if(partyType.toLowerCase()=='school'){
      const response = await window.Platform.database.getSchool(body);
      if (response) {
          setPartyOptions(response.data)
          setTotalCount(response.data1)
          setSelectPartyTab(true);
          
      }
    }else{
      const response = await window.Platform.database.getDistributorA(body);
      if (response) {
          setPartyOptions(response.data)
          setTotalCount(response.data1)
          setSelectPartyTab(true);
      }
    }
}

async function selectEditPartyType(){
  
  let body = {
   id:partyId
  };

    console.log(partyType,"dadad");
    if(partyType.toLowerCase()=='school'){
      const response = await window.Platform.database.getSchoolById(body);
      if (response) {
          console.log(response.data[0])
          chooseParty(response.data[0]);setTab(1)
          
          
      }
    }else{
      const response = await window.Platform.database.getDistributorByID(body);
      if (response) {
        chooseParty(response.data[0]);setTab(1)
      }
    }
}


useEffect(()=>{
    if(editMode){
      getAllRsm('se')
    }else{
if(user.role.includes('admin')){
getAllRsm('rsm')
}else{
  getUser(user.id,user.role)
}
    }

},[])


  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      {!selectPartyTab&&<div>
            {!editMode&&user.role.includes('admin')&&<FormControl fullWidth>
              <InputLabel>{"Select RSM"}</InputLabel>
              <Select value={rsm}  label={"Select RSM"} onChange={(e)=>getUser(e.target.value,"rsm")}>
                {rsmOptions.map((option, i) => (
                  <MenuItem key={i} value={option?.id}>{option?.name}</MenuItem>
                ))}
              </Select>
              </FormControl>}
              {!editMode&&(user.role.includes('admin')||user.role=='rsm')&&<FormControl className='my-2' fullWidth>
              <InputLabel>{"Select ASM"}</InputLabel>
              <Select value={asm}  label={"Select ASM"} onChange={(e)=>getUser(e.target.value,"asm")}>
                {asmOptions.map((option, i) => (
                  <MenuItem key={i} value={option?.id}>{option?.name}</MenuItem>
                ))}
              </Select>
              </FormControl>}
              <FormControl fullWidth>
              <InputLabel>{"Select RM"}</InputLabel>
            
              <Select value={rm}  label={"Select RM"} onChange={(e)=>{setRm(e.target.value);setOwner(e.target.value)}}>
                {rmOptions.map((option, i) => (
                  <MenuItem key={i} value={option?.id}>{option?.name}</MenuItem>
                ))}
              </Select>
              </FormControl>
              <FormControl fullWidth className='my-2'>
              <InputLabel>{"Party Type"}</InputLabel>
              <Select value={partyType}  label={"Party Type"} onChange={(e)=>{choosePartyType(e.target.value)}}>
                {partyTypeOptions.map((option, i) => (
                  <MenuItem key={i} value={option}>{option}</MenuItem>
                ))}
              </Select>
              </FormControl>
              {/* <button className='btn btn-info' onClick={()=>{selectPartyType()}}> Search Party</button> */}
        <button
          className='btn btn-info'
          onClick={selectPartyType}
          disabled={!rm || !partyType}
        >
          Search Party
        </button>

      </div>}

         {selectPartyTab && <Paper>
          <button className='btn btn-primary' onClick={()=>{setSelectPartyTab(false)}}> back</button>
      <TableContainer>
        <Table>
          <TableHead >
            <TableRow >
              <TableCell className='text-white'>Party ID</TableCell>
              <TableCell className='text-white'>Party Name</TableCell>
              {/* <TableCell className='text-white'>Mobile</TableCell> */}
              <TableCell className='text-white'>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partyOptions.map((party) => (
              <TableRow key={party.id} onClick={()=>{chooseParty(party);setTab(1)}}>
                <TableCell style={{cursor:"pointer"}}>{partyType.toLowerCase()=='school'?party.schoolId:party.distributorID}</TableCell>
                <TableCell  style={{cursor:"pointer"}}>{partyType.toLowerCase()=='school'?party.schoolName:party.DistributorName}</TableCell>
                {/* <TableCell  style={{cursor:"pointer"}}>{party?.makerContact?party?.makerContact : "N/A"}</TableCell> */}
                <TableCell  style={{cursor:"pointer"}}>{party?.email? party?.email : "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>}
     
    </Container>
  );
}
