import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Divider,
} from "@mui/material";

const GroupedCartPopup = ({ open, onClose, cartItems, seriesData, applyDiscounts ,setDiscountsPer,editMode,seriesD}) => {

  
  
  const groupedItems = {};
  cartItems.forEach((item) => {
    if (!groupedItems[item.series]) {
      groupedItems[item.series] = [];
    }
    groupedItems[item.series].push(item);
  });

 
  const seriesTotals = {};
  Object.entries(groupedItems).forEach(([series, items]) => {
    seriesTotals[series] = items.reduce(
      (sum, item) => sum + item.price * item.qty * (1 - item.discount / 100),
      0
    );
  });

  const [discounts, setDiscounts] = useState(
    Object.fromEntries(Object.keys(groupedItems).map((series) => [series, groupedItems[series][0].discount || 0]))
  );
  
  
  const [errorMessages, setErrorMessages] = useState({});

  
  const handleDiscountChange = (series, value) => {
    const newDiscount = parseInt(value) || 0;
    const seriesInfo = seriesData.find((s) => s.seriesTableId === series);
    
    let errorMessage = null;
    if (seriesInfo) {
      const { discountType, minDiscount = 0, maxDiscount = 100 } = seriesInfo;

      if (discountType === "flat" && newDiscount !== minDiscount) {
        errorMessage = `Discount must be exactly ${minDiscount}%`;
      } else if (discountType === "range" && (newDiscount < minDiscount || newDiscount > maxDiscount)) {
        errorMessage = `Discount must be between ${minDiscount}% and ${maxDiscount}%`;
      }
    }

    setErrorMessages((prev) => ({ ...prev, [series]: errorMessage }));
    
    if (!errorMessage) {
      let temp={...discounts}
      temp[series]=newDiscount
      setDiscountsPer(temp)
      
      setDiscounts((prev) => ({ ...prev, [series]: newDiscount }));

      // Update item discounts
      groupedItems[series].forEach((item) => (item.discount = newDiscount));

      // Update series total
      seriesTotals[series] = groupedItems[series].reduce(
        (sum, item) => sum + item.price * item.qty * (1 - item.discount / 100),
        0
      );
    }
  };

  function saveDiscounts(){
   
    applyDiscounts(discounts);
  }
  useEffect(()=>{
    if(editMode){
      Object.keys(JSON.parse(seriesD)).forEach((ele)=>{
        handleDiscountChange(ele,JSON.parse(seriesD)[ele])
      })  
      }
  },[])
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Apply Discount</DialogTitle>
      <DialogContent>
        {Object.entries(groupedItems).map(([series, items]) => {
          const total = seriesTotals[series] || 0;
          const seriesInfo = seriesData.find((s) => s.seriesTableId === series);
          return (
            <div key={series} style={{ marginBottom: "20px" }}>
              <Typography variant="subtitle1">
                <strong>Series:</strong> {seriesInfo?.seriesName || series}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <strong>Total:</strong> ₹{total.toFixed(2)}
              </Typography>

              <TextField
                label="Update Discount (%)"
                type="number"
                variant="outlined"
                fullWidth
                margin="dense"
                value={discounts[series]}
                onChange={(e) => handleDiscountChange(series, e.target.value)}
                error={!!errorMessages[series]}
                helperText={errorMessages[series]}
              />

              <Divider style={{ margin: "10px 0" }} />
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button  variant="contained" color="primary" onClick={saveDiscounts}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupedCartPopup;
