import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CloseOutlined } from "@mui/icons-material";

import {
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Button,
    Tooltip,
  } from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkAddDistributor = ({onClose,fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStats, setUploadStats] = useState(null);
  const [failedEntries, setFailedEntries] = useState([]);

  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const hiddenFileInput = useRef(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [data, setData] = useState([]);
  const [assignedToData, setAssignTodata] = useState([])
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

useEffect(()=>{
  fetchEmployees()
}, [])
  const getRowClassName = (params) => {
    let uploadStatus = params.row.status || "";
    if (uploadStatus === "Success") {
      return "successRow";
    } else if (uploadStatus === "Failure") {
      return "failureRow";
    } else {
      return "";
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
          csvOptions={{
            fileName: 'bulk-upload-Supplier',
          }}
        />
      </GridToolbarContainer>
    );
  }

  // const getEmployeeName = (
  //   employeeCode,
  //   reportingManagerStringNeeded = false
  // ) => {
  //   let result = "Admin";
  //   let employee = props.staffMembers.find(
  //     (member) => member.employeeCode === employeeCode
  //   );
  //   if (reportingManagerStringNeeded && employee) {
  //     employee = props.staffMembers.find(
  //       (member) => member.employeeCode === employee.reportingManager
  //     );
  //   }
  //   if (employee) {
  //     let name = `${employee.firstName} ${
  //       employee.lastName ? employee.lastName : ""
  //     }`;
  //     if (!reportingManagerStringNeeded) {
  //       result = name;
  //     } else {
  //       result = `${name}-${employee.employeeCode} (${employee.role})`;
  //     }
  //   }
  //   return result;
  // };
    const handleButtonClick = () => {
    fileInputRef.current.click();
  };



    const handleImport = (file) => {
      try {
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        // const workbook = XLSX.read(data, { type: "array" });
        // const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        // if (headers[0] !== "lead" || headers[1] !== "leadData") {
        //     throw new Error("Invalid headers in the Excel file");
        //   }
        // const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // const formattedData = jsonData.slice(1).map((row,index) => ({
        //   name: row[0],
        //   ownerId:row[1],
        //   id:index+1
          
        // }));
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        // if (headers[0] !== "Lead" || headers[1] !== "UserId") {
        //   window.NotificationUtils.showError(
        //     "Please select a valid file for upload with exact header in template"
        //   );
        //   setLoading(false)
        //   return 
        // }

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formattedData = jsonData.slice(1).map((row, index) => ({
            distributorName: row[0],
            // makerName: row[1],
            // makerContact: row[2]?.toString(),
            // makerRole:row[3],
            // pincode: row[4],
            // addressLine1: row[5],
            // email:row[6],
            subDistributorName: row[1],
            gstNo: row[2],
            panNumber: row[3],
            kyc: row[4],
            makerName: row[5],
            makerContact: row[6]?.toString(),
            makerRole:row[7],
            pincode: row[8],
            addressLine1: row[9],
            bankName: row[10],
            holderName: row[11],
            branchName:row[12],
            accountNo:row[13],
            ifsc:row[14],
            billingPincode:row[15],
            billingAddressLine1:row[16]?.toString(),
            shippingAddressLine1:row[17],
            email:row[18],
            party_id:row[19],
            clusterName:row[20],
            series:row[21],
            ownerId:selectedValue,
          id: index + 1,
        }));
          console.log("formattedData",formattedData)
        setTargetRows(formattedData)
        setLoading(false);
        // setDisableButtons(false);
      };
      reader.readAsArrayBuffer(file);
    }}
    catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };

  const handleDownload = async ( ) => {
      try
     {
      const headers =['RM Name', "distributorName", "subDistributorName", "gstNo", "panNumber", "kyc", "decisionMakerName","decisionMakerContact", "makerRole","pincode","addressLine1","bankName","accountHolderName","branchName","accountNo","ifsc","billingPincode","billingAddressLine1","shippingAddressLine1","email", "party_id","clusterName", "series"]
      // const headers =['distributorName', "decisionMakerName", "decisionMakerContact", "makerRole", "pincode", "addressLine1", "email",]
      
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet1');
      // const decisionMakerRole = [newContactPersonRole]
     
      worksheet.addRow(headers);
    
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'distributorAdd.xlsx';
        a.click();
        URL.revokeObjectURL(url);
        setLoading(false)
      });
    }
    catch (error) {
      console.error(error);
      setLoading(false)
    }
 

  };

    const handleRowSelection = (selection) => {
    // console.log("SELECT",selection)
    // console.log("selection",rows)
    let array=[]
    let data=targetRows.filter((row) => selection.includes(row.id));
    let finalData=data.map((row) => {
      let sent={
        distributorName:row.distributorName,subDistributorName:row.subDistributorName,id:row.id,
        gstNo:row.gstNo,panNumber:row.panNumber,kyc:row.kyc,makerName:row.makerName,
        makerContact:row.makerContact,makerRole:row.makerRole,pincode:row.pincode,addressLine1:row.addressLine1,bankName:row.bankName,
        bankName:row.bankName,holderName:row.holderName,branchName:row.branchName,accountNo:row.accountNo,
        ifsc:row.ifsc,billingPincode:row.billingPincode,billingAddressLine1:row.billingAddressLine1,shippingAddressLine1:row.shippingAddressLine1,email:row.email, party_id:row.party_id, clusterName:row.clusterName, series:row.series , ownerId:row.ownerId,
      }
      array.push(sent)

    })
  
    // console.log("selection1",finalData)
    // setSelectedRows(finalData);
    return array;
  };

  const onClickUpload = async () => {
    try {
   const data=handleRowSelection(selectedRowIds)
   console.log("SELECTED ROWS",data)
    let finalData =[...targetRows]
    console.log("SELECTED ROWS",selectedRowIds);
    console.log("SELECTED ROWS FINALLY",finalData)
      setLoading(true);
      let response = await window.Platform.database.addBulkDistributor(data);
      console.log("SUCCESS",response)
      setTargetRows(response.data)
       
    //   let newTargetRows = [...newRows];
    console.log("FINAL",finalData)
    setSelectedRowIds([])
      for(let responseEntry of response.data){
        let rowIndex = finalData.findIndex(row => row.id === responseEntry.id);
        // console.log("ROWINDEX",rowIndex)
        if(rowIndex !== -1){
            finalData[rowIndex].status = responseEntry.status;
            finalData[rowIndex].reason = 'N/A';
            // console.log("CHECK!")
          if(responseEntry.status === 'Failure'){
            finalData[rowIndex].reason = responseEntry.reason;
          }
        }
      }
      setTargetRows(finalData);
      if (!uploaded) {
        setUploaded(true);
      }
      setLoading(false);
      window.NotificationUtils.showSuccess('Submition Complete')

      // window.NotificationUtils.showSuccess("Details Uploaded Please verify your upload");
      // fetchdata();
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError(error?.message);
      setLoading(false);
    }
  };
  
const fetchEmployees=async()=>{
  setLoading(true)
  try{
 const results = await window.Platform.database.getUsersForWork();
 setAssignTodata(results?.data)
 console.log(results.data,"Assign To data ")
 setLoading(false)
}
catch(err){
  console.log(err)
  setLoading(false)
}
}

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDownloadErrorReport = async () => {
    if (failedEntries.length === 0) return;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Error Report');
    const headers = Object.keys(failedEntries[0]); 
    worksheet.addRow(headers); 
    failedEntries.forEach(entry => {
        worksheet.addRow(headers.map(header => entry[header]));
    });

    
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Distributor_Error_Report.xlsx";
    a.click();
    URL.revokeObjectURL(url);
};


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setSelectedFile(file);
    setFileKey((prevKey) => prevKey + 1);
    console.log("Selected File:", file.name, file.type, file.size);
  };
  const handleUpload = async () => {
    if (!selectedFile) {
        window.NotificationUtils.showError("No file selected for upload.");
        return;
    }

    setLoading(true);
    try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        console.log("Uploading File:", selectedFile.name);

       let response = await window.Platform.database.addBulkDistributor11(formData);

        if (!response) {
            throw new Error("No response from server");
        }

        console.log("Upload Success:", response);

        // **Process analytics**
        const successCount = response.data.filter(entry => entry.status === "Success").length;
        const failureEntries = response.data.filter(entry => entry.status === "Failure");
        setUploadStats({ successCount, failureCount: failureEntries.length, total: response.data.length });

        // **Store failed entries for error report**
        setFailedEntries(failureEntries);

        window.NotificationUtils.showSuccess("File uploaded successfully!");
        setSelectedFile(null); // Clear file selection after upload
    } catch (error) {
        console.error("Error uploading file:", error);
        window.NotificationUtils.showError("Error uploading file!");
    } finally {
        setLoading(false);
    }
};


  const handleClose=()=>{
    onClose()
    fetchData()
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add Distributor Bulk</Typography>
          <IconButton disabled={loading} onClick={()=>handleClose()}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        <InputFieldContainer>
      
          <Button variant="contained" color="primary" onClick={handleDownload}>Download Template</Button>

          <Button variant="contained" color="primary" onClick={handleButtonClick}>
            Select File
          </Button>
          <input
            key={fileKey}
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
          />

          {selectedFile && (
            <Button
              variant="contained"
              color="success"
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          )}
        </InputFieldContainer>

       
        {uploadStats && (
          <div style={{
            marginTop: "20px",
            padding: "15px",
            borderRadius: "10px",
            background: "#f8f9fa",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center"
          }}>
            <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
              Upload Summary
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h6" color="green">{uploadStats.successCount}</Typography>
                <Typography variant="body2">Successful Entries</Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h6" color="red">{uploadStats.failureCount}</Typography>
                <Typography variant="body2">Failed Entries</Typography>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h6" color="blue">{uploadStats.total}</Typography>
                <Typography variant="body2">Total Entries</Typography>
              </div>
            </div>

       
            <div style={{ marginTop: "15px", width: "100%", height: "10px", background: "#e0e0e0", borderRadius: "5px" }}>
              <div style={{
                width: `${(uploadStats.successCount / uploadStats.total) * 100}%`,
                height: "100%",
                background: "green",
                borderRadius: "5px"
              }}></div>
            </div>
            {/* <h3>Progress</h3> */}
            {failedEntries.length > 0 && (
            <Button
                variant="contained"
                color="error"
                onClick={handleDownloadErrorReport}
                style={{ marginTop: "15px" }}
            >
                Download Error Report
            </Button>
        )}
          </div>
        )}
      
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkAddDistributor;

