import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Button, Card, CardContent, List, ListItem, ListItemText, Divider, Container } from "@mui/material";
import { ArrowBack, Remove, Add, Delete, Discount, ArrowForward } from "@mui/icons-material";
import GroupedCartPopup from "./modals/groupedCartItems";

const Cart = ({ payload, series, initialCartItems,proceed,setCart,seriesD,editMode }) => {
  const [cartItems, setCartItems] = useState([]);
  const [discountPopup,setDiscountPopup]=useState(false);
  const [discounts,setDiscounts]=useState({})

  const handleQuantityChange = (index, delta) => {
    setCartItems((prevCartItems) => {
      return prevCartItems.map((item, i) =>
        i === index ? { ...item, qty: Math.max(1, item.qty + delta) } : item
      );
    });

   
    let id =cartItems[index].itemId
     let temp=[...initialCartItems]
     for(let i=0;i<temp.length;i++){
      if(temp[i].data.skuId==id){
temp[i].quantity=Math.max(1, temp[i].quantity + delta)
break;
      }
     }
     setCart(temp)
  };

  const handleRemoveItem = (index) => {
    setCartItems((prevCartItems) => prevCartItems.filter((_, i) => i !== index));
    let id =cartItems[index].itemId
    let temp=[]
    for(let i=0;i<initialCartItems.length;i++){
     if(initialCartItems[i].data.skuId!=id){
temp.push(initialCartItems[i])
break;
     }
    }
    
    setCart(temp)
  };

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + (item.price * item.qty)*(1-item.discount/100), 0);
  };

  function setData(){
    const newCartItems = [];
    
    initialCartItems.forEach((order) => {
      const quantity = parseInt(order.quantity, 10) || 1;
      const group = order.group;
      const item = order.data || {};

      
        newCartItems.push({
          name: group === "6HPipXSLx5" ? item.product_name : item.nameSku,
          productGroup: group,
          price:
            item.unitPrice != null
              ? parseFloat(item.unitPrice)
              : parseFloat(item.landing_cost) || 0.0,
          qty: quantity,
          series: item.seriesCategory || "",
          itemId: item.skuId || item.id,
          total: item.unitPrice != null ? (parseFloat(item.unitPrice) * quantity || 0.0) : 0.0,
          discount: item.discount?item.discount:0,
          itemType: order.orderType,
        });
     
    });

    setCartItems(newCartItems);
  }
  useEffect(()=>{setData()},[])

  function applyD(discounts){
let temp=[...cartItems]
temp.forEach((ele)=>{
    ele.discount=discounts[ele.series]
})
setDiscountPopup(false)
setCartItems(temp);
  }

  function payloadCreate(){
    var  body  = {...payload};
    body['totalAmount']=body['orderType'].toLowerCase()=='sales'?getTotalPrice():0;
    body['seriesDiscount']=discounts;
    body['orders'] = cartItems.map((item) =>{
      return {
        'itemId': item.itemId,
        'name': item.name,
        'price': item.price,
        'qty': item.qty,
        'productGroup':item.productGroup,
        'orderType':item.itemType??"",
        'total':payload['orderType'].toLowerCase()=='sales'?item.price * item.qty*(1-item.discount/100):0
      };
    });
    
    proceed(cartItems,body)
  }

  return (
    <Container>
      
      { discountPopup?<div>
    

      <GroupedCartPopup
        open={discountPopup}
        onClose={() => setDiscountPopup(false)}
        cartItems={cartItems}
        seriesData={series}
        applyDiscounts={applyD}
        editMode={editMode}
        seriesD={seriesD}
        setDiscountsPer={setDiscounts}
      />
    </div>:
    <div>
     
      {/* Cart Items */}
      <List>
        {cartItems.map((item, index) => (
          <Card key={index} sx={{ margin: 2, padding: 1 }}>
            <CardContent>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={`Total Price: ₹${(item.price * item.qty).toFixed(2)} | Price: ₹${item.price}`}
                />
                <IconButton onClick={() => handleQuantityChange(index, -1)}>
                  <Remove />
                </IconButton>
                <Typography>{item.qty}</Typography>
                <IconButton onClick={() => handleQuantityChange(index, 1)}>
                  <Add />
                </IconButton>
                <IconButton onClick={() => handleRemoveItem(index)} color="error">
                  <Delete />
                </IconButton>
              </ListItem>
            </CardContent>
          </Card>
        ))}
      </List>

      {/* Total & Buttons */}
      <Divider />
      <Typography variant="h6" sx={{ textAlign: "center", marginY: 2 }}>
        Total: ₹{payload.orderType.toLowerCase() === "sales" ? getTotalPrice().toFixed(2) : 0}
      </Typography>

      <div style={{ display: "flex", justifyContent: "space-between", padding: 16 }}>
        <Button variant="contained" color="success" startIcon={<Discount />} onClick={() => setDiscountPopup(!discountPopup)}>
          Discounts
        </Button>
        <Button variant="contained" color="primary" startIcon={<ArrowForward />} onClick={() => payloadCreate()}>
          Proceed
        </Button>
      </div>
      </div>}
    </Container>
  );
};

export default Cart;
