import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Fab,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Grid,
  Select,Modal,
  MenuItem,
  InputAdornment,
  TextField,
  Stack,
} from "@mui/material";

import React, { useState, useEffect, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import styled from "styled-components";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridDensitySelector,
  getGridStringOperators,
} from "@mui/x-data-grid";

import SucceedCheck from "../../components/common/LotteStyles/succeedCheck.json";

import Drawer from "../../components/common/drawer/drawer";

import { TABS, ROLES, DATE_FORMATS } from "../../constants";

// import ReplaceManagerModal from "../../components/modals/replaceManagerModal";

// import BulkUploadRoleSelector from "../../components/modals/bulkUploadRoleSelector";

// import BulkUserDelete from '../../components/modals/bulkUserDelete';

// import DeletionReasonModal from '../../components/modals/deletionReasonModal';

import moment from "moment";

import { AuthContext } from "../../components/contextAPI/ContextAPI";

import {
  Add,
  AddAPhoto,
  AddTask,
  SaveAlt,
  SaveAltOutlined,
  SaveAs,
  ManageSearch,
  Send,
  Search
} from "@mui/icons-material";

// import { LottiePlayer } from 'lottie-react';

import Lottie from "lottie-react";

import DeletionReasonModal from "../../components/modals/deletionReasonModal";

import { useNavigate } from "react-router-dom";
import StockistModal from "../../components/modals/StockistModal";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";
import CommonOtpModal from "../../components/modals/commonOtpModal";
import BulkAddStockist from "../../components/modals/BulkAddStockist";
import CommonOtpModalConsent from "../../components/modals/commonOtpModalConsent";
import "../../App.css";

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 105px)`};
  width: 100%;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const HeaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: space-between;

  margin-bottom: 7px;
`;

const TableContainer = styled.div`
  //   height: calc(
  //     100vh - ${isMobile
    ? "56px - 20px - 82.23px"
    : "128px - 29px - 50.77px"}
  //   );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
`;

const DetailsPreviewContainer = styled.div`
  height: 100%;

  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;

  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px);

  `}
`;

const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const AvatarContainer = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;

  align-items: center;
`;

const DataGridContainer = styled.div`
  max-width: 100%; /* Add this line to limit the width */
  margin: 0 auto; /* Center the content horizontally */
  height: 100%;
  width: 100%;
  //   border: solid 1px lightGrey;
  display: ${isMobile ? "flex" : "grid"};
  border-radius: 4px;

  ${isMobile &&
  `
    flex-direction: column;
  `}
`;

const LoadingContainer = styled.div`
  height: calc(100% - 49px);

  display: flex;

  justify-content: center;

  align-items: center;
`;

const TabsContainer = styled.div`
  ${isMobile &&
  `

    height: calc(100vh - 56px - 20px - 43.77px);

  `}
`;

const BulkUploadButton = styled(Button)`
  margin-right: 10px !important;
`;

const FabStyle = styled(Fab)`

  && {

    z-index: 1};

  }

`;

const StockistManagement = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentPage1, setCurrentPage1] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 20,
  });
  // const [paginationModel1, setPaginationModel1] = useState({
  //   page: currentPage1 - 1,
  //   pageSize: 11,
  // });
  const [otpVerify, setOtpVerify] = useState(false);
  const [rowCount, setRowCount] = useState();
  const [rowCount1, setRowCount1] = useState();
  const [rowCount2, setRowCount2] = useState();
  const [rowCountNew, setRowCountNew] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange1, setDateRange1] = useState(false);

  const [startDate1, setStartDate1] = useState();
  const [endDate1, setEndDate1] = useState();
  const [minDate, setMinDate] = useState();
  const [consentData, setConsentData] = useState(null);

  const navigation = useNavigate();

  const [userModal, setUserModal] = useState(null);

  const [staffMembers, setStaffMembers] = useState([]);

  const [visibleEmployees, setVisibleEmployees] = useState([]);

  const [reasonModalObj, setReasonModalObj] = useState({});

  const [selectedMember, setSelectedMember] = useState(null);

  const [selectedTab, setSelectedTab] = useState(0);
  const [showDeletionReasonPicker, setShowDeletionReasonPicker] =
    useState(false);

  const [editStockist, setEditStockist] = useState(false);
  const [loading, setLoading] = useState(false);

  const { auth, setAuth } = useContext(AuthContext);
  const [stockistModal, setStockistModal] = useState(false);
  const [editData, setEditData] = useState();
  const [editData1, setEditData1] = useState();
  const [filter, setFilter] = useState(false);

  const [searchTerm, setSearchTerm] = useState(null);
  const [searchTerm1, setSearchTerm1] = useState(null);
  const [searchTermNew, setSearchTermNew] = useState(null);
  const [otpModalData, setOtpModalData] = useState();
  const [otpModal1, setOtpModal1] = useState(false);
  const [bulkStockist, setBulkStockist] = useState(false);
    const [modalData, setModalData] = useState([])
      const [openViewModal, setOpenViewModal] = useState(false)

  const consentOtpResend = async (data) => {
    try {
      setLoading(true);

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const consentOtpSend = async (data) => {
    try {
      setLoading(true);

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const consentOtpSubmit = async (data, otp) => {
    try {
      setLoading(true);

      // const response = await window.Platform.database.resendOTP({ mobile: otpModalData1.mobileNo })
      const response = await window.Platform.database.consentOtpSubmit({
        partyId: data.partyId,
        otp: otp,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (filter && selectedTab === 0) {
  //     onFilterChangeNew(searchTermNew)
  //   }
  // }, [paginationModel.page]);

  useEffect(() => {
    if (filter && selectedTab === 0) {
      onFilterChangeNew(searchTermNew);
    }
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, [selectedTab, paginationModel.page, paginationModel.pageSize,]);

  let contextState = { ...auth };

  const formatTimeStamp = (timestampFromDB) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour clock
    };

    const formattedDate = new Date(timestampFromDB).toLocaleString(
      "en-US",
      options
    );

    return formattedDate;
  };

  const handleOpenViewModal = (id) => {
    console.log(id,"isdsdsdsd")
    setModalData(id)
    setOpenViewModal(true);
  }

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setModalData([]);
  }


  const schoolColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ["contains"].includes(op.value)
    );

    let result = [
        // {
        //   field: "approvalStatus",
        //   headerName: "Approval Status",
        //   width: 150,
        //   filterable: false,

        //   // height: 10,
        //   // headerAlign: 'center',
        //   sortable: false,
        //   // editable: true,
        //   valueGetter: (params) => {
        //     let name =
        //       params.row?.approveStatus === 0
        //         ? "Pending"
        //         : params.row?.approveStatus === 1
        //         ? "Approved"
        //         : "Rejected" || "N/A";
        //     return name;
        //   },
        //   renderCell: (params) => {
        //     let name =
        //       params.row?.approveStatus === 0
        //         ? "Pending"
        //         : params.row?.approveStatus === 1
        //         ? "Approved"
        //         : "Rejected" || "N/A";

        //     return (
        //       <Tooltip title={name || "N/A"}>
        //         <Typography variant="inherit">{name}</Typography>
        //       </Tooltip>
        //     );
        //   },
        // },
        {
          field: "StockistId",
  
          headerName: "Stockist Id",
  
          minWidth: 140,
  
          valueGetter: (params) => {
            let name = params.row?.stockistID;
            return name;
          },
  
          flex: 0.22,
  
          editable: false,
          filterOperators: stringOperators,
          renderCell: (params) => {
            let name = params.row?.stockistID || "N/A";
  
            return (
              <Tooltip title={name || "N/A"}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },
        },
      {
        field: "StockistName",

        headerName: "Name",

        minWidth: 200,

        valueGetter: (params) => {
          let name = params.row?.StockistName;
          return name.split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
          .join(" ");
        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row?.StockistName.split(" ")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
          .join(" ") || "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "name",

        headerName: "Assigned To",

        minWidth: 150,

        valueGetter: (params) => {
          let name = params.row.name;
          return name;
        },

        flex: 0.22,

        editable: false,
        filterOperators: stringOperators,
        renderCell: (params) => {
          let name = params.row.name || "N/A";

          return (
            <Tooltip title={name || "N/A"}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      // {
      //   field: "GSTno",

      //   headerName: "GSTno",

      //   minWidth: 170,

      //   flex: 0.35,

      //   editable: false,
      //   filterable: false,

      //   valueGetter: (params) => {
      //     return params.row.GSTno || "";
      //   },

      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.row.GSTno || "N/A"}>
      //         <Typography variant="inherit">
      //           {params.row.GSTno || "N/A"}
      //         </Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "email",
      //   headerName: "School Email",
      //   minWidth: 250,
      //   flex: 0.35,
      //   editable: false,
      //   filterable: false,

      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.row.email || "N/A"}>
      //         <Typography variant="inherit">{params.row.email}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },

      // {
      //   field: "createdAt",
      //   headerName: "Created At",
      //   width: 250,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   filterable: false,

      //   // editable: true,
      //   renderCell: (params) => {
      //     let name = formatTimeStamp(params.row.createdAt) || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "Website",
      //   headerName: "Stockist Website",
      //   minWidth: 250,
      //   flex: 0.35,
      //   editable: false,
      //   filterable: false,
      //   valueGetter: (params) => {
      //     let name = params.row?.website;
      //     return name;
      //   },
      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.row.website || "N/A"}>
      //         <Typography variant="inherit">{params.row.website}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },
      // {
      //   field: "transporter_name",
      //   headerName: "Transporter Name",
      //   minWidth: 250,
      //   flex: 0.35,
      //   editable: false,
      //   filterable: false,
      //   valueGetter: (params) => {
      //     let name = params.row.transporter_name;
      //     if (name == null || name?.length == 0) name = "N/A";
      //     return name;
      //   },
      //   renderCell: (params) => {
      //     let name = params.row.transporter_name;
      //     if (name == null || name?.length == 0) name = "N/A";
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      // {
      //   field: "onboardForErp",
      //   headerName: "Onboarder For ERP",
      //   minWidth: 200,
      //   flex: 0.35,
      //   editable: false,
      //   filterable: false,
      //   valueGetter: (params) => {
      //     let name = params.row?.onboardForErp;
      //     return name;
      //   },
      //   renderCell: (params) => {
      //     return (
      //       <Tooltip title={params.row.onboardForErp || "N/A"}>
      //         <Typography variant="inherit">{params.row.onboardForErp === 1 ? "Yes" : "No"}</Typography>
      //       </Tooltip>
      //     )
      //   },
      // },

      {
        field: "makerName",
        headerName: "Contact Person Name",
        minWidth: 200,
        flex: 0.35,
        editable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.makerName || "N/A"}>
              <Typography variant="inherit">
                {params.row.makerName || "N/A"}
              </Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "makerContact",
        headerName: "Contact Person Contact",
        minWidth: 160,
        flex: 0.35,
        editable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Tooltip title={params.row.makerContact || "N/A"}>
              <Typography variant="inherit">
                {params.row.makerContact || "N/A"}
              </Typography>
            </Tooltip>
          );
        },
      },

      {
        field: "View",
        headerName: "View",
        minWidth: 130,
        flex: 0.17,
        editable: false,
        renderCell: (params) => {
          return (
            <>
              <Tooltip>
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleOpenViewModal(params.row)}
                    sx={{ minWidth: "80px" }}
                  >
                    {"View"}
                  </Button>
                </span>
              </Tooltip>
              <Modal open={openViewModal} onClose={handleCloseViewModal}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 900,
                    bgcolor: "white",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "90%",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="h6" component="h2" mb={5} style={{ textAlign: "center" }}>
                    Stockist Details
                  </Typography>
                  <div>
                    <div className="row mb-4 mt-2">
                      <div className="col-md-6">
                        <b> Stockist Id :</b> {modalData && modalData?.stockistID || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b>Stockist Name : </b> {modalData && modalData?.StockistName || "N/A"}
                      </div>
                      
                    </div>
                    <div className="row mb-4">
                      
                    <div className="col-md-6">
                        <b> Assigned To : </b>{modalData && modalData?.name || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b>Contact Person Name :</b> {modalData && modalData?.makerName || "N/A"}
                      </div>

                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Contact Person Number :</b> {modalData && modalData?.makerContact || "N/A"}
                      </div>
                      <div className="col-md-6">
                        <b>Contact Person Role :</b> {modalData && modalData?.decisionMakerRole || "N/A"}
                      </div>{console.log(modalData,"modalDatamodalData")}

                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>Email :</b> {modalData && modalData?.email || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b> Pincode :</b> {modalData && modalData?.Pincode || "N/A"}
                      </div>

                    </div>

                    <div className="row mb-4">
                      <div className="col-md-6">
                        <b>State :</b> {modalData && modalData?.State || "N/A"}
                      </div>

                      <div className="col-md-6">
                        <b> District :</b> {modalData && modalData?.District || "N/A"}
                      </div>

                    </div>
                  </div>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={handleCloseViewModal} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </>
          );
        },
      },

      // {
      //   field: "incorporationDate",

      //   headerName: "Incorporation Date",

      //   editable: false,

      //   hide: true,

      //   minWidth: 250,
      //   filterable: false,

      //   valueGetter: (params) => {
      //     let name = formatTimeStamp(params.row.incorporationDate);

      //     return name;
      //   },

      //   renderCell: (params) => {
      //     let name = params.row.incorporationDate
      //       ? formatTimeStamp(params.row.incorporationDate)
      //       : "N/A";
      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "State",

      //   headerName: "State",

      //   editable: false,

      //   hide: true,

      //   minWidth: 150,
      //   filterOperators: stringOperators,
      //   valueGetter: (params) => {
      //     let name = params.row.State;

      //     return name;
      //   },

      //   renderCell: (params) => {
      //     let name = params.row.State || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "District",

      //   headerName: "District",

      //   editable: false,

      //   hide: true,

      //   minWidth: 200,
      //   filterOperators: stringOperators,
      //   valueGetter: (params) => {
      //     let name = params.row.District;

      //     return name;
      //   },

      //   renderCell: (params) => {
      //     let name = params.row.District || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "AddressLine1",

      //   headerName: "Address",

      //   editable: false,

      //   hide: true,

      //   minWidth: 200,
      //   filterable: false,

      //   valueGetter: (params) => {
      //     let name =
      //       params.row?.AddressLine1?.toString() || "N/A"

      //     return name;
      //   },

      //   renderCell: (params) => {
      //     let name =
      //       params.row?.AddressLine1?.toString() || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "Landmark",

      //   headerName: "Landmark",

      //   editable: false,

      //   hide: true,

      //   minWidth: 200,
      //   filterable: false,

      //   valueGetter: (params) => {
      //     let name = params.row.Landmark;

      //     return name;
      //   },

      //   renderCell: (params) => {
      //     let name = params.row.Landmark || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },

      // {
      //   field: "Location",

      //   headerName: "Location",

      //   editable: false,

      //   hide: true,

      //   minWidth: 200,
      //   filterable: false,

      //   valueGetter: (params) => {
      //     let name = params.row.Location;

      //     return name;
      //   },

      //   renderCell: (params) => {
      //     let name = params.row.Location || "N/A";

      //     return (
      //       <Tooltip title={name || "N/A"}>
      //         <Typography variant="inherit">{name}</Typography>
      //       </Tooltip>
      //     );
      //   },
      // },
      {
        field: "Approve Order",
        headerName: "Actions ",
        width: 200,
        // height: 10,
        // headerAlign: 'center',
        sortable: false,
        // editable: true,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          let value = params.row?.stockistID;

          return (
            <Stack direction={"row"} spacing={1}>
              <Tooltip title={"Edit Stockist"}>
                <IconButton onClick={() => handleEdit(params)}>
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Stockist"}>
                <IconButton
                  onClick={(e) => handleDelete(params.row.stockistID, e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Click to approve Stockist"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "#008000" }} onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({ stockistId: value, approvalStatus: "1" });
            }} >
              <ThumbUpIcon />
            </IconButton></Tooltip>


            <Tooltip title="Click to reject Stockist"><IconButton disabled={params.row?.approveStatus === 1 || params.row?.approveStatus === 2} variant="extended" sx={{ color: "red" }} onClick={(e) => {
              e.stopPropagation();
              approveRejectOrder({ stockistId: value, approvalStatus: "2" });
            }} >
              <ThumbDownIcon />
            </IconButton></Tooltip> */}
            </Stack>
          );
        },
      },

      // {
      //   field: "Consent",
      //   headerName: "Consent",
      //   width: 120,
      //   // height: 10,
      //   // headerAlign: 'center',
      //   sortable: false,
      //   // editable: true,
      //   filterable: false,
      //   disableExport: true,
      //   renderCell: (params) => {

      //     let value = params.row?.consent

      //     return (
      //       <Stack direction={"row"} spacing={1}>
      //         <Tooltip title={"Consent Approval"}>

      //           <IconButton disabled={params.row.consent || !params.row.makerContact?.length} onClick={() => {
      //             setConsentData(params.row)
      //             updateConsent(params.row)
      //           }} >
      //             <Send />
      //           </IconButton>

      //         </Tooltip>
      //         {/* <Tooltip title="Click "><IconButton disabled={params.row?.approveStatus===1||params.row?.approveStatus===2} variant="extended" sx={{color:"#008000"}}  onClick={(e) => {
      //               e.stopPropagation();
      //               approveRejectOrder({id:value,approvalStatus:"1"});}} >
      //              <ThumbUpIcon/>
      //             </IconButton></Tooltip> */}
      //         {/* <Tooltip title="Click to reject school"><IconButton disabled={params.row?.approveStatus===1||params.row?.approveStatus===2} variant="extended" sx={{color:"red"}} onClick={(e) => {
      //               e.stopPropagation();
      //               approveRejectOrder({id:value,approvalStatus:"2"});}} >
      //              <ThumbDownIcon/>
      //             </IconButton></Tooltip> */}
      //       </Stack>
      //     )

      //   },
      // },
      // {

      //   field: "Consent Status",

      //   headerName: "Consent Status",

      //   editable: false,

      //   hide: true,

      //   minWidth: 150,
      //   filterable: false,

      //   valueGetter: (params) => {

      //     let name = params.row.consent ? 'true' : 'false';

      //     return name;

      //   },

      //   renderCell: (params) => {

      //     let name = params.row.consent ? 'true' : 'false';

      //     return (

      //       <Tooltip title={name || "N/A"}>

      //         <Typography variant="inherit">{name === 'true' ? 'Active' : 'Inactive'}</Typography>

      //       </Tooltip>

      //     )

      //   },

      // },
    ];
    return result;
  };

  const handleDelete = async (stockistId, event) => {
    event.stopPropagation(); // Prevent row selection on click

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Stockist ?"
    );
    if (!confirmDelete) return;

    try {
      setLoading(true);

      const response = await window.Platform.database.deleteStockist({
        id: stockistId,
      });

      window.NotificationUtils.showSuccess("Stockist deleted successfully");

      fetchEmployees();
    } catch (error) {
      console.error("Error deleting the Stockist:", error);
      window.NotificationUtils.showError("Error deleting the Stockist");
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = (data) => {
    console.log("editData", data);
    setEditStockist(true);
    setEditData(data.row);
  };

  const updateConsent = (data) => {
    console.log("consent", data);
    consentOtpSend(data);
    setOtpVerify(true);
  };

  const onSubmitOtp = async (data, otp) => {
    try {
      setLoading(true);
      let onsubmitOtp = await window.Platform.database.consentOtpSubmit({
        partyId: data.schoolId,
        otp: otp,
      });

      if (onsubmitOtp.status) {
        setLoading(false);
        window.NotificationUtils.showError(onSubmitOtp.message);
      } else {
        setLoading(false);
        window.NotificationUtils.showSuccess("Consent verified successfully");
        setOtpVerify(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      window.NotificationUtils.showError("OTP Submission Failed");
    }
  };

  const handleTabChange = async (value, keepSelected = false) => {
    setVisibleEmployees([]);
    if (!keepSelected) {
      setSelectedMember(null);
    }
    setFilter(false);
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setSelectedTab(value);
  };

  const addNewStockist = () => {
    setStockistModal(true);

    if (isMobile) {
      scrollToPreview();
    }
  };

  const fetchEmployees = async (data) => {
    try {
      setVisibleEmployees([]);
      setLoading(true);
      console.log("authhhh", contextState);
      let results;
      if (selectedTab === 0) {
        results = await window.Platform.database.getStockist({
          pageNumber: paginationModel.page,
          recordPerPage: paginationModel.pageSize,
        });
        setRowCountNew(results.data1);
      }

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));

      setVisibleEmployees(jsonArrayWithId);

      setLoading(false);
      // window.NotificationUtils.showError(results.message)
      if (results.status) {
        window.NotificationUtils.showError(results.message);
      } else {
        // window.NotificationUtils.showSuccess(results.message)
      }
    } catch (error) {
      console.error(error);

      window.NotificationUtils.showError("Error Fetching Data");

      setLoading(false);
    }
  };

  const scrollToPreview = () => {
    window.scrollTo(
      0,
      document.getElementById("detailsPreviewContainer").getBoundingClientRect()
        .top
    );
  };
  const onFilterChangeNew = async (filterModel) => {
    try {
      setLoading(true);
      setFilter(true);
      console.log(filterModel, "filterModel");
      if (filterModel) {
        const response = await window.Platform.database.filterStockist({
          filterField: filterModel.field,
          filterValue: filterModel.value,
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
        });
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setVisibleEmployees(jsonArrayWithId);
        setRowCountNew(response.data1);
        setLoading(true);
      } else {
        const response = await window.Platform.database.filterStockist({
          filterField: "date",
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
        });
        console.log("respponse", response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setVisibleEmployees(jsonArrayWithId);
        setRowCountNew(response.data1);
        setLoading(true);
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton />

        <GridToolbarFilterButton />

        <GridToolbarDensitySelector />

        <GridToolbarExport
          csvOptions={{
            fileName: "users-report",
          }}
        /> */}
      </GridToolbarContainer>
    );
  }

  const renderTabContent = () => {
    if (selectedTab === 0) {
      return (
        <>
        <div className="d-flex justify-content-end align-items-center  py-2">
                                  <Typography variant="body1">Select Page Size:&nbsp; </Typography>
                              <Select
                                value={paginationModel.pageSize}
                                variant="outlined"
                                size="small"
                                onChange={(e)=>{setPaginationModel({...paginationModel,pageSize:e.target.value})}}
                                sx={{ width: 90, fontSize: 14 }}
                              >
                                {[10,20,30,40,50,60].map((role) => (
                                  <MenuItem key={role} value={role}>{role}</MenuItem>
                                ))}
                              </Select>
                                  </div>
       
        <DataGridContainer
          style={{ height: !visibleEmployees?.length ? "200px" : "auto" }}
        >
          <DataGrid
            className="payrollGrid"
            // checkboxSelection={true}
            density="compact"
            rows={visibleEmployees || []}
            columns={schoolColumns()}
            // disableSelectionOnClick

            disableSelectionOnClick
            disableRowSelectionOnClick
            pageSizeOptions={[10]}
            onRowClick={(rowParams, event) => {
              setUserModal(rowParams.row);
              console.log(rowParams.onRowClick);
            }}
            rowHeight={60}
            onFilterModelChange={(val) => {
              console.log("vvvv", val);

              if (val?.items[0]?.value?.length > 0) {
                onFilterChangeNew(val.items[0]);
                setSearchTermNew(val.items[0]);
              } else if (!val.items[0]?.value && startDate && endDate) {
                setSearchTermNew(null);
                onFilterChangeNew();
                console.log("CHECKING...1");
              } else {
                fetchEmployees();
                setFilter(false);
                setSearchTermNew(null);
              }
            }}
            filterMode="server"
            rowCount={rowCountNew}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
        </>
      );
    }
  };

  const approveRejectOrder = async (val) => {
    // console.log(val, "valll")
    const confirmText = val.approvalStatus === "1" ? "approve" : "reject";
    const isConfirmed = window.confirm(
      `Are you sure you want to ${confirmText} this party?`
    );

    if (!isConfirmed) {
      return;
    }

    try {
      setLoading(true);
      const results = await window.Platform.database.approveRejectParty(val);

      if (results.status) {
        window.NotificationUtils.showError(results?.message);
        setLoading(false);
        return;
      }

      if (val.approvalStatus === "1") {
        window.NotificationUtils.showSuccess("Party Approved");
      } else {
        window.NotificationUtils.showSuccess("Party Rejected");
      }

      setLoading(false);
      fetchEmployees();
    } catch (err) {
      console.log(err);
      window.NotificationUtils.showError("Something Went Wrong!");
      setLoading(false);
    }
  };

  const formatDate = async (data) => {
    setMinDate(data);
    setDateRange1(true);
    setEndDate1(null);
    setEndDate(null);
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
    const day = String(datePickerResponse.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate1(formattedDate);
    setStartDate(formattedDate);
    console.log("CHECK!!");
    console.log("CHECKENDDATE", endDate);
  };
  const finalDateRangeFilter = async (data) => {
    try {
      setLoading(true);
      setFilter(true);

      const datePickerResponse = new Date(data);

      const year = datePickerResponse.getFullYear();
      const month = String(datePickerResponse.getMonth() + 1).padStart(2, "0");
      const day = String(datePickerResponse.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      if (data) {
        setEndDate(formattedDate);
        setEndDate1(formattedDate);
      }
      if (searchTerm || searchTerm1 || searchTermNew) {
        if (selectedTab === 0 && searchTermNew) {
          const response = await window.Platform.database.filterStockist({
            filterField: searchTermNew.field,
            filterValue: searchTermNew?.value,
            pageNumber: paginationModel.page,
            startDate: startDate,
            endDate: data ? formattedDate : endDate,
          });
          const jsonArrayWithId = response?.data?.map((obj, index) => ({
            ...obj,
            id: index + 1,
          }));
          setVisibleEmployees(jsonArrayWithId);
          setRowCountNew(response.data1);
        }
      } else {
        if (selectedTab === 0) {
          const response = await window.Platform.database.filterStockist({
            filterField: "date",
            filterValue: searchTermNew?.value,
            pageNumber: paginationModel.page,
            startDate: startDate,
            endDate: data ? formattedDate : endDate,
          });
          const jsonArrayWithId = response?.data?.map((obj, index) => ({
            ...obj,
            id: index + 1,
          }));
          setVisibleEmployees(jsonArrayWithId);
          setRowCountNew(response.data1);
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        "Error While Recieving Data Please Wait and try again"
      );

      fetchEmployees();
    }
  };
  const clearDateFilter = async () => {
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    if (searchTerm) {
      try {
        setLoading(true);
        if (selectedTab === 0) {
          const response = await window.Platform.database.filterStockist({
            filterField: searchTermNew ? searchTermNew.field : "date",
            filterValue: searchTermNew?.value,
            pageNumber: paginationModel.page,
            startDate: "",
            endDate: "",
          });
          const jsonArrayWithId = response?.data?.map((obj, index) => ({
            ...obj,
            id: index + 1,
          }));
          setVisibleEmployees(jsonArrayWithId);
          setRowCountNew(response.data1);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        window.NotificationUtils.showError(
          "Error While Recieving Data Please Wait and try again"
        );
        fetchEmployees();
      }
    } else {
      setFilter(false);
      fetchEmployees();
    }
  };

  const onClose = async (data) => {
    console.log("COMMONOTPMODAL", data, "lllllllllllllllllll");
    try {
      setLoading(true);
      console.log(data, "aayaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
      const response1 = await window.Platform.database.addStockist(data);
      setLoading(false);
      if (response1.status === true) {
        window.NotificationUtils.showError(response1.message);
      }
      // const response = await window.Platform.database.sendOtp({ mobile: data.makerContact })
      // window.NotificationUtils.showSuccess("OTP Sent Successfully");

      // setOtpModalData(data)
      // setOtpModal1(true)
      // setSchoolModal(false)
      // setEditSchool(false)
      fetchEmployees( )
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const resendOTP = async (data) => {
    try {
      setLoading(true);
      const response = await window.Platform.database.resendOTP({
        mobile: otpModalData.makerContact,
      });

      setLoading(false);
      window.NotificationUtils.showSuccess("OTP Sent Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const submitOtp = async (data) => {
    // try {
    //     const response = await window.Platform.database.verifyOtp({ otp: data, mobile: otpModalData.makerContact })
    //     if (response.status === true) {
    //         window.NotificationUtils.showError(response.message);
    //         setLoading(false)
    //         return;
    //     }
    //     setOtpModal1(false)
    //     setLoading(true)
    //     const response1 = await window.Platform.database.addStockist({ ...otpModalData })
    //     if (response1.status === true) {
    //         window.NotificationUtils.showError(response1.message);
    //         setLoading(false)
    //         return;
    //     }
    //     setSchoolModal(false)
    //     setLoading(false)
    //     fetchEmployees()
    //     window.NotificationUtils.showSuccess("Party Added Successfully");
    // }
    // catch (error) {
    //     console.log(error)
    //     setLoading(false)
    // }
  };

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
        <div style={{ width: "60%", display: "flex", flexDirection: "row", gap: "10px" }}>
        
        </div>

        <Grid item xs={12} md={8} textAlign="right">
                <Stack spacing={1} direction={"row"}>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      selectedTab === 0 ? () => setBulkStockist(true) : ""
                    }
                    sx={{ mr: 1, borderRadius: "10px" }}
                  >
                    <Add sx={{ mr: 1 }} />
                    {selectedTab === 0 ? "Bulk Add Stockist" : ""}
                  </Button> */}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={selectedTab === 0 ? addNewStockist : ""}
                    sx={{ mr: 1, borderRadius: "10px" }}
                  >
                    <Add sx={{ mr: 1 }} />
                    {selectedTab === 0 ? "Add Stockist" : ""}
                  </Button>
                </Stack>
              </Grid>
              
          {/* <Box
            sx={{
              p: 3,
              borderRadius: 2,
              bgcolor: "white",
              boxShadow: 2,
              width: "100%",
            }}
          > */}
            {/* <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Assigned To:</Typography>
                <Select fullWidth size="small">
                  <MenuItem value="">All</MenuItem>
                  {["1st", "2nd", "3rd"].map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="body1">Select State:</Typography>
                <Select fullWidth size="small">
                  <MenuItem value="">All</MenuItem>
                  {["1st", "2nd", "3rd"].map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="body1">Select District:</Typography>
                <Select fullWidth size="small">
                  <MenuItem value="">All</MenuItem>
                  {["1st", "2nd", "3rd"].map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid> */}
{/* 
            <Grid container spacing={2} alignItems="center" mt={2}>
              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate1}
                    disabled={loading}
                    format="YYYY/MM/DD"
                    onChange={(data) => formatDate(data)}
                    disableFuture={true}
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    minDate={minDate}
                    value={endDate1}
                    disabled={!dateRange1 || loading}
                    format="YYYY/MM/DD"
                    onChange={(data) => finalDateRangeFilter(data.$d)}
                    disableFuture={true}
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <Button
                  variant="text"
                  onClick={clearDateFilter}
                  disabled={!startDate1 || loading}
                >
                  Clear
                </Button>
              </Grid>
            </Grid> */}

            {/* <Grid container spacing={2} alignItems="center" mt={2}> */}
              {/* <Grid item xs={12} md={4}> */}

                {/* <Tooltip title="Search By Name , GST No. , Transporter , Address ">
                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        size="small"
                        placeholder="Search By Name , GST No. , Transporter , Address "
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search color="action" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Tooltip> */}
              {/* </Grid> */}

              
            {/* </Grid> */}
          {/* </Box> */}
        </HeaderContainer>

        <TableContainer>
          <TabsContainer>
            {loading && <OpaqueLoading />}

            {renderTabContent()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>

      {otpVerify && (
        <CommonOtpModalConsent
          data={consentData}
          closeModal={() => setOtpVerify(false)}
          onClickSubmit={onSubmitOtp}
          resendOTP={consentOtpResend}
        />
      )}

      {stockistModal && (
        <StockistModal
          onClose1={(data) => onClose(data)}
          onClose={() => setStockistModal(false)}
          fetchData={() => fetchEmployees()}
          status={true}
        />
      )}

      {editStockist && (
        <StockistModal
          onClose={() => setEditStockist(false)}
          fetchData={() => fetchEmployees()}
          dataProp={editData}
          status={false}
        />
      )}

      {otpModal1 && (
        <CommonOtpModal
          closeModal={() => setOtpModal1(false)}
          onClickSubmit={(data) => submitOtp(data)}
          resendOTP={() => resendOTP()}
        />
      )}

      {bulkStockist && (
        <BulkAddStockist
          onClose={() => setBulkStockist(false)}
          fetchData={() => fetchEmployees()}
        />
      )}
    </>
  );
};

export default StockistManagement;
