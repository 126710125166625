import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CloseOutlined } from "@mui/icons-material";

import {
    Dialog,
    DialogContent,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography,
    IconButton,
    Button,
    Tooltip,
  } from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkAddSchool = ({onClose,fetchData}) => {
  const [loading, setLoading] = useState(false);
 const [failedEntries, setFailedEntries] = useState([]);
  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const [assignedToData, setAssignTodata] = useState([])
  const [boardData, setBoardData] = useState([])
  const [mediumData, setMediumData] = useState([])
  const [gradeData, setGradeData] = useState([])
  const [contactPersonRole, setContactPersonRole] = useState([])
  const [schoolCategories, setSchoolCategories] = useState([])
  const [distributor, setDistributor] = useState([])
  const hiddenFileInput = useRef(null);
    const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [uploadStats, setUploadStats] = useState(null); // Store analytics
  const [selectedFile, setSelectedFile] = useState(null); // Track selected file
  const [selectedValueDistributor, setSelectedValueDistributor] = useState('');
useEffect(()=>{
  fetchEmployees()
  getBoard()
  getMedium()
  getContactPersonRole()
  getGrade()
  fetchDistributors()
  // getSchoolCategories();
},[])


const fetchEmployees=async()=>{
  try{
  setLoading(true)
 const results = await window.Platform.database.getUsersForWork();
 setAssignTodata(results?.data)
 console.log(results.data,"Assign To data ")
}
catch(err){
  console.log(err)
}
}
const getBoard=async(data)=>{
  let results = await window.Platform.database.getBoard();
  setBoardData(results.data)
}
const getMedium=async(data)=>{
  let results = await window.Platform.database.getMedium();
  setMediumData(results.data)
}

const getGrade=async(data)=>{
  try{
    setLoading(true)
    const results = await window.Platform.database.getGrade();
    setGradeData(results.data)
    setLoading(false)

  }
  catch(err){
    console.log(err)
    setLoading(false)

  }
}

const getContactPersonRole=async(data)=>{
  try{
    setLoading(true)
    const results = await window.Platform.database.getContactPersonRole();
    setContactPersonRole(results.data)
    setLoading(false)

  }
  catch(err){
    console.log(err)
    setLoading(false)

  }
}


const fetchDistributors=async()=>{
  try{
    setLoading(true)
  const results = await window.Platform.database.getDistributor();
  setDistributor(results.data)
 
  setLoading(false)

}catch(err){
  setLoading(false)

  console.log(err)
}

}





    const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const newBoardData = boardData?.map((ele, ind)=>{
    const tosend =ele.boardName
    return tosend
  })

  const newMediumData = mediumData?.map((ele, ind)=>{
    const tosend =ele.mediumName
    return tosend
  })

  const newContactPersonRole = contactPersonRole?.map((ele, ind)=>{
    const tosend =ele.roleName
    return tosend
  })

  const newGradeData = gradeData?.map((ele, ind)=>{
  const tosend =`${ele.gradeName}`
    return tosend
  })


  const handleDownload = async ( ) => {
    try
   {
    const headers =['RM Name', 'schoolName', "parentSchoolName", "board", "medium", "decisionMakerName", "decisionMakerContact", "decisionMakerRole", "email", "website", "grade", "strength", "gstNo", "registrationNo", "affiliation/pan No", "bankName", "accountHolderName", "accountNo" , "ifscCode", "pincode","addressLine1", "distributorName","party_id", "clusterName"]
    
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
   
    worksheet.addRow(headers);
   
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'schoolAdd.xlsx';
      a.click();
      URL.revokeObjectURL(url);
      setLoading(false)
    });
  }
  catch (error) {
    console.error(error);
    setLoading(false)
  }

  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setSelectedFile(file); // Store the selected file
    setFileKey((prevKey) => prevKey + 1);

    console.log(file,"Selected File:", file.name, file.type, file.size);
};


const handleUpload = async () => {
  if (!selectedFile) {
      window.NotificationUtils.showError("No file selected for upload.");
      return;
  }

  setLoading(true);
  try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      console.log("Uploading File:", selectedFile.name);

    
      let response = await window.Platform.database.addSchoolBulk11(formData);

      if (!response) {
          throw new Error("No response from server");
      }

      console.log("Upload Success:", response);

      
      const successCount = response.data.filter(entry => entry.status === "Success").length;
      const failureEntries = response.data.filter(entry => entry.status === "Failure");
      setUploadStats({ successCount, failureCount: failureEntries.length, total: response.data.length });
     
      
       setFailedEntries(failureEntries);
      window.NotificationUtils.showSuccess("File uploaded successfully!");

      setSelectedFile(null);
  } catch (error) {
      console.error("Error uploading file:", error);
      window.NotificationUtils.showError("Error uploading file!");
  } finally {
      setLoading(false);
  }
};




  const handleDownloadErrorReport = async () => {
    if (failedEntries.length === 0) return;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Error Report');
    const headers = Object.keys(failedEntries[0]); 
    worksheet.addRow(headers); 
    failedEntries.forEach(entry => {
        worksheet.addRow(headers.map(header => entry[header]));
    });

    
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "School_Error_Report.xlsx";
    a.click();
    URL.revokeObjectURL(url);
};




    const handleImport = (file) => {
      try {
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
  
        const workbook = XLSX.read(data, { type: "array" });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formattedData = jsonData.slice(1).map((row, index) => ({
          assignedTo : selectedValue,
          // schoolId:selectedValueDistributor,
            schoolName: row[0],
            parentSchoolName: row[1],
            board: row[2],
            medium: row[3],
            makerName: row[4],
            makerContact: row[5]?.toString(),
            makerRole:row[6],
            email: row[7],
            website: row[8],
            grade: row[9],
            strength: row[10],
            // schoolId:row[11],
            gstNo:row[11],
            registrationNo:row[12],
            affiliationPan:row[13],
            bankName: row[14],
            holderName:row[15],
            accountNo:row[16]?.toString(),
            ifsc:row[17],
            branchName:row[18],
            pincode:row[19]?.toString(),
            addressLine1:row[20],
            distributorName:row[21],
            party_id:row[22],
            clusterName:row[23],
          id: index + 1,
        }));
          console.log("formattedData",formattedData)
        setTargetRows(formattedData)
        setLoading(false);
  
      };
      reader.readAsArrayBuffer(file);
    }}
    catch (error) {
      console.error(error);
      window.NotificationUtils.showError("Error retreiving targets data");
      setLoading(false);
    }
  };

 
const handleClose=()=>{
    onClose()
    fetchData()
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add School Bulk</Typography>
          <IconButton disabled={loading} onClick={()=>handleClose()}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        <InputFieldContainer>
    <Button
        variant="contained"
        sx={{
            background: "primary",
            borderColor: "#003974",
            height: "50px",
            padding: "5px 10px",
            marginRight: "20px",
        }}
        onClick={handleDownload}
    >
        Download Template
    </Button>

    <input
        key={fileKey}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        ref={fileInputRef}
    />

    <Button
        variant="contained"
        sx={{
            background: "primary",
            borderColor: "#003974",
            height: "50px",
            padding: "5px 10px",
            marginRight: "20px",
        }}
        onClick={handleButtonClick}
    >
        Select File
    </Button>

    {selectedFile && (
        <Button
            variant="contained"
            color="success"
            sx={{
                height: "50px",
                padding: "5px 10px",
                marginRight: "20px",
            }}
            onClick={handleUpload}
            disabled={loading}
        >
            {loading ? "Uploading..." : "Upload"}
        </Button>
    )}
</InputFieldContainer>

{uploadStats && (
    <div style={{
        marginTop: "20px",
        padding: "15px",
        borderRadius: "10px",
        background: "#f8f9fa",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center"
    }}>
        <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
            Upload Summary
        </Typography>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <div style={{ textAlign: "center" }}>
                <Typography variant="h6" color="green">{uploadStats.successCount}</Typography>
                <Typography variant="body2">Successful Entries</Typography>
            </div>
            <div style={{ textAlign: "center" }}>
                <Typography variant="h6" color="red">{uploadStats.failureCount}</Typography>
                <Typography variant="body2">Failed Entries</Typography>
            </div>
            <div style={{ textAlign: "center" }}>
                <Typography variant="h6" color="blue">{uploadStats.total}</Typography>
                <Typography variant="body2">Total Entries</Typography>
            </div>
        </div>

      
        <div style={{ marginTop: "15px", width: "100%", height: "10px", background: "#e0e0e0", borderRadius: "5px" }}>
            <div style={{
                width: `${(uploadStats.successCount / uploadStats.total) * 100}%`,
                height: "100%",
                background: "green",
                borderRadius: "5px"
            }}></div>
        </div>

          {failedEntries.length > 0 && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDownloadErrorReport}
                        style={{ marginTop: "15px" }}
                    >
                        Download Error Report
                    </Button>
                )}

        
    </div>
)}


      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkAddSchool;

