import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { CloseOutlined } from "@mui/icons-material";

import {
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
// import { BULK_UPLOAD_FAILURE_CODES } from "../../constants";
import OpaqueLoading from "../../components/opaqueLoading/opaqueLoading";

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 400px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  label{
    margin-bottom:0px;
  }
`;


const BulkProductAdd = ({ onClose, fetchData }) => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const [targetRows, setTargetRows] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [uploaded, setUploaded] = useState(false);

  const hiddenFileInput = useRef(null);
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const fileInputRef = useRef(null);
  const [failedData, setFailedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fileKey, setFileKey] = useState(0);
  const [display, setDisplay] = useState(false);
  const [isloader, setIsloader] = React.useState(false);
  const [dataId, setDataId] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
   const [selectedValue, setSelectedValue] = useState('');
const [seriesData, setSeriesData] = useState([])
 const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStats, setUploadStats] = useState(null);
  const [failedEntries, setFailedEntries] = useState([]); 

  const getRowClassName = (params) => {
    let uploadStatus = params.row.status || "";
    if (uploadStatus === "Success") {
      return "successRow";
    } else if (uploadStatus === "Failure") {
      return "failureRow";
    } else {
      return "";
    }
  };
  useEffect(()=>{
    fetchSeriesData()
  },[])
  
  console.log(selectedValue,"selectedValueselectedValue");
  const fetchSeriesData=async()=>{
    setLoading(true)
    try{
   const results = await window.Platform.database.getSeriesCategory();
   setSeriesData(results?.data)
   setLoading(false)
  }
  catch(err){
    setLoading(false)
    console.log(err)
  }
  }


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: 'bulk-upload-Supplier',
          }}
        />
      </GridToolbarContainer>
    );
  }

   const handleDownloadErrorReport = async () => {
      if (failedEntries.length === 0) return;
  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Error Report');
      const headers = Object.keys(failedEntries[0]); 
      worksheet.addRow(headers); 
      failedEntries.forEach(entry => {
          worksheet.addRow(headers.map(header => entry[header]));
      });
  
      
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Product_Error_Report.xlsx";
      a.click();
      URL.revokeObjectURL(url);
  };
  
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setSelectedFile(file); // Store the selected file
    setFileKey((prevKey) => prevKey + 1);

    console.log(file,"Selected File:", file.name, file.type, file.size);
};


const handleUpload = async () => {
  if (!selectedFile) {
      window.NotificationUtils.showError("No file selected for upload.");
      return;
  }

  setLoading(true);
  try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      console.log("Uploading File11:", selectedFile.name);

    
      let response = await window.Platform.database.productByExcel(formData);
      // let response = await window.Platform.database.addProductBulk(formData);

      if (!response) {
          throw new Error("No response from server");
      }

      console.log("Upload Success:", response);

      
      const successCount = response?.results?.filter(entry => entry?.status === "Success").length;
      const failureEntries = response?.results?.filter(entry => entry?.status === "Failure");
      setUploadStats({ successCount, failureCount: failureEntries?.length, total: response?.results?.length });
     
      
       setFailedEntries(failureEntries);
      window.NotificationUtils.showSuccess("File uploaded successfully!");

      setSelectedFile(null);
  } catch (error) {
      console.error("Error uploading file:", error);
      window.NotificationUtils.showError("Error uploading file!");
  } finally {
      setLoading(false);
  }
};



  const getGridColumns = () => {
    let columns = [
      {
        field: "id",
        headerName: "S.no",
        minWidth: 100,
        valueGetter: (params) => {
          let name = params.row.id || "";
          return name;
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          let name = params.row.id || "";
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>{console.log(params.row,"lllljjj")}
            </Tooltip>
          );
        },
      },
      {
        field: "SeriesName",
        headerName: "Series Name",
        minWidth: 150,
        valueGetter: (params) => {
          let name = params.row.SeriesName
          return name
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.SeriesName
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "ProductName: ",
        headerName: "Product Name ",
        minWidth: 150,
        valueGetter: (params) => {
          let name = params.row.ProductName
          return name
        },
        flex: 0.22,
        editable: false,
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.ProductName 
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "UnitPrice",
        headerName: "UnitPrice",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.UnitPrice;
          return name;
        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.UnitPrice
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );
        }
      },
      {
        field: "Board",
        headerName: "Board",
        editable: false,
        hide: true,
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Board
          return name;


        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.Board
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );

        },
      },
      {
        field: "Medium",
        headerName: "Medium",
        editable: false,
        hide: true,
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Medium
          return name;


        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.Medium
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );

        },
      },
      {
        field: "Class",
        headerName: "Class",
        editable: false,
        hide: true,
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Class
          return name;


        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.Class
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );

        },
      },
      {
        field: "Subject",
        headerName: "Subject",
        editable: false,
        hide: true,
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Subject
          return name;


        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.Subject
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );

        },
      },
      {
        field: "skuCode",
        headerName: "skuCode",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.skuCode;
          return name;
        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.skuCode
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );

        },
      },
      {
        field: "Type",
        headerName: "Type",
        minWidth: 200,
        valueGetter: (params) => {
          let name = params.row.Type;
          return name;
        },
        renderCell: (params) => {
          // console.log("RESPONSE",params)
          let name = params.row.Type
          return (
            <Tooltip title={name}>
              <Typography variant="inherit">{name}</Typography>
            </Tooltip>
          );

        },
      },

    ];
    if (uploaded) {
      columns.splice(
        0,
        0,
        {
          field: "status",
          headerName: "Upload Status",
          width: 100,
          renderCell: (params) => {
            // console.log("PARAMSINDATA",params)
            let name = "";
            if (params.row.status) {
              name =
                params.row.status === "Success" ? "Success" : "Failure";
            }
            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },
          editable: false,
        },
        {
          field: "reason",
          headerName: "Reason",
          width: 200,
          renderCell: (params) => {
            let name = params.row.reason || "";
            return (
              <Tooltip title={name}>
                <Typography variant="inherit">{name}</Typography>
              </Tooltip>
            );
          },
          editable: false,
        }
      );
    }
    return columns;
  }

  const getFileRows = async (file) => {
    try {
      let data = await file.arrayBuffer();
      const wb = XLSX.read(data, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let dataParse = XLSX.utils.sheet_to_json(ws);
      if (dataParse.length > 0) {
        dataParse = dataParse.map((entry, index) => {
          return {
            ...entry,
            id: index,
          };
        });
      }
      let element = document.getElementById("fileUploadPicker");
      if (element) {
        element.value = "";
      }
      return dataParse;
    } catch (error) {
      throw error;
    }
  };

  // const handleUpload = async () => {
  //   try {
  //     let uploadInput = document.getElementById("filePickerButton");
  //     if (uploadInput) {
  //       if (uploadInput.files.length > 0) {
  //         let file = uploadInput.files[0];
  //         setLoading(true);
  //         let response = await getFileRows(file);
  //         // console.log("RESPONSE",response)
  //         if (response.length > 0) {
  //           setTargetRows(response);
  //           setUploaded(false);
  //         }
  //         setLoading(false);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     window.NotificationUtils.showError("Error retreiving targets data");
  //     setLoading(false);
  //   }
  // };

  const hasErrors = () => {
    try {
      if (!targetRows) {
        window.NotificationUtils.showError(
          "Please select a valid file for upload"
        );
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const dataRowCheck = (rowDetails) => {
    try {
      let result = false;
      if (!rowDetails.employeeCode) {
        // result = BULK_UPLOAD_FAILURE_CODES.MISSING_EMPLOYEE_CODE;
      }
      return result;
    } catch (error) {
      throw error;
    }
  };

  const handleRowSelection = (selection) => {
    // console.log("SELECT",selection)
    // console.log("selection",rows)
    let array = []
    let data = targetRows.filter((row) => selection.includes(row.id));
    let finalData = data.map((row) => {
      console.log('first', row)
      let sent = {
        SeriesName: row.SeriesName, ProductName: row.ProductName, UnitPrice: row.UnitPrice, Board: row.Board, Medium: row.Medium, Class: row.Class, Subject: row.Subject, skuCode: row.skuCode, Type:row.Type
      }
      array.push(sent)

    })

    // console.log("selection1",finalData)
    // setSelectedRows(finalData);
    return array;
  };

  // const onClickUpload = async () => {
  //   try {
  //     const data = handleRowSelection(selectedRowIds)
  //     console.log("SELECTED ROWS", data)
  //     let finalData = [...targetRows]
  //     console.log("SELECTED ROWS", selectedRowIds);
  //     console.log("SELECTED ROWS FINALLY", finalData)
  //     setLoading(true);
  //     let response = await window.Platform.database.addProductBulk(data);
  //     // console.log("SUCCESS",response)
  //     setTargetRows(response.data)

  //     // let newTargetRows = [...newRows];
  //     // console.log("FINAL",finalData)
  //     setSelectedRowIds([])
  //     for (let responseEntry of response.data) {
  //       let rowIndex = finalData.findIndex(row => row.id === responseEntry.id);
  //       // console.log("ROWINDEX",rowIndex)
  //       if (rowIndex !== -1) {
  //         finalData[rowIndex].status = responseEntry.status;
  //         finalData[rowIndex].reason = 'N/A';
  //         // console.log("CHECK!")
  //         if (responseEntry.status === 'Failure') {
  //           finalData[rowIndex].reason = responseEntry.reason;
  //         }
  //       }
  //     }
  //     setTargetRows(finalData);
  //     if (!uploaded) {
  //       setUploaded(true);
  //     }
  //     setLoading(false);
  //     window.NotificationUtils.showSuccess('Submition Complete')

  //     // window.NotificationUtils.showSuccess("Details Uploaded Please verify your upload");
  //     fetchData();
  //   } catch (error) {
  //     console.error(error);
  //     window.NotificationUtils.showError(error?.message);
  //     setLoading(false);
  //   }
  // };

  const handleSeriesChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleDownload = async () => {
    try {

      setLoading(true)
      // const worksheet = XLSX.utils.json_to_sheet(jsonData);
      // const workbook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      // XLSX.writeFile(workbook, `${fileName}.xlsx`);
      const selectedColumns = ['SeriesName', 'ProductName', 'UnitPrice', 'Board', 'Medium', 'Class', 'Subject', "skuCode", "Type"]

     const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.addRow(selectedColumns);
      // console.log("hello",allData)
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'productBulk.xlsx';
        a.click();
        URL.revokeObjectURL(url);
        setLoading(false)
      });
    }


    catch (error) {
      console.error(error);
      setLoading(false)
    }

  };

  const renderModalContent = () => {
    return (
      <>
        {targetRows.length > 0 && (
          <>
            <DataGridContainer>
              <DataGrid
                disableSelectionOnClick={true}
                checkboxSelection={true}
                rows={targetRows}
                columns={getGridColumns()}
                // onRowSelectionModelChange={handleRowSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowIds(newRowSelectionModel);
                }}

                rowSelectionModel={selectedRowIds}
                density="compact"
                autoPageSize
                getRowClassName={getRowClassName}
                components={{
                  Toolbar: CustomToolbar,
                }}
                pageSizeOptions={[10]}
              />
            </DataGridContainer>
            <NoteText variant="body2">
              Note: If selected, only the selected rows will be uploaded
            </NoteText>
          </>
        )}
        <InputFieldContainer>
        {/* <FormControl sx={{ width: "25%" }} variant="outlined" disabled={loading}>
                <InputLabel  id="Series-label">Select Series</InputLabel>
                <Select labelId="Series-label" label={'Select Series'} onChange={handleSeriesChange}>
                  {seriesData && seriesData.length > 0 ? (
                    seriesData.map((ele, ind) => (
                      <MenuItem key={ind} value={`${ele.seriesName}/${ele.seriesTableId}`}>
                        {`${ele.seriesName}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>{loading ? "Loading..." : "No option"}</MenuItem>
                  )}
                </Select>
              </FormControl> */}

          {/* <a target="_blank" href="/assets/productAdd.xlsx" download> */}
          <Button variant="contained"  sx={{
                      background: "primary",
                      borderColor: "#003974",
                      height:"50px",
                      // borderRadius: "40px",
                      padding: "5px 10px",
                      marginRight: "20px",
                    }} color="primary" onClick={handleDownload}>Download Template</Button>
          {/* </a> */}
          {/* <label htmlFor="filePickerButton">
            <Input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              id="filePickerButton"
              type="file"
              ref={hiddenFileInput}
              onChange={handleUpload}
            />
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              component="span"
            > */}
          <input
            key={fileKey}
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <Button
            // style={{ color: '#003974', borderColor: '#003974' }}
            variant="contained"
            sx={{
              background: "primary",
              borderColor: "#003974",
              // borderRadius: "40px",
              height:"50px",
              padding: "5px 10px",
              marginRight: "20px",
            }}
            // startIcon={<Iconify icon="eva:attach-outline" />}
            onClick={handleButtonClick}
          // disabled={disableButtons}
          >
            Select File
          </Button>
           {selectedFile && (
                  <Button
                      variant="contained"
                      color="success"
                      sx={{
                          height: "50px",
                          padding: "5px 10px",
                          marginRight: "20px",
                      }}
                      onClick={handleUpload}
                      disabled={loading}
                  >
                      {loading ? "Uploading..." : "Upload"}
                  </Button>
              )}
          {/* Select File
            </Button>
          </label> */}
        </InputFieldContainer>
        {/* <ModalActionsContainer>
        
          <div>

          </div>
          <Button
            disabled={loading || selectedRowIds.length === 0}
            onClick={handleUpload}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </ModalActionsContainer> */}
        {uploadStats && (
            <div style={{
                marginTop: "20px",
                padding: "15px",
                borderRadius: "10px",
                background: "#f8f9fa",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                textAlign: "center"
            }}>
                <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Upload Summary
                </Typography>
                <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                        <Typography variant="h6" color="green">{uploadStats.successCount}</Typography>
                        <Typography variant="body2">Successful Entries</Typography>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Typography variant="h6" color="red">{uploadStats.failureCount}</Typography>
                        <Typography variant="body2">Failed Entries</Typography>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <Typography variant="h6" color="blue">{uploadStats.total}</Typography>
                        <Typography variant="body2">Total Entries</Typography>
                    </div>
                </div>
        
              
                <div style={{ marginTop: "15px", width: "100%", height: "10px", background: "#e0e0e0", borderRadius: "5px" }}>
                    <div style={{
                        width: `${(uploadStats.successCount / uploadStats.total) * 100}%`,
                        height: "100%",
                        background: "green",
                        borderRadius: "5px"
                    }}></div>
                </div>
        
                  {failedEntries.length > 0 && (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleDownloadErrorReport}
                                style={{ marginTop: "15px" }}
                            >
                                Download Error Report
                            </Button>
                        )}
        
                
            </div>
        )}
      </>
    );
  };

  const handleClose = () => {
    onClose()
    fetchData()
  }

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth="md"
      fullWidth={true}
      open={showModal}
    >
      <StyledDialogContent>
        <ModalHeaderContainer>
          <Typography variant="h5">Add Product Bulk</Typography>
          <IconButton disabled={loading} onClick={() => handleClose()}>
            <CloseOutlined />
          </IconButton>
        </ModalHeaderContainer>
        {loading && <OpaqueLoading />}
        {renderModalContent()}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default BulkProductAdd;

